import { ENetUC_Common } from "../../asn1/EUCSrv/stubs/types";
import {
	ELogLevel,
	IFilterConfig,
	IFilterConfigs,
	ILogCallback,
	ILogData,
	ILogEntryCallback,
	ILogEntryWithFilter,
	ILogger,
	ILogMessage,
	IProcessLogMessage,
	LogLevels
} from "./ILogger";
import { LogMessage } from "./LogMessage";
import { hasToJSON, isAsnRequestError, isError } from "./typeGuards";
import { AsnChatEvent } from "../../asn1/EUCSrv/stubs/ENetUC_ChatV2";
import { AsnJournalEntryChangedListArgument } from "../../asn1/EUCSrv/stubs/ENetUC_Journal";
import { cloneDeep } from "lodash";
import { AsnConfigSTUNandTURN } from "../../asn1/EUCSrv/stubs/ENetUC_Common_SIPCTI";

/**
  The logger for the client side
 */
export class Logger implements ILogger {
	// Instance of this class to use as singleton.
	private static instance: Logger;
	private _log?: ILogMessage[];
	private logEntryCallbacks = new Map<ILogEntryCallback, ILogEntryWithFilter>();
	private consoleFilterConfig?: IFilterConfigs;
	private logLevel: ELogLevel;
	// Access to the console for logging
	private con: Console = console;

	/**
	 * Constructs Logger.
	 * Method is private as we follow the Singleton Approach using getInstance
	 */
	private constructor() {
		this.logLevel = ELogLevel.DEBUG;
	}

	/**
	 * Gets instance of Logger to use as singleton.
	 *
	 * @returns - an instance of this class.
	 */
	public static getInstance(): Logger {
		if (!Logger.instance)
			Logger.instance = new Logger();
		return Logger.instance;
	}

	/**
	 * Initializes the logger
	 *
	 * @param logLevel - The loglevel to set initially, otherwise the level is gDefaultLogLevel
	 * @param consoleConfig - Configuration with filters what to log
	 * @param bStoreLog - true if the logger shall store log entries in the _log variable or false if not
	 */
	public init(logLevel: ELogLevel | undefined, consoleConfig: IFilterConfigs | undefined, bStoreLog: boolean): void {
		if (logLevel)
			this.logLevel = logLevel;
		if (consoleConfig)
			this.consoleFilterConfig = consoleConfig;
		if (bStoreLog)
			this._log = [];
	}

	/**
	 * Uninitializes the logger
	 */
	public exit(): void {
	}

	/**
	 * Enables or disables storing logs in the logger
	 *
	 * @param store - enables or disables the log storing
	 */
	public storeLogs(store: boolean): void {
		if (store && this._log === undefined)
			this._log = [];
		else if (!store && this._log)
			this._log = undefined;
	}

	/**
	 * To change the loglevel
	 *
	 * @param logLevel - the new log level to set
	 */
	public setLogLevel(logLevel: ELogLevel): void {
		this.logLevel = logLevel;
	}

	/**
	 * Returns the collected log messages e.g. to provide them in a downloadable file on a user interaction
	 *
	 * @returns - an array of log messages
	 */
	public get log(): ILogMessage[] {
		return this._log || [];
	}

	/**
	 * Clears the internal log cache (_log)
	 */
	public clearLog(): void {
		if (this._log)
			this._log = [];
	}

	/**
	 * Adds a callback that is called whenever a log message is processed.
	 * This allows the frontend to handle certain log events coming from parts the frontend does not directly handle
	 *
	 * @param callback - a Class/Object implementing the ILogEntryCallback
	 * @param filter - an optional Filter object that defines for which log calls the callback wants to get informed
	 */
	public addLogEntryCallback(callback: ILogEntryCallback, filter: IFilterConfigs | undefined): void {
		if (!this.logEntryCallbacks.has(callback)) {
			this.logEntryCallbacks.set(callback, {
				callback,
				filter
			});
		}
	}

	/**
	 * Removes a callback from the callback entries
	 *
	 * @param callback - a class/object implementing the ILogEntryCallback
	 */
	public removeLogEntryCallback(callback: ILogEntryCallback): void {
		if (this.logEntryCallbacks.has(callback))
			this.logEntryCallbacks.delete(callback);
	}

	/**
	 * Add a debug log message to the logger
	 *
	 * @param msg - the message for the log entry, do NOT add contextual data into this message, use the meta data for it
	 * @param calling_method - name of the caller
	 * @param logdata_or_callback - either a class/object implementing the ILogCallback or directly ILogData.
	 * If you are calling from a class component hand over *this* and implement the callback that returns an ILogData object.
	 * The callback fills the classname as well as contextual data you want to have in every log entry (e.g. sessionids)
	 * @param meta - Meta data you want to have logged with the message (arguments, results, intermediate data, anything that might be usefull later)
	 * @param error - In case of an exception pass it here.
	 */
	public debug(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, error?: unknown): void {
		if (this.logLevel >= ELogLevel.DEBUG)
			this.writeLog("debug", msg, calling_method, logdata_or_callback, meta, error);
	}

	/**
	 * Add a info log message to the logger
	 *
	 * @param msg - the message for the log entry, do NOT add contextual data into this message, use the meta data for it
	 * @param calling_method - name of the caller
	 * @param logdata_or_callback - either a class/object implementing the ILogCallback or directly ILogData.
	 * If you are calling from a class component hand over *this* and implement the callback that returns an ILogData object.
	 * The callback fills the classname as well as contextual data you want to have in every log entry (e.g. sessionids)
	 * @param meta - Meta data you want to have logged with the message (arguments, results, intermediate data, anything that might be usefull later)
	 * @param error - In case of an exception pass it here.
	 */
	public info(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, error?: unknown): void {
		if (this.logLevel >= ELogLevel.INFO)
			this.writeLog("info", msg, calling_method, logdata_or_callback, meta, error);
	}

	/**
	 * Add a warn log message to the logger
	 *
	 * @param msg - the message for the log entry, do NOT add contextual data into this message, use the meta data for it
	 * @param calling_method - name of the caller
	 * @param logdata_or_callback - either a class/object implementing the ILogCallback or directly ILogData.
	 * If you are calling from a class component hand over *this* and implement the callback that returns an ILogData object.
	 * The callback fills the classname as well as contextual data you want to have in every log entry (e.g. sessionids)
	 * @param meta - Meta data you want to have logged with the message (arguments, results, intermediate data, anything that might be usefull later)
	 * @param error - In case of an exception pass it here.
	 */
	public warn(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, error?: unknown): void {
		if (this.logLevel >= ELogLevel.WARN)
			this.writeLog("warn", msg, calling_method, logdata_or_callback, meta, error);
	}

	/**
	 * Add an error log message to the logger
	 *
	 * @param msg - the message for the log entry, do NOT add contextual data into this message, use the meta data for it
	 * @param calling_method - name of the caller
	 * @param logdata_or_callback - either a class/object implementing the ILogCallback or directly ILogData.
	 * If you are calling from a class component hand over *this* and implement the callback that returns an ILogData object.
	 * The callback fills the classname as well as contextual data you want to have in every log entry (e.g. sessionids)
	 * @param meta - Meta data you want to have logged with the message (arguments, results, intermediate data, anything that might be usefull later)
	 * @param error - In case of an exception pass it here.
	 */
	public error(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, error?: unknown): void {
		if (this.logLevel >= ELogLevel.ERROR) {
			if (!error)
				error = this.removeStackTrace(new Error(msg));

			this.writeLog("error", msg, calling_method, logdata_or_callback, meta, error);
		}
	}

	/**
	 * Logs to the console. Strings are only printed if configured in the settings
	 *
	 * @param data - data to log
	 * @param optionalParams - Additional arguments
	 */
	public console_log(data: unknown, ...optionalParams: any[]): void {
		this.con.log(data, ...optionalParams);
	}

	/**
	 * Logs to the console. Strings are only printed if configured in the settings
	 *
	 * @param data - data to log
	 * @param optionalParams - Additional arguments
	 */
	public console_warn(data: unknown, ...optionalParams: any[]): void {
		this.con.warn(data, ...optionalParams);
	}

	/**
	 * Logs to the console. Strings are only printed if configured in the settings
	 *
	 * @param data - data to log
	 * @param optionalParams - Additional arguments
	 */
	public console_error(data: unknown, ...optionalParams: any[]): void {
		this.con.log(data, ...optionalParams);
	}

	/**
	 * Calls all attached logEntryCallbacks and notifies about the new logmessage
	 * If the calle returns to process the entry through sentry we will process it
	 *
	 * @param msg - the ELogMessage object as created in the writeLog message
	 * @returns - returns the IProcessLogMessage if one of the onLogMessage handlers provided one or undefined
	 */
	private fireLogEntryCallback(msg: LogMessage): IProcessLogMessage | undefined {
		let result: IProcessLogMessage | undefined;
		result = undefined;
		for (const handler of this.logEntryCallbacks.values()) {
			if (handler.filter === undefined || this.checkFilter(msg, handler.filter)) {
				const res = handler.callback.onLogMessage(msg);
				if (!result && res)
					result = res;
			}
		}
		return result;
	}

	/**
	 * Validates if the log messages meets the filter conditions.
	 *
	 * @param msg - the ELogMessage object as created in the writeLog message
	 * @param filters - the Filter parameters which will be used for the message
	 * @returns - returns true if the filter was matching (or no filter defined) and false if the logmessage did not match the
	 */
	private checkFilter(msg: ILogMessage, filters: IFilterConfigs | undefined): boolean {
		if (filters === undefined)
			return true;

		let filter: IFilterConfig | boolean | undefined;
		if (msg.level === "error")
			filter = filters.error;
		else if (msg.level === "warn")
			filter = filters.warn;
		else if (msg.level === "debug")
			filter = filters.debug;
		else if (msg.level === "info")
			filter = filters.info;
		else
			debugger;

		if (filter === undefined || filter === true)
			return true;
		else if (filter === false)
			return false;

		// define result as undefined
		let bAllow;
		if (filter.includeClassNames || filter.includeMethodNames || filter.includeMessages) {
			// If any include is configured, set allow to false
			// any match will set allow to true
			bAllow = false;
			/* istanbul ignore else */
			if (filter.includeClassNames && msg.className && msg.className.match) {
				for (const classname of filter.includeClassNames) {
					if (msg.className.match(classname)) {
						bAllow = true;
						break;
					}
				}
			}
			/* istanbul ignore else */
			if (!bAllow && filter.includeMethodNames && msg.method && msg.method.match) {
				for (const methodname of filter.includeMethodNames) {
					if (msg.method.match(methodname)) {
						bAllow = true;
						break;
					}
				}
			}
			/* istanbul ignore else */
			if (!bAllow && filter.includeMessages && msg.message && msg.message.match) {
				for (const message of filter.includeMessages) {
					if (msg.message.match(message)) {
						bAllow = true;
						break;
					}
				}
			}
			// if no include was set the allow is false and we return here
			if (bAllow === false)
				return false;
		}

		// If include was set or it was left undefined check the excludes
		bAllow = true;
		/* istanbul ignore else */
		if (filter.excludeClassNames || filter.excludeMethodNames || filter.excludeMessages) {
			// Id one of the excludes matches we set the log to false
			/* istanbul ignore else */
			if (filter.excludeClassNames && msg.className) {
				for (const classname of filter.excludeClassNames) {
					if (msg.className.match(classname)) {
						bAllow = false;
						break;
					}
				}
			}
			/* istanbul ignore else */
			if (bAllow && filter.excludeMethodNames && msg.method) {
				for (const methodname of filter.excludeMethodNames) {
					if (msg.method.match(methodname)) {
						bAllow = false;
						break;
					}
				}
			}
			/* istanbul ignore else */
			if (bAllow && filter.excludeMessages && msg.message) {
				for (const message of filter.excludeMessages) {
					if (msg.message.match(message)) {
						bAllow = false;
						break;
					}
				}
			}
		}

		return bAllow;
	}

	/**
	 * Remove sensitive Data from the logMessages
	 * For now we remove the content of the chat messages and the username & password
	 * for the ice servers we receive
	 *
	 * @param className - the class from where the log originated
	 * @param meta - the information object containing the log
	 * @returns a filtered meta object
	 */
	private filterSensitiveData(className: string, meta: Record<string, unknown>): Record<string, unknown> {
		if (!className)
			return meta;
		// clonedeep otherwise as this is passed by reference, sometimes the fields are readonly
		// throwing errors in the console
		const metaEntry = cloneDeep(meta);
		if (className === "ENetUC_ChatV2ROSE") {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (metaEntry && metaEntry.operationName === "asnChatTextMessage") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (metaEntry.argument && metaEntry.argument.u8sMessage !== "")
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					metaEntry.argument.u8sMessage = "#_deleted_#";
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
			} else if (metaEntry && metaEntry.operationName === "asnChatEvent") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (metaEntry.argument.asnChatEventList) {
					const newEvents: AsnChatEvent[] = [];
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					for (const event of metaEntry.argument.asnChatEventList) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						if (event.asnChatMessage)
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							event.asnChatMessage.u8sMessage = "#_deleted_#";
						newEvents.push(event);
					}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					metaEntry.argument.asnChatEventList = newEvents;
				}
			}
		} else if (className === "ENetUC_JournalROSE") {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (metaEntry.argument && metaEntry.argument.journalEntryChangedList) {
				const newEntries: AsnJournalEntryChangedListArgument[] = [];
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				for (const entry of metaEntry.argument.journalEntryChangedList) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					if (entry.journalEntry.u8sMemo)
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						entry.journalEntry.u8sMemo = "#_deleted_#";
					newEntries.push(entry);
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				metaEntry.argument.journalEntryChangedList = newEntries;
			}
		} else if (className === "ENetUC_AVROSE") {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (metaEntry.result && metaEntry.result.listConfigSTUNandTURN) {
				const newConfigs: AsnConfigSTUNandTURN[] = [];
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				for (const config of metaEntry.result.listConfigSTUNandTURN) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					if (config.u8sUsername)
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						config.u8sUsername = "#_deleted_#";
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					if (config.u8sPassword)
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						config.u8sPassword = "#_deleted_#";
					newConfigs.push(config);
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				metaEntry.result.listConfigSTUNandTURN = newConfigs;
			}
		}
		return metaEntry;
	}

	/**
	 * The actual message that handles the log entry.
	 *
	 * @param level - The level of the log message
	 * @param msg - the message for the log entry, do NOT add contextual data into this message, use the meta data for it
	 * @param calling_method - name of the caller
	 * @param logdata_or_callback - either a class/object implementing the ILogCallback or directly ILogData.
	 * If you are calling from a class component hand over *this* and implement the callback that returns an ILogData object.
	 * The callback fills the classname as well as contextual data you want to have in every log entry (e.g. sessionids)
	 * @param meta - Meta data you want to have logged with the message (arguments, results, intermediate data, anything that might be usefull later)
	 * @param error - In case of an error pass it here.
	 */
	private writeLog(level: LogLevels, msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, error?: unknown): void {
		// Make sure exception is of type error
		if (error) {
			if (isError(error)) {
				// We validate if the handed over object contains the mandatory fields of an exception object (name and message)
				// If thats the case we can map it into the error object as the required fields are there (if a stack is there it´s handed over as well)
				// We create a new object as the original exception might not be clonable in all circumstances
				error = {
					message: error.message,
					name: error.name,
					stack: error.stack
				};
			} else {
				let err: Error | undefined;
				if (isAsnRequestError(error)) {
					if (!meta)
						meta = {};
					meta["asnRequestError"] = error as ENetUC_Common.AsnRequestError;
					err = new Error((error as ENetUC_Common.AsnRequestError).u8sErrorString);
				} else if (hasToJSON(error)) {
					if (!meta)
						meta = {};
					meta["customException"] = error.toJSON();
					err = new Error(`Custom exception ${typeof error}`);
				}
				if (err) {
					error = {
						message: err.message,
						name: err.name,
						stack: this.removeStackTrace(err, 2).stack
					};
				}
			}
		}

		const logMessage = new LogMessage();

		// Build the log message
		logMessage.level = level;
		logMessage.method = calling_method;

		// In case of a logdata or callback argument check...
		let classProps: { [propName: string]: unknown } | undefined;
		if (logdata_or_callback) {
			// can we call getLogData, then do so and get LogData from there
			if ((logdata_or_callback as ILogCallback).getLogData) {
				const logData = (logdata_or_callback as ILogCallback).getLogData();
				logMessage.className = logData.className;
				classProps = logData.classProps;
			} else {
				// or directly take over the data from the handed over object
				logMessage.className = (logdata_or_callback as ILogData).className;
				classProps = (logdata_or_callback as ILogData).classProps;
			}
		}

		// If no message has been provided and we have an error with an exception set exception as message
		if (msg === "" && level === "error" && error)
			logMessage.message = "exception";
		else if (msg.length)
			logMessage.message = msg;
		if (meta) {
			if (meta["lokiLabelsKey"]) {
				logMessage["lokiLabelsKey"] = (meta["lokiLabelsKey"] as string);
				delete meta["lokiLabelsKey"];
			}
			logMessage.meta = this.filterSensitiveData(logMessage.className, meta);
		}
		if (classProps)
			logMessage.classProps = classProps;

		logMessage.error = error;

		// Add the log to the cache to have it available for later
		if (this._log)
			this._log.push(logMessage);

		// Map console to con to not let the linter see console.xxx entries
		const con = console;

		// Validate the filters and if the filter matches write the log entry to he appropriate console log function
		if (this.checkFilter(logMessage, this.consoleFilterConfig)) {
			if (level === "error")
				con.error(logMessage);
			else if (level === "warn")
				con.warn(logMessage);
			else if (level === "info")
				con.info(logMessage);
			else if (level === "debug")
				con.debug(logMessage);
			else
				debugger;
		}

		// Hand over the logentry to the frontend and if it returns to process it through sentry do so
		const processSentry = this.fireLogEntryCallback(logMessage);
		if (processSentry?.bProcess)
			con.error("SENTRY");
	}

	/**
	 * Manipulates stack trace entries from a given error by removing entries
	 *
	 * @param exception - Error instance to remove stacktrance entries from
	 * @param level - Level of stack traces to remove
	 * @returns - the cleaned error object
	 */
	private removeStackTrace(exception: Error, level = 1): Error {
		if (exception.stack && level > 0) {
			for (let i = 0; i < level; i++) {
				const pos1: number = exception.stack.indexOf("\n");
				if (pos1 > 0) {
					const pos2: number = exception.stack.indexOf("\n", pos1 + 1);
					if (pos2 > 0)
						exception.stack = exception.stack.substring(0, pos1) + exception.stack.substring(pos2);
				}
			}
		}
		return exception;
	}
}
