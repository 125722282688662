import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, ListItemButton, ListItemText, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import { Interweave } from "interweave";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import {
	faArrowLeft,
	faArrowRight,
	faCar,
	faCircleO,
	faEllipsisV,
	faHouse,
	faInfoCircle,
	faMobile,
	faMugSaucer,
	faPhone,
	faTreePalm
} from "web-fortawesome/npm/pro-light";

import { AsnAgentStateEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnNetDatabaseContact } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { AsnReachabilityEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { IKeyValuePair } from "../../../web-shared-components/interfaces/IKeyValuePair";
import { IContactContainer } from "../../../web-shared-components/interfaces/interfaces";
import { getPhoneNumbersFromAsnContact } from "../../../web-shared-components/react-components/contactDetailsComponent/processing/ContactDetailsHelper";
import { PresenceIconWithTooltip } from "../../../web-shared-components/react-components/presenceIconWithTooltip/PresenceIconWithTooltip";
import { theAVManager } from "../../globals";
import { boldAndBlueString } from "../../lib/commonHelper";
import ContactHelper from "../../lib/ContactHelper";
import { ConstrContactFromModel } from "../../modelconstructors/ConstrModelContact";
import { ConstrContentDetailsFromJson } from "../../modelconstructors/ConstrModelContent";
import { IDynamicStatusInfosCD } from "../../models/ModelContent";
import { useStore } from "../../zustand/store";
import { CallOptionsMenu } from "../CallOptionsMenu/CallOptionsMenu";
import { CircleIconButton } from "../common/Button/CircleIconButton";
import { Typography } from "../common/Typography/Typography";

interface IStatusInfoProps {
	statusInfo: IDynamicStatusInfosCD;
	note?: string;
	nextAppointment?: string;
	callNote?: string;
}

const getReachabilityIcon = (reachability: AsnReachabilityEnum): JSX.Element => {
	let icon = <></>;
	switch (reachability) {
		case AsnReachabilityEnum.eREACHABILITYPAUSE:
			icon = <FontAwesomeIcon icon={faMugSaucer} />;
			break;
		case AsnReachabilityEnum.eREACHABILITYBUSINESSTRIP:
			icon = <FontAwesomeIcon icon={faCar} />;
			break;
		case AsnReachabilityEnum.eREACHABILITYHOMEOFFICE:
			icon = <FontAwesomeIcon icon={faHouse} />;
			break;
		case AsnReachabilityEnum.eREACHABILITYVACATION:
			icon = <FontAwesomeIcon icon={faTreePalm} />;
			break;
		case AsnReachabilityEnum.eREACHABILITYNORMAL:
		default:
			break;
	}

	return icon;
};

const getAgentStateIcon = (agentState: AsnAgentStateEnum): JSX.Element => {
	let icon = <></>;
	switch (agentState) {
		case AsnAgentStateEnum.eAGENTSTATEREADY:
		case AsnAgentStateEnum.eAGENTSTATEBUSY:
		case AsnAgentStateEnum.eAGENTSTATEWORKINGAFTERCALL:
			icon = <FontAwesomeIcon icon={faCircleO} />;
			break;
		default:
			break;
	}
	return icon;
};

const getCallnote = (callNote: string, statusInfo: IDynamicStatusInfosCD): JSX.Element[] => {
	const note = [
		<Typography variant="body2" key="callNote-key">
			{callNote}
		</Typography>
	];

	let outBound;
	let otherContactDisplayName;
	if (statusInfo?.seqCalls?.length) {
		outBound = statusInfo?.seqCalls[0]?.iOutbound;
		otherContactDisplayName = statusInfo?.seqCalls[0]?.remoteContact?.u8sDisplayName;
	}

	if (statusInfo.u8sNote === "IDS_CALL_CONNECTED" || statusInfo.u8sNote === "IDS_CALL_RINGING") {
		if (outBound === 0) note.push(<FontAwesomeIcon key="arrow-left-icon" transform={"shrink-1"} icon={faArrowLeft} />);
		else note.push(<FontAwesomeIcon key="arrow-right-icon" transform={"shrink-1"} icon={faArrowRight} />);

		note.push(
			<Typography key="otherContactDisplayName-key" variant="body2">
				{otherContactDisplayName}
			</Typography>
		);
	}

	return note;
};

export const StatusInfo: FC<IStatusInfoProps> = ({ statusInfo, note, nextAppointment, callNote }) => {
	return (
		<Stack flexDirection={"row"} gap={1} alignItems={"center"}>
			{statusInfo.isMobileAvailable ? <FontAwesomeIcon icon={faMobile} /> : null}
			{getReachabilityIcon(statusInfo.reachability)}
			{getAgentStateIcon(statusInfo.agentState)}
			{note || nextAppointment || callNote ? (
				<>
					{note ? <FontAwesomeIcon icon={faInfoCircle} /> : null}
					{callNote ? (
						getCallnote(callNote, statusInfo)
					) : (
						<Typography variant="body2" noWrap>
							{nextAppointment && nextAppointment !== "" ? nextAppointment : note}
						</Typography>
					)}
				</>
			) : null}
		</Stack>
	);
};

export const ContactActions: FC<{ contact: AsnNetDatabaseContact; isSmallWidth: boolean }> = ({
	contact,
	isSmallWidth
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const mySip = useStore((state) => state.ownContactId) || "";
	const theme = useTheme();
	const contacts = useStore((state) => state.contactsPresence);
	const statusInfos = contacts.get(contact.u8sSIPAddress ?? "");
	const contactDetails = useStore((state) => state.contactDetails);
	const customLabels = useStore((state) => state.customLabels);

	const phoneNumbers = useMemo((): IKeyValuePair[] => {
		return getPhoneNumbersFromAsnContact(contact, customLabels.CustLabels, contactDetails.ContactDetails);
	}, [contact, contactDetails.ContactDetails, customLabels.CustLabels]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const startWebRTCChat = (type: "audio" | "video") => {
		// const otherParty = conversation?.asnChatConversation.asnChatParties.find(item => item.u8sContactURI !== myOwnContact?.contactID);
		// if (!otherParty)
		// 	return;
		const avCall =
			type === "audio"
				? theAVManager.createAvCall(mySip, contact.u8sSIPAddress!, "", true)
				: theAVManager.createAvCall(mySip, contact.u8sSIPAddress!, "");
		if (!avCall) return;

		void avCall.start();
	};

	return (
		<>
			{isSmallWidth ? (
				<CircleIconButton icon={faEllipsisV} onClick={handleClick} color={theme.palette.black56.main} />
			) : (
				<>
					<CircleIconButton
						outlined
						sx={{ ml: 1 }}
						onClick={handleClick}
						disabled={!statusInfos?.clientCapabilities?.handleAudio && !phoneNumbers?.length}
						icon={faPhone}
					/>
					{/* <CircleIconButton
						disabled={!checkUserHasVideoChat(statusInfos)}
						outlined
						sx={{ ml: 1 }}
						onClick={async (e) => {
							e.stopPropagation();
							startCall(addSipPrefixIfNotExists(contact.u8sSIPAddress ?? ""), "video");
						}}
						icon={faVideo}
					/> */}
				</>
			)}
			<CallOptionsMenu contact={contact} anchorEl={anchorEl} showVideoOption={false} onClose={handleClose} />
		</>
	);
};

interface IContactListItemProps {
	contact: AsnNetDatabaseContact;
	contactsMap: Map<string, IContactContainer>;
	searchTerm?: string;
	selected?: boolean;
	onClick: (contact: AsnNetDatabaseContact) => void;
}

export const ContactListItem: FC<IContactListItemProps> = ({ contact, onClick, searchTerm = "", selected }) => {
	const { t } = useTranslation();

	const contacts = useStore((state) => state.contactsPresence);
	const customLabels = useStore((state) => state.customLabels);
	const cDetails = useStore((state) => state.contactDetails);
	const statusInfos = contacts.get(contact.u8sSIPAddress!);

	const [status, setStatus] = useState<IContactContainer>();

	useEffect(() => {
		setStatus(statusInfos);
	}, [statusInfos]);

	const contentDetails = ConstrContentDetailsFromJson({
		...contact,
		statusInfos: {
			presence: status?.presence || 0,
			asnCustomNote: status?.customNote || "",
			asnNextAppointment: status?.nextAppointment,
			reachability: status?.reachability,
			agentState: status?.agentState,
			isMobileAvailable: status?.isMobileAvailable,
			currentAppointments: status?.currentAppointments,
			msTeamsEmail: status?.msTeamsEmail,
			seqCalls: status?.seqCalls,
			seqLineForwards: status?.seqLineForwards,
			seqPhoneLines: status?.seqPhoneLines
		}
	});

	const constructedContact = ConstrContactFromModel(
		contentDetails,
		customLabels.CustLabels,
		cDetails.ContactDetails,
		"contactDetails"
	);

	const callNote = constructedContact?.statusInfos.u8sNote?.startsWith("IDS_")
		? t(constructedContact.statusInfos.u8sNote)
		: "";

	let nextAppointment = "";
	const currentAppointments = constructedContact?.statusInfos.currentAppointments;
	if (currentAppointments && currentAppointments[0])
		nextAppointment = t("IDS_APPOINTMENT") + " - " + currentAppointments[0].u8sSubject;

	const currentAppointmentTime = statusInfos ? ContactHelper.getCurrentAppointment(statusInfos) : [];
	const nextAppointmentTime = statusInfos ? ContactHelper.getNextAppointment(statusInfos) : "";

	const { width, ref } = useResizeDetector();
	const isSmallWidth = (width || 0) < 400;

	return (
		<>
			<ListItemButton
				selected={selected}
				ref={ref}
				disableRipple
				sx={{
					flexDirection: "row",
					flex: 1,
					overflow: "hidden",
					alignItems: "center",
					height: "70px",
					cursor: "pointer"
				}}
				onClick={() => {
					onClick(contact);
				}}
			>
				<PresenceIconWithTooltip
					contact={{ ...status, contactID: contact.u8sSIPAddress!, asnNetDatabaseContact: contact }}
					contactDetails={cDetails}
					customLabels={customLabels}
					combiPic={ContactHelper.getPic(contact)}
					currentAppointmentTime={currentAppointmentTime}
					nextAppointmentTime={nextAppointmentTime}
					mouseHover={() => {}}
				/>

				<ListItemText
					primary={<Interweave content={boldAndBlueString(constructedContact.displayName, searchTerm)}></Interweave>}
					secondaryTypographyProps={{ component: "span" }}
					secondary={
						contact.iCtiServerUser === 1
							? StatusInfo({
									statusInfo: constructedContact.statusInfos || 0,
									note: constructedContact.statusInfos.u8sNote || "",
									nextAppointment,
									callNote
								})
							: contact.u8sCompany
					}
					sx={{
						paddingLeft: "16px",
						"& .MuiListItemText-primary": {
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							overflow: "hidden"
						}
					}}
				/>
				<ContactActions contact={contact} isSmallWidth={isSmallWidth} />
			</ListItemButton>
			<Divider variant="middle" component="li" />
		</>
	);
};
