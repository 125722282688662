import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

import { ELogLevel } from "../../web-shared-components/helpers/logger/ILogger";
import { theLogger } from "../globals";

/**
 * Generates a uuidv4
 * @returns - the uuidv4
 */
export function generateGUID(): string {
	return uuidv4().split("-").join("");
}

/**
 * function to get the initials from a username. e.g. MA from Matthias Abele
 * if ucserverDisplayName exists (logged in user) use this for the initals otherwise (guest)
 * use the userEnteredDisplayName to create the initals
 * @param ucserverDisplayName - the username in the ucserver
 * @param userEnteredDisplayName - the username in meetings
 * @returns the string with the initials
 */
export function getInitials(ucserverDisplayName: string, userEnteredDisplayName: string): string {
	const regexpEmoji = /\p{Emoji}/gu;
	let initials: string;
	const name = userEnteredDisplayName.length > 0 ? userEnteredDisplayName : ucserverDisplayName;
	if (name === "") return "";

	const bits: Array<string> = name.split(" ");

	if (bits.length > 1) {
		const bit1 = bits[0]?.replace(regexpEmoji, "") || "";
		const bit2 = bits[bits.length - 1]?.replace(regexpEmoji, "") || "";
		let initial1: string;
		let initial2: string;

		if (bit1 === "") initial1 = ":)";
		else initial1 = bit1[0] || "";

		if (bit2 === "") initial2 = ":)";
		else initial2 = bit2[0] || "";

		initials = (initial1 + initial2).toUpperCase();
	} else {
		const initial = name.replace(regexpEmoji, "");

		if (initial && initial.length > 0) {
			if (initial.length === 1) initials = initial[0]?.toUpperCase() || "";
			else initials = initial.substring(0, 2).toUpperCase();
		} else initials = ":)";
	}

	return initials;
}

/**
 * Add the sip prefix if it does not exist in the contact id
 * @param contactID - the contact ID to check
 * @returns - the correct sip
 */
export function addSipPrefixIfNotExists(contactID?: string): string {
	if (!contactID) return "";

	const sip = contactID.substring(0, 4);
	if (sip === "sip:") return contactID;

	return "sip:" + contactID;
}

/**
 * Remove sip prefix if it does exist in the contact id
 * @param contactID - the contact ID to check
 * @returns - the contact id without the sip prefix
 */
export function removeSipPrefixIfExists(contactID: string | undefined): string {
	if (!contactID) return "";

	const sip = contactID.substring(0, 4);
	if (sip === "sip:") return contactID.substring(4, contactID.length);

	return contactID;
}

/**
 * Get the phone number from a full string sip uri
 * @param sipUri - the uri to check
 * @returns - the phone number only
 */
export function getPhoneNumberFromSIPURI(sipUri?: string): string {
	if (sipUri === undefined) return "";

	let phone = removeSipPrefixIfExists(sipUri);
	const split = phone.split("@");
	if (split.length) phone = split[0];

	return phone;
}

/**
 * Convert seconds in readable format
 * @param secs - the seconds to convert
 * @returns - a readable format like 00:00:00
 */
export function secondsToReadable(secs?: number): string {
	if (secs === undefined) return "";

	const date = new Date(0);
	date.setSeconds(secs);
	return date.toISOString().substring(11, 19);
}

/**
 * Check if the text is a phone number
 * @param numberToCheck - The number to check
 * @returns boolean
 */
export function checkIsPhoneNumber(numberToCheck: string) {
	if (!numberToCheck?.length) return false;

	// TODO: the check is very basic and should be improved
	return numberToCheck.match(/^[+]?(\d+(\d-\s*\d+)*)?$/g) !== null;
}

/**
 *	Start an audio or video call by opening the installed app
 * @param u8sSIPAddress - the sip address of the contact
 * @param type - "audio" | "video"
 */
export function startCall(u8sSIPAddress: string, type: "audio" | "video") {
	if (!u8sSIPAddress || ["audio", "video"].indexOf(type) === -1) return;

	if (type === "audio") return window.open(`eucc-audio:${u8sSIPAddress}`, "_self");

	if (type === "video") return window.open(`eucc-video:${u8sSIPAddress}`, "_self");
}

/**
 * Sanitize a string for regexp
 * @param str - the string to sanitize
 * @returns - a safe string for regexp
 */
export function escapeRegExp(str: string): string {
	const newString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
	return newString;
}

/**
 * Replace a substring inside a string with the same string but in bold and blue
 * (for the results in a search, according to the design)
 * @param str -  the total string
 * @param substr - the substring in bold
 * @returns the modified string
 */
export function boldAndBlueString(str: string | undefined, substr: string) {
	// In order to keep the original case (upper/lower), find the string, add the style, replace the original with it.
	let newString = "";
	let replacedString = "";
	if (str === undefined) return "";
	try {
		const foundString = str.match(RegExp(escapeRegExp(substr), "i"));
		if (foundString && foundString.length)
			newString = `<span style="color:#537b97;font-weight:bold">${foundString[0]}</span>`;
		replacedString = str.replace(RegExp(escapeRegExp(substr), "gi"), newString);
	} catch (e) {
		theLogger.debug(
			"Search string contained invalid regexp",
			"boldAndBlueString",
			{ className: "commonHelpers.ts" },
			{ error: e }
		);
	}
	return replacedString;
}

/**
 * Get the LogLevel Enum from a string
 * @param logLevel - the loglevel as a string
 * @returns - the enum
 */
export const getELogLevelFromString = (logLevel: string): ELogLevel => {
	switch (logLevel) {
		case "error":
			return ELogLevel.ERROR;
		case "warn":
			return ELogLevel.WARN;
		case "info":
			return ELogLevel.INFO;
		case "debug":
			return ELogLevel.DEBUG;
		default:
			return ELogLevel.DEBUG;
	}
};

/**
 * injects the (external short) appTitle in the given resource
 * @param resource - resource from i18n
 * @returns - the matching and updated string
 */
export const getAppTitleExternalShort = (resource: string): string => {
	const appTitle = t("IDS_APPTITLE_WEBAPP_EXTERNAL_SHORT");
	return t(resource, { appTitle });
};

/**
 * injects the (internal) appTitle in the given resource
 * @param resource - resource from i18n
 * @returns - the matching and updated string
 */
export const getAppTitleInternal = (resource: string): string => {
	const appTitle = t("IDS_APPTITLE_WEBAPP_INTERNAL");
	return t(resource, { appTitle });
};

/**
 * Let's remove a trailing slash from a string (e.g. from an URL)
 * @param str - the string to clean
 * @returns - the cleaned string
 */
export const stripTrailingSlash = (str: string): string => {
	return str.endsWith("/") ? str.slice(0, -1) : str;
};

/**
 * Reformat the url used to log to a local server, by adding the default ports and protocols
 * @param url - the url to parse
 * @returns - the formatted url
 */
export const getFormattedLocalUCServerURL = (url: string): string | undefined => {
	let webUrl: URL | undefined;
	try {
		if (/(http(s?)):\/\//i.test(url)) webUrl = new URL(url);
		else {
			url = "https://" + url;
			webUrl = new URL(url);
			if (webUrl.port === "") webUrl.port = "7225";
		}
		if (webUrl.protocol === "http:") webUrl.port = "7224";

		// The last "/" must be removed if it's there, because we already add it to construct all the needed endpoints
		return stripTrailingSlash(webUrl.toString());
	} catch (e) {
		return undefined;
	}
};
