// [PrintTSCodeOne]
// [PrintTSComments]
/*
 * ENetUC_Journal.ts
 * "UC-Server-Access-Protocol-Journal" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Journal interface
 * ## Module description
 * This Module defines structures and operations for accessing the journal database of the UCServer.
 *
 * ## Features
 * - Get journal entries from the database (asnGetJournalEntries).
 * - Update journal entries in the database.
 * - Notify CRUD interface for getting events from the journal database.
 *
 * New journal entries where only generated by the UCServer if the corresponding events happend (Calls, Chats etc.) and cannot be generated by this client API.
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_Journal";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNetDatabaseJournalCallType {
	/** Calls on PBX lines (TAPI) */
	eJOURNALCALLTYPEPBX = 0,
	/** Video Chat (WebRTC based) (only Video, no Audio; e.g. desktop sharing session) */
	eJOURNALCALLTYPEVIDEOCHAT = 1,
	/** Audio Chat (WebRTC based) (only Audio, no Video) */
	eJOURNALCALLTYPEAUDIOCHAT = 2,
	/** Video Chat (WebRTC based) (Audio and Video) */
	eJOURNALCALLTYPEAUDIOVIDEOCHAT = 3,
	/** Text Chat */
	eJOURNALCALLTYPEIM = 4,
	/** Bluetooth mobile phone call */
	eJOURNALCALLTYPEBLUETOOTHMOBILE = 5,
	/** Bluetooth mobile phone - Call duration of the connected call is (1 sec, offline Journal) */
	eJOURNALCALLTYPEBLUETOOTHMOBILEUNKNOWNDURATION = 6,
	/** Audio chat via SIP connection to PBX. Looks like a normal PBX call, but is technically a SIP softphone call via PBX using WebRTC. */
	eJOURNALCALLTYPESIPAVCALL = 7,
	/** SMS */
	eJOURNALCALLTYPESMS = 8,
	/** Screen\/Desktop sharing (WebRTC based, invite := outgoing) */
	eJOURNALCALLTYPESCREENSHARING = 9,
	/** Screen\/Desktop sharing (WebRTC based, request := incoming) */
	eJOURNALCALLTYPERSCREENSHARING = 10
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNetDatabaseJournalFilterEnumeration {
	/** 0x00000001 -	Internal calls */
	flagIntern = 1,
	/** 0x00000002 -	External calls */
	flagExtern = 2,
	/** 0x00000004 -	Inbound calls */
	flagInbound = 4,
	/** 0x00000008 -	Outbound calls */
	flagOutbound = 8,
	/** 0x00000010 -	Connected calls */
	flagConnected = 16,
	/** 0x00000020 -	Not connected calls */
	flagNotConnected = 32,
	/** 0x00000040 -	The memo field contains text */
	flagHasMemo = 64,
	/** 0x00000080 -	The memo field is empty */
	flagHasNoMemo = 128,
	/** 0x00000100 -	Only entries, which are not marked as \"read\" */
	flagUnreadOnly = 256,
	/** 0x00000200 -	Exclude all calls without a caller number (clipped numbers) */
	flagNoUnknown = 512,
	/** 0x00000400 -	Include text chats	(default: TBD) */
	flagTextChats = 1024,
	/** 0x00000800 -	Include audio chats (default: TBD) */
	flagAudioChats = 2048,
	/** 0x00001000 -	Include video chats (default: TBD) */
	flagVideoChats = 4096,
	/** 0x00002000 -	Include phone calls (default: set) */
	flagPhoneCalls = 8192,
	/** 0x00004000 -	Include screen-\/deskshare chats */
	flagDeskShare = 16384,
	/** 0x00010000 -	Include all entries, which are marked as \"public\" and the user has access rights on. */
	flagIncludeAllPublic = 65536
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnJournalSubscribeFlags {
	/** Get only user owened jounral entries */
	eDEFAULT = 0,
	/** Include the journal entries of the users groups, if the view permissions in the groups are set accordingly */
	eGROUPJOURNAL = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnJournalPrivateCallOptions {
	/** No special treatment of private calls */
	eNORMAL = 0,
	/** Numbers in private calls should be shortned */
	eSHORTENNUMBERS = 1,
	/** Numbers of private calls wont be persistet */
	eNONUMBERS = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnUpdateJournalUpdateFlags {
	/** 0x0001 - Read flag update */
	eUpdateFlagRead = 1,
	/** 0x0002 - Contact data update */
	eUpdateFlagContact = 2,
	/** 0x0004 - Project rules update */
	eUpdateFlagProject = 4,
	/** 0x0008 - Memo text update */
	eUpdateFlagMemo = 8,
	/** 0x0010 - Subject text update */
	eUpdateFlagSubject = 16,
	/** 0x0020 - Acceptor changed */
	eUpdateFlagAcceptor = 32,
	/** 0x0040 - Phone number changed */
	eUpdateFlagPhoneNumber = 64,
	/** 0x0080 - Tracker last call changed */
	eUpdateFlagTrackerLastCall = 128,
	/** 0x0100 - No statitistics flag changed */
	eUpdateFlagNoStatistic = 256,
	/** 0x0200 - Call type changed */
	eUpdateFlagCallType = 512,
	/** 0x0400 - Label changed */
	eUpdateFlagLabel = 1024,
	/** 0x0800 - Deleted flag changed */
	eUpdateFlagDeleted = 2048,
	/** 0x1000 - Tracker call flow changed */
	eUpdateFlagTrackerCallFlow = 4096,
	/** 0x2000 - Contact name changed */
	eUpdateFlagContactName = 8192
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnGetOfflineJournal.
 */
export class AsnGetOfflineJournalArgument {
	public constructor(that: AsnGetOfflineJournalArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetOfflineJournalArgument {
		return new AsnGetOfflineJournalArgument({
			iMaxDays: 0,
			iMarkRead: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iMaxDays",
			"iMarkRead"
		];
		return p;
	}

	public static type = "AsnGetOfflineJournalArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetOfflineJournalArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iMaxDays" }),
				new asn1ts.Integer({ name: "iMarkRead" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Maximum Days to get offline journal entries. (0 = 365 days) */
	public iMaxDays!: number;
	/** 0: Don't change the entries; 1: Mark the offline entries as \"read\", which means delete the entry from the offline database. The corresponding entry in the journal database will not be touched. */
	public iMarkRead!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The structure of a journal entry of conversations on the UCServer.
 * This sequence describes a call or other conversation when it is stored in the history database called \"journal\".
 *
 * With this structure it it possible to see when an conversation or event has happend and other meta information about it.
 * The API provides operations to alter or add information of that entry.
 * New entries can only be generated by the UCServer.
 */
export class AsnNetDatabaseJournal {
	public constructor(that: AsnNetDatabaseJournal) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNetDatabaseJournal {
		return new AsnNetDatabaseJournal({
			u8sConnectionID: "",
			stStartTime: new Date(),
			stStopTime: new Date(),
			iDurationTotal: 0,
			stConnectTime: new Date(),
			stDisconnectTime: new Date(),
			iDurationConnected: 0,
			iOrigin: 0,
			iInternal: 0,
			u8sLineNumber: "",
			u8sLineName: "",
			u8sLineUserName: "",
			iLineUserOffline: 0,
			u8sLocalNumber: "",
			u8sPhoneNumber: "",
			u8sRedirectingNumber: "",
			u8sContactName: "",
			u8sContactCompany: "",
			u8sContactEntryID: "",
			u8sContactEntryStoreID: "",
			u8sContactEntryDBID: "",
			u8sCtiServerUserName: "",
			u8sProviderNumber: "",
			rCharging: 0.0,
			iSucessState: 0,
			u8sSubject: "",
			u8sMemo: "",
			iReadFlag: 0,
			projectPinRule: ENetUC_Common_SIPCTI.AsnProjectPinRule["initEmpty"].call(0),
			u8sTrackerID: "",
			u8sAcceptorName: "",
			u8sAcceptorCtiServerUserName: "",
			u8sAcceptorConnectionID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionID",
			"stStartTime",
			"stStopTime",
			"iDurationTotal",
			"stConnectTime",
			"stDisconnectTime",
			"iDurationConnected",
			"iOrigin",
			"iInternal",
			"u8sLineNumber",
			"u8sLineName",
			"u8sLineUserName",
			"iLineUserOffline",
			"u8sLocalNumber",
			"u8sPhoneNumber",
			"u8sRedirectingNumber",
			"u8sContactName",
			"u8sContactCompany",
			"u8sContactEntryID",
			"u8sContactEntryStoreID",
			"u8sContactEntryDBID",
			"u8sCtiServerUserName",
			"u8sProviderNumber",
			"rCharging",
			"iSucessState",
			"u8sSubject",
			"u8sMemo",
			"iReadFlag",
			"projectPinRule",
			"u8sTrackerID",
			"u8sAcceptorName",
			"u8sAcceptorCtiServerUserName",
			"u8sAcceptorConnectionID"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnNetDatabaseJournal";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNetDatabaseJournal",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Real({ name: "stStartTime" }),
				new asn1ts.Real({ name: "stStopTime" }),
				new asn1ts.Integer({ name: "iDurationTotal" }),
				new asn1ts.Real({ name: "stConnectTime" }),
				new asn1ts.Real({ name: "stDisconnectTime" }),
				new asn1ts.Integer({ name: "iDurationConnected" }),
				new asn1ts.Integer({ name: "iOrigin" }),
				new asn1ts.Integer({ name: "iInternal" }),
				new asn1ts.Utf8String({ name: "u8sLineNumber" }),
				new asn1ts.Utf8String({ name: "u8sLineName" }),
				new asn1ts.Utf8String({ name: "u8sLineUserName" }),
				new asn1ts.Integer({ name: "iLineUserOffline" }),
				new asn1ts.Utf8String({ name: "u8sLocalNumber" }),
				new asn1ts.Utf8String({ name: "u8sPhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sRedirectingNumber" }),
				new asn1ts.Utf8String({ name: "u8sContactName" }),
				new asn1ts.Utf8String({ name: "u8sContactCompany" }),
				new asn1ts.Utf8String({ name: "u8sContactEntryID" }),
				new asn1ts.Utf8String({ name: "u8sContactEntryStoreID" }),
				new asn1ts.Utf8String({ name: "u8sContactEntryDBID" }),
				new asn1ts.Utf8String({ name: "u8sCtiServerUserName" }),
				new asn1ts.Utf8String({ name: "u8sProviderNumber" }),
				new asn1ts.Real({ name: "rCharging" }),
				new asn1ts.Integer({ name: "iSucessState" }),
				new asn1ts.Utf8String({ name: "u8sSubject" }),
				new asn1ts.Utf8String({ name: "u8sMemo" }),
				new asn1ts.Integer({ name: "iReadFlag" }),
				ENetUC_Common_SIPCTI.AsnProjectPinRule.getASN1Schema({ name: "projectPinRule" }),
				new asn1ts.Utf8String({ name: "u8sTrackerID" }),
				new asn1ts.Utf8String({ name: "u8sAcceptorName" }),
				new asn1ts.Utf8String({ name: "u8sAcceptorCtiServerUserName" }),
				new asn1ts.Utf8String({ name: "u8sAcceptorConnectionID" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true }),
				new asn1ts.Extension()
			]
		});
	}

	/** Unique ID of the call */
	public u8sConnectionID!: string;
	/** Time stamp start of call (first event). */
	public stStartTime!: Date;
	/** Time stamp end of call (last event). */
	public stStopTime!: Date;
	/** Duration of the call from stStartTime to stStopTime in seconds. */
	public iDurationTotal!: number;
	/** Time stamp when the call was connected (e.g. accepted). */
	public stConnectTime!: Date;
	/** Time stamp when the call was disconnected (e.g. hangup). */
	public stDisconnectTime!: Date;
	/** Duration of the call from stConnectTime to stDisconnectTime in seconds. */
	public iDurationConnected!: number;
	/** 1: outgoing call (outbound), 0: incoming Gespräch (inbound) */
	public iOrigin!: number;
	/** 1: internal call (call with internal partner), 0: external call (call with external partner) */
	public iInternal!: number;
	/** Number of the line, the call was active. */
	public u8sLineNumber!: string;
	/** A Name of the Line (can be the owner's name or a name configured in the PBX). */
	public u8sLineName!: string;
	/** The user name of the line owner. */
	public u8sLineUserName!: string;
	/** 1: user was offline during the call was active. */
	public iLineUserOffline!: number;
	/** Number of the line in local format (can be different to u8sLineNumber). */
	public u8sLocalNumber!: string;
	/** Phone number of the partner of the call. */
	public u8sPhoneNumber!: string;
	/** If the call was redirected from another extension, the phone number of that line is given here. */
	public u8sRedirectingNumber!: string;
	/** If available, the name of the call partner. */
	public u8sContactName!: string;
	/** If available, the company of the call partner. */
	public u8sContactCompany!: string;
	/** If available, entry id of the contact data to get it from the source database. */
	public u8sContactEntryID!: string;
	/** If available, store id of the contact data to get it from the source database. */
	public u8sContactEntryStoreID!: string;
	/** If available, entry database id of the contact data to get it from the source database. */
	public u8sContactEntryDBID!: string;
	/** User name of the owner. */
	public u8sCtiServerUserName!: string;
	/** TBD */
	public u8sProviderNumber!: string;
	/** @deprecated 09.06.2015 unused. */
	public rCharging!: number;
	/** TBD */
	public iSucessState!: number;
	/** Free text, unused. */
	public u8sSubject!: string;
	/** Free text, contains possible comment to the call. */
	public u8sMemo!: string;
	/** 0: call was not marked as read, 1: call was marked as read. */
	public iReadFlag!: number;
	/** Contains further information about possible used project or pin rules. */
	/** (see AsnProjectPinRule) */
	public projectPinRule!: ENetUC_Common_SIPCTI.AsnProjectPinRule;
	/** Unique ID for the call tracker, which contains further information about the call history if the call was forwarded for example. */
	public u8sTrackerID!: string;
	/** If available, the name of the user who accepted the call (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorName!: string;
	/** If available, the user name of the user who accepted the call (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorCtiServerUserName!: string;
	/** If available, the unique id of the journal entry, which was the call of the acceptor (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorConnectionID!: string;
	/**
	 * Additional parameters for AsnNetDatabaseJournalFindOptions added here for backward compatibility.
	 *
	 * - Key \"bMemoComplete\", Value bool, optional. Only present if the entry represnets a text chat entry and the logged in user is owner of this entry.
	 * - Key \"TrackerLastCall\", Value integer, optional. 1 if it is the last call of a call tracker sequence of calls.
	 * - Key \"NoStatistic\", Value integer, optional. 1 if this entry should be ingnored for statistic services (e.g. ProCall Analytics).
	 * - Key \"CallType\", Value integer, not optional. Type of the call, valid values are defined in AsnNetDatabaseJournalCallType.
	 * - Key \"iTransactionID\", Value integer, not optional. Transaction id of the entry in the database.
	 * - Key \"iChatSequenceID\", Value integer, optional. Sequence id of text chat entries.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Filter options for finding more specific journal entries.
 * The find options are use to give more details about the journal entries which should be in the result list.
 * By default, the search is limited to the journal entries of the logged in user (public and private). Deleted ones are never listed.
 *
 * If there are user names given in u8slistLineUserNames, the search can be expanded on users, where access rights were granted (public only).
 */
export class AsnNetDatabaseJournalFindOptionsV2 {
	public constructor(that: AsnNetDatabaseJournalFindOptionsV2) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNetDatabaseJournalFindOptionsV2 {
		return new AsnNetDatabaseJournalFindOptionsV2({
			stStartTimeFrom: new Date(),
			stStartTimeTo: new Date(),
			u8slistLineUserNames: new ENetUC_Common.UTF8StringList(),
			u8slistPhoneNumber: new ENetUC_Common.UTF8StringList(),
			u8sContactName: "",
			u8sCompanyName: "",
			u8sFilterText: "",
			iMaxNumEntries: 0,
			iFilter: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"stStartTimeFrom",
			"stStartTimeTo",
			"u8slistLineUserNames",
			"u8slistPhoneNumber",
			"u8sContactName",
			"u8sCompanyName",
			"u8sFilterText",
			"iMaxNumEntries",
			"iFilter"
		];
		if (bIncludeOptionals)
			p.push("iLastKnownGlobTransactionID");
		return p;
	}

	public static type = "AsnNetDatabaseJournalFindOptionsV2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNetDatabaseJournalFindOptionsV2",
			...params,
			value: [
				new asn1ts.Real({ name: "stStartTimeFrom" }),
				new asn1ts.Real({ name: "stStartTimeTo" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8slistLineUserNames" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8slistPhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sContactName" }),
				new asn1ts.Utf8String({ name: "u8sCompanyName" }),
				new asn1ts.Utf8String({ name: "u8sFilterText" }),
				new asn1ts.Integer({ name: "iMaxNumEntries" }),
				new asn1ts.Integer({ name: "iFilter" }),
				new asn1ts.Integer({ name: "iLastKnownGlobTransactionID", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Filter on \"StartTime\", first time stamp of a desired time window of journal entries. */
	public stStartTimeFrom!: Date;
	/** Filter on \"StartTime\", last time stamp of a desired time window of journal entries. */
	public stStartTimeTo!: Date;
	/** A list of CTIUserNames of which journal entries should be searched. */
	public u8slistLineUserNames!: ENetUC_Common.UTF8StringList;
	/**
	 * The own user name of the logged in user is not necessary and should not be given here.
	 * Filter on sought after phone numbers.
	 */
	public u8slistPhoneNumber!: ENetUC_Common.UTF8StringList;
	/** Name of the conversational partner. */
	public u8sContactName!: string;
	/** Company name of the conversational partner. */
	public u8sCompanyName!: string;
	/** Free text which will be found in the contact name, company name, phone number or memo field. */
	public u8sFilterText!: string;
	/** Max count of journal entries to return, 0 would be \"all\". Higher values resulting in a increasing perfomance impact on the ucserver. A good value is 100. */
	public iMaxNumEntries!: number;
	/** Defines a filter based on AsnNetDatabaseJournalFilterEnumeration */
	/** (see AsnNetDatabaseJournalFilterEnumeration) */
	public iFilter!: number;
	/** Last known global transaction ID. If this parameter is given, only entries with a global transaction ID newer than iLastKnownGlobTransactionID will be delivered. The value has to be greater than 0. */
	public iLastKnownGlobTransactionID?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateCallAndJournalContactAndNumber.
 */
export class AsnUpdateCallAndJournalContactAndNumberArgument {
	public constructor(that: AsnUpdateCallAndJournalContactAndNumberArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateCallAndJournalContactAndNumberArgument {
		return new AsnUpdateCallAndJournalContactAndNumberArgument({
			u8sConnectionID: "",
			u8sRemoteNumberSC: "",
			remoteContact: ENetUC_Common.AsnNetDatabaseContact["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionID",
			"u8sRemoteNumberSC",
			"remoteContact"
		];
		return p;
	}

	public static type = "AsnUpdateCallAndJournalContactAndNumberArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateCallAndJournalContactAndNumberArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sRemoteNumberSC" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "remoteContact" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Unique ID of the call or journal entry to modify. */
	public u8sConnectionID!: string;
	/** Phone number to set for the contact in long format (e.g. +49815136856177). */
	public u8sRemoteNumberSC!: string;
	/** The contact to set for the journal entry. */
	/** (see AsnNetDatabaseContact) */
	public remoteContact!: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The result of asnUpdateCallAndJournalContactAndNumber.
 */
export class AsnUpdateCallAndJournalContactAndNumberResult {
	public constructor(that: AsnUpdateCallAndJournalContactAndNumberResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateCallAndJournalContactAndNumberResult {
		return new AsnUpdateCallAndJournalContactAndNumberResult({
			bResultCallInfoUpdate: false,
			bResultJournalUpdate: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bResultCallInfoUpdate",
			"bResultJournalUpdate"
		];
		return p;
	}

	public static type = "AsnUpdateCallAndJournalContactAndNumberResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateCallAndJournalContactAndNumberResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bResultCallInfoUpdate" }),
				new asn1ts.Boolean({ name: "bResultJournalUpdate" }),
				new asn1ts.Extension()
			]
		});
	}

	/** If the active call was updated, this will be \"true\". */
	public bResultCallInfoUpdate!: boolean;
	/** If the journal entry was updated, this will be \"true\". */
	public bResultJournalUpdate!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateCallLocalContact event to the server
 */
export class AsnUpdateCallLocalContactArgument {
	public constructor(that: AsnUpdateCallLocalContactArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateCallLocalContactArgument {
		return new AsnUpdateCallLocalContactArgument({
			u8sConnectionID: "",
			localContact: ENetUC_Common.AsnNetDatabaseContact["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionID",
			"localContact"
		];
		return p;
	}

	public static type = "AsnUpdateCallLocalContactArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateCallLocalContactArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "localContact" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Connection ID of the journal entry to change the local contact (\"Mandantenfunktion\") */
	public u8sConnectionID!: string;
	/** Contact to set in the journal entry */
	public localContact!: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateJournalMemo
 */
export class AsnUpdateJournalMemoArgument {
	public constructor(that: AsnUpdateJournalMemoArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalMemoArgument {
		return new AsnUpdateJournalMemoArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			u8sMemo: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionIDList",
			"u8sMemo"
		];
		return p;
	}

	public static type = "AsnUpdateJournalMemoArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalMemoArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sConnectionIDList" }),
				new asn1ts.Utf8String({ name: "u8sMemo" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** Die Notiz zu einem bestimmten Call */
	public u8sMemo!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUpdateJournalMemo
 */
export class AsnUpdateJournalMemoResult {
	public constructor(that: AsnUpdateJournalMemoResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalMemoResult {
		return new AsnUpdateJournalMemoResult({
			bSuccess: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bSuccess"
		];
		return p;
	}

	public static type = "AsnUpdateJournalMemoResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalMemoResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Extension()
			]
		});
	}

	/** True if the update wasa successful, false if not. */
	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateJournalProject
 */
export class AsnUpdateJournalProjectArgument {
	public constructor(that: AsnUpdateJournalProjectArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalProjectArgument {
		return new AsnUpdateJournalProjectArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			projectPinRule: ENetUC_Common_SIPCTI.AsnProjectPinRule["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionIDList",
			"projectPinRule"
		];
		return p;
	}

	public static type = "AsnUpdateJournalProjectArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalProjectArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sConnectionIDList" }),
				ENetUC_Common_SIPCTI.AsnProjectPinRule.getASN1Schema({ name: "projectPinRule" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of IDs to identify the journal entries to modify with new project rules */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** Project data to set to the jounral entries */
	public projectPinRule!: ENetUC_Common_SIPCTI.AsnProjectPinRule;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUpdateJournalProject
 */
export class AsnUpdateJournalProjectResult {
	public constructor(that: AsnUpdateJournalProjectResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalProjectResult {
		return new AsnUpdateJournalProjectResult({
			bSuccess: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bSuccess"
		];
		return p;
	}

	public static type = "AsnUpdateJournalProjectResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalProjectResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Extension()
			]
		});
	}

	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateJournalReadFlag
 */
export class AsnUpdateJournalReadFlagArgument {
	public constructor(that: AsnUpdateJournalReadFlagArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalReadFlagArgument {
		return new AsnUpdateJournalReadFlagArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			bReadFlag: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionIDList",
			"bReadFlag"
		];
		return p;
	}

	public static type = "AsnUpdateJournalReadFlagArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalReadFlagArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sConnectionIDList" }),
				new asn1ts.Boolean({ name: "bReadFlag" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of ids to specify the journal entries to change their read flag */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** True if the entry(s) should be set to \"read\", False if they should be set to \"unread\" */
	public bReadFlag!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUpdateJournalReadFlag
 */
export class AsnUpdateJournalReadFlagResult {
	public constructor(that: AsnUpdateJournalReadFlagResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalReadFlagResult {
		return new AsnUpdateJournalReadFlagResult({
			bSuccess: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bSuccess"
		];
		return p;
	}

	public static type = "AsnUpdateJournalReadFlagResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalReadFlagResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Extension()
			]
		});
	}

	/** True if the entroes were modified, false if there was a problem. */
	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateJournalSubject
 */
export class AsnUpdateJournalSubjectArgument {
	public constructor(that: AsnUpdateJournalSubjectArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalSubjectArgument {
		return new AsnUpdateJournalSubjectArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			u8sSubject: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionIDList",
			"u8sSubject"
		];
		return p;
	}

	public static type = "AsnUpdateJournalSubjectArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalSubjectArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sConnectionIDList" }),
				new asn1ts.Utf8String({ name: "u8sSubject" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of ids to specify the journal entries to change their subject field */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** New text for the subject */
	public u8sSubject!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUpdateJournalSubject
 */
export class AsnUpdateJournalSubjectResult {
	public constructor(that: AsnUpdateJournalSubjectResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalSubjectResult {
		return new AsnUpdateJournalSubjectResult({
			bSuccess: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bSuccess"
		];
		return p;
	}

	public static type = "AsnUpdateJournalSubjectResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalSubjectResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Extension()
			]
		});
	}

	/** True if the subject was changed in the databse, false is not */
	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetJournalEntriesCount and asnGetJournalEntriesCountV2 method
 */
export class AsnGetJournalEntriesCountResult {
	public constructor(that: AsnGetJournalEntriesCountResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetJournalEntriesCountResult {
		return new AsnGetJournalEntriesCountResult({
			iCount: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iCount"
		];
		return p;
	}

	public static type = "AsnGetJournalEntriesCountResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetJournalEntriesCountResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iCount" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Count of journal entries found in the database according the AsnNetDatabaseJournalFindOptions given in the argument */
	public iCount!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnJournalEntryGet
 */
export class AsnJournalEntryGetArgument {
	public constructor(that: AsnJournalEntryGetArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalEntryGetArgument {
		return new AsnJournalEntryGetArgument({
			u8sConnectionID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionID"
		];
		return p;
	}

	public static type = "AsnJournalEntryGetArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalEntryGetArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** ConnectionID of the call to get its journal entry */
	public u8sConnectionID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnJournalSubscribeEvents
 */
export class AsnJournalSubscribeEventsArgument {
	public constructor(that: AsnJournalSubscribeEventsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalSubscribeEventsArgument {
		return new AsnJournalSubscribeEventsArgument({
			bReceiveJournalEvents: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bReceiveJournalEvents"
		];
		if (bIncludeOptionals) {
			p.push(
				"iLastKnownGlobTransactionID",
				"iMaxEntries",
				"iFlags"
			);
		}
		return p;
	}

	public static type = "AsnJournalSubscribeEventsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalSubscribeEventsArgument",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bReceiveJournalEvents" }),
				new asn1ts.Integer({ name: "iLastKnownGlobTransactionID", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iMaxEntries", idBlock: { optionalID: 1 } }),
				new asn1ts.Integer({ name: "iFlags", idBlock: { optionalID: 2 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Set to true to subscribe, set to false to unsubscribe (completely) */
	public bReceiveJournalEvents!: boolean;
	/**
	 * If this parameter is set to the last known transaction id, the server will only send the journal entries with a higher transaction id.
	 * The search is limited to the last 60 days. If older entries are of interest, the client should use asnGetJournalEntries with find options set accordingly.
	 */
	public iLastKnownGlobTransactionID?: number;
	/** Maximum number of journal entries to retrieve by this call */
	public iMaxEntries?: number;
	/** Additional subscribe options defined in AsnJournalSubscribeFlags */
	public iFlags?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnJournalSubscribeEvents if the call is successful. If an error occured, the AsnRequestError will be returned
 */
export class AsnJournalSubscribeEventsResult {
	public constructor(that: AsnJournalSubscribeEventsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalSubscribeEventsResult {
		return new AsnJournalSubscribeEventsResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnJournalSubscribeEventsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalSubscribeEventsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true }),
				new asn1ts.Extension()
			]
		});
	}

	/** If the call was successful, 0 is returned. If there was an error, the AsnRequestError of the call will be returned instead. */
	public iResult!: number;
	/** No optional parameters yet */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnJournalGetSettings - there are no parameters needed, but the call needs an argument.
 */
export class AsnJournalGetSettingsArgument {
	public constructor(that?: AsnJournalGetSettingsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalGetSettingsArgument {
		return new AsnJournalGetSettingsArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnJournalGetSettingsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalGetSettingsArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnDeleteJournalEntries
 */
export class AsnDeleteJournalEntriesArgument {
	public constructor(that: AsnDeleteJournalEntriesArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnDeleteJournalEntriesArgument {
		return new AsnDeleteJournalEntriesArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sConnectionIDList"
		];
		return p;
	}

	public static type = "AsnDeleteJournalEntriesArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDeleteJournalEntriesArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sConnectionIDList" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of connection ids to indentify the journal entries to delete */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnDeleteJournalEntries
 */
export class AsnDeleteJournalEntriesResult {
	public constructor(that: AsnDeleteJournalEntriesResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnDeleteJournalEntriesResult {
		return new AsnDeleteJournalEntriesResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		return p;
	}

	public static type = "AsnDeleteJournalEntriesResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDeleteJournalEntriesResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Extension()
			]
		});
	}

	/** 0 if the call was successful */
	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnMarkAllJournalEntriesAsRead
 */
export class AsnMarkAllJournalEntriesAsReadArgument {
	public constructor(that?: AsnMarkAllJournalEntriesAsReadArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnMarkAllJournalEntriesAsReadArgument {
		return new AsnMarkAllJournalEntriesAsReadArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnMarkAllJournalEntriesAsReadArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMarkAllJournalEntriesAsReadArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnMarkAllJournalEntriesAsRead
 */
export class AsnMarkAllJournalEntriesAsReadResult {
	public constructor(that: AsnMarkAllJournalEntriesAsReadResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnMarkAllJournalEntriesAsReadResult {
		return new AsnMarkAllJournalEntriesAsReadResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		return p;
	}

	public static type = "AsnMarkAllJournalEntriesAsReadResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMarkAllJournalEntriesAsReadResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Extension()
			]
		});
	}

	/** 0 if the call was successful */
	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnNetDatabaseJournalList extends Array<AsnNetDatabaseJournal> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnNetDatabaseJournal.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetJournalEntries and asnGetJournalEntriesV2 operation
 */
export class AsnGetJournalEntriesResult {
	public constructor(that: AsnGetJournalEntriesResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetJournalEntriesResult {
		return new AsnGetJournalEntriesResult({
			journalList: new AsnNetDatabaseJournalList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"journalList"
		];
		return p;
	}

	public static type = "AsnGetJournalEntriesResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetJournalEntriesResult",
			...params,
			value: [
				AsnNetDatabaseJournalList.getASN1Schema({ name: "journalList" }),
				new asn1ts.Extension()
			]
		});
	}

	public journalList!: AsnNetDatabaseJournalList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnGetJournalEntriesV2Argument {
	public constructor(that: AsnGetJournalEntriesV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetJournalEntriesV2Argument {
		return new AsnGetJournalEntriesV2Argument({
			findOptions: AsnNetDatabaseJournalFindOptionsV2["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"findOptions"
		];
		return p;
	}

	public static type = "AsnGetJournalEntriesV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetJournalEntriesV2Argument",
			...params,
			value: [
				AsnNetDatabaseJournalFindOptionsV2.getASN1Schema({ name: "findOptions" }),
				new asn1ts.Extension()
			]
		});
	}

	public findOptions!: AsnNetDatabaseJournalFindOptionsV2;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnGetJournalEntriesCountV2Argument {
	public constructor(that: AsnGetJournalEntriesCountV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetJournalEntriesCountV2Argument {
		return new AsnGetJournalEntriesCountV2Argument({
			findOptions: AsnNetDatabaseJournalFindOptionsV2["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"findOptions"
		];
		return p;
	}

	public static type = "AsnGetJournalEntriesCountV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetJournalEntriesCountV2Argument",
			...params,
			value: [
				AsnNetDatabaseJournalFindOptionsV2.getASN1Schema({ name: "findOptions" }),
				new asn1ts.Extension()
			]
		});
	}

	/** specify the journal entries to count */
	public findOptions!: AsnNetDatabaseJournalFindOptionsV2;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnJournalEntryGet
 */
export class AsnJournalEntryGetResult {
	public constructor(that: AsnJournalEntryGetResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalEntryGetResult {
		return new AsnJournalEntryGetResult({
			journalEntry: AsnNetDatabaseJournal["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"journalEntry"
		];
		return p;
	}

	public static type = "AsnJournalEntryGetResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalEntryGetResult",
			...params,
			value: [
				AsnNetDatabaseJournal.getASN1Schema({ name: "journalEntry" }),
				new asn1ts.Extension()
			]
		});
	}

	/** If a journal entry can be found, this result contains a copy of it. */
	public journalEntry!: AsnNetDatabaseJournal;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for the asnJournalEntryChanged event
 */
export class AsnJournalEntryChangedArgument {
	public constructor(that: AsnJournalEntryChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalEntryChangedArgument {
		return new AsnJournalEntryChangedArgument({
			iFlags: 0,
			journalEntry: AsnNetDatabaseJournal["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iFlags",
			"journalEntry"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnJournalEntryChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalEntryChangedArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iFlags" }),
				AsnNetDatabaseJournal.getASN1Schema({ name: "journalEntry" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true }),
				new asn1ts.Extension()
			]
		});
	}

	/**
	 * Journal entry added(1), modified(2), deleted(3), added or modified(4).&lt;br \/&gt;
	 * If the event was triggered by a differential update the server will set 'added or modified'(4)
	 * because it cannot differentiate between added or modified.
	 */
	public iFlags!: number;
	/** Journal entry (at least with connection id in case of delete) */
	public journalEntry!: AsnNetDatabaseJournal;
	/** - optionalParams Name \"iTransactionID\" Value String, transaction ID of the entrys, so older desktop clients can support diff updates. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnJournalEntryChangedList extends Array<AsnJournalEntryChangedArgument> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnJournalEntryChangedArgument.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument of asnJournalEntryListChanged from the server
 */
export class AsnJournalEntryChangedListArgument {
	public constructor(that: AsnJournalEntryChangedListArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalEntryChangedListArgument {
		return new AsnJournalEntryChangedListArgument({
			iLastTransactionID: 0,
			journalEntryChangedList: new AsnJournalEntryChangedList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iLastTransactionID",
			"journalEntryChangedList"
		];
		return p;
	}

	public static type = "AsnJournalEntryChangedListArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalEntryChangedListArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iLastTransactionID" }),
				AsnJournalEntryChangedList.getASN1Schema({ name: "journalEntryChangedList" }),
				new asn1ts.Extension()
			]
		});
	}

	/** A counter increased by every database change to support differetial updates */
	public iLastTransactionID!: number;
	/** List of journal entries changed with this event */
	public journalEntryChangedList!: AsnJournalEntryChangedList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnJournalGetSettings
 */
export class AsnJournalGetSettingsResult {
	public constructor(that: AsnJournalGetSettingsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnJournalGetSettingsResult {
		return new AsnJournalGetSettingsResult({
			ePrivacyLevel: AsnJournalPrivateCallOptions.eNORMAL,
			bUseOfflineJournal: false,
			bOfflineDeleteOldEntries: false,
			iOfflineDaysNoDelete: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"ePrivacyLevel",
			"bUseOfflineJournal",
			"bOfflineDeleteOldEntries",
			"iOfflineDaysNoDelete"
		];
		return p;
	}

	public static type = "AsnJournalGetSettingsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnJournalGetSettingsResult",
			...params,
			value: [
				new asn1ts.Enumerated({ name: "ePrivacyLevel" }),
				new asn1ts.Boolean({ name: "bUseOfflineJournal" }),
				new asn1ts.Boolean({ name: "bOfflineDeleteOldEntries" }),
				new asn1ts.Integer({ name: "iOfflineDaysNoDelete" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Enumerated AsnJournalPrivateCallOptions to describe how the privatzed calls are handled by the server */
	public ePrivacyLevel!: number;
	/** True if the server use the offline journal database, false if not */
	public bUseOfflineJournal!: boolean;
	/** True: delete old offline journal entries */
	public bOfflineDeleteOldEntries!: boolean;
	/** Maximum days to keep old offline entries */
	public iOfflineDaysNoDelete!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUpdateJournal, event to the server.
 */
export class AsnUpdateJournalArgument {
	public constructor(that: AsnUpdateJournalArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdateJournalArgument {
		return new AsnUpdateJournalArgument({
			journalList: new AsnNetDatabaseJournalList(),
			iFlags: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"journalList",
			"iFlags"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnUpdateJournalArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateJournalArgument",
			...params,
			value: [
				AsnNetDatabaseJournalList.getASN1Schema({ name: "journalList" }),
				new asn1ts.Integer({ name: "iFlags" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of AsnNetDatabaseJournal to update on the server */
	public journalList!: AsnNetDatabaseJournalList;
	/** Bitfield for describing what has changed. If set to 0, the server assumes that all fields have changed. */
	public iFlags!: number;
	/** optionalParam key \"CreateMobile\", unsigned Integer 0 or 1, 1: special handling of mobile journal entries, should only be set by mobile clients */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetOfflineJournal.
 */
export class AsnGetOfflineJournalResult {
	public constructor(that: AsnGetOfflineJournalResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetOfflineJournalResult {
		return new AsnGetOfflineJournalResult({
			journalList: new AsnNetDatabaseJournalList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"journalList"
		];
		return p;
	}

	public static type = "AsnGetOfflineJournalResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetOfflineJournalResult",
			...params,
			value: [
				AsnNetDatabaseJournalList.getASN1Schema({ name: "journalList" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of AsnNetDatabaseJournal objects, containing the offline journal entries found. */
	public journalList!: AsnNetDatabaseJournalList;
}
