import { BrowserDetector } from "../web-shared-components/helpers/browser-detector/BrowserDetector";
import { ELogLevel, IFilterConfigs } from "../web-shared-components/helpers/logger/ILogger";
import { ILokiConfig, ILokiConfigLabel } from "../web-shared-components/helpers/logger/ILokiLogger";
import { Logger } from "../web-shared-components/helpers/logger/Logger";
import { TranslationManager } from "../web-shared-components/managers/translationManager";
import { ConfigManager } from "./config";
import { IBaseSingletons } from "./interfaces/IBaseSingletons";
import AudioPlayer from "./lib/AudioPlayer";
import { LoggerCallback } from "./lib/LoggerCallback";
import { LokiLogger } from "./lib/LokiLogger";
import { AVManager } from "./managers/AVManager";
import { ClientPersistenceManager } from "./managers/ClientPersistenceManager";
import { ContactManager } from "./managers/ContactManager/ContactManager";
import { CtiManager } from "./managers/CtiManager";
import { JournalManager } from "./managers/JournalManager";
import { MeManager } from "./managers/MeManager";
import { OemManager } from "./managers/OemManager";
import { UCClient } from "./session/UCClient";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		chrome: {
			webview: unknown;
		};
		webkitAudioContext: typeof AudioContext;
	}
}

// TODO: we may want to distinguish the log level in console, depending on the environment
// dev - stg - prod
const consoleLoggingFilter: IFilterConfigs = {
	warn: true,
	error: true,
	info: true,
	debug: true
};

export const theLogger = Logger.getInstance();
export const theConfig = ConfigManager.getInstance(import.meta.env);
await theConfig.init();

const logCallback = new LoggerCallback();
theLogger.addLogEntryCallback(logCallback, consoleLoggingFilter);
// TODO: we may also want to distinguish the general logs, depending on the environment
theLogger.init(ELogLevel.DEBUG, consoleLoggingFilter, false);
export const theBrowser = BrowserDetector.getInstance();
theBrowser.setLogger(theLogger);
// The singletons (managers, controllers) need to access to the basic singletons: theLogger, theBrowser
// To avoid circular dependencies, there's a base class that all the managers should extend.
const baseSingletons: IBaseSingletons = {
	browser: theBrowser,
	logger: theLogger
};

void theBrowser.init();
export const theUCClient = UCClient.getInstance(baseSingletons);
export const theJournalManager = JournalManager.getInstance(baseSingletons);
export const theContactManager = ContactManager.getInstance();
export const theCtiManager = CtiManager.getInstance(baseSingletons);
export const theAudioPlayer = AudioPlayer.getInstance(theBrowser);
export const theAVManager = AVManager.getInstance();
export const theClientPersistenceManager = ClientPersistenceManager.getInstance([
	"webview2-custom-labels",
	"webview2-contactdetails"
]);
export const theTranslationManager = TranslationManager.getInstance();
export const theOemManager = OemManager.getInstance(baseSingletons);
export const theMeManager = MeManager.getInstance(baseSingletons);

export const lokiLabelTeamsStatistics = "teams-statistics";

const alternateLabels = new Map<string, ILokiConfigLabel>();
alternateLabels.set(lokiLabelTeamsStatistics, {
	job: lokiLabelTeamsStatistics,
	instance: theUCClient.getClientDeviceID(),
	environment: theConfig.config.environment
});

const lokiLoggerConfig: ILokiConfig = {
	bEnabled: theConfig.config.lokiEnabled,
	host: theConfig.config.lokiHost,
	labels: {
		environment: theConfig.config.environment,
		instance: theUCClient.getClientDeviceID(),
		job: "teamsapp"
	},
	alternateLabels,
	basicAuth: theConfig.config.lokiAuth,
	logAsn1: theConfig.config.lokiLogAsn1
};
export const theLokiLogger = LokiLogger.getInstance(theLogger);
const lokiLoggingFilter: IFilterConfigs = {
	warn: true,
	error: true,
	info: true,
	debug: {
		excludeMessages: ["asn1Transport", "result in", "result out", "invoke in", "invoke out", "event in", "event out"]
	}
};
if (theConfig.config.lokiEnabled) {
	theLokiLogger.init(lokiLoggerConfig);
	theLogger.addLogEntryCallback(theLokiLogger, lokiLoggingFilter);
}
