import { Button, Checkbox, CircularProgress, FormControlLabel, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EError, ILogData } from "../../../web-shared-components/helpers/logger/ILogger";
import { theConfig, theLogger, theUCClient } from "../../globals";
import { getAppTitleInternal, getFormattedLocalUCServerURL } from "../../lib/commonHelper";
import ErrorInfoHelper, { EAsnLogonErrorEnumEX } from "../../lib/ErrorInfoHelper";
import UCCHelper from "../../lib/UCCHelper";
import { ILoginParameters } from "../../session/UCClient";
import styles from "./Login.module.scss";

interface ILoginCredentials {
	username: string;
	password: string;
	ucsIdOrUcWeb: string;
}

interface IUCSIDandServerURI {
	ucsID?: string;
	serverURI: string;
}

// this is taken from
// https://learn.microsoft.com/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_mssymbol_19.svg
const msIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
		<title>MS-SymbolLockup</title>
		<rect x="1" y="1" width="9" height="9" fill="#f25022" />
		<rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
		<rect x="11" y="1" width="9" height="9" fill="#7fba00" />
		<rect x="11" y="11" width="9" height="9" fill="#ffb900" />
	</svg>
);

interface IProps {
	errorFromPWA?: number; // type of EAsnLogonErrorEnumEX
}

/**
 * The component rendering the login
 * @param props - the props
 * @returns Login page
 */
export default function LoginOauth(props: IProps): JSX.Element {
	const { t } = useTranslation();
	const [formState, setFormState] = useState<ILoginCredentials>({
		username: "",
		password: "",
		ucsIdOrUcWeb: ""
	});
	// errors in first step (ucsid or direct url input field)
	const [errorStep1, setErrorStep1] = useState<number | undefined>(undefined);
	// errors in second step only for the basic auth (username / password)
	const [errorStep2, setErrorStep2] = useState<number | undefined>(undefined);
	// errors when trying to perform a login with microsoft (errors can come from get entraId link)
	const [errorMSLogin, setErrorMSLogin] = useState<number | undefined>(undefined);
	const [isWaiting, setIsWaiting] = useState<boolean>(false);
	// UCConnect choice as default if nothing found in localStorage or in case the config doesn't allow the local ucWeb login
	const [useUCConnect, setUseUCConnect] = useState<boolean>(true);
	const [wantToStayLogged, setWantToStayLogged] = useState<boolean>(false);
	// UCSID and Server URI must be stored together to avoid inconsistency
	const [ucsIDandServerURI, setUcsIDandServerURI] = useState<IUCSIDandServerURI | undefined>();
	const [loginAvailable, setLoginAvailable] = useState<boolean>(false);
	const [loginChallenges, setLoginChallenges] = useState<string[] | undefined>();
	const ucconnectLabel = useUCConnect ? t("IDS_LOGIN_UCCONNECT_ID") : t("IDS_LOGIN_UCSERVER_URL");
	const [nextStep, setNextStep] = useState<boolean>(false);

	const getLogData = (): ILogData => {
		return {
			className: "Login"
		};
	};

	/**
	 * Catch error from AppPWA auto login with token
	 * showing a snackbar
	 */
	useEffect(() => {
		if (props.errorFromPWA === undefined) return;
		theLogger.error(
			ErrorInfoHelper.parseLogonError(props.errorFromPWA),
			"login",
			getLogData(),
			{
				e: ErrorInfoHelper.parseLogonError(props.errorFromPWA)
			},
			props.errorFromPWA
		);
	}, [props.errorFromPWA]);

	/**
	 * logic to handle the input fields
	 * triggers when user changes the input field
	 * @param event - the event
	 */
	const handleChange = (event: React.ChangeEvent): void => {
		const { target } = event;
		let value: string = (target as HTMLInputElement).value as string;
		const name: string = (target as HTMLInputElement).name as string;

		if (name === "ucsIdOrUcWeb" || name === "username") value = value.trim();

		// Reset existing errors
		if (name === "ucsIdOrUcWeb") setErrorStep1(undefined);
		else setErrorStep2(undefined);

		setFormState((prevState: ILoginCredentials) => ({
			...prevState,
			[name]: value
		}));
	};

	const removeTrailingSlash = useCallback((url: string): string => {
		return url.replace(/\/$/, "");
	}, []);

	const resetLoginLogic = () => {
		// Reset login logic while searching for chances
		setLoginAvailable(false);
		setLoginChallenges(undefined);
		setUcsIDandServerURI(undefined);
	};

	const checkUCSIDOrURI = async () => {
		const formStateID = removeTrailingSlash(formState.ucsIdOrUcWeb);
		if (formStateID.length === 0) return;
		resetLoginLogic();
		// Direct URL doesn't use UCConnect
		if (!useUCConnect) {
			const formattedURI = getFormattedLocalUCServerURL(formStateID);
			if (!formattedURI) {
				setErrorStep1(EAsnLogonErrorEnumEX.exInvalidUCServerURI);
				return;
			}
			// also set the new formatted value in the form
			setFormState((prevState: ILoginCredentials) => ({
				...prevState,
				ucsIdOrUcWeb: formattedURI
			}));
			setUcsIDandServerURI({
				serverURI: formattedURI
			});
			return;
		}
		// UCConnect login needs to retrieve the UCServer URI
		const uriFromUCController = await UCCHelper.getUriFromUCC(formStateID, new URL(theUCClient.UCControllerURL));
		if (typeof uriFromUCController !== "number") {
			setUcsIDandServerURI({
				ucsID: formStateID,
				serverURI: uriFromUCController
			});
		} else setErrorStep1(uriFromUCController);
	};

	const getLoginChallenges = async () => {
		if (!ucsIDandServerURI) return;
		// To show the spinner and disable the button and input fields
		setIsWaiting(true);
		const challenges = await UCCHelper.getLoginChallenges(ucsIDandServerURI.serverURI, ucsIDandServerURI.ucsID);
		if (typeof challenges !== "number") {
			const found = theConfig.config.acceptedLoginChallenges.some((r) => challenges.includes(r));
			setLoginAvailable(found);
			setLoginChallenges(challenges);
			setNextStep(true);
		} else {
			setLoginChallenges(undefined);
			setErrorStep1(challenges);
		}
		setIsWaiting(false);
	};

	useEffect(() => {
		if (!ucsIDandServerURI) return;
		void getLoginChallenges();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ucsIDandServerURI]);

	/**
	 * Login with MicroSoft
	 */
	const loginWithMicroSoft = async () => {
		setErrorMSLogin(undefined);
		if (!ucsIDandServerURI) return;
		const entraIdLink = await UCCHelper.getEntraIdLink(ucsIDandServerURI.serverURI, ucsIDandServerURI.ucsID);
		if (typeof entraIdLink === "number") {
			setErrorMSLogin(entraIdLink);
			theLogger.error("Cannot retrieve the link", "getEntraIdLink", getLogData());
		} else window.location.replace(entraIdLink);
	};

	/**
	 * Login direct to UCServer
	 */
	const loginDirect = async () => {
		if (!formState.ucsIdOrUcWeb) return;
		if (!formState.username) return;
		// To show the spinner and disable the button and input fields
		// It's re-set to false in case of error only
		// because if the login is successful, this whole component is unmounted
		setIsWaiting(true);
		let ucsid: string | undefined;
		let ucserveruri: string | undefined;
		const formStateID = removeTrailingSlash(formState.ucsIdOrUcWeb);
		if (useUCConnect) ucsid = formStateID;
		else ucserveruri = formStateID;

		const loginParameters: ILoginParameters = {
			UCSid: ucsid,
			UCSUri: ucserveruri,
			username: formState.username,
			password: formState.password,
			keepMeLoggedIn: wantToStayLogged
		};
		// The result here is only needed to catch the errors
		// if the login is successful, some new states will be set in redux and AppPWA will do the rest
		const token = await theUCClient.connectStandAlone(loginParameters);
		// If the returned value is a number, it means it's an error (a type of EAsnLogonErrorEnumEX,
		// which extends the AsnLogonErrorEnum)
		if (typeof token === "number") {
			const errorMsg = ErrorInfoHelper.parseLogonError(token);
			// Particular case when we found in redux that the current user is different from the previous user
			// but the logout was not performed (redux persistent store was manually forced)
			// The confirmation dialog logic is done in Redux: inform the user about what's happening
			// reload page on click will fix it because at this point the cache is completely cleaned.
			if (token !== EAsnLogonErrorEnumEX.exInconsistentDataInCacheFound) {
				// All the other tracked error cases
				theLogger.error(
					errorMsg,
					"login",
					getLogData(),
					{
						e: errorMsg
					},
					EError.AuthenticationErrorUCServer
				);
				setErrorStep2(token);
				setIsWaiting(false);
			}
		} else if (token === undefined) {
			// Completely unexpected error
			const errorMsg = ErrorInfoHelper.parseLogonError(EAsnLogonErrorEnumEX.exUnexpectedError);
			setErrorStep2(EAsnLogonErrorEnumEX.exUnexpectedError);
			theLogger.error(
				errorMsg,
				"login",
				getLogData(),
				{
					e: errorMsg
				},
				EError.AuthenticationErrorUCServer
			);
			setIsWaiting(false);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
	const stayLogged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWantToStayLogged(event.currentTarget.checked);
	};

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
	const useUcConnect = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorStep1(undefined);
		setUseUCConnect(event.currentTarget.checked);
	};

	/**
	 * Catch the ENTER key in the UCSID or Server URL field
	 * @param event - the keyboard event
	 */
	const onKeyDownUCSIDOrServerURI = (event: React.KeyboardEvent) => {
		if (event.key !== "Enter") return;
		event.preventDefault();
		void checkUCSIDOrURI();
	};

	/**
	 * Catch the ENTER key in the username and the password fields
	 * @param event - the keyboard event
	 */
	const onKeyDownUsernameOrPassword = (event: React.KeyboardEvent) => {
		if (event.key !== "Enter") return;
		event.preventDefault();
		void loginDirect();
	};

	return (
		<div className={styles.details} data-cy={"login-form"}>
			<div className={styles.detailsLeft}>
				<Typography className={styles.detailsWelcome} variant="h1">
					{getAppTitleInternal("IDS_WELCOME_UCMSTEAMSAPP")}
				</Typography>
				<p className={styles.detailsSubtitle}>{t("IDS_WELCOME_UCMSTEAMSAPP_SUBTITLE")}</p>
				<img className={styles.image} alt={""} src={"./assets/login-page-illustration.svg"} />
			</div>
			<div className={styles.detailsRight}>
				<div className={styles.detailsRightTop}>
					<Typography className={styles.title} variant="h2">
						{t("IDS_REGISTRATION_TITLE")}
					</Typography>
					<p className={styles.detailsSubtitle}>{t("IDS_REGISTRATION_SUBTITLE")}</p>
					<div className={styles.stepsContainer}>
						<div className={styles.steps}>
							<motion.div className={`${styles.step} ${!nextStep ? styles.selected : styles.default}`} />
							<motion.div
								className={`${styles.step} ${nextStep ? styles.selected : styles.default}`}
								animate={{
									opacity: nextStep ? 1 : 0.5
								}}
								transition={{
									ease: "linear",
									duration: 0.25
								}}
							/>
						</div>
						<Typography className={styles.detailsSubtitle} variant="caption">
							{t("IDS_STEPS", { currentStep: nextStep ? "2" : "1", stepCount: "2" })}
						</Typography>
					</div>
				</div>
				{!nextStep ? (
					<form id="login-form">
						<div>
							<Typography className={styles.loginTitle} variant="h3">
								{t("IDS_SERVER_LOGIN_TITLE")}
							</Typography>
							<Typography className={styles.loginSubtitle} variant="caption">
								{t("IDS_SERVER_LOGIN_SUBTITLE")}
							</Typography>
						</div>
						<FormControlLabel
							className={styles.formLabel}
							control={
								<Checkbox
									disabled={isWaiting}
									aria-description={t("IDS_LOGIN_CHECKBOX_UCCONNECT_DESCRIPTION")}
									color="secondary"
									checked={useUCConnect}
									aria-checked={useUCConnect}
									className={`${styles.checkbox} com-a11yFocusVisible-outline`}
									name="use_uc_connect"
									onChange={useUcConnect}
								/>
							}
							label={<Typography className={styles.insideLabel}>{t("IDS_LOGIN_UCCONNECT_USE")}</Typography>}
						/>
						<TextField
							disabled={isWaiting}
							className={`${styles.textField} com-a11yFocusVisible-outline`}
							inputProps={{
								autoComplete: "off",
								"data-testid": "idLoginFormUsernameInput"
							}}
							autoFocus={errorStep1 !== undefined}
							variant="outlined"
							size="small"
							margin="normal"
							fullWidth
							data-cy="login-form-ucsid"
							id={"input-ucsIdOrUcWeb"}
							name="ucsIdOrUcWeb"
							label={ucconnectLabel}
							type="text"
							value={formState.ucsIdOrUcWeb}
							onChange={handleChange}
							onKeyDown={(e) => {
								onKeyDownUCSIDOrServerURI(e);
							}}
							placeholder={useUCConnect ? t("IDS_LOGIN_UCSID_HINT") : t("IDS_LOGIN_UCSERVER_HINT")}
							helperText={errorStep1 !== undefined ? ErrorInfoHelper.parseLogonError(errorStep1) : ""}
							error={errorStep1 !== undefined}
							aria-errormessage={"input-ucsIdOrUcWeb-helper-text"}
							aria-invalid={errorStep1 !== undefined ? true : undefined}
						/>
						<Button
							disabled={isWaiting}
							id={"button-next"}
							sx={{ maxWidth: "150px", margin: "32px 0 0" }}
							variant="contained"
							onClick={() => {
								void checkUCSIDOrURI();
							}}
						>
							{t("IDS_LOGIN_NEXT")}
						</Button>
					</form>
				) : loginAvailable ? ( // some login challenge is available
					loginChallenges?.includes("entraid") ? (
						<form id="login-form">
							<div>
								<Typography className={styles.loginTitle} variant="h3">
									{t("IDS_LOGIN_USER_INFO_TITLE")}
								</Typography>
								<Typography className={styles.loginSubtitle} variant="caption">
									{t("IDS_LOGIN_MICROSOFT_USER_INFO_SUBTITLE")}
								</Typography>
							</div>
							<div className={styles.loginBottom}>
								<div>
									<Button
										className="com-a11yFocusVisible-outline"
										variant="outlined"
										fullWidth
										startIcon={msIcon}
										data-cy="login-form-confirm-microsoft"
										onClick={() => {
											void loginWithMicroSoft();
										}}
									>
										{t("IDS_LOGIN_OAUTH_MICROSOFT")}
									</Button>
								</div>
								{errorMSLogin ? (
									<p className={styles.requestError}>{ErrorInfoHelper.parseLogonError(errorMSLogin)}</p>
								) : null}
								<Button
									className="com-a11yFocusVisible-outline"
									sx={{ maxWidth: "150px", margin: "20px 0 0" }}
									disabled={formState.ucsIdOrUcWeb.length < 2}
									variant="outlined"
									fullWidth
									data-cy="login-form-confirm-microsoft"
									onClick={() => {
										setErrorMSLogin(undefined);
										setNextStep(false);
									}}
								>
									{t("IDS_BACK")}
								</Button>
							</div>
						</form>
					) : (
						<form id="login-form">
							<div>
								<Typography className={styles.loginTitle} variant="h3">
									{t("IDS_LOGIN_USER_INFO_TITLE")}
								</Typography>
								<Typography className={styles.loginSubtitle} variant="caption">
									{t("IDS_LOGIN_USER_INFO_SUBTITLE")}
								</Typography>
							</div>
							<TextField
								disabled={isWaiting}
								className={`${styles.textField} com-a11yFocusVisible-outline`}
								inputProps={{
									autoComplete: "off",
									"data-testid": "idLoginFormUsernameInput"
								}}
								autoFocus
								variant="outlined"
								size="small"
								fullWidth
								data-cy="login-form-username"
								value={formState.username}
								id={"input-username"}
								name="username"
								label={t("IDS_LOGIN_USERNAME")}
								type="text"
								onChange={handleChange}
								onKeyDown={(e) => {
									onKeyDownUsernameOrPassword(e);
								}}
								helperText={errorStep2 !== undefined ? ErrorInfoHelper.parseLogonError(errorStep2) : ""}
								error={errorStep2 !== undefined}
								aria-errormessage={"input-username-helper-text"}
								aria-invalid={errorStep2 !== undefined ? true : undefined}
							/>
							<TextField
								disabled={isWaiting}
								className={`${styles.textField} com-a11yFocusVisible-outline`}
								inputProps={{
									autoComplete: "off",
									"data-testid": "idLoginFormUsernameInput"
								}}
								variant="outlined"
								size="small"
								fullWidth
								data-cy="login-form-password"
								id="password"
								name="password"
								value={formState.password}
								label={t("IDS_LOGIN_PASSWORD")}
								type="password"
								onChange={handleChange}
								onKeyDown={(e) => {
									onKeyDownUsernameOrPassword(e);
								}}
							/>
							<div className={styles.formControl}>
								<FormControlLabel
									className={styles.formLabel}
									control={
										<Checkbox
											disabled={isWaiting}
											color="secondary"
											className={`${styles.checkbox} com-a11yFocusVisible-outline`}
											name="stay_logged"
											data-cy="login-stay-logged"
											onChange={stayLogged}
											role="checkbox"
											aria-label={t("IDS_STAY_LOGGED_IN")}
											aria-checked={wantToStayLogged}
										/>
									}
									label={<Typography className={styles.insideLabel}>{t("IDS_STAY_LOGGED_IN")}</Typography>}
								/>
							</div>

							<div className={styles.buttonContainer}>
								<Button
									disabled={isWaiting}
									sx={{
										width: "150px"
									}}
									className={`${styles.buttonNext} com-a11yFocusVisible-outline`}
									variant="outlined"
									role="button"
									aria-label={t("IDS_BACK")}
									onClick={() => {
										setNextStep(false);
										setErrorStep2(undefined);
									}}
								>
									{t("IDS_BACK")}
								</Button>
								<Button
									id={"button-login"}
									disabled={isWaiting}
									sx={{
										width: "150px"
									}}
									className={`${styles.buttonNext} com-a11yFocusVisible-outline`}
									variant="contained"
									role="button"
									aria-label={t("IDS_LOGIN_BUTTON")}
									onClick={loginDirect}
								>
									{t("IDS_LOGIN_BUTTON")}
								</Button>
							</div>
						</form>
					)
				) : null}
			</div>
			{isWaiting ? (
				<div className={styles.loadingSpinner}>
					<CircularProgress size={40} variant="indeterminate" />
				</div>
			) : null}
		</div>
	);
}
