import { AsnStringPair } from "../../asn1/EUCSrv/stubs/ENetUC_Common";
import { IAsnStringPair } from "../serializable/IAsnStringPair";

/**
 * convert from AsnStringPair to IAsnStringPair
 *
 * @param asnStringPair - the stringpair
 * @returns an IAsnStringPair
 */
export function stringPairConverter(asnStringPair?: AsnStringPair): IAsnStringPair | undefined {
	if (!asnStringPair)
		return undefined;
	return {
		key: asnStringPair.key,
		value: asnStringPair.value
	};
}

/**
 * convert from AsnStringPair to IAsnStringPair
 *
 * @param asnStringPairList - the stringpairlist
 * @returns an IAsnStringPair Array
 */
export function stringPairListConverter(asnStringPairList?: AsnStringPair[]): IAsnStringPair[] | undefined {
	if (!asnStringPairList)
		return undefined;
	const convertedPairList: IAsnStringPair[] = [];
	for (const pair of asnStringPairList) {
		const converted = stringPairConverter(pair);
		if (converted)
			convertedPairList.push(converted);
	}
	return convertedPairList;
}
