import { Stack } from "@mui/material";
import { Suspense, useEffect, useRef, useState } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import { ErrorBanner } from "../components/ErrorBanner/ErrorBanner";
import { theUCClient } from "../globals";
import SessionStorageHelper from "../lib/SessionStorageHelper";
import { Dashboard } from "../pages/Dashboard";
import LoginOauth from "../pages/Login/LoginOAuth";
import { ILoginParameters } from "../session/UCClient";
import { getState, useStore } from "../zustand/store";
import { LoginRouteWrapper } from "./LoginRouteWrapper";
import { ProtectedRoute } from "./ProtectedRoute";

const router = createBrowserRouter([
	{
		path: "/login",
		element: (
			<LoginRouteWrapper>
				<LoginOauth />
			</LoginRouteWrapper>
		)
	},
	{
		path: "/dashboard",
		element: (
			<ProtectedRoute>
				<Dashboard />
			</ProtectedRoute>
		)
	},
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<Navigate replace to="/dashboard" />
			</ProtectedRoute>
		)
	}
]);

/**
 * Router
 * @returns Router
 */
export const Router = () => {
	const [loading, setLoading] = useState(true);
	const loggedIn = useStore((state) => state.loggedIn);
	const token = useStore((state) => state.token);
	const ucsid = useStore((state) => state.ucsid);
	const ucserveruri = useStore((state) => state.ucserveruri);
	const setMySelfIsLogged = useStore((state) => state.setMySelfIsLogged);

	const isConnected = useRef(false);

	/**
	 * Component mount:
	 * - check if is there a token in the URL hash (from entraid oauth)
	 * - check if the latest login was done using entraid
	 */
	useEffect(() => {
		const findTokenInHasRegEx = /(?<=id_token=).*$/g;
		const tokenMatch = window.location.hash.match(findTokenInHasRegEx);
		let tokenInHash = tokenMatch ? tokenMatch[0] : undefined;
		const extraParamsRegEx = /(&session_state).*$/;
		if (tokenInHash) {
			tokenInHash = tokenInHash.replace(extraParamsRegEx, "");
			// in case we found a token in hash, set it to the session token and reload the page removing the hash
			// this way the rest of the logic is done in the existing useEffect
			// that retrieves a token from a session in case it's set
			// JWT or Bearer auth is done according to another parameter set in the session storage
			// Important: this token is only used for the first login with entraid
			// then, this one is removed and replaced with a UCServer one.
			// https://jira.estos.de/browse/PROCALL-5906
			SessionStorageHelper.setEntraIdToken(tokenInHash);
			window.location.hash = "";
		}
	}, []);

	useEffect(() => {
		if (isConnected.current) return;

		setLoading(true);
		const tokenFromEntraID = SessionStorageHelper.getEntraIdToken();
		const UCSIdForEntraID = SessionStorageHelper.getEntraIdLoginUCSID();

		if (tokenFromEntraID && UCSIdForEntraID) {
			const parameters: ILoginParameters = {
				UCSid: UCSIdForEntraID,
				token: tokenFromEntraID,
				useEntraIdAuth: true
			};
			SessionStorageHelper.removeEntraIdToken();
			theUCClient
				.connectStandAlone(parameters)
				.then((result) => {
					if (typeof result === "number") {
						getState().setMySelfToken(undefined);
						getState().setMySelfIsLogged(false);
					}
				})
				.catch((result) => {
					if (typeof result === "number") {
						getState().setMySelfToken(undefined);
						getState().setMySelfIsLogged(false);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else if (token) {
			// If the token is set, and we wanted to stay logged in the previous login, try to use the token to login
			theUCClient
				.connectStandAlone({ UCSid: ucsid, UCSUri: ucserveruri, token })
				.then((result) => {
					if (typeof result === "number") {
						getState().setMySelfToken(undefined);
						getState().setMySelfIsLogged(false);
					}
				})
				.catch((result) => {
					if (typeof result === "number") {
						getState().setMySelfToken(undefined);
						getState().setMySelfIsLogged(false);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(false);
			setMySelfIsLogged(false);
		}

		isConnected.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedIn, token]);

	if (loading) return null;

	return (
		<Suspense fallback={<div />}>
			<Stack height="100%">
				<ErrorBanner spinner showCloseIcon />
				<RouterProvider router={router} />
			</Stack>
		</Suspense>
	);
};
