// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_PresenceV2_Converter.ts
 * "UC-Server-Access-Protocol-PresenceV2" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_PresenceV2 from "./ENetUC_PresenceV2";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
import * as ENetUC_ClientCapabilities from "./ENetUC_ClientCapabilities";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";
import * as ENetUC_Common_Appointments_Converter from "./ENetUC_Common_Appointments_Converter";
import * as ENetUC_ClientCapabilities_Converter from "./ENetUC_ClientCapabilities_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_PresenceV2_Converter";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSEncoderDecoderCode]
export class AsnPresenceLine_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceLine, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceLine & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceLine");

		const t = {} as ENetUC_PresenceV2.AsnPresenceLine & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresenceLine";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnPresenceLine"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceLine | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceLine");

		let t: ENetUC_PresenceV2.AsnPresenceLine | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceLine>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnPresenceLine["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnPresenceLine"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceLine | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceLine";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceLine");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iState", "number", errors, newContext);
		TSConverter.validateParam(s, "iDoNotDisturb", "number", errors, newContext);
		TSConverter.validateParam(s, "iForwardFeaturesPBX", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineNumber, name: "u8sLineNumber" }));
			t.push(new asn1ts.Integer({ value: s.iState, name: "iState" }));
			t.push(new asn1ts.Integer({ value: s.iDoNotDisturb, name: "iDoNotDisturb" }));
			t.push(new asn1ts.Integer({ value: s.iForwardFeaturesPBX, name: "iForwardFeaturesPBX" }));
		}

		if (errors.validateResult(newContext, "AsnPresenceLine"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceLine | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceLine");

		let t: ENetUC_PresenceV2.AsnPresenceLine | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceLine.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnPresenceLine["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDoNotDisturb", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardFeaturesPBX", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnPresenceLine"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceCall_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceCall, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceCall & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceCall");

		const t = {} as ENetUC_PresenceV2.AsnPresenceCall & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresenceCall";
		TSConverter.fillJSONParam(s, t, "iAction", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCurrentNumberSC", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCallState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iOutbound", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iInternal", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCallType", "number", errors, newContext);
		if (s.remoteContact) {
			const _remoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.remoteContact, errors, newContext, "remoteContact");
			if (_remoteContact)
				t.remoteContact = _remoteContact;
		}
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnPresenceCall"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceCall | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceCall");

		let t: ENetUC_PresenceV2.AsnPresenceCall | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceCall>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnPresenceCall["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iAction", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCurrentNumberSC", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iCallState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iOutbound", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iInternal", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iCallType", "number", errors, newContext, false);
			const _remotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.remoteContact, errors, newContext, "remoteContact", true);
			if (_remotecontact)
				t.remoteContact = _remotecontact;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnPresenceCall"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceCall | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceCall";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceCall");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iAction", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCurrentNumberSC", "string", errors, newContext);
		TSConverter.validateParam(s, "iCallState", "number", errors, newContext);
		TSConverter.validateParam(s, "iFeatures", "number", errors, newContext);
		TSConverter.validateParam(s, "iOutbound", "number", errors, newContext);
		TSConverter.validateParam(s, "iInternal", "number", errors, newContext);
		TSConverter.validateParam(s, "iCallType", "number", errors, newContext);
		const _remoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.remoteContact, errors, newContext, "remoteContact", 0);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 1);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iAction, name: "iAction" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineNumber, name: "u8sLineNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCurrentNumberSC, name: "u8sCurrentNumberSC" }));
			t.push(new asn1ts.Integer({ value: s.iCallState, name: "iCallState" }));
			t.push(new asn1ts.Integer({ value: s.iFeatures, name: "iFeatures" }));
			t.push(new asn1ts.Integer({ value: s.iOutbound, name: "iOutbound" }));
			t.push(new asn1ts.Integer({ value: s.iInternal, name: "iInternal" }));
			t.push(new asn1ts.Integer({ value: s.iCallType, name: "iCallType" }));
			if (_remoteContact)
				t.push(_remoteContact);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnPresenceCall"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceCall | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceCall");

		let t: ENetUC_PresenceV2.AsnPresenceCall | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceCall.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnPresenceCall["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iAction", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCurrentNumberSC", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCallState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFeatures", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iOutbound", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iInternal", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCallType", "Integer", errors, newContext);
			t.remoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteContact"), errors, newContext, "remoteContact", true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnPresenceCall"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceForward_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceForward, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceForward & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceForward");

		const t = {} as ENetUC_PresenceV2.AsnPresenceForward & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresenceForward";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iInternalID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDestination", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardMode", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iNoAnswerTime", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnPresenceForward"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceForward | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceForward");

		let t: ENetUC_PresenceV2.AsnPresenceForward | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceForward>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnPresenceForward["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iInternalID", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDestination", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardMode", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iNoAnswerTime", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnPresenceForward"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceForward | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceForward";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceForward");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iInternalID", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDestination", "string", errors, newContext);
		TSConverter.validateParam(s, "iForwardMode", "number", errors, newContext);
		TSConverter.validateParam(s, "iNoAnswerTime", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineNumber, name: "u8sLineNumber" }));
			t.push(new asn1ts.Integer({ value: s.iInternalID, name: "iInternalID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDestination, name: "u8sDestination" }));
			t.push(new asn1ts.Integer({ value: s.iForwardMode, name: "iForwardMode" }));
			if (s.iNoAnswerTime !== undefined)
				t.push(new asn1ts.Integer({ value: s.iNoAnswerTime, name: "iNoAnswerTime", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnPresenceForward"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceForward | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceForward");

		let t: ENetUC_PresenceV2.AsnPresenceForward | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceForward.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnPresenceForward["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iInternalID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDestination", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardMode", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iNoAnswerTime", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnPresenceForward"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnActualUserDynamicRights_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnActualUserDynamicRights, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnActualUserDynamicRights & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActualUserDynamicRights");

		const t = {} as ENetUC_PresenceV2.AsnActualUserDynamicRights & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnActualUserDynamicRights";
		const _asnMinPossibleRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.asnMinPossibleRights, errors, newContext, "asnMinPossibleRights");
		if (_asnMinPossibleRights)
			t.asnMinPossibleRights = _asnMinPossibleRights;
		const _asnMaxPossibleRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.asnMaxPossibleRights, errors, newContext, "asnMaxPossibleRights");
		if (_asnMaxPossibleRights)
			t.asnMaxPossibleRights = _asnMaxPossibleRights;
		if (s.asnCurrentRights) {
			const _asnCurrentRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.asnCurrentRights, errors, newContext, "asnCurrentRights");
			if (_asnCurrentRights)
				t.asnCurrentRights = _asnCurrentRights;
		}

		if (errors.validateResult(newContext, "AsnActualUserDynamicRights"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnActualUserDynamicRights | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActualUserDynamicRights");

		let t: ENetUC_PresenceV2.AsnActualUserDynamicRights | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnActualUserDynamicRights>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnActualUserDynamicRights["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _asnminpossiblerights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.asnMinPossibleRights, errors, newContext, "asnMinPossibleRights", false);
			if (_asnminpossiblerights)
				t.asnMinPossibleRights = _asnminpossiblerights;
			const _asnmaxpossiblerights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.asnMaxPossibleRights, errors, newContext, "asnMaxPossibleRights", false);
			if (_asnmaxpossiblerights)
				t.asnMaxPossibleRights = _asnmaxpossiblerights;
			const _asncurrentrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.asnCurrentRights, errors, newContext, "asnCurrentRights", true);
			if (_asncurrentrights)
				t.asnCurrentRights = _asncurrentrights;
		}

		if (errors.validateResult(newContext, "AsnActualUserDynamicRights"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnActualUserDynamicRights | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnActualUserDynamicRights";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActualUserDynamicRights");

		// [Print_BER_EncoderSeqDefCode]
		const _asnMinPossibleRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.asnMinPossibleRights, errors, newContext, "asnMinPossibleRights");
		const _asnMaxPossibleRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.asnMaxPossibleRights, errors, newContext, "asnMaxPossibleRights");
		const _asnCurrentRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.asnCurrentRights, errors, newContext, "asnCurrentRights", 0);
		if (!errors.hasNewErrors()) {
			if (_asnMinPossibleRights)
				t.push(_asnMinPossibleRights);
			if (_asnMaxPossibleRights)
				t.push(_asnMaxPossibleRights);
			if (_asnCurrentRights)
				t.push(_asnCurrentRights);
		}

		if (errors.validateResult(newContext, "AsnActualUserDynamicRights"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnActualUserDynamicRights | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActualUserDynamicRights");

		let t: ENetUC_PresenceV2.AsnActualUserDynamicRights | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnActualUserDynamicRights.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnActualUserDynamicRights["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _asnminpossiblerights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnMinPossibleRights"), errors, newContext, "asnMinPossibleRights");
			if (_asnminpossiblerights)
				t.asnMinPossibleRights = _asnminpossiblerights;
			const _asnmaxpossiblerights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnMaxPossibleRights"), errors, newContext, "asnMaxPossibleRights");
			if (_asnmaxpossiblerights)
				t.asnMaxPossibleRights = _asnmaxpossiblerights;
			t.asnCurrentRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnCurrentRights"), errors, newContext, "asnCurrentRights", true);
		}

		if (errors.validateResult(newContext, "AsnActualUserDynamicRights"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAgentState_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnAgentState, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnAgentState & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAgentState");

		const t = {} as ENetUC_PresenceV2.AsnAgentState & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAgentState";
		TSConverter.fillJSONParam(s, t, "dwState", "number", errors, newContext);
		if (s.mapDetails) {
			const _mapDetails = ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.toJSON(s.mapDetails, errors, newContext, "mapDetails");
			if (_mapDetails)
				t.mapDetails = _mapDetails;
		}

		if (errors.validateResult(newContext, "AsnAgentState"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnAgentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAgentState");

		let t: ENetUC_PresenceV2.AsnAgentState | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnAgentState>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnAgentState["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "dwState", "number", errors, newContext, false);
			const _mapdetails = ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.fromJSON(s.mapDetails, errors, newContext, "mapDetails", true);
			if (_mapdetails)
				t.mapDetails = _mapdetails;
		}

		if (errors.validateResult(newContext, "AsnAgentState"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnAgentState | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAgentState";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAgentState");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "dwState", "number", errors, newContext);
		const _mapDetails = ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.toBER(s.mapDetails, errors, newContext, "mapDetails", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.dwState, name: "dwState" }));
			if (_mapDetails)
				t.push(_mapDetails);
		}

		if (errors.validateResult(newContext, "AsnAgentState"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnAgentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAgentState");

		let t: ENetUC_PresenceV2.AsnAgentState | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnAgentState.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnAgentState["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "dwState", "Integer", errors, newContext);
			t.mapDetails = ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "mapDetails"), errors, newContext, "mapDetails", true);
		}

		if (errors.validateResult(newContext, "AsnAgentState"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRemovePresenceArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRemovePresenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRemovePresenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemovePresenceArgument");

		const t = {} as ENetUC_PresenceV2.AsnRemovePresenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRemovePresenceArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRemovePresenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemovePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemovePresenceArgument");

		let t: ENetUC_PresenceV2.AsnRemovePresenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRemovePresenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRemovePresenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRemovePresenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRemovePresenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRemovePresenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemovePresenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
		}

		if (errors.validateResult(newContext, "AsnRemovePresenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemovePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemovePresenceArgument");

		let t: ENetUC_PresenceV2.AsnRemovePresenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRemovePresenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRemovePresenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRemovePresenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAndSubscribePresenceArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceArgument");

		const t = {} as ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAndSubscribePresenceArgument";
		const _seqContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (_seqContactIDs)
			t.seqContactIDs = _seqContactIDs;
		TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iContactDetailsReturnFilter", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bDontRequestPermissions", "boolean", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceArgument");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.seqContactIDs, errors, newContext, "seqContactIDs", false);
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
			TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iContactDetailsReturnFilter", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bDontRequestPermissions", "boolean", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAndSubscribePresenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _seqContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.seqContactIDs, errors, newContext, "seqContactIDs");
		TSConverter.validateParam(s, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iContactDetailsReturnFilter", "number", errors, newContext, true);
		TSConverter.validateParam(s, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bDontRequestPermissions", "boolean", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (_seqContactIDs)
				t.push(_seqContactIDs);
			if (s.iEventFlagsToSubscribe !== undefined)
				t.push(new asn1ts.Integer({ value: s.iEventFlagsToSubscribe, name: "iEventFlagsToSubscribe", idBlock: { optionalID: 0 } }));
			if (s.iContactDetailsReturnFilter !== undefined)
				t.push(new asn1ts.Integer({ value: s.iContactDetailsReturnFilter, name: "iContactDetailsReturnFilter", idBlock: { optionalID: 1 } }));
			if (s.bOpenOnlyInternalContacts !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bOpenOnlyInternalContacts, name: "bOpenOnlyInternalContacts", idBlock: { optionalID: 2 } }));
			if (s.bDontRequestPermissions !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bDontRequestPermissions, name: "bDontRequestPermissions", idBlock: { optionalID: 3 } }));
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceArgument");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqContactIDs"), errors, newContext, "seqContactIDs");
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
			TSConverter.fillASN1Param(s, t, "iEventFlagsToSubscribe", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iContactDetailsReturnFilter", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bOpenOnlyInternalContacts", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bDontRequestPermissions", "Boolean", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribePresenceArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnUnsubscribePresenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribePresenceArgument");

		const t = {} as ENetUC_PresenceV2.AsnUnsubscribePresenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribePresenceArgument";
		const _seqContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (_seqContactIDs)
			t.seqContactIDs = _seqContactIDs;

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUnsubscribePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribePresenceArgument");

		let t: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnUnsubscribePresenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnUnsubscribePresenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.seqContactIDs, errors, newContext, "seqContactIDs", false);
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribePresenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribePresenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _seqContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (!errors.hasNewErrors()) {
			if (_seqContactIDs)
				t.push(_seqContactIDs);
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUnsubscribePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribePresenceArgument");

		let t: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnUnsubscribePresenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnUnsubscribePresenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqContactIDs"), errors, newContext, "seqContactIDs");
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribePresenceResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnUnsubscribePresenceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnUnsubscribePresenceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribePresenceResult");

		const t = {} as ENetUC_PresenceV2.AsnUnsubscribePresenceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribePresenceResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUnsubscribePresenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribePresenceResult");

		let t: ENetUC_PresenceV2.AsnUnsubscribePresenceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnUnsubscribePresenceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnUnsubscribePresenceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnUnsubscribePresenceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribePresenceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribePresenceResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUnsubscribePresenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribePresenceResult");

		let t: ENetUC_PresenceV2.AsnUnsubscribePresenceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnUnsubscribePresenceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnUnsubscribePresenceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUnsubscribePresenceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetDynamicRightsArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnSetDynamicRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnSetDynamicRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetDynamicRightsArgument");

		const t = {} as ENetUC_PresenceV2.AsnSetDynamicRightsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetDynamicRightsArgument";
		TSConverter.fillJSONParam(s, t, "u8sOwnerContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRemoteContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDynamicRights", "number", errors, newContext);
		if (s.dynamicRights) {
			const _dynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.dynamicRights, errors, newContext, "dynamicRights");
			if (_dynamicRights)
				t.dynamicRights = _dynamicRights;
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetDynamicRightsArgument");

		let t: ENetUC_PresenceV2.AsnSetDynamicRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnSetDynamicRightsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnSetDynamicRightsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sOwnerContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sRemoteContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDynamicRights", "number", errors, newContext, false);
			const _dynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.dynamicRights, errors, newContext, "dynamicRights", true);
			if (_dynamicrights)
				t.dynamicRights = _dynamicrights;
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnSetDynamicRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetDynamicRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetDynamicRightsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sOwnerContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRemoteContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iDynamicRights", "number", errors, newContext);
		const _dynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.dynamicRights, errors, newContext, "dynamicRights", 1);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sOwnerContactID, name: "u8sOwnerContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRemoteContactID, name: "u8sRemoteContactID" }));
			t.push(new asn1ts.Integer({ value: s.iDynamicRights, name: "iDynamicRights" }));
			if (_dynamicRights)
				t.push(_dynamicRights);
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetDynamicRightsArgument");

		let t: ENetUC_PresenceV2.AsnSetDynamicRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnSetDynamicRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnSetDynamicRightsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sOwnerContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRemoteContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDynamicRights", "Integer", errors, newContext);
			t.dynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "dynamicRights"), errors, newContext, "dynamicRights", true);
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetDynamicRightsResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnSetDynamicRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnSetDynamicRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetDynamicRightsResult");

		const t = {} as ENetUC_PresenceV2.AsnSetDynamicRightsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetDynamicRightsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		const _dynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.dynamicRights, errors, newContext, "dynamicRights");
		if (_dynamicRights)
			t.dynamicRights = _dynamicRights;

		if (errors.validateResult(newContext, "AsnSetDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetDynamicRightsResult");

		let t: ENetUC_PresenceV2.AsnSetDynamicRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnSetDynamicRightsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnSetDynamicRightsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
			const _dynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.dynamicRights, errors, newContext, "dynamicRights", false);
			if (_dynamicrights)
				t.dynamicRights = _dynamicrights;
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnSetDynamicRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetDynamicRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetDynamicRightsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		const _dynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.dynamicRights, errors, newContext, "dynamicRights");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (_dynamicRights)
				t.push(_dynamicRights);
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetDynamicRightsResult");

		let t: ENetUC_PresenceV2.AsnSetDynamicRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnSetDynamicRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnSetDynamicRightsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			const _dynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "dynamicRights"), errors, newContext, "dynamicRights");
			if (_dynamicrights)
				t.dynamicRights = _dynamicrights;
		}

		if (errors.validateResult(newContext, "AsnSetDynamicRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRemoveDynamicRightsArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoveDynamicRightsArgument");

		const t = {} as ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRemoveDynamicRightsArgument";
		TSConverter.fillJSONParam(s, t, "u8sOwnerContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRemoteContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoveDynamicRightsArgument");

		let t: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sOwnerContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sRemoteContactID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRemoveDynamicRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoveDynamicRightsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sOwnerContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRemoteContactID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sOwnerContactID, name: "u8sOwnerContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRemoteContactID, name: "u8sRemoteContactID" }));
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoveDynamicRightsArgument");

		let t: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sOwnerContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRemoteContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRemoveDynamicRightsResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRemoveDynamicRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoveDynamicRightsResult");

		const t = {} as ENetUC_PresenceV2.AsnRemoveDynamicRightsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRemoveDynamicRightsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemoveDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoveDynamicRightsResult");

		let t: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRemoveDynamicRightsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRemoveDynamicRightsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRemoveDynamicRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoveDynamicRightsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRemoveDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoveDynamicRightsResult");

		let t: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRemoveDynamicRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRemoveDynamicRightsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRemoveDynamicRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestLargeProfileImageArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestLargeProfileImageArgument");

		const t = {} as ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestLargeProfileImageArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sKnownHash", "string", errors, newContext);
		if (s.netDatabaseContactID) {
			const _netDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID");
			if (_netDatabaseContactID)
				t.netDatabaseContactID = _netDatabaseContactID;
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestLargeProfileImageArgument");

		let t: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sKnownHash", "string", errors, newContext, false);
			const _netdatabasecontactid = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID", true);
			if (_netdatabasecontactid)
				t.netDatabaseContactID = _netdatabasecontactid;
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestLargeProfileImageArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestLargeProfileImageArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sKnownHash", "string", errors, newContext);
		const _netDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toBER(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sKnownHash, name: "u8sKnownHash" }));
			if (_netDatabaseContactID)
				t.push(_netDatabaseContactID);
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestLargeProfileImageArgument");

		let t: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sKnownHash", "Utf8String", errors, newContext);
			t.netDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "netDatabaseContactID"), errors, newContext, "netDatabaseContactID", true);
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestLargeProfileImageResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRequestLargeProfileImageResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestLargeProfileImageResult");

		const t = {} as ENetUC_PresenceV2.AsnRequestLargeProfileImageResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestLargeProfileImageResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sImageData", "Uint8Array", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sImageHash", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestLargeProfileImageResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestLargeProfileImageResult");

		let t: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRequestLargeProfileImageResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRequestLargeProfileImageResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
			if (TSConverter.validateParam(s, "sImageData", "string", errors, newContext, true) && s.sImageData)
				t.sImageData = TSConverter.decode64(s.sImageData as unknown as string);
			TSConverter.fillJSONParam(s, t, "u8sImageHash", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestLargeProfileImageResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestLargeProfileImageResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "sImageData", "Uint8Array", errors, newContext, true);
		TSConverter.validateParam(s, "u8sImageHash", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (s.sImageData !== undefined)
				t.push(new asn1ts.OctetString({ valueHex: s.sImageData, name: "sImageData", idBlock: { optionalID: 0 } }));
			if (s.u8sImageHash !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sImageHash, name: "u8sImageHash", idBlock: { optionalID: 1 } }));
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestLargeProfileImageResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestLargeProfileImageResult");

		let t: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRequestLargeProfileImageResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRequestLargeProfileImageResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sImageData", "OctetString", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sImageHash", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRequestLargeProfileImageResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestContactImageArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRequestContactImageArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRequestContactImageArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestContactImageArgument");

		const t = {} as ENetUC_PresenceV2.AsnRequestContactImageArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestContactImageArgument";
		const _netDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID");
		if (_netDatabaseContactID)
			t.netDatabaseContactID = _netDatabaseContactID;
		TSConverter.fillJSONParam(s, t, "u8sKnownHash", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRequestContactImageArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestContactImageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestContactImageArgument");

		let t: ENetUC_PresenceV2.AsnRequestContactImageArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRequestContactImageArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRequestContactImageArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _netdatabasecontactid = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID", false);
			if (_netdatabasecontactid)
				t.netDatabaseContactID = _netdatabasecontactid;
			TSConverter.fillJSONParam(s, t, "u8sKnownHash", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRequestContactImageArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestContactImageArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestContactImageArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _netDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toBER(s.netDatabaseContactID, errors, newContext, "netDatabaseContactID");
		TSConverter.validateParam(s, "u8sKnownHash", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_netDatabaseContactID)
				t.push(_netDatabaseContactID);
			t.push(new asn1ts.Utf8String({ value: s.u8sKnownHash, name: "u8sKnownHash" }));
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestContactImageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestContactImageArgument");

		let t: ENetUC_PresenceV2.AsnRequestContactImageArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRequestContactImageArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRequestContactImageArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _netdatabasecontactid = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "netDatabaseContactID"), errors, newContext, "netDatabaseContactID");
			if (_netdatabasecontactid)
				t.netDatabaseContactID = _netdatabasecontactid;
			TSConverter.fillASN1Param(s, t, "u8sKnownHash", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestContactImageResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnRequestContactImageResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnRequestContactImageResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestContactImageResult");

		const t = {} as ENetUC_PresenceV2.AsnRequestContactImageResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestContactImageResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sImageData", "Uint8Array", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sImageHash", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnRequestContactImageResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestContactImageResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestContactImageResult");

		let t: ENetUC_PresenceV2.AsnRequestContactImageResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnRequestContactImageResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnRequestContactImageResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
			if (TSConverter.validateParam(s, "sImageData", "string", errors, newContext, true) && s.sImageData)
				t.sImageData = TSConverter.decode64(s.sImageData as unknown as string);
			TSConverter.fillJSONParam(s, t, "u8sImageHash", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnRequestContactImageResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestContactImageResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestContactImageResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "sImageData", "Uint8Array", errors, newContext, true);
		TSConverter.validateParam(s, "u8sImageHash", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (s.sImageData !== undefined)
				t.push(new asn1ts.OctetString({ valueHex: s.sImageData, name: "sImageData", idBlock: { optionalID: 0 } }));
			if (s.u8sImageHash !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sImageHash, name: "u8sImageHash", idBlock: { optionalID: 1 } }));
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnRequestContactImageResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestContactImageResult");

		let t: ENetUC_PresenceV2.AsnRequestContactImageResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnRequestContactImageResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnRequestContactImageResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sImageData", "OctetString", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sImageHash", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRequestContactImageResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMonitorGroupingAttribute_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnMonitorGroupingAttribute, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnMonitorGroupingAttribute & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMonitorGroupingAttribute");

		const t = {} as ENetUC_PresenceV2.AsnMonitorGroupingAttribute & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMonitorGroupingAttribute";
		TSConverter.fillJSONParam(s, t, "u8sAttributeGUID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAttributeShort", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAttributeLong", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAdditionalText", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnMonitorGroupingAttribute"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnMonitorGroupingAttribute | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMonitorGroupingAttribute");

		let t: ENetUC_PresenceV2.AsnMonitorGroupingAttribute | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnMonitorGroupingAttribute>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnMonitorGroupingAttribute["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sAttributeGUID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sAttributeShort", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sAttributeLong", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sAdditionalText", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnMonitorGroupingAttribute"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnMonitorGroupingAttribute | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMonitorGroupingAttribute";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMonitorGroupingAttribute");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sAttributeGUID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAttributeShort", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAttributeLong", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAdditionalText", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (s.u8sAttributeGUID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAttributeGUID, name: "u8sAttributeGUID", idBlock: { optionalID: 0 } }));
			if (s.u8sAttributeShort !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAttributeShort, name: "u8sAttributeShort", idBlock: { optionalID: 1 } }));
			if (s.u8sAttributeLong !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAttributeLong, name: "u8sAttributeLong", idBlock: { optionalID: 2 } }));
			if (s.u8sAdditionalText !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAdditionalText, name: "u8sAdditionalText", idBlock: { optionalID: 3 } }));
		}

		if (errors.validateResult(newContext, "AsnMonitorGroupingAttribute"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnMonitorGroupingAttribute | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMonitorGroupingAttribute");

		let t: ENetUC_PresenceV2.AsnMonitorGroupingAttribute | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnMonitorGroupingAttribute.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnMonitorGroupingAttribute["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sAttributeGUID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAttributeShort", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAttributeLong", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAdditionalText", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnMonitorGroupingAttribute"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetMonitorGroupingAttributeResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMonitorGroupingAttributeResult");

		const t = {} as ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetMonitorGroupingAttributeResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMonitorGroupingAttributeResult");

		let t: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetMonitorGroupingAttributeResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMonitorGroupingAttributeResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMonitorGroupingAttributeResult");

		let t: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceLines_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceLines, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceLines | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceLines");

		const t = [] as ENetUC_PresenceV2.AsnPresenceLines;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnPresenceLine_Converter.toJSON(se, errors, newContext, "AsnPresenceLine");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnPresenceLines"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceLines | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceLines");

		let t: ENetUC_PresenceV2.AsnPresenceLines | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceLines>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_PresenceV2.AsnPresenceLines();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnPresenceLine_Converter.fromJSON(se, errors, newContext, "AsnPresenceLine", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceLines"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceLines | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceLines";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceLines");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnPresenceLine_Converter.toBER(s[id], errors, newContext, "AsnPresenceLine");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnPresenceLines"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceLines | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceLines");

		let t: ENetUC_PresenceV2.AsnPresenceLines | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceLines.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_PresenceV2.AsnPresenceLines();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnPresenceLine_Converter.fromBER(se, errors, newContext, "AsnPresenceLine", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceLines"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceCalls_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceCalls, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceCalls | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceCalls");

		const t = [] as ENetUC_PresenceV2.AsnPresenceCalls;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnPresenceCall_Converter.toJSON(se, errors, newContext, "AsnPresenceCall");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnPresenceCalls"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceCalls | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceCalls");

		let t: ENetUC_PresenceV2.AsnPresenceCalls | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceCalls>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_PresenceV2.AsnPresenceCalls();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnPresenceCall_Converter.fromJSON(se, errors, newContext, "AsnPresenceCall", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceCalls"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceCalls | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceCalls";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceCalls");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnPresenceCall_Converter.toBER(s[id], errors, newContext, "AsnPresenceCall");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnPresenceCalls"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceCalls | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceCalls");

		let t: ENetUC_PresenceV2.AsnPresenceCalls | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceCalls.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_PresenceV2.AsnPresenceCalls();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnPresenceCall_Converter.fromBER(se, errors, newContext, "AsnPresenceCall", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceCalls"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceForwards_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceForwards, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceForwards | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceForwards");

		const t = [] as ENetUC_PresenceV2.AsnPresenceForwards;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnPresenceForward_Converter.toJSON(se, errors, newContext, "AsnPresenceForward");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnPresenceForwards"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceForwards | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceForwards");

		let t: ENetUC_PresenceV2.AsnPresenceForwards | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceForwards>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_PresenceV2.AsnPresenceForwards();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnPresenceForward_Converter.fromJSON(se, errors, newContext, "AsnPresenceForward", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceForwards"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceForwards | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceForwards";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceForwards");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnPresenceForward_Converter.toBER(s[id], errors, newContext, "AsnPresenceForward");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnPresenceForwards"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceForwards | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceForwards");

		let t: ENetUC_PresenceV2.AsnPresenceForwards | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceForwards.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_PresenceV2.AsnPresenceForwards();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnPresenceForward_Converter.fromBER(se, errors, newContext, "AsnPresenceForward", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceForwards"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresence_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresence, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresence & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresence");

		const t = {} as ENetUC_PresenceV2.AsnPresence & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresence";
		TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext, true);
		if (s.asnRemoteContact) {
			const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact");
			if (_asnRemoteContact)
				t.asnRemoteContact = _asnRemoteContact;
		}
		if (s.asnUserPropertyBag) {
			const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
			if (_asnUserPropertyBag)
				t.asnUserPropertyBag = _asnUserPropertyBag;
		}
		TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iStaticRights", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iMonitorAccessRights", "number", errors, newContext, true);
		if (s.asnAbsentState) {
			const _asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(s.asnAbsentState, errors, newContext, "asnAbsentState");
			if (_asnAbsentState)
				t.asnAbsentState = _asnAbsentState;
		}
		if (s.asnCustomNote) {
			const _asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(s.asnCustomNote, errors, newContext, "asnCustomNote");
			if (_asnCustomNote)
				t.asnCustomNote = _asnCustomNote;
		}
		if (s.seqActualAppointments) {
			const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments");
			if (_seqActualAppointments)
				t.seqActualAppointments = _seqActualAppointments;
		}
		if (s.asnNextAppointment) {
			const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
			if (_asnNextAppointment)
				t.asnNextAppointment = _asnNextAppointment;
		}
		if (s.seqPhoneLines) {
			const _seqPhoneLines = AsnPresenceLines_Converter.toJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines");
			if (_seqPhoneLines)
				t.seqPhoneLines = _seqPhoneLines;
		}
		if (s.seqCalls) {
			const _seqCalls = AsnPresenceCalls_Converter.toJSON(s.seqCalls, errors, newContext, "seqCalls");
			if (_seqCalls)
				t.seqCalls = _seqCalls;
		}
		if (s.seqLineForwards) {
			const _seqLineForwards = AsnPresenceForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
			if (_seqLineForwards)
				t.seqLineForwards = _seqLineForwards;
		}
		if (s.asnContactCapabilitiesEx) {
			const _asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.toJSON(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx");
			if (_asnContactCapabilitiesEx)
				t.asnContactCapabilitiesEx = _asnContactCapabilitiesEx;
		}
		if (s.asnCurrentUserDynamicRights) {
			const _asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.toJSON(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights");
			if (_asnCurrentUserDynamicRights)
				t.asnCurrentUserDynamicRights = _asnCurrentUserDynamicRights;
		}
		TSConverter.fillJSONParam(s, t, "iStaticRights2", "number", errors, newContext, true);
		if (s.asnAgentState) {
			const _asnAgentState = AsnAgentState_Converter.toJSON(s.asnAgentState, errors, newContext, "asnAgentState");
			if (_asnAgentState)
				t.asnAgentState = _asnAgentState;
		}
		if (s.asnMonitorGroupingAttribute) {
			const _asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.toJSON(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute");
			if (_asnMonitorGroupingAttribute)
				t.asnMonitorGroupingAttribute = _asnMonitorGroupingAttribute;
		}
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnPresence"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresence | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresence");

		let t: ENetUC_PresenceV2.AsnPresence | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresence>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnPresence["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext, true);
			const _asnremotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact", true);
			if (_asnremotecontact)
				t.asnRemoteContact = _asnremotecontact;
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", true);
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
			TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iStaticRights", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iMonitorAccessRights", "number", errors, newContext, true);
			const _asnabsentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(s.asnAbsentState, errors, newContext, "asnAbsentState", true);
			if (_asnabsentstate)
				t.asnAbsentState = _asnabsentstate;
			const _asncustomnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(s.asnCustomNote, errors, newContext, "asnCustomNote", true);
			if (_asncustomnote)
				t.asnCustomNote = _asncustomnote;
			const _seqactualappointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments", true);
			if (_seqactualappointments)
				t.seqActualAppointments = _seqactualappointments;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment", true);
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
			const _seqphonelines = AsnPresenceLines_Converter.fromJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines", true);
			if (_seqphonelines)
				t.seqPhoneLines = _seqphonelines;
			const _seqcalls = AsnPresenceCalls_Converter.fromJSON(s.seqCalls, errors, newContext, "seqCalls", true);
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			const _seqlineforwards = AsnPresenceForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", true);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _asncontactcapabilitiesex = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.fromJSON(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx", true);
			if (_asncontactcapabilitiesex)
				t.asnContactCapabilitiesEx = _asncontactcapabilitiesex;
			const _asncurrentuserdynamicrights = AsnActualUserDynamicRights_Converter.fromJSON(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights", true);
			if (_asncurrentuserdynamicrights)
				t.asnCurrentUserDynamicRights = _asncurrentuserdynamicrights;
			TSConverter.fillJSONParam(s, t, "iStaticRights2", "number", errors, newContext, true);
			const _asnagentstate = AsnAgentState_Converter.fromJSON(s.asnAgentState, errors, newContext, "asnAgentState", true);
			if (_asnagentstate)
				t.asnAgentState = _asnagentstate;
			const _asnmonitorgroupingattribute = AsnMonitorGroupingAttribute_Converter.fromJSON(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute", true);
			if (_asnmonitorgroupingattribute)
				t.asnMonitorGroupingAttribute = _asnmonitorgroupingattribute;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnPresence"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresence | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresence";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresence");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactId", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext, true);
		const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnRemoteContact, errors, newContext, "asnRemoteContact", 1);
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toBER(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", 2);
		TSConverter.validateParam(s, "iContactFeatureSet", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iStaticRights", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iMonitorAccessRights", "number", errors, newContext, true);
		const _asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toBER(s.asnAbsentState, errors, newContext, "asnAbsentState", 6);
		const _asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toBER(s.asnCustomNote, errors, newContext, "asnCustomNote", 7);
		const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.seqActualAppointments, errors, newContext, "seqActualAppointments", 8);
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toBER(s.asnNextAppointment, errors, newContext, "asnNextAppointment", 9);
		const _seqPhoneLines = AsnPresenceLines_Converter.toBER(s.seqPhoneLines, errors, newContext, "seqPhoneLines", 10);
		const _seqCalls = AsnPresenceCalls_Converter.toBER(s.seqCalls, errors, newContext, "seqCalls", 11);
		const _seqLineForwards = AsnPresenceForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards", 12);
		const _asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.toBER(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx", 14);
		const _asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.toBER(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights", 15);
		TSConverter.validateParam(s, "iStaticRights2", "number", errors, newContext, true);
		const _asnAgentState = AsnAgentState_Converter.toBER(s.asnAgentState, errors, newContext, "asnAgentState", 17);
		const _asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.toBER(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute", 18);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 99);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactId, name: "u8sContactId" }));
			if (s.iPresenceState !== undefined)
				t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState", idBlock: { optionalID: 0 } }));
			if (_asnRemoteContact)
				t.push(_asnRemoteContact);
			if (_asnUserPropertyBag)
				t.push(_asnUserPropertyBag);
			if (s.iContactFeatureSet !== undefined)
				t.push(new asn1ts.Integer({ value: s.iContactFeatureSet, name: "iContactFeatureSet", idBlock: { optionalID: 3 } }));
			if (s.iStaticRights !== undefined)
				t.push(new asn1ts.Integer({ value: s.iStaticRights, name: "iStaticRights", idBlock: { optionalID: 4 } }));
			if (s.iMonitorAccessRights !== undefined)
				t.push(new asn1ts.Integer({ value: s.iMonitorAccessRights, name: "iMonitorAccessRights", idBlock: { optionalID: 5 } }));
			if (_asnAbsentState)
				t.push(_asnAbsentState);
			if (_asnCustomNote)
				t.push(_asnCustomNote);
			if (_seqActualAppointments)
				t.push(_seqActualAppointments);
			if (_asnNextAppointment)
				t.push(_asnNextAppointment);
			if (_seqPhoneLines)
				t.push(_seqPhoneLines);
			if (_seqCalls)
				t.push(_seqCalls);
			if (_seqLineForwards)
				t.push(_seqLineForwards);
			if (_asnContactCapabilitiesEx)
				t.push(_asnContactCapabilitiesEx);
			if (_asnCurrentUserDynamicRights)
				t.push(_asnCurrentUserDynamicRights);
			if (s.iStaticRights2 !== undefined)
				t.push(new asn1ts.Integer({ value: s.iStaticRights2, name: "iStaticRights2", idBlock: { optionalID: 16 } }));
			if (_asnAgentState)
				t.push(_asnAgentState);
			if (_asnMonitorGroupingAttribute)
				t.push(_asnMonitorGroupingAttribute);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnPresence"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresence | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresence");

		let t: ENetUC_PresenceV2.AsnPresence | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresence.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnPresence["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext, true);
			t.asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnRemoteContact"), errors, newContext, "asnRemoteContact", true);
			t.asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnUserPropertyBag"), errors, newContext, "asnUserPropertyBag", true);
			TSConverter.fillASN1Param(s, t, "iContactFeatureSet", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iStaticRights", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iMonitorAccessRights", "Integer", errors, newContext, true);
			t.asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnAbsentState"), errors, newContext, "asnAbsentState", true);
			t.asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnCustomNote"), errors, newContext, "asnCustomNote", true);
			t.seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqActualAppointments"), errors, newContext, "seqActualAppointments", true);
			t.asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointment"), errors, newContext, "asnNextAppointment", true);
			t.seqPhoneLines = AsnPresenceLines_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqPhoneLines"), errors, newContext, "seqPhoneLines", true);
			t.seqCalls = AsnPresenceCalls_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqCalls"), errors, newContext, "seqCalls", true);
			t.seqLineForwards = AsnPresenceForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards", true);
			t.asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnContactCapabilitiesEx"), errors, newContext, "asnContactCapabilitiesEx", true);
			t.asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnCurrentUserDynamicRights"), errors, newContext, "asnCurrentUserDynamicRights", true);
			TSConverter.fillASN1Param(s, t, "iStaticRights2", "Integer", errors, newContext, true);
			t.asnAgentState = AsnAgentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnAgentState"), errors, newContext, "asnAgentState", true);
			t.asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnMonitorGroupingAttribute"), errors, newContext, "asnMonitorGroupingAttribute", true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnPresence"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceList_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceList");

		const t = [] as ENetUC_PresenceV2.AsnPresenceList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnPresence_Converter.toJSON(se, errors, newContext, "AsnPresence");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnPresenceList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceList");

		let t: ENetUC_PresenceV2.AsnPresenceList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_PresenceV2.AsnPresenceList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnPresence_Converter.fromJSON(se, errors, newContext, "AsnPresence", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnPresence_Converter.toBER(s[id], errors, newContext, "AsnPresence");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnPresenceList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceList");

		let t: ENetUC_PresenceV2.AsnPresenceList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_PresenceV2.AsnPresenceList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnPresence_Converter.fromBER(se, errors, newContext, "AsnPresence", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceV2_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceV2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceV2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceV2");

		const t = {} as ENetUC_PresenceV2.AsnPresenceV2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresenceV2";
		TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext, true);
		if (s.asnRemoteContact) {
			const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact");
			if (_asnRemoteContact)
				t.asnRemoteContact = _asnRemoteContact;
		}
		if (s.asnUserPropertyBag) {
			const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
			if (_asnUserPropertyBag)
				t.asnUserPropertyBag = _asnUserPropertyBag;
		}
		TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iStaticRights", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iMonitorAccessRights", "number", errors, newContext, true);
		if (s.asnAbsentState) {
			const _asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentStateV2_Converter.toJSON(s.asnAbsentState, errors, newContext, "asnAbsentState");
			if (_asnAbsentState)
				t.asnAbsentState = _asnAbsentState;
		}
		if (s.asnCustomNote) {
			const _asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(s.asnCustomNote, errors, newContext, "asnCustomNote");
			if (_asnCustomNote)
				t.asnCustomNote = _asnCustomNote;
		}
		if (s.seqActualAppointments) {
			const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments");
			if (_seqActualAppointments)
				t.seqActualAppointments = _seqActualAppointments;
		}
		if (s.asnNextAppointment) {
			const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
			if (_asnNextAppointment)
				t.asnNextAppointment = _asnNextAppointment;
		}
		if (s.seqPhoneLines) {
			const _seqPhoneLines = AsnPresenceLines_Converter.toJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines");
			if (_seqPhoneLines)
				t.seqPhoneLines = _seqPhoneLines;
		}
		if (s.seqCalls) {
			const _seqCalls = AsnPresenceCalls_Converter.toJSON(s.seqCalls, errors, newContext, "seqCalls");
			if (_seqCalls)
				t.seqCalls = _seqCalls;
		}
		if (s.seqLineForwards) {
			const _seqLineForwards = AsnPresenceForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
			if (_seqLineForwards)
				t.seqLineForwards = _seqLineForwards;
		}
		if (s.asnContactCapabilitiesEx) {
			const _asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.toJSON(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx");
			if (_asnContactCapabilitiesEx)
				t.asnContactCapabilitiesEx = _asnContactCapabilitiesEx;
		}
		if (s.asnCurrentUserDynamicRights) {
			const _asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.toJSON(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights");
			if (_asnCurrentUserDynamicRights)
				t.asnCurrentUserDynamicRights = _asnCurrentUserDynamicRights;
		}
		TSConverter.fillJSONParam(s, t, "iStaticRights2", "number", errors, newContext, true);
		if (s.asnAgentState) {
			const _asnAgentState = AsnAgentState_Converter.toJSON(s.asnAgentState, errors, newContext, "asnAgentState");
			if (_asnAgentState)
				t.asnAgentState = _asnAgentState;
		}
		if (s.asnMonitorGroupingAttribute) {
			const _asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.toJSON(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute");
			if (_asnMonitorGroupingAttribute)
				t.asnMonitorGroupingAttribute = _asnMonitorGroupingAttribute;
		}
		if (s.asnNextAppointments) {
			const _asnNextAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.asnNextAppointments, errors, newContext, "asnNextAppointments");
			if (_asnNextAppointments)
				t.asnNextAppointments = _asnNextAppointments;
		}

		if (errors.validateResult(newContext, "AsnPresenceV2"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceV2");

		let t: ENetUC_PresenceV2.AsnPresenceV2 | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceV2>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnPresenceV2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext, true);
			const _asnremotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact", true);
			if (_asnremotecontact)
				t.asnRemoteContact = _asnremotecontact;
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", true);
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
			TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iStaticRights", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iMonitorAccessRights", "number", errors, newContext, true);
			const _asnabsentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentStateV2_Converter.fromJSON(s.asnAbsentState, errors, newContext, "asnAbsentState", true);
			if (_asnabsentstate)
				t.asnAbsentState = _asnabsentstate;
			const _asncustomnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(s.asnCustomNote, errors, newContext, "asnCustomNote", true);
			if (_asncustomnote)
				t.asnCustomNote = _asncustomnote;
			const _seqactualappointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments", true);
			if (_seqactualappointments)
				t.seqActualAppointments = _seqactualappointments;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment", true);
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
			const _seqphonelines = AsnPresenceLines_Converter.fromJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines", true);
			if (_seqphonelines)
				t.seqPhoneLines = _seqphonelines;
			const _seqcalls = AsnPresenceCalls_Converter.fromJSON(s.seqCalls, errors, newContext, "seqCalls", true);
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			const _seqlineforwards = AsnPresenceForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", true);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _asncontactcapabilitiesex = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.fromJSON(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx", true);
			if (_asncontactcapabilitiesex)
				t.asnContactCapabilitiesEx = _asncontactcapabilitiesex;
			const _asncurrentuserdynamicrights = AsnActualUserDynamicRights_Converter.fromJSON(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights", true);
			if (_asncurrentuserdynamicrights)
				t.asnCurrentUserDynamicRights = _asncurrentuserdynamicrights;
			TSConverter.fillJSONParam(s, t, "iStaticRights2", "number", errors, newContext, true);
			const _asnagentstate = AsnAgentState_Converter.fromJSON(s.asnAgentState, errors, newContext, "asnAgentState", true);
			if (_asnagentstate)
				t.asnAgentState = _asnagentstate;
			const _asnmonitorgroupingattribute = AsnMonitorGroupingAttribute_Converter.fromJSON(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute", true);
			if (_asnmonitorgroupingattribute)
				t.asnMonitorGroupingAttribute = _asnmonitorgroupingattribute;
			const _asnnextappointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.asnNextAppointments, errors, newContext, "asnNextAppointments", true);
			if (_asnnextappointments)
				t.asnNextAppointments = _asnnextappointments;
		}

		if (errors.validateResult(newContext, "AsnPresenceV2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceV2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceV2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceV2");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactId", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext, true);
		const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnRemoteContact, errors, newContext, "asnRemoteContact", 1);
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toBER(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", 2);
		TSConverter.validateParam(s, "iContactFeatureSet", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iStaticRights", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iMonitorAccessRights", "number", errors, newContext, true);
		const _asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentStateV2_Converter.toBER(s.asnAbsentState, errors, newContext, "asnAbsentState", 6);
		const _asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toBER(s.asnCustomNote, errors, newContext, "asnCustomNote", 7);
		const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.seqActualAppointments, errors, newContext, "seqActualAppointments", 8);
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toBER(s.asnNextAppointment, errors, newContext, "asnNextAppointment", 9);
		const _seqPhoneLines = AsnPresenceLines_Converter.toBER(s.seqPhoneLines, errors, newContext, "seqPhoneLines", 10);
		const _seqCalls = AsnPresenceCalls_Converter.toBER(s.seqCalls, errors, newContext, "seqCalls", 11);
		const _seqLineForwards = AsnPresenceForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards", 12);
		const _asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.toBER(s.asnContactCapabilitiesEx, errors, newContext, "asnContactCapabilitiesEx", 14);
		const _asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.toBER(s.asnCurrentUserDynamicRights, errors, newContext, "asnCurrentUserDynamicRights", 15);
		TSConverter.validateParam(s, "iStaticRights2", "number", errors, newContext, true);
		const _asnAgentState = AsnAgentState_Converter.toBER(s.asnAgentState, errors, newContext, "asnAgentState", 17);
		const _asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.toBER(s.asnMonitorGroupingAttribute, errors, newContext, "asnMonitorGroupingAttribute", 18);
		const _asnNextAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.asnNextAppointments, errors, newContext, "asnNextAppointments", 19);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactId, name: "u8sContactId" }));
			if (s.iPresenceState !== undefined)
				t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState", idBlock: { optionalID: 0 } }));
			if (_asnRemoteContact)
				t.push(_asnRemoteContact);
			if (_asnUserPropertyBag)
				t.push(_asnUserPropertyBag);
			if (s.iContactFeatureSet !== undefined)
				t.push(new asn1ts.Integer({ value: s.iContactFeatureSet, name: "iContactFeatureSet", idBlock: { optionalID: 3 } }));
			if (s.iStaticRights !== undefined)
				t.push(new asn1ts.Integer({ value: s.iStaticRights, name: "iStaticRights", idBlock: { optionalID: 4 } }));
			if (s.iMonitorAccessRights !== undefined)
				t.push(new asn1ts.Integer({ value: s.iMonitorAccessRights, name: "iMonitorAccessRights", idBlock: { optionalID: 5 } }));
			if (_asnAbsentState)
				t.push(_asnAbsentState);
			if (_asnCustomNote)
				t.push(_asnCustomNote);
			if (_seqActualAppointments)
				t.push(_seqActualAppointments);
			if (_asnNextAppointment)
				t.push(_asnNextAppointment);
			if (_seqPhoneLines)
				t.push(_seqPhoneLines);
			if (_seqCalls)
				t.push(_seqCalls);
			if (_seqLineForwards)
				t.push(_seqLineForwards);
			if (_asnContactCapabilitiesEx)
				t.push(_asnContactCapabilitiesEx);
			if (_asnCurrentUserDynamicRights)
				t.push(_asnCurrentUserDynamicRights);
			if (s.iStaticRights2 !== undefined)
				t.push(new asn1ts.Integer({ value: s.iStaticRights2, name: "iStaticRights2", idBlock: { optionalID: 16 } }));
			if (_asnAgentState)
				t.push(_asnAgentState);
			if (_asnMonitorGroupingAttribute)
				t.push(_asnMonitorGroupingAttribute);
			if (_asnNextAppointments)
				t.push(_asnNextAppointments);
		}

		if (errors.validateResult(newContext, "AsnPresenceV2"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceV2");

		let t: ENetUC_PresenceV2.AsnPresenceV2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceV2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnPresenceV2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext, true);
			t.asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnRemoteContact"), errors, newContext, "asnRemoteContact", true);
			t.asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnUserPropertyBag"), errors, newContext, "asnUserPropertyBag", true);
			TSConverter.fillASN1Param(s, t, "iContactFeatureSet", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iStaticRights", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iMonitorAccessRights", "Integer", errors, newContext, true);
			t.asnAbsentState = ENetUC_Common_AsnContact_Converter.AsnAbsentStateV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnAbsentState"), errors, newContext, "asnAbsentState", true);
			t.asnCustomNote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnCustomNote"), errors, newContext, "asnCustomNote", true);
			t.seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqActualAppointments"), errors, newContext, "seqActualAppointments", true);
			t.asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointment"), errors, newContext, "asnNextAppointment", true);
			t.seqPhoneLines = AsnPresenceLines_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqPhoneLines"), errors, newContext, "seqPhoneLines", true);
			t.seqCalls = AsnPresenceCalls_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqCalls"), errors, newContext, "seqCalls", true);
			t.seqLineForwards = AsnPresenceForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards", true);
			t.asnContactCapabilitiesEx = ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnContactCapabilitiesEx"), errors, newContext, "asnContactCapabilitiesEx", true);
			t.asnCurrentUserDynamicRights = AsnActualUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnCurrentUserDynamicRights"), errors, newContext, "asnCurrentUserDynamicRights", true);
			TSConverter.fillASN1Param(s, t, "iStaticRights2", "Integer", errors, newContext, true);
			t.asnAgentState = AsnAgentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnAgentState"), errors, newContext, "asnAgentState", true);
			t.asnMonitorGroupingAttribute = AsnMonitorGroupingAttribute_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnMonitorGroupingAttribute"), errors, newContext, "asnMonitorGroupingAttribute", true);
			t.asnNextAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointments"), errors, newContext, "asnNextAppointments", true);
		}

		if (errors.validateResult(newContext, "AsnPresenceV2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceV2List_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnPresenceV2List, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnPresenceV2List | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceV2List");

		const t = [] as ENetUC_PresenceV2.AsnPresenceV2List;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnPresenceV2_Converter.toJSON(se, errors, newContext, "AsnPresenceV2");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnPresenceV2List"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceV2List | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceV2List");

		let t: ENetUC_PresenceV2.AsnPresenceV2List | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnPresenceV2List>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_PresenceV2.AsnPresenceV2List();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnPresenceV2_Converter.fromJSON(se, errors, newContext, "AsnPresenceV2", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceV2List"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnPresenceV2List | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceV2List";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceV2List");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnPresenceV2_Converter.toBER(s[id], errors, newContext, "AsnPresenceV2");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnPresenceV2List"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnPresenceV2List | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceV2List");

		let t: ENetUC_PresenceV2.AsnPresenceV2List | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnPresenceV2List.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_PresenceV2.AsnPresenceV2List();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnPresenceV2_Converter.fromBER(se, errors, newContext, "AsnPresenceV2", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnPresenceV2List"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdatePresenceArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnUpdatePresenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnUpdatePresenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdatePresenceArgument");

		const t = {} as ENetUC_PresenceV2.AsnUpdatePresenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdatePresenceArgument";
		const _presence = AsnPresence_Converter.toJSON(s.presence, errors, newContext, "presence");
		if (_presence)
			t.presence = _presence;
		TSConverter.fillJSONParam(s, t, "iChangedFlags", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdatePresenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUpdatePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdatePresenceArgument");

		let t: ENetUC_PresenceV2.AsnUpdatePresenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnUpdatePresenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnUpdatePresenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _presence = AsnPresence_Converter.fromJSON(s.presence, errors, newContext, "presence", false);
			if (_presence)
				t.presence = _presence;
			TSConverter.fillJSONParam(s, t, "iChangedFlags", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnUpdatePresenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdatePresenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdatePresenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _presence = AsnPresence_Converter.toBER(s.presence, errors, newContext, "presence");
		TSConverter.validateParam(s, "iChangedFlags", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_presence)
				t.push(_presence);
			t.push(new asn1ts.Integer({ value: s.iChangedFlags, name: "iChangedFlags" }));
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUpdatePresenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdatePresenceArgument");

		let t: ENetUC_PresenceV2.AsnUpdatePresenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnUpdatePresenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnUpdatePresenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _presence = AsnPresence_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "presence"), errors, newContext, "presence");
			if (_presence)
				t.presence = _presence;
			TSConverter.fillASN1Param(s, t, "iChangedFlags", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdatePresenceV2Argument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnUpdatePresenceV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnUpdatePresenceV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdatePresenceV2Argument");

		const t = {} as ENetUC_PresenceV2.AsnUpdatePresenceV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdatePresenceV2Argument";
		const _presence = AsnPresenceV2_Converter.toJSON(s.presence, errors, newContext, "presence");
		if (_presence)
			t.presence = _presence;
		TSConverter.fillJSONParam(s, t, "iChangedFlags", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdatePresenceV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUpdatePresenceV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdatePresenceV2Argument");

		let t: ENetUC_PresenceV2.AsnUpdatePresenceV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnUpdatePresenceV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnUpdatePresenceV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _presence = AsnPresenceV2_Converter.fromJSON(s.presence, errors, newContext, "presence", false);
			if (_presence)
				t.presence = _presence;
			TSConverter.fillJSONParam(s, t, "iChangedFlags", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnUpdatePresenceV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdatePresenceV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdatePresenceV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		const _presence = AsnPresenceV2_Converter.toBER(s.presence, errors, newContext, "presence");
		TSConverter.validateParam(s, "iChangedFlags", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_presence)
				t.push(_presence);
			t.push(new asn1ts.Integer({ value: s.iChangedFlags, name: "iChangedFlags" }));
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnUpdatePresenceV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdatePresenceV2Argument");

		let t: ENetUC_PresenceV2.AsnUpdatePresenceV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnUpdatePresenceV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnUpdatePresenceV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _presence = AsnPresenceV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "presence"), errors, newContext, "presence");
			if (_presence)
				t.presence = _presence;
			TSConverter.fillASN1Param(s, t, "iChangedFlags", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdatePresenceV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAndSubscribePresenceResult_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceResult");

		const t = {} as ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAndSubscribePresenceResult";
		const _seqSubscribedPresence = AsnPresenceList_Converter.toJSON(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence");
		if (_seqSubscribedPresence)
			t.seqSubscribedPresence = _seqSubscribedPresence;

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceResult");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqsubscribedpresence = AsnPresenceList_Converter.fromJSON(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence", false);
			if (_seqsubscribedpresence)
				t.seqSubscribedPresence = _seqsubscribedpresence;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAndSubscribePresenceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceResult");

		// [Print_BER_EncoderSeqDefCode]
		const _seqSubscribedPresence = AsnPresenceList_Converter.toBER(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence");
		if (!errors.hasNewErrors()) {
			if (_seqSubscribedPresence)
				t.push(_seqSubscribedPresence);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceResult");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqsubscribedpresence = AsnPresenceList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqSubscribedPresence"), errors, newContext, "seqSubscribedPresence");
			if (_seqsubscribedpresence)
				t.seqSubscribedPresence = _seqsubscribedpresence;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAndSubscribePresenceV2Result_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceV2Result");

		const t = {} as ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAndSubscribePresenceV2Result";
		const _seqSubscribedPresence = AsnPresenceV2List_Converter.toJSON(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence");
		if (_seqSubscribedPresence)
			t.seqSubscribedPresence = _seqSubscribedPresence;

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceV2Result"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceV2Result");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqsubscribedpresence = AsnPresenceV2List_Converter.fromJSON(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence", false);
			if (_seqsubscribedpresence)
				t.seqSubscribedPresence = _seqsubscribedpresence;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceV2Result"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAndSubscribePresenceV2Result";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribePresenceV2Result");

		// [Print_BER_EncoderSeqDefCode]
		const _seqSubscribedPresence = AsnPresenceV2List_Converter.toBER(s.seqSubscribedPresence, errors, newContext, "seqSubscribedPresence");
		if (!errors.hasNewErrors()) {
			if (_seqSubscribedPresence)
				t.push(_seqSubscribedPresence);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceV2Result"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribePresenceV2Result");

		let t: ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnGetAndSubscribePresenceV2Result["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqsubscribedpresence = AsnPresenceV2List_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqSubscribedPresence"), errors, newContext, "seqSubscribedPresence");
			if (_seqsubscribedpresence)
				t.seqSubscribedPresence = _seqsubscribedpresence;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribePresenceV2Result"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetMonitorGroupingAttributeArgument_Converter {
	public static toJSON(s: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMonitorGroupingAttributeArgument");

		const t = {} as ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetMonitorGroupingAttributeArgument";
		if (s.attribute) {
			const _attribute = AsnMonitorGroupingAttribute_Converter.toJSON(s.attribute, errors, newContext, "attribute");
			if (_attribute)
				t.attribute = _attribute;
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMonitorGroupingAttributeArgument");

		let t: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _attribute = AsnMonitorGroupingAttribute_Converter.fromJSON(s.attribute, errors, newContext, "attribute", true);
			if (_attribute)
				t.attribute = _attribute;
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetMonitorGroupingAttributeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMonitorGroupingAttributeArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _attribute = AsnMonitorGroupingAttribute_Converter.toBER(s.attribute, errors, newContext, "attribute", 0);
		if (!errors.hasNewErrors()) {
			if (_attribute)
				t.push(_attribute);
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMonitorGroupingAttributeArgument");

		let t: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.attribute = AsnMonitorGroupingAttribute_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "attribute"), errors, newContext, "attribute", true);
		}

		if (errors.validateResult(newContext, "AsnSetMonitorGroupingAttributeArgument"))
			return t;

		return undefined;
	}
}
