// [PrintTSCodeOne]
// [PrintTSComments]
/*
 * ENetUC_PresenceV2.ts
 * "UC-Server-Access-Protocol-PresenceV2" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Presence interface (Version 2)
 * ## Module description
 * This module defines structures and operations for the presence V2 interface.
 * A version 2 of the presence interface was necessary to improve the amount of contact and service data.
 * The presence shows at any time the current availability of the person you wish to communicate with. For a detailed description see the help of the UCClient.
 * The following presence states are available:
 * - Available (green)
 * - Busy (red)
 * - Absent (yellow)
 * - Inactive (green\/yellow)
 * - Don't disturb  (red with a white stripe)
 * - Offline (gray)
 *
 * Additionally the API delivers information in form of contact data, service states and much more.
 * With all these infromations, it is possible to create an application, which give user the ability to see a detailed picture of a contact enriched with live state changes.
 *
 * The live changes are results of the different services the UCServer combines the presence state of the user:
 * - Telephone (via CTI)
 * - Calendar
 * - Audio\/Video
 * - Softphone
 * - Bluetooth device (locally attached to a client of the user).
 * - Manual input of the user
 *
 * ## Prerequisite for using the interface
 * - The client needs a socket for secure data Transmission between client-server and vise versa.
 * - Installation of ProCall Software in advance
 * - Then create a user for testing purposes
 * - login in this user name and check the functionality of this interface practically
 *
 *
 * ## Functionalities
 * - The status for client will be driven from various sources like:
 * - Digital Telephone, Mobile, Calender(ms outlook), Audio\/Video Chat, Manually changing Status and Computer system(login\/logout).
 * - The user has the permission to manually change the status
 * - The user can set a user-defined status (ex: Coffee Break)
 * - The user can set a couple of notices (ex: listing the daily appointments)
 * - The user can change status and notice
 * - The user can remove status and notice
 *
 *
 * ## Events
 * The Events that the client receives from the server are:
 *
 * \\- **[asnUpdatePresence(V2)](PresenceV2.asnUpdatePresence.html)** :
 * - It delivers the responses from Server to Client as per Client request
 * - The Server receives the specific request like [asnCustomNote](Common.type.AsnCustomNote.html) from Client
 * - The Client reciprocally receives an [asnUpdatePresence(V2)](PresenceV2.asnUpdatePresence.html) from Server
 * - The Update-Event will also be sent to those clients who are in touch with this client in the same Server
 * - It receives the Event from server in two forms whether an Argument or an Error
 * - As Argument: It handles the **Presence** and **iChangedFlags** events
 * \\- [Presence](PresenceV2.type.AsnUpdatePresenceArgument.html) : it shows updated data of the present-contact.
 * \\- [iChangedFlags](PresenceV2.type.AsnUpdatePresenceArgument.html) : it indicates that which area triggers the event and it's included because later on the server fills the fields
 * \\** check the [AsnPresence](PresenceV2.type.AsnPresence.html)(Active details) for more details
 *
 * \\- **[asnRemovePresence](PresenceV2.asnRemovePresence.html)** :
 * - It needs a contact id to remove the presence state
 * - It has an optionalParams for further implementation
 *
 * ## How the API works
 * Here is a very simple diagram of how the presence API works
 * UML section is not exported to this file!
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
import * as ENetUC_ClientCapabilities from "./ENetUC_ClientCapabilities";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_PresenceV2";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceAction {
	/** @private */
	eAdded = 0,
	/** @private */
	eChanged = 1,
	/** @private */
	eDeleted = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceSubscriptionFlags {
	/** Send the calculated contact presence value (default: on) */
	ePresenceSubscriptionPresence = 1,
	/** Send the complete, static contact data (name, adress, etc.) (default: on) */
	ePresenceSubscriptionData = 2,
	/** Send the complete contact image (if there is one) not only the hash value (default: on) */
	ePresenceSubscriptionDataIncludeImage = 4,
	/** Send information about the contact configuration (changes about access rights etc.) (default: on) */
	ePresenceSubscriptionConfiguration = 8,
	/** Send information about the actual absent state. This is manual set presence, not the calculated one, which includes not only the absent state. (default: on) */
	ePresenceSubscriptionAbsentstate = 16,
	/** Send information about the note of the contact (default: on) */
	ePresenceSubscriptionNote = 32,
	/** Send information about appointments (default: on) */
	ePresenceSubscriptionAppointment = 64,
	/** Send information about the phone lines of the contact (default: on) */
	ePresenceSubscriptionPhoneLines = 128,
	/** Send information about the calls on the phone lines of the contact (default: on) */
	ePresenceSubscriptionPhoneCalls = 256,
	/** Send information about the forward states on the phone lines of the contact (default: on) */
	ePresenceSubscriptionPhoneForwards = 512,
	/** Send information about the contact right challenges if some contzact requests new contact rights */
	ePresenceSubscriptionMyChallengeUpdates = 1024,
	/**
	 * Send information about changes to the own authorization list
	 * = 0x00000800,
	 */
	ePresenceSubscriptionDynamicRightsUpdate = 2048,
	/**
	 * Send information about changes to an authorization list on a group the logged in user is member of
	 * = 0x00001000,
	 */
	ePresenceSubscriptionDynamicGroupRightsUpdate = 4096,
	/**
	 * Send informations about changes to the global authorisation list
	 * = 0x00002000,
	 */
	ePresenceSubscriptionDynamicGlobalRightsUpdate = 8192,
	/**
	 * Send agent state events of other users. This is not part of the default value!
	 * = 0x00008000,
	 */
	ePresenceSubscriptionAgentStatus = 32768,
	/**
	 * Send events by changes on the monitor grouping attributes. This is not part of the default value!
	 * = 0x00010000
	 */
	ePresenceSubScriptionMonitorGroupingAttribute = 65536
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnSetDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	writeprofiledenied = 2,
	unknownrights = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnRemoveDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	writeprofiledenied = 2,
	unknownrights = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumRequestImageResult {
	/** The requested image is attached in the result. */
	eIMAGEATTACHED = 0,
	/** The image has not changed according to the given hash and therefore there is no image in the result. */
	eIMAGEHASHNOTCHANGED = 1,
	/** There is no image fopr the given contact. */
	eNOIMAGEFOUND = 2
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The structure is used to describe a phone line of a contact.
 * The structure is used to describe a phone line of a contact, including it state, their number and URI, forwards on the line currently set.
 */
export class AsnPresenceLine {
	public constructor(that: AsnPresenceLine) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnPresenceLine {
		return new AsnPresenceLine({
			u8sLinePhoneNumber: "",
			u8sLineNumber: "",
			iState: 0,
			iDoNotDisturb: 0,
			iForwardFeaturesPBX: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sLinePhoneNumber",
			"u8sLineNumber",
			"iState",
			"iDoNotDisturb",
			"iForwardFeaturesPBX"
		];
		return p;
	}

	public static type = "AsnPresenceLine";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresenceLine",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sLineNumber" }),
				new asn1ts.Integer({ name: "iState" }),
				new asn1ts.Integer({ name: "iDoNotDisturb" }),
				new asn1ts.Integer({ name: "iForwardFeaturesPBX" }),
				new asn1ts.Extension()
			]
		});
	}

	/** LineURI */
	public u8sLinePhoneNumber!: string;
	/** Lesbare Nummer der Leitung (extrahiert aus der LineURI) */
	public u8sLineNumber!: string;
	/** Line State */
	public iState!: number;
	/** Line is on DoNotDisturb (1) or not (0) */
	public iDoNotDisturb!: number;
	/** What kind of forwarding this line supports */
	public iForwardFeaturesPBX!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This structure describes the information about a call on a phone line.
 * This structure describes the information about a call on a phone line of a contact including the last action taken
 * by the call (added, removed, changed), the current call state, features\/functions available on the call, call type and
 * if available the remote contact.
 */
export class AsnPresenceCall {
	public constructor(that: AsnPresenceCall) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnPresenceCall {
		return new AsnPresenceCall({
			iAction: 0,
			u8sConnectionID: "",
			u8sLinePhoneNumber: "",
			u8sLineNumber: "",
			u8sCurrentNumberSC: "",
			iCallState: 0,
			iFeatures: 0,
			iOutbound: 0,
			iInternal: 0,
			iCallType: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iAction",
			"u8sConnectionID",
			"u8sLinePhoneNumber",
			"u8sLineNumber",
			"u8sCurrentNumberSC",
			"iCallState",
			"iFeatures",
			"iOutbound",
			"iInternal",
			"iCallType"
		];
		if (bIncludeOptionals) {
			p.push(
				"remoteContact",
				"optionalParams"
			);
		}
		return p;
	}

	public static type = "AsnPresenceCall";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresenceCall",
			...params,
			value: [
				new asn1ts.Integer({ name: "iAction" }),
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sLineNumber" }),
				new asn1ts.Utf8String({ name: "u8sCurrentNumberSC" }),
				new asn1ts.Integer({ name: "iCallState" }),
				new asn1ts.Integer({ name: "iFeatures" }),
				new asn1ts.Integer({ name: "iOutbound" }),
				new asn1ts.Integer({ name: "iInternal" }),
				new asn1ts.Integer({ name: "iCallType" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "remoteContact", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 1 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** add\/remove\/change */
	public iAction!: number;
	/** Die ID des Calls */
	public u8sConnectionID!: string;
	/** LineURI der Leitung auf der der Call statt findet */
	public u8sLinePhoneNumber!: string;
	/** Lesbare Nummer der Leitung (extrahiert aus der LineURI) */
	public u8sLineNumber!: string;
	/** Rufnummer des Anrufers */
	public u8sCurrentNumberSC!: string;
	/** Zustand des Calls */
	public iCallState!: number;
	/** Was darf der Eventgempfänger mit dem Call machen */
	public iFeatures!: number;
	/** 1: Abgehend, 0: Ankommend */
	public iOutbound!: number;
	/** 1: Interner Gesprächspartner, 0: externer Gesprächspartner */
	public iInternal!: number;
	/** erstmal nur fuer unterscheidung controller oder nicht-controller */
	/** (see EnumCallType) */
	public iCallType!: number;
	/** Das nötigste über den Anrufer soweit bekannt */
	public remoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/**
	 * optionalParams Name \"TrackerID\" Value String
	 * optionalParams Name \"eJournalCallType\" Value Number
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This strutures describes the phone forward state of a phone line.
 * A phone line can be set to forward call automatically to other endpoints. This structure describes
 * the different forward states.
 */
export class AsnPresenceForward {
	public constructor(that: AsnPresenceForward) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnPresenceForward {
		return new AsnPresenceForward({
			u8sLinePhoneNumber: "",
			u8sLineNumber: "",
			iInternalID: 0,
			u8sDestination: "",
			iForwardMode: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sLinePhoneNumber",
			"u8sLineNumber",
			"iInternalID",
			"u8sDestination",
			"iForwardMode"
		];
		if (bIncludeOptionals)
			p.push("iNoAnswerTime");
		return p;
	}

	public static type = "AsnPresenceForward";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresenceForward",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sLineNumber" }),
				new asn1ts.Integer({ name: "iInternalID" }),
				new asn1ts.Utf8String({ name: "u8sDestination" }),
				new asn1ts.Integer({ name: "iForwardMode" }),
				new asn1ts.Integer({ name: "iNoAnswerTime", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** LineURI der betroffenen Leitung */
	public u8sLinePhoneNumber!: string;
	/** Lesbare Nummer der Leitung (extrahiert aus der LineURI) */
	public u8sLineNumber!: string;
	/** Interne ID des Forwards */
	public iInternalID!: number;
	/** Ziel der Umleitung */
	public u8sDestination!: string;
	/** Art der Umleitung */
	public iForwardMode!: number;
	/** nach wie vielen sekunden bei noAns weitergeleitet wird */
	public iNoAnswerTime?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Structure for AsnPresence to give the client a hint, whcih access rights are effective to set on an contact.
 * Minimum and maximum of the user dynamic rights which can be set to a contact. See more details in AsnPresence .
 */
export class AsnActualUserDynamicRights {
	public constructor(that: AsnActualUserDynamicRights) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnActualUserDynamicRights {
		return new AsnActualUserDynamicRights({
			asnMinPossibleRights: ENetUC_Common_AsnContact.AsnUserDynamicRights["initEmpty"].call(0),
			asnMaxPossibleRights: ENetUC_Common_AsnContact.AsnUserDynamicRights["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"asnMinPossibleRights",
			"asnMaxPossibleRights"
		];
		if (bIncludeOptionals)
			p.push("asnCurrentRights");
		return p;
	}

	public static type = "AsnActualUserDynamicRights";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnActualUserDynamicRights",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "asnMinPossibleRights" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "asnMaxPossibleRights" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "asnCurrentRights", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** The minimum of possible rights to set. */
	public asnMinPossibleRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
	/** The maximum of possible rights to set. */
	public asnMaxPossibleRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
	/** If not set, there are no rights set already. */
	public asnCurrentRights?: ENetUC_Common_AsnContact.AsnUserDynamicRights;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Struture for AsnPresence of the actual agent state of the contact
 * To reflect the actual agent state of a contact which is a PBX or LiveChat Agent, this struture
 * contains the state and agentgroup list of the contact.
 */
export class AsnAgentState {
	public constructor(that: AsnAgentState) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentState {
		return new AsnAgentState({
			dwState: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"dwState"
		];
		if (bIncludeOptionals)
			p.push("mapDetails");
		return p;
	}

	public static type = "AsnAgentState";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentState",
			...params,
			value: [
				new asn1ts.Integer({ name: "dwState" }),
				ENetUC_Common.AsnStringIntegerPairList.getASN1Schema({ name: "mapDetails", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** AsnAgentStateEnum - the current agent state of the contact. */
	public dwState!: number;
	/** To be Documented - Agent Group List with states? */
	public mapDetails?: ENetUC_Common.AsnStringIntegerPairList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for the event asnRemovePresence.
 * The argument contains only the ContactID if the contact whcih has been removed.
 */
export class AsnRemovePresenceArgument {
	public constructor(that: AsnRemovePresenceArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRemovePresenceArgument {
		return new AsnRemovePresenceArgument({
			u8sContactID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sContactID"
		];
		return p;
	}

	public static type = "AsnRemovePresenceArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRemovePresenceArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID of the contact removed. */
	public u8sContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Call this operation to get information about and events of another contact.
 * This operation enables the client to request informations and events about another contact.
 * The minimal parameter is a list of contact IDs (e.g. 'sip:tester\@test.com'). These contacts
 * will be searched in the ucserver and the service will return contact data back in the result
 * and send events about changes from there on.
 *
 * The data returned and events which will be send are depending on access rights and accessability on the contact data.
 *
 * Which events and return values are necessary for the client can be adjusted by the iEventFlagsToSubscribe and
 * iContactDetailsReturnFilter members.
 *
 * **Note**: Federation contacts (contgacts managed on another server as the connected one) may not return most of the data
 * even the bits are set. This is because the ucserver has to contact the appropriate server first and this can take some time.
 * To prevent long response times, the data which will be eventually given by the appropriate server will be send back via event.
 *
 * If the client wants to prevent SIP or XMPP fedeation contacts, it can set bOpenOnlyInternalContacts to true.
 *
 * If the client wants to prevent requesting new contact rights on the contact it can set bDontRequestPermissions to true.
 */
export class AsnGetAndSubscribePresenceArgument {
	public constructor(that: AsnGetAndSubscribePresenceArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetAndSubscribePresenceArgument {
		return new AsnGetAndSubscribePresenceArgument({
			seqContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"seqContactIDs"
		];
		if (bIncludeOptionals) {
			p.push(
				"iEventFlagsToSubscribe",
				"iContactDetailsReturnFilter",
				"bOpenOnlyInternalContacts",
				"bDontRequestPermissions"
			);
		}
		return p;
	}

	public static type = "AsnGetAndSubscribePresenceArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAndSubscribePresenceArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "seqContactIDs" }),
				new asn1ts.Integer({ name: "iEventFlagsToSubscribe", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iContactDetailsReturnFilter", idBlock: { optionalID: 1 } }),
				new asn1ts.Boolean({ name: "bOpenOnlyInternalContacts", idBlock: { optionalID: 2 } }),
				new asn1ts.Boolean({ name: "bDontRequestPermissions", idBlock: { optionalID: 3 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of strings containing the contact ID of the requestet contact (e.g. 'sip:tester\@test.com') */
	public seqContactIDs!: ENetUC_Common.UTF8StringList;
	/** AsnPresenceSubscriptionFlags - which events does the client wants to receive from the server. */
	public iEventFlagsToSubscribe?: number;
	/** AsnPresenceSubscriptionFlags - which return values are needed from the ucserver. */
	public iContactDetailsReturnFilter?: number;
	public bOpenOnlyInternalContacts?: boolean;
	/** Keine Berechtigungsanfrage starten */
	public bDontRequestPermissions?: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for stopping contact subscrptions on the ucserver.
 * To stop getting contact events from the ucserver with the operation asnUnsubscribePresence this sequence
 * contains the list of contact IDs to unsubscribe from.
 */
export class AsnUnsubscribePresenceArgument {
	public constructor(that: AsnUnsubscribePresenceArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribePresenceArgument {
		return new AsnUnsubscribePresenceArgument({
			seqContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"seqContactIDs"
		];
		return p;
	}

	public static type = "AsnUnsubscribePresenceArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribePresenceArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "seqContactIDs" }),
				new asn1ts.Extension()
			]
		});
	}

	/** List of contact IDs to unsubscribe from (e.g. 'sip:tester\@test.com'). */
	public seqContactIDs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUnsubscribePresence if the operation was executed successfully
 * This result sequence is send by the server if the unsibscribe wents successfull. iResult should always be 0.
 */
export class AsnUnsubscribePresenceResult {
	public constructor(that: AsnUnsubscribePresenceResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribePresenceResult {
		return new AsnUnsubscribePresenceResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		return p;
	}

	public static type = "AsnUnsubscribePresenceResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribePresenceResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Extension()
			]
		});
	}

	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnSetDynamicRights to set\/change access rights to contact data and its service informations
 * The argument must contain the contact ID of the contact which access rights list has to be modified, the contact ID
 * of the contact which gets\/looses the rights and the rights itself.
 *
 * The rights can be set with a constant value (iDynamicRights) or more specialized with the optional AsnUserDynamicRights member
 * (not recommended, only use it for very special edge cases).
 *
 * The values for iDynamicRights are part of AsnPresenceRightsFlags in the general module. The valid values are:
 * &lt;ul&gt;
 * &lt;li&gt;eBlocked (0)&lt;\/li&gt;
 * &lt;li&gt;ePublic (65536, 0x0001 0000)&lt;\/li&gt;
 * &lt;li&gt;eCompany (131072, 0x0002 0000)&lt;\/li&gt;
 * &lt;li&gt;eTeam (262144, 0x0004 0000)&lt;\/li&gt;
 * &lt;li&gt;ePersonal (524288, 0x0008 0000)&lt;\/li&gt;
 * &lt;\/ul&gt;
 */
export class AsnSetDynamicRightsArgument {
	public constructor(that: AsnSetDynamicRightsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetDynamicRightsArgument {
		return new AsnSetDynamicRightsArgument({
			u8sOwnerContactID: "",
			u8sRemoteContactID: "",
			iDynamicRights: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sOwnerContactID",
			"u8sRemoteContactID",
			"iDynamicRights"
		];
		if (bIncludeOptionals)
			p.push("dynamicRights");
		return p;
	}

	public static type = "AsnSetDynamicRightsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetDynamicRightsArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sOwnerContactID" }),
				new asn1ts.Utf8String({ name: "u8sRemoteContactID" }),
				new asn1ts.Integer({ name: "iDynamicRights" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "dynamicRights", idBlock: { optionalID: 1 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID of the contact who grants\/changes the rights. */
	public u8sOwnerContactID!: string;
	/** ContactID of the contact to give to\/change the rights. */
	public u8sRemoteContactID!: string;
	/**
	 * Valid values are (taken from AsnPresenceRightsFlags):
	 * &lt;ul&gt;
	 * &lt;li&gt;eBlocked (0)&lt;\/li&gt;
	 * &lt;li&gt;ePublic (65536, 0x0001 0000)&lt;\/li&gt;
	 * &lt;li&gt;eCompany (131072, 0x0002 0000)&lt;\/li&gt;
	 * &lt;li&gt;eTeam (262144, 0x0004 0000)&lt;\/li&gt;
	 * &lt;li&gt;ePersonal (524288, 0x0008 0000)&lt;\/li&gt;
	 * &lt;\/ul&gt;
	 */
	public iDynamicRights!: number;
	/**
	 * Only use this member to give access rights, which are not part of the predefined patterns.&lt;br \/&gt;
	 * **This is not recommended**, so you can leave this optional parameter unset.&lt;br \/&gt;
	 * If these rights are set, the parameters u8sOwnerContactID a iDynamicRights are ignored.
	 */
	public dynamicRights?: ENetUC_Common_AsnContact.AsnUserDynamicRights;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnSetDynamicRights if the operation was successful.
 * If asnSetDynamicRights was executed successful, this result will be returned and it will contain the resulting access rights list.
 */
export class AsnSetDynamicRightsResult {
	public constructor(that: AsnSetDynamicRightsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetDynamicRightsResult {
		return new AsnSetDynamicRightsResult({
			iResult: 0,
			dynamicRights: ENetUC_Common_AsnContact.AsnUserDynamicRights["initEmpty"].call(0)
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult",
			"dynamicRights"
		];
		return p;
	}

	public static type = "AsnSetDynamicRightsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetDynamicRightsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "dynamicRights" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Should always be 0 (NO_ERROR). */
	public iResult!: number;
	/** The resulting rights in the rights list of the contact which rightslist had to be changed. This includes NOT the rights granted by global or group rights! */
	public dynamicRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnRemoveDynamicRights
 * The operation asnRemoveDynamicRights get only two parameters in its argument. u8sOwnerContactID of the contact which access list will be changed and
 * u8sRemoteContactID of the contact which will be removed from the list.
 */
export class AsnRemoveDynamicRightsArgument {
	public constructor(that: AsnRemoveDynamicRightsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRemoveDynamicRightsArgument {
		return new AsnRemoveDynamicRightsArgument({
			u8sOwnerContactID: "",
			u8sRemoteContactID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sOwnerContactID",
			"u8sRemoteContactID"
		];
		return p;
	}

	public static type = "AsnRemoveDynamicRightsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRemoveDynamicRightsArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sOwnerContactID" }),
				new asn1ts.Utf8String({ name: "u8sRemoteContactID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID - owner of the access list whoich will be changed */
	public u8sOwnerContactID!: string;
	/** ContactID - Contact which will be removed from the access list. */
	public u8sRemoteContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnRemoveDynamicRights
 * The operation asnRemoveDynamicRights returns this result, if removing the dynmaic rights from the list was successful.
 */
export class AsnRemoveDynamicRightsResult {
	public constructor(that: AsnRemoveDynamicRightsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRemoveDynamicRightsResult {
		return new AsnRemoveDynamicRightsResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		return p;
	}

	public static type = "AsnRemoveDynamicRightsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRemoveDynamicRightsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Rückgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnRequestLargeProfileImage
 * To get or check an large image of a contact, the client calls asnRequestContactImage with the ContactID (e.g. 'tester\@test.com') or the AsnNetDatabaseContactID of the contact and
 * the known image hash the client already has. If the client has no hash, an empty string will indicate the server that
 * the client does not have an image yet.
 */
export class AsnRequestLargeProfileImageArgument {
	public constructor(that: AsnRequestLargeProfileImageArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestLargeProfileImageArgument {
		return new AsnRequestLargeProfileImageArgument({
			u8sContactID: "",
			u8sKnownHash: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sContactID",
			"u8sKnownHash"
		];
		if (bIncludeOptionals)
			p.push("netDatabaseContactID");
		return p;
	}

	public static type = "AsnRequestLargeProfileImageArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestLargeProfileImageArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				new asn1ts.Utf8String({ name: "u8sKnownHash" }),
				ENetUC_Common.AsnNetDatabaseContactID.getASN1Schema({ name: "netDatabaseContactID", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID of the contact to get or check the image */
	public u8sContactID!: string;
	/** If the client has an image already, it sends its hash so the server can determine if the image has changed. */
	public u8sKnownHash!: string;
	/** Alternatively to u8sContactID one can use the AsnNetDatabaseContactID to identify the contact. */
	public netDatabaseContactID?: ENetUC_Common.AsnNetDatabaseContactID;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnRequestLargeProfileImage
 * If the operation asnRequestLargeProfileImage was executed successfully, this result will be send back to the client.
 * If there is no image or the image has not changed according the hash value given in the argument, iResult will contain the corresponding value from EnumRequestImageResult.
 * If the server will send back an image, sImageData will contain the binary data of the image and u8sImageHash the corresponding
 * new hash value for that image.
 */
export class AsnRequestLargeProfileImageResult {
	public constructor(that: AsnRequestLargeProfileImageResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestLargeProfileImageResult {
		return new AsnRequestLargeProfileImageResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		if (bIncludeOptionals) {
			p.push(
				"sImageData",
				"u8sImageHash"
			);
		}
		return p;
	}

	public static type = "AsnRequestLargeProfileImageResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestLargeProfileImageResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.OctetString({ name: "sImageData", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sImageHash", idBlock: { optionalID: 1 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** EnumRequestImageResult - result of the call, if there is a new image, no image at all or the image hash has not changed. */
	public iResult!: number;
	/** If an image is send to the client, this field contains the binary data of the image. */
	public sImageData?: Uint8Array;
	/** If an image is send to the client, this field contains the corresponding hash value for that image. */
	public u8sImageHash?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnRequestContactImage
 * To get or check an image of a contact, the client calls asnRequestContactImage with the AsnNetDatabaseContactID of the contact and
 * the known image hash the client already has. If the client has no hash, an empty string will indicate the server that
 * the client does not have an image yet.
 */
export class AsnRequestContactImageArgument {
	public constructor(that: AsnRequestContactImageArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestContactImageArgument {
		return new AsnRequestContactImageArgument({
			netDatabaseContactID: ENetUC_Common.AsnNetDatabaseContactID["initEmpty"].call(0),
			u8sKnownHash: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"netDatabaseContactID",
			"u8sKnownHash"
		];
		return p;
	}

	public static type = "AsnRequestContactImageArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestContactImageArgument",
			...params,
			value: [
				ENetUC_Common.AsnNetDatabaseContactID.getASN1Schema({ name: "netDatabaseContactID" }),
				new asn1ts.Utf8String({ name: "u8sKnownHash" }),
				new asn1ts.Extension()
			]
		});
	}

	/** The contact identity of the contact of which the client wants to check or get the image of. */
	public netDatabaseContactID!: ENetUC_Common.AsnNetDatabaseContactID;
	/** If the client has an image already, it sends its hash so the server can determine if the image has changed. */
	public u8sKnownHash!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnRequestContactImage
 * If the operation asnRequestContactImage was executed successfully, this result will be send back to the client.
 * If there is no image or the image has not changed according the hash value given in the argument, iResult will contain the corresponding value from EnumRequestImageResult.
 * If the server will send back an image, sImageData will contain the binary data of the image and u8sImageHash the corresponding
 * new hash value for that image.
 */
export class AsnRequestContactImageResult {
	public constructor(that: AsnRequestContactImageResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestContactImageResult {
		return new AsnRequestContactImageResult({
			iResult: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iResult"
		];
		if (bIncludeOptionals) {
			p.push(
				"sImageData",
				"u8sImageHash"
			);
		}
		return p;
	}

	public static type = "AsnRequestContactImageResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestContactImageResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.OctetString({ name: "sImageData", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sImageHash", idBlock: { optionalID: 1 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** EnumRequestImageResult - result of the call, if there is a new image, no image at all or the image hash has not changed. */
	public iResult!: number;
	/** If an image is send to the client, this field contains the binary data of the image. */
	public sImageData?: Uint8Array;
	/** If an image is send to the client, this field contains the corresponding hash value for that image. */
	public u8sImageHash?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Future use
 */
export class AsnMonitorGroupingAttribute {
	public constructor(that?: AsnMonitorGroupingAttribute) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnMonitorGroupingAttribute {
		return new AsnMonitorGroupingAttribute();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		if (bIncludeOptionals) {
			p.push(
				"u8sAttributeGUID",
				"u8sAttributeShort",
				"u8sAttributeLong",
				"u8sAdditionalText"
			);
		}
		return p;
	}

	public static type = "AsnMonitorGroupingAttribute";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMonitorGroupingAttribute",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAttributeGUID", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAttributeShort", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sAttributeLong", idBlock: { optionalID: 2 } }),
				new asn1ts.Utf8String({ name: "u8sAdditionalText", idBlock: { optionalID: 3 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Identification of grouping attribute set if it is defined by administration */
	public u8sAttributeGUID?: string;
	/** Short or abbreviation of the attribute */
	public u8sAttributeShort?: string;
	/** Attribute or \"Main\" attribute */
	public u8sAttributeLong?: string;
	/** Additional text or \"secondary\" attribute */
	public u8sAdditionalText?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnSetMonitorGroupingAttribute
 *
 * @private
 */
export class AsnSetMonitorGroupingAttributeResult {
	public constructor(that: AsnSetMonitorGroupingAttributeResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetMonitorGroupingAttributeResult {
		return new AsnSetMonitorGroupingAttributeResult({
			bSuccess: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bSuccess"
		];
		return p;
	}

	public static type = "AsnSetMonitorGroupingAttributeResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetMonitorGroupingAttributeResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Extension()
			]
		});
	}

	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnPresenceLines extends Array<AsnPresenceLine> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnPresenceLine.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnPresenceCalls extends Array<AsnPresenceCall> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnPresenceCall.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnPresenceForwards extends Array<AsnPresenceForward> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnPresenceForward.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This sequence contains the data and service states of a contact, called its 'presence'.
 * AsnPresence contains all the data and service states which represents the 'presence' of a contact.&lt;br \/&gt;
 * A client subscribing to the presence of a contact (via asnGetAndSubscribePresence ), will get back this structure filled according to his needs and access rights given.
 * This structure is also used for update events ( asnUpdatePresence(V2) ) and in this case only the changed values or sections will be enclosed.
 *
 * @deprecated 26.07.2024 -&gt; use AsnPresenceV2
 */
export class AsnPresence {
	public constructor(that: AsnPresence) {
		TSDeprecatedCallback.deprecatedObject(1721952000, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnPresence {
		return new AsnPresence({
			u8sContactId: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sContactId"
		];
		if (bIncludeOptionals) {
			p.push(
				"iPresenceState",
				"asnRemoteContact",
				"asnUserPropertyBag",
				"iContactFeatureSet",
				"iStaticRights",
				"iMonitorAccessRights",
				"asnAbsentState",
				"asnCustomNote",
				"seqActualAppointments",
				"asnNextAppointment",
				"seqPhoneLines",
				"seqCalls",
				"seqLineForwards",
				"asnContactCapabilities",
				"asnContactCapabilitiesEx",
				"asnCurrentUserDynamicRights",
				"iStaticRights2",
				"asnAgentState",
				"asnMonitorGroupingAttribute",
				"optionalParams"
			);
		}
		return p;
	}

	public static type = "AsnPresence";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresence",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" }),
				new asn1ts.Integer({ name: "iPresenceState", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRemoteContact", idBlock: { optionalID: 1 } }),
				ENetUC_Common.AsnUserPropertyBag.getASN1Schema({ name: "asnUserPropertyBag", idBlock: { optionalID: 2 } }),
				new asn1ts.Integer({ name: "iContactFeatureSet", idBlock: { optionalID: 3 } }),
				new asn1ts.Integer({ name: "iStaticRights", idBlock: { optionalID: 4 } }),
				new asn1ts.Integer({ name: "iMonitorAccessRights", idBlock: { optionalID: 5 } }),
				ENetUC_Common_AsnContact.AsnAbsentState.getASN1Schema({ name: "asnAbsentState", idBlock: { optionalID: 6 } }),
				ENetUC_Common_AsnContact.AsnCustomNote.getASN1Schema({ name: "asnCustomNote", idBlock: { optionalID: 7 } }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "seqActualAppointments", idBlock: { optionalID: 8 } }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment", idBlock: { optionalID: 9 } }),
				AsnPresenceLines.getASN1Schema({ name: "seqPhoneLines", idBlock: { optionalID: 10 } }),
				AsnPresenceCalls.getASN1Schema({ name: "seqCalls", idBlock: { optionalID: 11 } }),
				AsnPresenceForwards.getASN1Schema({ name: "seqLineForwards", idBlock: { optionalID: 12 } }),
				ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.getASN1Schema({ name: "asnContactCapabilitiesEx", idBlock: { optionalID: 14 } }),
				AsnActualUserDynamicRights.getASN1Schema({ name: "asnCurrentUserDynamicRights", idBlock: { optionalID: 15 } }),
				new asn1ts.Integer({ name: "iStaticRights2", idBlock: { optionalID: 16 } }),
				AsnAgentState.getASN1Schema({ name: "asnAgentState", idBlock: { optionalID: 17 } }),
				AsnMonitorGroupingAttribute.getASN1Schema({ name: "asnMonitorGroupingAttribute", idBlock: { optionalID: 18 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 99 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID of the contact which is responsible for this data */
	public u8sContactId!: string;
	/** Calculated presence of the contact. This is the over all presence of all the services which have an influnce on the current presence state of the contact (line, appointments, absent state a.s.o.) */
	public iPresenceState?: number;
	/** The contact data of the contact */
	public asnRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** If the contact is an ucserver user, this may contain additional information about the user filtered by access rights. */
	public asnUserPropertyBag?: ENetUC_Common.AsnUserPropertyBag;
	/** Feature set on the contact. Bitfield with flags, which actions are possible on the contact (like \"set forwards\"). */
	public iContactFeatureSet?: number;
	/** Access rights on the contact. */
	public iStaticRights?: number;
	/** Access rights on the probably configured, additional lines of the contact. */
	public iMonitorAccessRights?: number;
	/** Actual set absent state. */
	public asnAbsentState?: ENetUC_Common_AsnContact.AsnAbsentState;
	/** Actual set note. */
	public asnCustomNote?: ENetUC_Common_AsnContact.AsnCustomNote;
	/** Actual appointments of the contact. */
	public seqActualAppointments?: ENetUC_Common_Appointments.SEQAppointmentList;
	/** First appointment in the future of the contact in a given time frame of 30 days. */
	public asnNextAppointment?: ENetUC_Common_Appointments.AsnAppointmentEntry;
	/** The phone lines of the contact. */
	public seqPhoneLines?: AsnPresenceLines;
	/** The current calls of the contact. */
	public seqCalls?: AsnPresenceCalls;
	/** The currently set phone forwards of the contact. */
	public seqLineForwards?: AsnPresenceForwards;
	/** Capabilities in a simpler to expand format of the connected clients of the contact (audio, video, calendar replication etc.) */
	public asnContactCapabilitiesEx?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2;
	/**
	 * Minimum and maximum of the user dynamic rights which can be set to the contact.&lt;br \/&gt;
	 * If there are administrative contact rights set, the client can only modify it in a such a way, that he can grant additional
	 * rights. This field helps the client to show the user the remaining options.
	 */
	public asnCurrentUserDynamicRights?: AsnActualUserDynamicRights;
	/** Additional static user rights. See AsnUserStaticRights2. */
	public iStaticRights2?: number;
	/** Actual state of the user if he is an agent (via PBX\/ACD or LiveChat-Modules) */
	public asnAgentState?: AsnAgentState;
	/** Future use - If the user has a monitor grouping attribute set, it will be delivered here. */
	public asnMonitorGroupingAttribute?: AsnMonitorGroupingAttribute;
	/**
	 * Optional Params:
	 * - Key \"iStaticRights2\" Value DWORD - Additional static user rights. See AsnUserStaticRights2.
	 * - Key \"NextAppointments\" Value SEQAppointmentList - The next appointments of this user (binary encoded)
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnPresenceList extends Array<AsnPresence> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnPresence.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This sequence contains the data and service states of a contact, called its 'presence'.
 * AsnPresence contains all the data and service states which represents the 'presence' of a contact.&lt;br \/&gt;
 * A client subscribing to the presence of a contact (via asnGetAndSubscribePresence ), will get back this structure filled according to his needs and access rights given.
 * This structure is also used for update events ( asnUpdatePresence(V2) ) and in this case only the changed values or sections will be enclosed.
 */
export class AsnPresenceV2 {
	public constructor(that: AsnPresenceV2) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnPresenceV2 {
		return new AsnPresenceV2({
			u8sContactId: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sContactId"
		];
		if (bIncludeOptionals) {
			p.push(
				"iPresenceState",
				"asnRemoteContact",
				"asnUserPropertyBag",
				"iContactFeatureSet",
				"iStaticRights",
				"iMonitorAccessRights",
				"asnAbsentState",
				"asnCustomNote",
				"seqActualAppointments",
				"asnNextAppointment",
				"seqPhoneLines",
				"seqCalls",
				"seqLineForwards",
				"asnContactCapabilities",
				"asnContactCapabilitiesEx",
				"asnCurrentUserDynamicRights",
				"iStaticRights2",
				"asnAgentState",
				"asnMonitorGroupingAttribute",
				"asnNextAppointments"
			);
		}
		return p;
	}

	public static type = "AsnPresenceV2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresenceV2",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" }),
				new asn1ts.Integer({ name: "iPresenceState", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRemoteContact", idBlock: { optionalID: 1 } }),
				ENetUC_Common.AsnUserPropertyBag.getASN1Schema({ name: "asnUserPropertyBag", idBlock: { optionalID: 2 } }),
				new asn1ts.Integer({ name: "iContactFeatureSet", idBlock: { optionalID: 3 } }),
				new asn1ts.Integer({ name: "iStaticRights", idBlock: { optionalID: 4 } }),
				new asn1ts.Integer({ name: "iMonitorAccessRights", idBlock: { optionalID: 5 } }),
				ENetUC_Common_AsnContact.AsnAbsentStateV2.getASN1Schema({ name: "asnAbsentState", idBlock: { optionalID: 6 } }),
				ENetUC_Common_AsnContact.AsnCustomNote.getASN1Schema({ name: "asnCustomNote", idBlock: { optionalID: 7 } }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "seqActualAppointments", idBlock: { optionalID: 8 } }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment", idBlock: { optionalID: 9 } }),
				AsnPresenceLines.getASN1Schema({ name: "seqPhoneLines", idBlock: { optionalID: 10 } }),
				AsnPresenceCalls.getASN1Schema({ name: "seqCalls", idBlock: { optionalID: 11 } }),
				AsnPresenceForwards.getASN1Schema({ name: "seqLineForwards", idBlock: { optionalID: 12 } }),
				ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.getASN1Schema({ name: "asnContactCapabilitiesEx", idBlock: { optionalID: 14 } }),
				AsnActualUserDynamicRights.getASN1Schema({ name: "asnCurrentUserDynamicRights", idBlock: { optionalID: 15 } }),
				new asn1ts.Integer({ name: "iStaticRights2", idBlock: { optionalID: 16 } }),
				AsnAgentState.getASN1Schema({ name: "asnAgentState", idBlock: { optionalID: 17 } }),
				AsnMonitorGroupingAttribute.getASN1Schema({ name: "asnMonitorGroupingAttribute", idBlock: { optionalID: 18 } }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "asnNextAppointments", idBlock: { optionalID: 19 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** ContactID of the contact which is responsible for this data */
	public u8sContactId!: string;
	/** Calculated presence of the contact. This is the over all presence of all the services which have an influnce on the current presence state of the contact (line, appointments, absent state a.s.o.) */
	public iPresenceState?: number;
	/** The contact data of the contact */
	public asnRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** If the contact is an ucserver user, this may contain additional information about the user filtered by access rights. */
	public asnUserPropertyBag?: ENetUC_Common.AsnUserPropertyBag;
	/** Feature set on the contact. Bitfield with flags, which actions are possible on the contact (like \"set forwards\"). */
	public iContactFeatureSet?: number;
	/** Access rights on the contact. */
	public iStaticRights?: number;
	/** Access rights on the probably configured, additional lines of the contact. */
	public iMonitorAccessRights?: number;
	/** Actual set absent state. */
	public asnAbsentState?: ENetUC_Common_AsnContact.AsnAbsentStateV2;
	/** Actual set note. */
	public asnCustomNote?: ENetUC_Common_AsnContact.AsnCustomNote;
	/** Actual appointments of the contact. */
	public seqActualAppointments?: ENetUC_Common_Appointments.SEQAppointmentList;
	/** First appointment in the future of the contact in a given time frame of 30 days. */
	public asnNextAppointment?: ENetUC_Common_Appointments.AsnAppointmentEntry;
	/** The phone lines of the contact. */
	public seqPhoneLines?: AsnPresenceLines;
	/** The current calls of the contact. */
	public seqCalls?: AsnPresenceCalls;
	/** The currently set phone forwards of the contact. */
	public seqLineForwards?: AsnPresenceForwards;
	/** Capabilities in a simpler to expand format of the connected clients of the contact (audio, video, calendar replication etc.) */
	public asnContactCapabilitiesEx?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2;
	/**
	 * Minimum and maximum of the user dynamic rights which can be set to the contact.&lt;br \/&gt;
	 * If there are administrative contact rights set, the client can only modify it in a such a way, that he can grant additional
	 * rights. This field helps the client to show the user the remaining options.
	 */
	public asnCurrentUserDynamicRights?: AsnActualUserDynamicRights;
	/** Additional static user rights. See AsnUserStaticRights2. */
	public iStaticRights2?: number;
	/** Actual state of the user if he is an agent (via PBX\/ACD or LiveChat-Modules) */
	public asnAgentState?: AsnAgentState;
	/** Future use - If the user has a monitor grouping attribute set, it will be delivered here. */
	public asnMonitorGroupingAttribute?: AsnMonitorGroupingAttribute;
	/** The next appointments of this user */
	public asnNextAppointments?: ENetUC_Common_Appointments.SEQAppointmentList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnPresenceV2List extends Array<AsnPresenceV2> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnPresenceV2.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument of asnUpdatePresence
 * This argument contains the changes which triggered the event on the server and the new presence state of the corresponding contact.
 *
 * @deprecated 26.07.2024 -&gt; use AsnUpdatePresenceArgumentV2
 */
export class AsnUpdatePresenceArgument {
	public constructor(that: AsnUpdatePresenceArgument) {
		TSDeprecatedCallback.deprecatedObject(1721952000, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdatePresenceArgument {
		return new AsnUpdatePresenceArgument({
			presence: AsnPresence["initEmpty"].call(0),
			iChangedFlags: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"presence",
			"iChangedFlags"
		];
		return p;
	}

	public static type = "AsnUpdatePresenceArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdatePresenceArgument",
			...params,
			value: [
				AsnPresence.getASN1Schema({ name: "presence" }),
				new asn1ts.Integer({ name: "iChangedFlags" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Updated data and service states of the contact. See AsnPresence for more details. */
	public presence!: AsnPresence;
	/** Flags indicating which data sections od the contact have triggered the event.\ @linked AsnPresenceSubscriptionFlags */
	public iChangedFlags!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument of asnUpdatePresence
 * This argument contains the changes which triggered the event on the server and the new presence state of the corresponding contact.
 */
export class AsnUpdatePresenceV2Argument {
	public constructor(that: AsnUpdatePresenceV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUpdatePresenceV2Argument {
		return new AsnUpdatePresenceV2Argument({
			presence: AsnPresenceV2["initEmpty"].call(0),
			iChangedFlags: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"presence",
			"iChangedFlags"
		];
		return p;
	}

	public static type = "AsnUpdatePresenceV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdatePresenceV2Argument",
			...params,
			value: [
				AsnPresenceV2.getASN1Schema({ name: "presence" }),
				new asn1ts.Integer({ name: "iChangedFlags" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Updated data and service states of the contact. See AsnPresence for more details. */
	public presence!: AsnPresenceV2;
	/** Flags indicating which data sections od the contact have triggered the event.\ @linked AsnPresenceSubscriptionFlags */
	public iChangedFlags!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetAndSubscribePresence with the list of successfully subscribed contacts.
 * If the operation was successfully executed this result contains the list of successfully subscribed contacts, containing all requested data (if available).
 *
 * @deprecated 26.07.2024 -&gt; use AsnGetAndSubscribePresenceV2Result
 */
export class AsnGetAndSubscribePresenceResult {
	public constructor(that: AsnGetAndSubscribePresenceResult) {
		TSDeprecatedCallback.deprecatedObject(1721952000, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetAndSubscribePresenceResult {
		return new AsnGetAndSubscribePresenceResult({
			seqSubscribedPresence: new AsnPresenceList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"seqSubscribedPresence"
		];
		return p;
	}

	public static type = "AsnGetAndSubscribePresenceResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAndSubscribePresenceResult",
			...params,
			value: [
				AsnPresenceList.getASN1Schema({ name: "seqSubscribedPresence" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Kontakte die angefordert wurden */
	public seqSubscribedPresence!: AsnPresenceList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetAndSubscribePresence with the list of successfully subscribed contacts.
 * If the operation was successfully executed this result contains the list of successfully subscribed contacts, containing all requested data (if available).
 */
export class AsnGetAndSubscribePresenceV2Result {
	public constructor(that: AsnGetAndSubscribePresenceV2Result) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetAndSubscribePresenceV2Result {
		return new AsnGetAndSubscribePresenceV2Result({
			seqSubscribedPresence: new AsnPresenceV2List()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"seqSubscribedPresence"
		];
		return p;
	}

	public static type = "AsnGetAndSubscribePresenceV2Result";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAndSubscribePresenceV2Result",
			...params,
			value: [
				AsnPresenceV2List.getASN1Schema({ name: "seqSubscribedPresence" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Kontakte die angefordert wurden */
	public seqSubscribedPresence!: AsnPresenceV2List;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnSetMonitorGroupingAttribute
 *
 * @private
 */
export class AsnSetMonitorGroupingAttributeArgument {
	public constructor(that?: AsnSetMonitorGroupingAttributeArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetMonitorGroupingAttributeArgument {
		return new AsnSetMonitorGroupingAttributeArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		if (bIncludeOptionals)
			p.push("attribute");
		return p;
	}

	public static type = "AsnSetMonitorGroupingAttributeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetMonitorGroupingAttributeArgument",
			...params,
			value: [
				AsnMonitorGroupingAttribute.getASN1Schema({ name: "attribute", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Send a grouping attribute struct to the server to set texts or to change them. If you have am ID you can use attributeid to set the attribute. */
	public attribute?: AsnMonitorGroupingAttribute;
}
