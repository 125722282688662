import { AsnLogonErrorEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Auth";
import { getAppTitleExternalShort } from "./commonHelper";

/**
 * Error to distinguish the way it has to be handled
 */
enum EErrorSupportType {
	NONE,
	OWN,
	SYS_ADMIN,
	TECHNICAL
}

/**
 * Extends the AsnLogonErrorEnum
 */
enum ELoginErrorEx {
	exUnexpectedError = 10000,
	exFetchError,
	exUnableToConnectToWebSocket,
	exInvalidUcsid,
	exInvalidUcsidFromUCController,
	exInvalidServerInterface,
	exInvalidUCControllerURL,
	exInvalidUCServerURI,
	exInvalidSessionID,
	exUcsIdNotAllowed,
	exMyOwnContactIsUndefined,
	exInconsistentDataInCacheFound,
	exOAuthEntraIdLinkNotAvailable
}

type EAsnLogonErrorEnumEX = ELoginErrorEx | AsnLogonErrorEnum;
export const EAsnLogonErrorEnumEX = { ...ELoginErrorEx, ...AsnLogonErrorEnum };

/**
 * Class dedicated on helpers to display error messages readable for the user.
 */
export default class ErrorInfoHelper {
	/**
	 * Helper function to display a human-readable error.
	 * Dedicated to the logon errors (also returned by createSession)
	 * @param error - the error of enum EAsnLogonErrorEnumEX
	 * @returns the translated string
	 */
	public static parseLogonError(error: EAsnLogonErrorEnumEX): string {
		let msg = "";
		let errType;
		switch (error) {
			case EAsnLogonErrorEnumEX.exInvalidUcsid:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_INVALID_UCSID");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInvalidServerInterface:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_UCSERVER_INCOMPATIBLE_VERSION");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInvalidUCControllerURL:
				// TODO: localization
				msg = "You need to setup the uccontroller URL";
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInvalidUcsidFromUCController:
				// TODO: localization
				msg = "UCController returned an invalid UCSID";
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInvalidUCServerURI:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_NO_UCSERVER");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInvalidSessionID:
				// TODO: localization
				msg = "UCServer returned no session id";
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exFetchError:
				msg = getAppTitleExternalShort("IDS_ERROR_FETCH_URL");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exUnableToConnectToWebSocket:
				// TODO: localization
				msg = "Unable to establish a connection via web socket";
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINTLSREQUIRED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_TLS_REQUIRED");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINTOKENEXIRED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_TOKEN_EXPIRED");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINTOOMANYATTEMPTS:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_TEMPORARY_LOCKED_OUT");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINNEGOTIATIONREQUIRED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_NEGOTIATION_REQUIRED");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINNOMOREUSERS:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_NO_MORE_USERS");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGININVALIDVERSION:
			case EAsnLogonErrorEnumEX.eNETLOGINSERVERNOTCOMPATIBLE:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_SERVER_NOT_COMPATIBLE");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINALREADYAUTHENTICATED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_ALREADY_AUTHENTICATED");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINUSERNOTENABLED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_USER_NOT_ENABLED");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINACCOUNTPASSWORDLOCKED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_ACCOUNT_PASSWORD_LOCKED");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGININVALIDAUTHENTICATIONMETHOD:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_INVALID_AUTHENTICATION_METHOD");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGININVALIDCOMPUTERNAME:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_INVALID_COMPUTER_NAME");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINMISSINGRIGHTS:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_MISSING_RIGHTS");
				errType = EErrorSupportType.SYS_ADMIN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGINDOMAINAUTHFAILED:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_FAILED");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGININVALUSERNAME:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_CREDENTIALS");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.eNETLOGININTERNALSERVERERROR:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_LOGIN_INTERNAL_SERVER_ERROR");
				errType = EErrorSupportType.TECHNICAL;
				break;
			case EAsnLogonErrorEnumEX.exUcsIdNotAllowed:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_UCSID_NOT_ALLOWED");
				errType = EErrorSupportType.OWN;
				break;
			case EAsnLogonErrorEnumEX.exInconsistentDataInCacheFound:
				msg = getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_INCONSISTENT_DATA_IN_CACHE");
				errType = EErrorSupportType.OWN;
				break;
			case ELoginErrorEx.exUnexpectedError:
			default:
				msg = getAppTitleExternalShort("IDS_ERROR_UNEXPECTED");
				errType = EErrorSupportType.OWN;
				break;
		}
		// we found a matching message
		if (msg !== "") {
			if (errType === EErrorSupportType.SYS_ADMIN)
				msg += " " + getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_CONTACT_SYSADMIN");
			else if (errType === EErrorSupportType.TECHNICAL)
				msg += " " + getAppTitleExternalShort("IDS_ERROR_AUTHENTICATION_CONTACT_SUPPORT");
			msg += " [" + error + "] ";
		}
		return msg;
	}
}
