/**
 * Check if a date is a Date instance or a string, convert it into a string if it's a Date.
 * @param date - date in Date or string format
 * @returns - the date in ISO string format or an empty string
 */
export function validateAndGetISOStringFromDate(date?: Date | string): string {
	if (!date)
		return "";
	if (typeof date === "string")
		return date;
	if (!isNaN(date.getTime()))
		return date.toISOString();
	return "";
}

/**
 * Helper to strip the initial prefix of a base64 string.
 * Instead of using String.replace, which may end in an error for excessive recursive calls
 * better just splitting the string. The initial part always ends this way "base64,"
 * @param base64 - the string to strip
 * @returns - the string without the prefix
 */
export function stripBase64Prefix(base64: string): string {
	const split = base64.split("base64,");
	if (!split.length)
		return "";
	return split[1];
}

/**
 * Converts array buffer to base64 string
 * @param buffer - buffer
 * @returns - base64 string
 */
export async function bufferToBase64(buffer?: Uint8Array): Promise<string | undefined> {
	if (!buffer)
		return undefined;
	// use a FileReader to generate a base64 data URI:
	const base64url: string = await new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = () => {
			if (typeof reader.result === "string")
				resolve(reader.result);
			else
				resolve("");
		};
		reader.readAsDataURL(new Blob([buffer]));
	});
	if (!base64url)
		return;
	return stripBase64Prefix(base64url);
}
