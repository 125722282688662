// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_AV_Converter.ts
 * "UC-Server-Access-Protocol-AV" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_AV from "./ENetUC_AV";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_AV_Converter";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSEncoderDecoderCode]
export class AsnAVCallAndConnectionID_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallAndConnectionID, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallAndConnectionID & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallAndConnectionID");

		const t = {} as ENetUC_AV.AsnAVCallAndConnectionID & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallAndConnectionID";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEndpointID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVCallAndConnectionID"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallAndConnectionID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallAndConnectionID");

		let t: ENetUC_AV.AsnAVCallAndConnectionID | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallAndConnectionID>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallAndConnectionID["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sEndpointID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVCallAndConnectionID"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallAndConnectionID | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallAndConnectionID";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallAndConnectionID");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEndpointID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEndpointID, name: "u8sEndpointID" }));
		}

		if (errors.validateResult(newContext, "AsnAVCallAndConnectionID"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallAndConnectionID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallAndConnectionID");

		let t: ENetUC_AV.AsnAVCallAndConnectionID | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallAndConnectionID.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallAndConnectionID["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEndpointID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVCallAndConnectionID"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVMakeCallArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVMakeCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVMakeCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallArgument");

		const t = {} as ENetUC_AV.AsnAVMakeCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVMakeCallArgument";
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}
		TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallArgument");

		let t: ENetUC_AV.AsnAVMakeCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVMakeCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVMakeCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
			TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVMakeCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVMakeCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", 0);
		TSConverter.validateParam(s, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 3);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.u8sBody), name: "u8sBody" }));
			if (_newCallParams)
				t.push(_newCallParams);
			if (s.u8sAssociatedTextChatConversationID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAssociatedTextChatConversationID, name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 2 } }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallArgument");

		let t: ENetUC_AV.AsnAVMakeCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVMakeCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVMakeCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
			TSConverter.fillASN1Param(s, t, "u8sAssociatedTextChatConversationID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVMakeCallV2Argument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVMakeCallV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVMakeCallV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallV2Argument");

		const t = {} as ENetUC_AV.AsnAVMakeCallV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVMakeCallV2Argument";
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}
		TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bIsPublicCall", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bIsRemoteControlCallOut", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bIsRemoteControlCallIn", "boolean", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVMakeCallV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallV2Argument");

		let t: ENetUC_AV.AsnAVMakeCallV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVMakeCallV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVMakeCallV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
			TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bIsPublicCall", "boolean", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bIsRemoteControlCallOut", "boolean", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bIsRemoteControlCallIn", "boolean", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVMakeCallV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVMakeCallV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", 0);
		TSConverter.validateParam(s, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		TSConverter.validateParam(s, "bIsPublicCall", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bIsRemoteControlCallOut", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bIsRemoteControlCallIn", "boolean", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody" }));
			if (_newCallParams)
				t.push(_newCallParams);
			if (s.u8sAssociatedTextChatConversationID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAssociatedTextChatConversationID, name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 2 } }));
			if (s.bIsPublicCall !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bIsPublicCall, name: "bIsPublicCall", idBlock: { optionalID: 3 } }));
			if (s.bIsRemoteControlCallOut !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bIsRemoteControlCallOut, name: "bIsRemoteControlCallOut", idBlock: { optionalID: 4 } }));
			if (s.bIsRemoteControlCallIn !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bIsRemoteControlCallIn, name: "bIsRemoteControlCallIn", idBlock: { optionalID: 5 } }));
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallV2Argument");

		let t: ENetUC_AV.AsnAVMakeCallV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVMakeCallV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVMakeCallV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
			TSConverter.fillASN1Param(s, t, "u8sAssociatedTextChatConversationID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bIsPublicCall", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bIsRemoteControlCallOut", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bIsRemoteControlCallIn", "Boolean", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallParty_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallParty, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallParty & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallParty");

		const t = {} as ENetUC_AV.AsnAVCallParty & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallParty";
		TSConverter.fillJSONParam(s, t, "endpointID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endPointState", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVCallParty"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallParty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallParty");

		let t: ENetUC_AV.AsnAVCallParty | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallParty>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallParty["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "endpointID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endPointState", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVCallParty"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallParty | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallParty";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallParty");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "endpointID", "string", errors, newContext);
		TSConverter.validateParam(s, "endPointState", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.endpointID, name: "endpointID" }));
			t.push(new asn1ts.Integer({ value: s.endPointState, name: "endPointState" }));
		}

		if (errors.validateResult(newContext, "AsnAVCallParty"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallParty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallParty");

		let t: ENetUC_AV.AsnAVCallParty | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallParty.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallParty["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "endpointID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endPointState", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVCallParty"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallMessageICE_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallMessageICE, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallMessageICE & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageICE");

		const t = {} as ENetUC_AV.AsnAVCallMessageICE & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallMessageICE";
		TSConverter.fillJSONParam(s, t, "candidate", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sdpMid", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sdpMLineIndex", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVCallMessageICE"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageICE | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageICE");

		let t: ENetUC_AV.AsnAVCallMessageICE | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallMessageICE>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallMessageICE["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "candidate", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "sdpMid", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "sdpMLineIndex", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICE"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallMessageICE | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallMessageICE";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageICE");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "candidate", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "sdpMid", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "sdpMLineIndex", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.candidate), name: "candidate" }));
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.sdpMid), name: "sdpMid" }));
			t.push(new asn1ts.Integer({ value: s.sdpMLineIndex, name: "sdpMLineIndex" }));
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICE"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageICE | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageICE");

		let t: ENetUC_AV.AsnAVCallMessageICE | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallMessageICE.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallMessageICE["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "candidate", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sdpMid", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sdpMLineIndex", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICE"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallMessageICEV2_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallMessageICEV2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallMessageICEV2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageICEV2");

		const t = {} as ENetUC_AV.AsnAVCallMessageICEV2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallMessageICEV2";
		TSConverter.fillJSONParam(s, t, "candidate", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sdpMid", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sdpMLineIndex", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVCallMessageICEV2"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageICEV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageICEV2");

		let t: ENetUC_AV.AsnAVCallMessageICEV2 | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallMessageICEV2>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallMessageICEV2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "candidate", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "sdpMid", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "sdpMLineIndex", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICEV2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallMessageICEV2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallMessageICEV2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageICEV2");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "candidate", "string", errors, newContext);
		TSConverter.validateParam(s, "sdpMid", "string", errors, newContext);
		TSConverter.validateParam(s, "sdpMLineIndex", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.candidate, name: "candidate" }));
			t.push(new asn1ts.Utf8String({ value: s.sdpMid, name: "sdpMid" }));
			t.push(new asn1ts.Integer({ value: s.sdpMLineIndex, name: "sdpMLineIndex" }));
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICEV2"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageICEV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageICEV2");

		let t: ENetUC_AV.AsnAVCallMessageICEV2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallMessageICEV2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallMessageICEV2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "candidate", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sdpMid", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sdpMLineIndex", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageICEV2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVConnectArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVConnectArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVConnectArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVConnectArgument");

		const t = {} as ENetUC_AV.AsnAVConnectArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVConnectArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVConnectArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVConnectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVConnectArgument");

		let t: ENetUC_AV.AsnAVConnectArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVConnectArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVConnectArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVConnectArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVConnectArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVConnectArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVConnectArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "connectedEndPointID", "string", errors, newContext);
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.connectedEndPointID, name: "connectedEndPointID" }));
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.u8sBody), name: "u8sBody" }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnAVConnectArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVConnectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVConnectArgument");

		let t: ENetUC_AV.AsnAVConnectArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVConnectArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVConnectArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "connectedEndPointID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVConnectArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVConnectV2Argument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVConnectV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVConnectV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVConnectV2Argument");

		const t = {} as ENetUC_AV.AsnAVConnectV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVConnectV2Argument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVConnectV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVConnectV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVConnectV2Argument");

		let t: ENetUC_AV.AsnAVConnectV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVConnectV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVConnectV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVConnectV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVConnectV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVConnectV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVConnectV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "connectedEndPointID", "string", errors, newContext);
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.connectedEndPointID, name: "connectedEndPointID" }));
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody" }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnAVConnectV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVConnectV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVConnectV2Argument");

		let t: ENetUC_AV.AsnAVConnectV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVConnectV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVConnectV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "connectedEndPointID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVConnectV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVAlertArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVAlertArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVAlertArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVAlertArgument");

		const t = {} as ENetUC_AV.AsnAVAlertArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVAlertArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVAlertArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVAlertArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVAlertArgument");

		let t: ENetUC_AV.AsnAVAlertArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVAlertArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVAlertArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVAlertArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVAlertArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVAlertArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVAlertArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.u8sBody), name: "u8sBody" }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnAVAlertArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVAlertArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVAlertArgument");

		let t: ENetUC_AV.AsnAVAlertArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVAlertArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVAlertArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVAlertArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVAlertV2Argument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVAlertV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVAlertV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVAlertV2Argument");

		const t = {} as ENetUC_AV.AsnAVAlertV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVAlertV2Argument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVAlertV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVAlertV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVAlertV2Argument");

		let t: ENetUC_AV.AsnAVAlertV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVAlertV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVAlertV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVAlertV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVAlertV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVAlertV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVAlertV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody" }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnAVAlertV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVAlertV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVAlertV2Argument");

		let t: ENetUC_AV.AsnAVAlertV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVAlertV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVAlertV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVAlertV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestSTUNandTURNArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnRequestSTUNandTURNArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnRequestSTUNandTURNArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestSTUNandTURNArgument");

		const t = {} as ENetUC_AV.AsnRequestSTUNandTURNArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestSTUNandTURNArgument";

		if (errors.validateResult(newContext, "AsnRequestSTUNandTURNArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnRequestSTUNandTURNArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestSTUNandTURNArgument");

		let t: ENetUC_AV.AsnRequestSTUNandTURNArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnRequestSTUNandTURNArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnRequestSTUNandTURNArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRequestSTUNandTURNArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnRequestSTUNandTURNArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestSTUNandTURNArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestSTUNandTURNArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnRequestSTUNandTURNArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnRequestSTUNandTURNArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestSTUNandTURNArgument");

		let t: ENetUC_AV.AsnRequestSTUNandTURNArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnRequestSTUNandTURNArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnRequestSTUNandTURNArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRequestSTUNandTURNArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSTUNandTURNArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnGetSTUNandTURNArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnGetSTUNandTURNArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSTUNandTURNArgument");

		const t = {} as ENetUC_AV.AsnGetSTUNandTURNArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetSTUNandTURNArgument";

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnGetSTUNandTURNArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSTUNandTURNArgument");

		let t: ENetUC_AV.AsnGetSTUNandTURNArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnGetSTUNandTURNArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnGetSTUNandTURNArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnGetSTUNandTURNArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSTUNandTURNArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSTUNandTURNArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnGetSTUNandTURNArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSTUNandTURNArgument");

		let t: ENetUC_AV.AsnGetSTUNandTURNArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnGetSTUNandTURNArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnGetSTUNandTURNArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSTUNandTURNResult_Converter {
	public static toJSON(s: ENetUC_AV.AsnGetSTUNandTURNResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnGetSTUNandTURNResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSTUNandTURNResult");

		const t = {} as ENetUC_AV.AsnGetSTUNandTURNResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetSTUNandTURNResult";
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
		if (_listConfigSTUNandTURN)
			t.listConfigSTUNandTURN = _listConfigSTUNandTURN;

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnGetSTUNandTURNResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSTUNandTURNResult");

		let t: ENetUC_AV.AsnGetSTUNandTURNResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnGetSTUNandTURNResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnGetSTUNandTURNResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _listconfigstunandturn = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", false);
			if (_listconfigstunandturn)
				t.listConfigSTUNandTURN = _listconfigstunandturn;
		}

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnGetSTUNandTURNResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSTUNandTURNResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSTUNandTURNResult");

		// [Print_BER_EncoderSeqDefCode]
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toBER(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
		if (!errors.hasNewErrors()) {
			if (_listConfigSTUNandTURN)
				t.push(_listConfigSTUNandTURN);
		}

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnGetSTUNandTURNResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSTUNandTURNResult");

		let t: ENetUC_AV.AsnGetSTUNandTURNResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnGetSTUNandTURNResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnGetSTUNandTURNResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _listconfigstunandturn = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listConfigSTUNandTURN"), errors, newContext, "listConfigSTUNandTURN");
			if (_listconfigstunandturn)
				t.listConfigSTUNandTURN = _listconfigstunandturn;
		}

		if (errors.validateResult(newContext, "AsnGetSTUNandTURNResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSTUNandTURNConfigChangedArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnSTUNandTURNConfigChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSTUNandTURNConfigChangedArgument");

		const t = {} as ENetUC_AV.AsnSTUNandTURNConfigChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSTUNandTURNConfigChangedArgument";
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
		if (_listConfigSTUNandTURN)
			t.listConfigSTUNandTURN = _listConfigSTUNandTURN;

		if (errors.validateResult(newContext, "AsnSTUNandTURNConfigChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnSTUNandTURNConfigChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSTUNandTURNConfigChangedArgument");

		let t: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnSTUNandTURNConfigChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnSTUNandTURNConfigChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _listconfigstunandturn = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", false);
			if (_listconfigstunandturn)
				t.listConfigSTUNandTURN = _listconfigstunandturn;
		}

		if (errors.validateResult(newContext, "AsnSTUNandTURNConfigChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSTUNandTURNConfigChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSTUNandTURNConfigChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toBER(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
		if (!errors.hasNewErrors()) {
			if (_listConfigSTUNandTURN)
				t.push(_listConfigSTUNandTURN);
		}

		if (errors.validateResult(newContext, "AsnSTUNandTURNConfigChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnSTUNandTURNConfigChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSTUNandTURNConfigChangedArgument");

		let t: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnSTUNandTURNConfigChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnSTUNandTURNConfigChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _listconfigstunandturn = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listConfigSTUNandTURN"), errors, newContext, "listConfigSTUNandTURN");
			if (_listconfigstunandturn)
				t.listConfigSTUNandTURN = _listconfigstunandturn;
		}

		if (errors.validateResult(newContext, "AsnSTUNandTURNConfigChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnIceServer_Converter {
	public static toJSON(s: ENetUC_AV.AsnIceServer, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnIceServer & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIceServer");

		const t = {} as ENetUC_AV.AsnIceServer & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnIceServer";
		TSConverter.fillJSONParam(s, t, "username", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "credential", "string", errors, newContext, true);
		const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.urls, errors, newContext, "urls");
		if (_urls)
			t.urls = _urls;

		if (errors.validateResult(newContext, "AsnIceServer"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnIceServer | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIceServer");

		let t: ENetUC_AV.AsnIceServer | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnIceServer>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnIceServer["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "username", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "credential", "string", errors, newContext, true);
			const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.urls, errors, newContext, "urls", false);
			if (_urls)
				t.urls = _urls;
		}

		if (errors.validateResult(newContext, "AsnIceServer"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnIceServer | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnIceServer";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIceServer");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "username", "string", errors, newContext, true);
		TSConverter.validateParam(s, "credential", "string", errors, newContext, true);
		const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.urls, errors, newContext, "urls");
		if (!errors.hasNewErrors()) {
			if (s.username !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.username, name: "username", idBlock: { optionalID: 0 } }));
			if (s.credential !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.credential, name: "credential", idBlock: { optionalID: 1 } }));
			if (_urls)
				t.push(_urls);
		}

		if (errors.validateResult(newContext, "AsnIceServer"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnIceServer | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIceServer");

		let t: ENetUC_AV.AsnIceServer | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnIceServer.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnIceServer["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "username", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "credential", "Utf8String", errors, newContext, true);
			const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "urls"), errors, newContext, "urls");
			if (_urls)
				t.urls = _urls;
		}

		if (errors.validateResult(newContext, "AsnIceServer"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVGetIceServersArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVGetIceServersArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVGetIceServersArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVGetIceServersArgument");

		const t = {} as ENetUC_AV.AsnAVGetIceServersArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVGetIceServersArgument";

		if (errors.validateResult(newContext, "AsnAVGetIceServersArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVGetIceServersArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVGetIceServersArgument");

		let t: ENetUC_AV.AsnAVGetIceServersArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVGetIceServersArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVGetIceServersArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnAVGetIceServersArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVGetIceServersArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVGetIceServersArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVGetIceServersArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnAVGetIceServersArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVGetIceServersArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVGetIceServersArgument");

		let t: ENetUC_AV.AsnAVGetIceServersArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVGetIceServersArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVGetIceServersArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnAVGetIceServersArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnConfigCodec_Converter {
	public static toJSON(s: ENetUC_AV.AsnConfigCodec, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnConfigCodec & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigCodec");

		const t = {} as ENetUC_AV.AsnConfigCodec & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnConfigCodec";
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnConfigCodec"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigCodec | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigCodec");

		let t: ENetUC_AV.AsnConfigCodec | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnConfigCodec>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnConfigCodec["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnConfigCodec"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnConfigCodec | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnConfigCodec";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigCodec");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iType", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iType, name: "iType" }));
		}

		if (errors.validateResult(newContext, "AsnConfigCodec"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigCodec | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigCodec");

		let t: ENetUC_AV.AsnConfigCodec | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnConfigCodec.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnConfigCodec["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnConfigCodec"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVMediaChangeArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVMediaChangeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVMediaChangeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMediaChangeArgument");

		const t = {} as ENetUC_AV.AsnAVMediaChangeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVMediaChangeArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMediaType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "Uint8Array", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMediaID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAvFlags", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAvDropReason", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVMediaChangeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMediaChangeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMediaChangeArgument");

		let t: ENetUC_AV.AsnAVMediaChangeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVMediaChangeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVMediaChangeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "connectedEndPointID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iMediaType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			if (TSConverter.validateParam(s, "u8sBody", "string", errors, newContext, false))
				t.u8sBody = TSConverter.decode64(s.u8sBody as unknown as string);
			TSConverter.fillJSONParam(s, t, "u8sMediaID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAvFlags", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAvDropReason", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVMediaChangeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVMediaChangeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVMediaChangeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMediaChangeArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "connectedEndPointID", "string", errors, newContext);
		TSConverter.validateParam(s, "iMediaType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "u8sMediaID", "string", errors, newContext);
		TSConverter.validateParam(s, "iAvFlags", "number", errors, newContext);
		TSConverter.validateParam(s, "iAvDropReason", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.connectedEndPointID, name: "connectedEndPointID" }));
			t.push(new asn1ts.Integer({ value: s.iMediaType, name: "iMediaType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.OctetString({ valueHex: s.u8sBody, name: "u8sBody" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMediaID, name: "u8sMediaID" }));
			t.push(new asn1ts.Integer({ value: s.iAvFlags, name: "iAvFlags" }));
			t.push(new asn1ts.Integer({ value: s.iAvDropReason, name: "iAvDropReason" }));
		}

		if (errors.validateResult(newContext, "AsnAVMediaChangeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMediaChangeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMediaChangeArgument");

		let t: ENetUC_AV.AsnAVMediaChangeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVMediaChangeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVMediaChangeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "connectedEndPointID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMediaType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMediaID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAvFlags", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAvDropReason", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVMediaChangeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVTransferArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVTransferArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVTransferArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVTransferArgument");

		const t = {} as ENetUC_AV.AsnAVTransferArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVTransferArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iTransferResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTransferTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTransferFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTransferTargetID", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAVTransferArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVTransferArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVTransferArgument");

		let t: ENetUC_AV.AsnAVTransferArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVTransferArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVTransferArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iTransferResult", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTransferTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTransferFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sTransferTargetID", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVTransferArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVTransferArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVTransferArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVTransferArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "iTransferResult", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTransferTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTransferFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTransferTargetID", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Integer({ value: s.iTransferResult, name: "iTransferResult" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTransferTo, name: "u8sUriTransferTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTransferFrom, name: "u8sUriTransferFrom" }));
			if (s.u8sTransferTargetID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sTransferTargetID, name: "u8sTransferTargetID", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnAVTransferArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVTransferArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVTransferArgument");

		let t: ENetUC_AV.AsnAVTransferArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVTransferArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVTransferArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iTransferResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTransferTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTransferFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTransferTargetID", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAVTransferArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallOrConnection_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallOrConnection, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallOrConnection & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallOrConnection");

		const t = {} as ENetUC_AV.AsnAVCallOrConnection & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallOrConnection";
		if (s.u8sCallID != null)
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		else if (s.connectionID != null) {
			const _connectionID = AsnAVCallAndConnectionID_Converter.toJSON(s.connectionID, errors, newContext, "connectionID");
			if (_connectionID)
				t.connectionID = _connectionID;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnAVCallOrConnection"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallOrConnection | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallOrConnection");

		let t: ENetUC_AV.AsnAVCallOrConnection | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallOrConnection>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallOrConnection["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.u8sCallID !== undefined) {
				if (TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext))
					t.u8sCallID = s.u8sCallID;
			} else if (s.connectionID !== undefined)
				t.connectionID = AsnAVCallAndConnectionID_Converter.fromJSON(s.connectionID, errors, newContext, "connectionID", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnAVCallOrConnection"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallOrConnection | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnAVCallOrConnection";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallOrConnection");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext, true))
			t = new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID", idBlock: { optionalID: 0 } });
		else if (s.connectionID)
			t = AsnAVCallAndConnectionID_Converter.toBER(s.connectionID, errors, newContext, "connectionID", 1);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnAVCallOrConnection"))
			return t;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallOrConnection | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallOrConnection");

		let t: ENetUC_AV.AsnAVCallOrConnection | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallOrConnection.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallOrConnection["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "u8sCallID" && asn1ts.Utf8String.typeGuard(s)) {
				const _u8sCallID = s.getValue();
				if (TSConverter.validateParamType(_u8sCallID, "u8sCallID", "string", errors, newContext, false))
					t.u8sCallID = _u8sCallID;
			} else if (s.choiceName === "connectionID" && asn1ts.Sequence.typeGuard(s))
				t.connectionID = AsnAVCallAndConnectionID_Converter.fromBER(s, undefined, newContext, "connectionid", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnAVCallOrConnection"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVMakeCallResult_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVMakeCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVMakeCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallResult");

		const t = {} as ENetUC_AV.AsnAVMakeCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVMakeCallResult";
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		if (_callAndConnectionID)
			t.callAndConnectionID = _callAndConnectionID;
		TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVMakeCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallResult");

		let t: ENetUC_AV.AsnAVMakeCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVMakeCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVMakeCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID", false);
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVMakeCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVMakeCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVMakeCallResult");

		// [Print_BER_EncoderSeqDefCode]
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toBER(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		TSConverter.validateParam(s, "u8sCTIConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_callAndConnectionID)
				t.push(_callAndConnectionID);
			t.push(new asn1ts.Utf8String({ value: s.u8sCTIConnectionID, name: "u8sCTIConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVMakeCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVMakeCallResult");

		let t: ENetUC_AV.AsnAVMakeCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVMakeCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVMakeCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callAndConnectionID"), errors, newContext, "callAndConnectionID");
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillASN1Param(s, t, "u8sCTIConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVMakeCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVDropCallArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVDropCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVDropCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVDropCallArgument");

		const t = {} as ENetUC_AV.AsnAVDropCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVDropCallArgument";
		const _dropTarget = AsnAVCallOrConnection_Converter.toJSON(s.dropTarget, errors, newContext, "dropTarget");
		if (_dropTarget)
			t.dropTarget = _dropTarget;
		TSConverter.fillJSONParam(s, t, "u8sCause", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAvDropReason", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnAVDropCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVDropCallArgument");

		let t: ENetUC_AV.AsnAVDropCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVDropCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVDropCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _droptarget = AsnAVCallOrConnection_Converter.fromJSON(s.dropTarget, errors, newContext, "dropTarget", false);
			if (_droptarget)
				t.dropTarget = _droptarget;
			TSConverter.fillJSONParam(s, t, "u8sCause", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAvDropReason", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAVDropCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVDropCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVDropCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVDropCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _dropTarget = AsnAVCallOrConnection_Converter.toBER(s.dropTarget, errors, newContext, "dropTarget");
		TSConverter.validateParam(s, "u8sCause", "string", errors, newContext);
		TSConverter.validateParam(s, "iAvDropReason", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_dropTarget)
				t.push(_dropTarget);
			t.push(new asn1ts.Utf8String({ value: s.u8sCause, name: "u8sCause" }));
			t.push(new asn1ts.Integer({ value: s.iAvDropReason, name: "iAvDropReason" }));
		}

		if (errors.validateResult(newContext, "AsnAVDropCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVDropCallArgument");

		let t: ENetUC_AV.AsnAVDropCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVDropCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVDropCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _droptarget = AsnAVCallOrConnection_Converter.fromBER(s.getValueByName("dropTarget"), errors, newContext, "dropTarget");
			if (_droptarget)
				t.dropTarget = _droptarget;
			TSConverter.fillASN1Param(s, t, "u8sCause", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAvDropReason", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAVDropCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVNewCallArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVNewCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVNewCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVNewCallArgument");

		const t = {} as ENetUC_AV.AsnAVNewCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVNewCallArgument";
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		if (_callAndConnectionID)
			t.callAndConnectionID = _callAndConnectionID;
		TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}
		TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnAVNewCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVNewCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVNewCallArgument");

		let t: ENetUC_AV.AsnAVNewCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVNewCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVNewCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID", false);
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
			TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnAVNewCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVNewCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVNewCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVNewCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toBER(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		TSConverter.validateParam(s, "u8sCTIConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", 0);
		TSConverter.validateParam(s, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 3);
		if (!errors.hasNewErrors()) {
			if (_callAndConnectionID)
				t.push(_callAndConnectionID);
			t.push(new asn1ts.Utf8String({ value: s.u8sCTIConnectionID, name: "u8sCTIConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.u8sBody), name: "u8sBody" }));
			if (_newCallParams)
				t.push(_newCallParams);
			if (s.u8sAssociatedTextChatConversationID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAssociatedTextChatConversationID, name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 2 } }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnAVNewCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVNewCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVNewCallArgument");

		let t: ENetUC_AV.AsnAVNewCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVNewCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVNewCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callAndConnectionID"), errors, newContext, "callAndConnectionID");
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillASN1Param(s, t, "u8sCTIConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
			TSConverter.fillASN1Param(s, t, "u8sAssociatedTextChatConversationID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnAVNewCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVNewCallV2Argument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVNewCallV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVNewCallV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVNewCallV2Argument");

		const t = {} as ENetUC_AV.AsnAVNewCallV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVNewCallV2Argument";
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		if (_callAndConnectionID)
			t.callAndConnectionID = _callAndConnectionID;
		TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}
		TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCTITrackerID", "string", errors, newContext, true);
		if (s.asnLocalContact) {
			const _asnLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnLocalContact, errors, newContext, "asnLocalContact");
			if (_asnLocalContact)
				t.asnLocalContact = _asnLocalContact;
		}
		TSConverter.fillJSONParam(s, t, "u8sHoldContactNumberSC", "string", errors, newContext, true);
		if (s.asnHoldContact) {
			const _asnHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnHoldContact, errors, newContext, "asnHoldContact");
			if (_asnHoldContact)
				t.asnHoldContact = _asnHoldContact;
		}
		TSConverter.fillJSONParam(s, t, "u8sRedirectingNumberSC", "string", errors, newContext, true);
		if (s.asnRedirectingContact) {
			const _asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact");
			if (_asnRedirectingContact)
				t.asnRedirectingContact = _asnRedirectingContact;
		}

		if (errors.validateResult(newContext, "AsnAVNewCallV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVNewCallV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVNewCallV2Argument");

		let t: ENetUC_AV.AsnAVNewCallV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVNewCallV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVNewCallV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromJSON(s.callAndConnectionID, errors, newContext, "callAndConnectionID", false);
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillJSONParam(s, t, "u8sCTIConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "callType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
			TSConverter.fillJSONParam(s, t, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sCaps", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sCTITrackerID", "string", errors, newContext, true);
			const _asnlocalcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnLocalContact, errors, newContext, "asnLocalContact", true);
			if (_asnlocalcontact)
				t.asnLocalContact = _asnlocalcontact;
			TSConverter.fillJSONParam(s, t, "u8sHoldContactNumberSC", "string", errors, newContext, true);
			const _asnholdcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnHoldContact, errors, newContext, "asnHoldContact", true);
			if (_asnholdcontact)
				t.asnHoldContact = _asnholdcontact;
			TSConverter.fillJSONParam(s, t, "u8sRedirectingNumberSC", "string", errors, newContext, true);
			const _asnredirectingcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact", true);
			if (_asnredirectingcontact)
				t.asnRedirectingContact = _asnredirectingcontact;
		}

		if (errors.validateResult(newContext, "AsnAVNewCallV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVNewCallV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVNewCallV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVNewCallV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		const _callAndConnectionID = AsnAVCallAndConnectionID_Converter.toBER(s.callAndConnectionID, errors, newContext, "callAndConnectionID");
		TSConverter.validateParam(s, "u8sCTIConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "callType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", 0);
		TSConverter.validateParam(s, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCaps", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCTITrackerID", "string", errors, newContext, true);
		const _asnLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnLocalContact, errors, newContext, "asnLocalContact", 4);
		TSConverter.validateParam(s, "u8sHoldContactNumberSC", "string", errors, newContext, true);
		const _asnHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnHoldContact, errors, newContext, "asnHoldContact", 6);
		TSConverter.validateParam(s, "u8sRedirectingNumberSC", "string", errors, newContext, true);
		const _asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact", 8);
		if (!errors.hasNewErrors()) {
			if (_callAndConnectionID)
				t.push(_callAndConnectionID);
			t.push(new asn1ts.Utf8String({ value: s.u8sCTIConnectionID, name: "u8sCTIConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.callType, name: "callType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody" }));
			if (_newCallParams)
				t.push(_newCallParams);
			if (s.u8sAssociatedTextChatConversationID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAssociatedTextChatConversationID, name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }));
			if (s.u8sCaps !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCaps, name: "u8sCaps", idBlock: { optionalID: 2 } }));
			if (s.u8sCTITrackerID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCTITrackerID, name: "u8sCTITrackerID", idBlock: { optionalID: 3 } }));
			if (_asnLocalContact)
				t.push(_asnLocalContact);
			if (s.u8sHoldContactNumberSC !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sHoldContactNumberSC, name: "u8sHoldContactNumberSC", idBlock: { optionalID: 5 } }));
			if (_asnHoldContact)
				t.push(_asnHoldContact);
			if (s.u8sRedirectingNumberSC !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sRedirectingNumberSC, name: "u8sRedirectingNumberSC", idBlock: { optionalID: 7 } }));
			if (_asnRedirectingContact)
				t.push(_asnRedirectingContact);
		}

		if (errors.validateResult(newContext, "AsnAVNewCallV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVNewCallV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVNewCallV2Argument");

		let t: ENetUC_AV.AsnAVNewCallV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVNewCallV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVNewCallV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _callandconnectionid = AsnAVCallAndConnectionID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callAndConnectionID"), errors, newContext, "callAndConnectionID");
			if (_callandconnectionid)
				t.callAndConnectionID = _callandconnectionid;
			TSConverter.fillASN1Param(s, t, "u8sCTIConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "callType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
			TSConverter.fillASN1Param(s, t, "u8sAssociatedTextChatConversationID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCaps", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCTITrackerID", "Utf8String", errors, newContext, true);
			t.asnLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnLocalContact"), errors, newContext, "asnLocalContact", true);
			TSConverter.fillASN1Param(s, t, "u8sHoldContactNumberSC", "Utf8String", errors, newContext, true);
			t.asnHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnHoldContact"), errors, newContext, "asnHoldContact", true);
			TSConverter.fillASN1Param(s, t, "u8sRedirectingNumberSC", "Utf8String", errors, newContext, true);
			t.asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnRedirectingContact"), errors, newContext, "asnRedirectingContact", true);
		}

		if (errors.validateResult(newContext, "AsnAVNewCallV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallPartyList_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallPartyList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallPartyList");

		const t = [] as ENetUC_AV.AsnAVCallPartyList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnAVCallParty_Converter.toJSON(se, errors, newContext, "AsnAVCallParty");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAVCallPartyList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallPartyList");

		let t: ENetUC_AV.AsnAVCallPartyList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallPartyList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_AV.AsnAVCallPartyList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnAVCallParty_Converter.fromJSON(se, errors, newContext, "AsnAVCallParty", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnAVCallPartyList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallPartyList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallPartyList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallPartyList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnAVCallParty_Converter.toBER(s[id], errors, newContext, "AsnAVCallParty");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnAVCallPartyList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallPartyList");

		let t: ENetUC_AV.AsnAVCallPartyList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallPartyList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_AV.AsnAVCallPartyList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnAVCallParty_Converter.fromBER(se, errors, newContext, "AsnAVCallParty", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnAVCallPartyList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallStateChangedArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallStateChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallStateChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallStateChangedArgument");

		const t = {} as ENetUC_AV.AsnAVCallStateChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallStateChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "callState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		const _parties = AsnAVCallPartyList_Converter.toJSON(s.parties, errors, newContext, "parties");
		if (_parties)
			t.parties = _parties;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnAVCallStateChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallStateChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallStateChangedArgument");

		let t: ENetUC_AV.AsnAVCallStateChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallStateChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallStateChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "callState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			const _parties = AsnAVCallPartyList_Converter.fromJSON(s.parties, errors, newContext, "parties", false);
			if (_parties)
				t.parties = _parties;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnAVCallStateChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallStateChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallStateChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallStateChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "callState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		const _parties = AsnAVCallPartyList_Converter.toBER(s.parties, errors, newContext, "parties");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Integer({ value: s.callState, name: "callState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriFrom, name: "u8sUriFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			if (_parties)
				t.push(_parties);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnAVCallStateChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallStateChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallStateChangedArgument");

		let t: ENetUC_AV.AsnAVCallStateChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallStateChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallStateChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "callState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			const _parties = AsnAVCallPartyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "parties"), errors, newContext, "parties");
			if (_parties)
				t.parties = _parties;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnAVCallStateChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallMessageArgument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallMessageArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallMessageArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageArgument");

		const t = {} as ENetUC_AV.AsnAVCallMessageArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallMessageArgument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "messageType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, true);
		if (s.iceCandidate) {
			const _iceCandidate = AsnAVCallMessageICE_Converter.toJSON(s.iceCandidate, errors, newContext, "iceCandidate");
			if (_iceCandidate)
				t.iceCandidate = _iceCandidate;
		}
		TSConverter.fillJSONParam(s, t, "u8sConnectedNumberSC", "string", errors, newContext, true);
		if (s.asnConnectedContact) {
			const _asnConnectedContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnConnectedContact, errors, newContext, "asnConnectedContact");
			if (_asnConnectedContact)
				t.asnConnectedContact = _asnConnectedContact;
		}
		TSConverter.fillJSONParam(s, t, "u8sRedirectingNumberSC", "string", errors, newContext, true);
		if (s.asnRedirectingContact) {
			const _asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact");
			if (_asnRedirectingContact)
				t.asnRedirectingContact = _asnRedirectingContact;
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageArgument");

		let t: ENetUC_AV.AsnAVCallMessageArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallMessageArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallMessageArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "messageType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, true);
			const _icecandidate = AsnAVCallMessageICE_Converter.fromJSON(s.iceCandidate, errors, newContext, "iceCandidate", true);
			if (_icecandidate)
				t.iceCandidate = _icecandidate;
			TSConverter.fillJSONParam(s, t, "u8sConnectedNumberSC", "string", errors, newContext, true);
			const _asnconnectedcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnConnectedContact, errors, newContext, "asnConnectedContact", true);
			if (_asnconnectedcontact)
				t.asnConnectedContact = _asnconnectedcontact;
			TSConverter.fillJSONParam(s, t, "u8sRedirectingNumberSC", "string", errors, newContext, true);
			const _asnredirectingcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact", true);
			if (_asnredirectingcontact)
				t.asnRedirectingContact = _asnredirectingcontact;
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallMessageArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallMessageArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "messageType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBody", "Uint8Array", errors, newContext, true);
		const _iceCandidate = AsnAVCallMessageICE_Converter.toBER(s.iceCandidate, errors, newContext, "iceCandidate", 2);
		TSConverter.validateParam(s, "u8sConnectedNumberSC", "string", errors, newContext, true);
		const _asnConnectedContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnConnectedContact, errors, newContext, "asnConnectedContact", 4);
		TSConverter.validateParam(s, "u8sRedirectingNumberSC", "string", errors, newContext, true);
		const _asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnRedirectingContact, errors, newContext, "asnRedirectingContact", 6);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Integer({ value: s.messageType, name: "messageType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			if (s.u8sContentType !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType", idBlock: { optionalID: 0 } }));
			if (s.u8sBody !== undefined)
				t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.u8sBody), name: "u8sBody", idBlock: { optionalID: 1 } }));
			if (_iceCandidate)
				t.push(_iceCandidate);
			if (s.u8sConnectedNumberSC !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sConnectedNumberSC, name: "u8sConnectedNumberSC", idBlock: { optionalID: 3 } }));
			if (_asnConnectedContact)
				t.push(_asnConnectedContact);
			if (s.u8sRedirectingNumberSC !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sRedirectingNumberSC, name: "u8sRedirectingNumberSC", idBlock: { optionalID: 5 } }));
			if (_asnRedirectingContact)
				t.push(_asnRedirectingContact);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageArgument");

		let t: ENetUC_AV.AsnAVCallMessageArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallMessageArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallMessageArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "messageType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBody", "OctetString", errors, newContext, true);
			t.iceCandidate = AsnAVCallMessageICE_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "iceCandidate"), errors, newContext, "iceCandidate", true);
			TSConverter.fillASN1Param(s, t, "u8sConnectedNumberSC", "Utf8String", errors, newContext, true);
			t.asnConnectedContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnConnectedContact"), errors, newContext, "asnConnectedContact", true);
			TSConverter.fillASN1Param(s, t, "u8sRedirectingNumberSC", "Utf8String", errors, newContext, true);
			t.asnRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnRedirectingContact"), errors, newContext, "asnRedirectingContact", true);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVCallMessageV2Argument_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVCallMessageV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVCallMessageV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageV2Argument");

		const t = {} as ENetUC_AV.AsnAVCallMessageV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVCallMessageV2Argument";
		TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "messageType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, true);
		if (s.iceCandidate) {
			const _iceCandidate = AsnAVCallMessageICEV2_Converter.toJSON(s.iceCandidate, errors, newContext, "iceCandidate");
			if (_iceCandidate)
				t.iceCandidate = _iceCandidate;
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageV2Argument");

		let t: ENetUC_AV.AsnAVCallMessageV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVCallMessageV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVCallMessageV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCallID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "endpointIDFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "messageType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUriTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sContentType", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, true);
			const _icecandidate = AsnAVCallMessageICEV2_Converter.fromJSON(s.iceCandidate, errors, newContext, "iceCandidate", true);
			if (_icecandidate)
				t.iceCandidate = _icecandidate;
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVCallMessageV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVCallMessageV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVCallMessageV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCallID", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDTo", "string", errors, newContext);
		TSConverter.validateParam(s, "endpointIDFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "messageType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUriTo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentType", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext, true);
		const _iceCandidate = AsnAVCallMessageICEV2_Converter.toBER(s.iceCandidate, errors, newContext, "iceCandidate", 2);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCallID, name: "u8sCallID" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDTo, name: "endpointIDTo" }));
			t.push(new asn1ts.Utf8String({ value: s.endpointIDFrom, name: "endpointIDFrom" }));
			t.push(new asn1ts.Integer({ value: s.messageType, name: "messageType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUriTo, name: "u8sUriTo" }));
			if (s.u8sContentType !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sContentType, name: "u8sContentType", idBlock: { optionalID: 0 } }));
			if (s.u8sBody !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody", idBlock: { optionalID: 1 } }));
			if (_iceCandidate)
				t.push(_iceCandidate);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVCallMessageV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVCallMessageV2Argument");

		let t: ENetUC_AV.AsnAVCallMessageV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVCallMessageV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVCallMessageV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCallID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "endpointIDFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "messageType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUriTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentType", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext, true);
			t.iceCandidate = AsnAVCallMessageICEV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "iceCandidate"), errors, newContext, "iceCandidate", true);
		}

		if (errors.validateResult(newContext, "AsnAVCallMessageV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnIceServerList_Converter {
	public static toJSON(s: ENetUC_AV.AsnIceServerList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIceServerList");

		const t = [] as ENetUC_AV.AsnIceServerList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnIceServer_Converter.toJSON(se, errors, newContext, "AsnIceServer");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnIceServerList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIceServerList");

		let t: ENetUC_AV.AsnIceServerList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnIceServerList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_AV.AsnIceServerList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnIceServer_Converter.fromJSON(se, errors, newContext, "AsnIceServer", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnIceServerList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnIceServerList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnIceServerList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIceServerList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnIceServer_Converter.toBER(s[id], errors, newContext, "AsnIceServer");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnIceServerList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIceServerList");

		let t: ENetUC_AV.AsnIceServerList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnIceServerList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_AV.AsnIceServerList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnIceServer_Converter.fromBER(se, errors, newContext, "AsnIceServer", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnIceServerList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAVGetIceServersResult_Converter {
	public static toJSON(s: ENetUC_AV.AsnAVGetIceServersResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnAVGetIceServersResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVGetIceServersResult");

		const t = {} as ENetUC_AV.AsnAVGetIceServersResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAVGetIceServersResult";
		const _iceServers = AsnIceServerList_Converter.toJSON(s.iceServers, errors, newContext, "iceServers");
		if (_iceServers)
			t.iceServers = _iceServers;

		if (errors.validateResult(newContext, "AsnAVGetIceServersResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVGetIceServersResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVGetIceServersResult");

		let t: ENetUC_AV.AsnAVGetIceServersResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnAVGetIceServersResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnAVGetIceServersResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _iceservers = AsnIceServerList_Converter.fromJSON(s.iceServers, errors, newContext, "iceServers", false);
			if (_iceservers)
				t.iceServers = _iceservers;
		}

		if (errors.validateResult(newContext, "AsnAVGetIceServersResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnAVGetIceServersResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAVGetIceServersResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAVGetIceServersResult");

		// [Print_BER_EncoderSeqDefCode]
		const _iceServers = AsnIceServerList_Converter.toBER(s.iceServers, errors, newContext, "iceServers");
		if (!errors.hasNewErrors()) {
			if (_iceServers)
				t.push(_iceServers);
		}

		if (errors.validateResult(newContext, "AsnAVGetIceServersResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnAVGetIceServersResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAVGetIceServersResult");

		let t: ENetUC_AV.AsnAVGetIceServersResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnAVGetIceServersResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnAVGetIceServersResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _iceservers = AsnIceServerList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "iceServers"), errors, newContext, "iceServers");
			if (_iceservers)
				t.iceServers = _iceservers;
		}

		if (errors.validateResult(newContext, "AsnAVGetIceServersResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnConfigCodecList_Converter {
	public static toJSON(s: ENetUC_AV.AsnConfigCodecList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnConfigCodecList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigCodecList");

		const t = [] as ENetUC_AV.AsnConfigCodecList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnConfigCodec_Converter.toJSON(se, errors, newContext, "AsnConfigCodec");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnConfigCodecList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigCodecList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigCodecList");

		let t: ENetUC_AV.AsnConfigCodecList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnConfigCodecList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_AV.AsnConfigCodecList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnConfigCodec_Converter.fromJSON(se, errors, newContext, "AsnConfigCodec", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnConfigCodecList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnConfigCodecList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnConfigCodecList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigCodecList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnConfigCodec_Converter.toBER(s[id], errors, newContext, "AsnConfigCodec");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnConfigCodecList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigCodecList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigCodecList");

		let t: ENetUC_AV.AsnConfigCodecList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnConfigCodecList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_AV.AsnConfigCodecList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnConfigCodec_Converter.fromBER(se, errors, newContext, "AsnConfigCodec", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnConfigCodecList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnConfigPorts_Converter {
	public static toJSON(s: ENetUC_AV.AsnConfigPorts, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_AV.AsnConfigPorts & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigPorts");

		const t = {} as ENetUC_AV.AsnConfigPorts & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnConfigPorts";
		TSConverter.fillJSONParam(s, t, "minPort", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "maxPort", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnConfigPorts"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigPorts | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigPorts");

		let t: ENetUC_AV.AsnConfigPorts | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_AV.AsnConfigPorts>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_AV.AsnConfigPorts["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "minPort", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "maxPort", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnConfigPorts"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_AV.AsnConfigPorts | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnConfigPorts";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnConfigPorts");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "minPort", "number", errors, newContext);
		TSConverter.validateParam(s, "maxPort", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.minPort, name: "minPort" }));
			t.push(new asn1ts.Integer({ value: s.maxPort, name: "maxPort" }));
		}

		if (errors.validateResult(newContext, "AsnConfigPorts"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_AV.AsnConfigPorts | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnConfigPorts");

		let t: ENetUC_AV.AsnConfigPorts | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_AV.AsnConfigPorts.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_AV.AsnConfigPorts["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "minPort", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "maxPort", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnConfigPorts"))
			return t;

		return undefined;
	}
}
