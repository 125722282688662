import { isNil } from "lodash";
import { StateCreator } from "zustand";

import { AsnNetDatabaseContact, AsnUserPropertyBag } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { IContactContainer } from "../../web-shared-components/interfaces/interfaces";
import { StoreT } from "./store";

export interface IClientContent extends AsnNetDatabaseContact {
	u8sEntryID: string;
}

export type ContactSliceT = IState & IActions;

interface IState {
	ownContactId: string | null;
	ownContact: IContactContainer | undefined;
	ownAsnUserPropertyBag: AsnUserPropertyBag | null;
	contactsPresence: Map<string, IContactContainer>;
	contactsDetails: Map<string, IClientContent>;
}

interface IActions {
	setContactsPresence: (contacts: IContactContainer[]) => void;
	setContactsDetails: (contacts: IClientContent[]) => void;
	updateContact: (contactID: string, contact: Partial<Omit<IContactContainer, "contactID">>) => void;
	removeContacts: (contactIDs: string[]) => void;
	setOwnContactId: (ownContactId: string) => void;
	setOwnContact: (ownContact: IContactContainer) => void;
	getOwnUser: () => IClientContent | null;
	setOwnUserPropertyBag: (ownAsnUserPropertyBag: AsnUserPropertyBag) => void;
	journalContacts: () => Map<string, IClientContent>;
}

export const initialState = {
	ownContactId: null,
	ownContact: undefined,
	ownAsnUserPropertyBag: null,
	contactsPresence: new Map(),
	contactsDetails: new Map()
};

export const createContactSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	ContactSliceT
> = (set, get) => ({
	...initialState,
	journalContacts: () =>
		new Map(
			[...get().journalContactIds]
				.filter((id) => get().ownContactId !== id)
				.map((id) => [id, get().contactsDetails?.get(id)])
				.filter(([, contact]) => !isNil(contact)) as [string, IClientContent][]
		),
	getOwnUser: () => {
		const { ownContactId, contactsDetails } = get();
		if (ownContactId === null) return null;

		return contactsDetails.get(ownContactId) ?? null;
	},
	setOwnContactId: (ownContactId: string) => {
		set(
			(prev): StoreT => ({
				...prev,
				ownContactId
			}),
			false,
			"setOwnContactId"
		);
	},
	setOwnContact: (ownContact: IContactContainer) => {
		set(
			(prev): StoreT => ({
				...prev,
				ownContact
			}),
			false,
			"setOwnContact"
		);
	},
	setOwnUserPropertyBag: (ownAsnUserPropertyBag: AsnUserPropertyBag) => {
		set(
			(prev): StoreT => ({
				...prev,
				ownAsnUserPropertyBag
			}),
			false,
			"setOwnUserPropertyBag"
		);
	},
	setContactsPresence: (contacts: IContactContainer[]) => {
		set(
			(prev): StoreT => ({
				...prev,
				contactsPresence: new Map<string, IContactContainer>([
					...prev.contactsPresence,
					...new Map(contacts.map((contact) => [contact.contactID, contact]))
				])
			}),
			false,
			"setContactsPresence"
		);
	},
	setContactsDetails: (clientContents: IClientContent[]) => {
		set(
			(prev): StoreT => ({
				...prev,
				contactsDetails: new Map<string, IClientContent>([
					...prev.contactsDetails,
					...new Map(clientContents.map((clientContent) => [clientContent.u8sEntryID, clientContent]))
				])
			}),
			false,
			"setContactsDetails"
		);
	},
	updateContact: (contactID: string, contact: Partial<IContactContainer>) => {
		set(
			(prev): StoreT => {
				const prevContact = prev.contactsPresence.get(contactID) || {};
				return {
					...prev,
					contactsPresence: new Map<string, IContactContainer>([
						...prev.contactsPresence,
						[contactID, { ...prevContact, ...(contact as IContactContainer) }]
					])
				};
			},
			false,
			"updateContactsPresence"
		);
	},
	removeContacts: (contactIDs: string[]) => {
		set(
			(prev): StoreT => ({
				...prev,
				contactsPresence: new Map<string, IContactContainer>(
					[...prev.contactsPresence].filter(([id]) => !contactIDs.includes(id))
				),
				contactsDetails: new Map<string, IClientContent>(
					[...prev.contactsDetails].filter(([id]) => !contactIDs.includes(id))
				)
			}),
			false,
			"removeContacts"
		);
	}
});
