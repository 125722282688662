import { t } from "i18next";
import { DateTime } from "luxon";

import { AsnNetDatabaseContact } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { IContactContainer } from "../../web-shared-components/interfaces/interfaces";
import { IAsnAppointmentEntry } from "../../web-shared-components/interfaces/serializable/IAsnAppointmentEntry";
import { getInitials } from "./commonHelper";

export interface ICombiPic {
	initials: string;
	jpegPhoto: string;
	visible: boolean;
}

/**
 *
 */
export default class ContactHelper {
	/**
	 * Gets the avatar image
	 * @param databaseContact - the asn database contact
	 * @returns - a string containing the encoded jpeg
	 */
	public static getAvatarImage = (databaseContact: AsnNetDatabaseContact): string => {
		let imageData = "";
		if (databaseContact && databaseContact.optionalParams) {
			const optionalParams = databaseContact.optionalParams;

			for (const [key, value] of Object.entries(optionalParams)) {
				if (key === "jpegPhoto") {
					for (const [innerKey, innerValue] of Object.entries(value)) {
						if (innerKey === "binarydata") {
							imageData = innerValue as string;
						}
					}
				}
			}
			return imageData;
		} else {
			return "";
		}
	};

	/**
	 * Get picture for a contact
	 * @param databaseContact - the AsnNetDatabaseContact
	 * @returns - an ICombiPic object
	 */
	public static getPic(databaseContact?: AsnNetDatabaseContact): ICombiPic {
		let pic: ICombiPic = {
			initials: "",
			jpegPhoto: "",
			visible: false
		};
		if (databaseContact) {
			const jpegPhoto = ContactHelper.getAvatarImage(databaseContact);
			pic = {
				initials: getInitials(databaseContact.u8sDisplayName || "", ""),
				jpegPhoto,
				visible: jpegPhoto !== ""
			};
		}
		return pic;
	}

	/**
	 * Helper to get the current appointment as an array of translated string
	 * @param contact - the contact
	 * @returns an array string containing the information
	 */
	public static getCurrentAppointment = (contact: IContactContainer): string[] => {
		const currentAppointments = contact.currentAppointments;
		let validAppointments: IAsnAppointmentEntry[] = [];
		const validAppointmentStrings: string[] = [];
		if (!currentAppointments) {
			return [];
		}

		for (const appointment of currentAppointments) {
			const isValid = appointment?.bIsValid ? appointment?.bIsValid : false;
			if (isValid) {
				validAppointments.push(appointment);
			} // selecting only valid appointments
		}

		if (validAppointments.length > 3) {
			validAppointments = validAppointments.slice(0, 3);
		} // choosing valid 3 appointments

		if (validAppointments && validAppointments.length > 0) {
			for (const validAppointment of validAppointments) {
				let currentAppointmentString = "";
				const stCurStart = DateTime.fromISO(validAppointment.stStart as unknown as string);
				const stCurEnd = DateTime.fromISO(validAppointment.stEnd as unknown as string);
				let startCurTime = stCurStart.toFormat("HH:mm");
				let endCurTime = stCurEnd.toFormat("HH:mm");
				const isCurStartToday = stCurStart.hasSame(DateTime.now(), "day");
				const isCurEndToday = stCurEnd.hasSame(DateTime.now(), "day");
				const isAllDay = validAppointment.bAllDay ? validAppointment.bAllDay : false;

				// allday event
				if (isAllDay) {
					const weekDay = stCurStart.weekdayLong;
					currentAppointmentString =
						weekDay + "," + " " + stCurStart.toFormat("dd/MM/yyyy") + " " + t("IDS_APPOINTMENT_ALLDAY");
					validAppointmentStrings.push(currentAppointmentString);
				} else {
					// today, and also only if start and end of the meeting is in the same day
					if (isCurStartToday && isCurEndToday) {
						currentAppointmentString = t("IDS_APPOINTMENT_DURATION", {
							starttime: startCurTime,
							endtime: endCurTime
						});
					} else {
						const startWeekDay = stCurStart.weekdayLong;
						const endWeekDay = stCurEnd.weekdayLong;
						const tomorrow = DateTime.now().plus({ hours: 24 });
						// tomorrow
						if (stCurStart <= tomorrow) {
							startCurTime = startWeekDay + "," + " " + stCurStart.toFormat("dd/MM/yyyy") + " " + startCurTime;
							endCurTime = endWeekDay + "," + " " + stCurEnd.toFormat("dd/MM/yyyy") + " " + endCurTime;
							currentAppointmentString = t("IDS_APPOINTMENT_DURATION", {
								starttime: startCurTime,
								endtime: endCurTime
							});
						} else {
							currentAppointmentString = t("IDS_APPOINTMENT_NO_NEXT24HRS");
						}
					}
					validAppointmentStrings.push(currentAppointmentString);
				}
			}
		}
		return validAppointmentStrings;
	};

	/**
	 * Helper to get the next appointment as a translated string
	 * @param contact - the contact
	 * @returns a string containing the information
	 */
	public static getNextAppointment = (contact: IContactContainer): string => {
		const nextAppointment = contact.nextAppointment;
		const isValid = nextAppointment?.bIsValid ? nextAppointment?.bIsValid : false;
		if (!isValid) {
			// invalid appointment ...
			return "";
		} // will result in empty string

		let nextAppointmentString = "";
		const stCurStart = DateTime.fromISO(nextAppointment?.stStart as unknown as string);
		const stCurEnd = DateTime.fromISO(nextAppointment?.stEnd as unknown as string);
		let startCurTime = stCurStart.toFormat("HH:mm");
		let endCurTime = stCurEnd.toFormat("HH:mm");
		const isCurStartToday = stCurStart.hasSame(DateTime.now(), "day");
		const isCurEndToday = stCurEnd.hasSame(DateTime.now(), "day");
		const isAllDay = nextAppointment?.bAllDay ? nextAppointment?.bAllDay : false;

		// allday event
		if (isAllDay) {
			const weekDay = stCurStart.weekdayLong;
			nextAppointmentString =
				weekDay + "," + " " + stCurStart.toFormat("dd/MM/yyyy") + " " + t("IDS_APPOINTMENT_ALLDAY");
		} else {
			// today, and also only if start and end of the meeting is in the same day
			if (isCurStartToday && isCurEndToday) {
				nextAppointmentString = t("IDS_APPOINTMENT_DURATION", { starttime: startCurTime, endtime: endCurTime });
			} else {
				const startWeekDay = stCurStart.weekdayLong;
				const endWeekDay = stCurEnd.weekdayLong;
				const tomorrow = DateTime.now().plus({ hours: 24 });
				// tomorrow
				if (stCurStart <= tomorrow) {
					startCurTime = startWeekDay + "," + " " + stCurStart.toFormat("dd/MM/yyyy") + " " + startCurTime;
					endCurTime = endWeekDay + "," + " " + stCurEnd.toFormat("dd/MM/yyyy") + " " + endCurTime;
					nextAppointmentString = t("IDS_APPOINTMENT_DURATION", {
						starttime: startCurTime,
						endtime: endCurTime
					});
				} else {
					if (typeof contact.currentAppointments !== "undefined" && contact.currentAppointments?.length > 0) {
						nextAppointmentString = t("IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS");
					} else {
						nextAppointmentString = t("IDS_APPOINTMENT_NO_NEXT24HRS");
					}
				}
			}
		}
		return nextAppointmentString;
	};
}
