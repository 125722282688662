/**
 * Helper for Session Storage
 * it also validates the stored data
 */
export default class SessionStorageHelper {
	/**
	 * Sets the entraId ucsid in sessionStorage
	 * @param ucsid - the ucsid
	 */
	public static setEntraIdLoginUCSID(ucsid: string): void {
		sessionStorage.setItem("entraIdLoginUCSID", ucsid);
	}

	/**
	 * Gets the entraId ucsid from sessionStorage
	 * @returns - string or undefined
	 */
	public static getEntraIdLoginUCSID(): string | undefined {
		const ucsid = sessionStorage.getItem("entraIdLoginUCSID");
		if (!ucsid) return undefined;
		return ucsid;
	}

	/**
	 * Remove the entraId ucsid
	 */
	public static removeEntraLoginUCSID(): void {
		sessionStorage.removeItem("entraIdLoginUCSID");
	}

	/**
	 * Sets the entraId UCServerURI in sessionStorage
	 * @param uri - the UCServerURI
	 */
	public static setEntraIdLoginUCServerURI(uri: string): void {
		sessionStorage.setItem("entraIdLoginUCServerURI", uri);
	}

	/**
	 * Gets the entraId UCServerURI from sessionStorage
	 * @returns - string or undefined
	 */
	public static getEntraIdLoginUCServerURI(): string | undefined {
		const uri = sessionStorage.getItem("entraIdLoginUCServerURI");
		if (!uri) return undefined;
		return uri;
	}

	/**
	 * Remove the entraId UCServerURI
	 */
	public static removeEntraLoginUCServerURI(): void {
		sessionStorage.removeItem("entraIdLoginUCServerURI");
	}

	/**
	 * Set entraid token
	 * @param token - token to set
	 */
	public static setEntraIdToken(token: string): void {
		sessionStorage.setItem("entraIdToken", token);
	}

	/**
	 * Get entraid token
	 * @returns - token or undefined
	 */
	public static getEntraIdToken(): string | undefined {
		const token = sessionStorage.getItem("entraIdToken");
		if (!token) return undefined;
		return token;
	}

	/**
	 * Remove the entra id token
	 */
	public static removeEntraIdToken(): void {
		sessionStorage.removeItem("entraIdToken");
	}

	/**
	 * Set UC token
	 * @param token - token to set
	 */
	public static setUCToken(token: string): void {
		sessionStorage.setItem("ucToken", token);
	}

	/**
	 * Get UC token
	 * @returns - token or undefined
	 */
	public static getUCToken(): string | undefined {
		const token = sessionStorage.getItem("ucToken");
		if (!token) return undefined;
		return token;
	}

	/**
	 * Remove the UC token
	 */
	public static removeUCToken(): void {
		sessionStorage.removeItem("ucToken");
	}
}
