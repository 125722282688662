import styles from "./UserPresenceIcon.module.scss";
import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "web-fortawesome/npm/pro-light";
import { ICombiPic } from "../contactDetailsComponent/models/ModelCombinedContact";
import { iPresenceStateToString } from "./UserPresenceHelper";

interface IProps {
	pict: ICombiPic | null | undefined;
	pres: number;
	size?: number;
	unknownPresence?: boolean;
	noPresenceBorder?: boolean;
	isGroup?: boolean;
}

type TPresenceIconSize = "default" | "sm" | "xs";

/**
 * The Icon showing the userImage with the presence.
 * In case it's a group it shows a generic icon.
 *
 * @param props - the props passed from the parent
 * @returns the Icon itself
 */
export default function UserPresenceIcon(props: IProps): JSX.Element {
	const textPresence = useMemo(() => {
		return iPresenceStateToString(props.pres);
	}, [props.pres]);

	/**
	 * Width of the presence icon (height is the same)
	 * in case it's not specified it's 46px
	 * it was 40px before the change to the presence icons with SVGs,
	 * but it resulted in 46 because of some further adding.
	 */
	const presenceIconWidth = useMemo(() => {
		return props.size || 46;
	}, [props.size]);

	/**
	 * For a quicker check, let's define a size (default, small, x-small)
	 * pixel thresholds are defined in Figma design
	 * Jira ticket for reference: https://jira.estos.de/browse/PROCALL-5540
	 */
	const presenceIconSize: TPresenceIconSize = useMemo(() => {
		const xs = 12;
		const s = 18;
		if (presenceIconWidth > s)
			return "default";
		else if (presenceIconWidth > xs && presenceIconWidth <= s)
			return "sm";
		else
			return "xs";
	}, [presenceIconWidth]);

	const fontSizeForInitials = useMemo(() => {
		let size = 18;
		if (props.size)
			size = props.size / 2.5;
		return size + "px";
	}, [props.size]);

	const userIsUnknown = !props.pict || (props.pict?.visible === false && (!props.pict?.initials || props.pict?.initials === ""));

	/**
	 * Check the correct SVG for the presence icon.
	 * According to the presence state (text) and the size.
	 */
	const svgBackgroundClass = useMemo(() => {
		// In some cases we do not show the presence at all, but just the user image
		// e.g.: when creating a new group chat
		if (props.noPresenceBorder)
			return "";

		switch (textPresence) {
			case "absent":
				if (presenceIconSize === "default")
					return styles.absent;
				else if (presenceIconSize === "sm")
					return styles.absentSmall;
				else
					return styles.absentXSmall;
			case "available":
				if (presenceIconSize === "default")
					return styles.available;
				else if (presenceIconSize === "sm")
					return styles.availableSmall;
				else
					return styles.availableXSmall;
			case "busy":
				if (presenceIconSize === "default")
					return styles.busy;
				else if (presenceIconSize === "sm")
					return styles.busySmall;
				else
					return styles.busyXSmall;
			case "dnd":
				if (presenceIconSize === "default")
					return styles.dnd;
				else if (presenceIconSize === "sm")
					return styles.dndSmall;
				else
					return styles.dndXSmall;
			case "idle":
				if (presenceIconSize === "default")
					return styles.idle;
				else if (presenceIconSize === "sm")
					return styles.idleSmall;
				else
					return styles.idleXSmall;
			case "offline":
				if (presenceIconSize === "default")
					return styles.offline;
				else if (presenceIconSize === "sm")
					return styles.offlineSmall;
				else
					return styles.offlineXSmall;
			case "ringing":
				if (presenceIconSize === "default")
					return styles.ringing;
				else if (presenceIconSize === "sm")
					return styles.ringingSmall;
				else
					return styles.ringingXSmall;
			case "unknown":
			default:
				if (presenceIconSize === "default")
					return styles.unknown;
				else if (presenceIconSize === "sm")
					return styles.unknown;
				else
					return styles.unknownXSmall;
		}
	}, [textPresence, presenceIconSize, props.noPresenceBorder]);

	/**
	 * The text color is needed when there's no user avatar to show the initials.
	 * It's also used as color of the internal icon for unknown users and groups without image.
	 */
	const textColor = useMemo(() => {
		switch (textPresence) {
			case "absent":
				return styles.textAbsent;
			case "available":
				return styles.textAvailable;
			case "busy":
				return styles.textBusy;
			case "idle":
				return styles.textIdle;
			case "offline":
				return styles.textOffline;
			case "ringing":
				return styles.textRinging;
			case "unknown":
			default:
				return styles.textUnknown;
		}
	}, [textPresence]);

	/**
	 * Check the correct SVG for the group icon.
	 * According to the size.
	 */
	const svgBackgroundClassForGroup = useMemo(() => {
		// In some cases we do not show the presence at all, but just the user image
		if (props.noPresenceBorder)
			return "";
		switch (presenceIconSize) {
			case "default":
				return styles.groupDefault;
			case "sm":
				return styles.groupSmall;
			case "xs":
				return styles.groupXSmall;
			default:
				return "";
		}
	}, [presenceIconSize, props.noPresenceBorder]);

	return (
		<>
			{!props.isGroup
				? <div /* SINGLE USER */
					style={{
						width: presenceIconWidth + "px",
						height: presenceIconWidth + "px"
					}}
					className={`${styles.container} ${svgBackgroundClass}`}
				>
					{/*
						The default size presence icon for the single user can have one of these contents:
						- the user avatar
						- the user initials in case there's no avatar
						- a generic icon in case the user is unknown
					*/}
					{presenceIconSize === "default"
						? <>
							{!userIsUnknown
								? <span	className={styles.content}>
									{/* user has an avatar: show it */}
									{props.pict?.visible && props.pict?.jpegPhoto
										? <img
											className={styles.image}
											src={"data:image/jpeg;base64," + props.pict.jpegPhoto}
											alt={""}
										/>
										: <span /* user doesn't have an avatar: show the initials */
											style={{ fontSize: fontSizeForInitials }}
											className={textColor}
										>
											{props.pict?.initials}
										</span>
									}
								</span>
								: <FontAwesomeIcon /* unknown user, no avatar, no initials: */
									className={`${styles.content} ${textColor}`}
									transform={"shrink-8"}
									icon={faUser}
								/>
							}
						</>
						: null // The small and xSmall size presence icons cannot have any content
					}
				</div>
				: <div /* GROUP CHAT */
					className={`${styles.container} ${svgBackgroundClassForGroup}`}
					style={{
						width: presenceIconWidth + "px",
						height: presenceIconWidth + "px"
					}}
				>
					{/*
						The default size presence icon for the group chat can have one of these contents:
						- the group chat image
						- a generic icon for the group when there's no group image
					*/}
					{presenceIconSize === "default"
						? <>
							{/* group has an image: show it */}
							{props.pict?.jpegPhoto
								? <span className={styles.content}>
									<img
										className={styles.image}
										src={"data:image/jpeg;base64," + props.pict.jpegPhoto}
										alt={""}
									/>
								</span>
								: <FontAwesomeIcon /* group doesn't have an image: show a generic icon */
									className={`${styles.content} ${textColor}`}
									transform={"shrink-8"}
									icon={faUsers}
									data-cy="upi-group-icon-container-span"
								/>
							}
						</>
						: null // The small and xSmall size presence icons cannot have any content
					}
				</div>
			}
		</>
	);
}
