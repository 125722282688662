import {
	IFinalLokiLabels,
	ILokiDataElement,
	ILokiStreamElement,
	IPartialLogMessage
} from "../../web-shared-components/helpers/logger/ILokiLogger";

/**
 * Respresentation of a Loki log entry
 */
export class LokiStream {
	private time: string;
	private stream: IFinalLokiLabels;
	private data: ILokiDataElement;

	/**
	 * Constructor
	 * @param labels - loki specific labels
	 * @param logMessage - the log messages as provided by the logger callback
	 */
	public constructor(labels: IFinalLokiLabels, logMessage: IPartialLogMessage) {
		this.time = new Date(logMessage.time).getTime().toFixed() + "000000";

		this.stream = labels;
		this.data = {
			...(logMessage.message && { message: logMessage.message }),
			...(logMessage.method && { method: logMessage.method }),
			...(logMessage.className && { className: logMessage.className }),
			...(logMessage.classProps && { classProps: logMessage.classProps }),
			...(logMessage.level && { level: logMessage.level })
		};
		if (logMessage.meta) this.data.meta = logMessage.meta;
		if (logMessage.error) this.data.error = logMessage.error;
	}

	/**
	 * Returns the loki log entry in the layout that is required to pass it to the server
	 * @returns the ready to use LokiSTreamElement for the server
	 */
	public getAsStreamElement(): ILokiStreamElement {
		try {
			return {
				stream: this.stream,
				values: [[this.time, JSON.stringify(this.data)]]
			};
		} catch (error) {
			(console as Console).error("Unable to JSON.stringify", this.data);
			debugger;
			const data = {
				circular_depencency: true,
				message: this.data.message,
				method: this.data.method,
				className: this.data.className
			};
			return {
				stream: this.stream,
				values: [[this.time, JSON.stringify(data)]]
			};
		}
	}
}
