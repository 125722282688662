import { IAsnContact } from "../serializable/IAsnContact";
import { AsnContact } from "../../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import {
	netDatabaseContactConverter
} from "./netDatabaseContactConverter";
import { absentStateConverter } from "./absentStateConverter";
import { customNoteConverter } from "./customNoteConverter";
import {
	appointmentEntryConverter,
	appointmentEntryListConverter
} from "./appointmentEntryConverter";

/**
 * the contactConverter
 * @param asnContact - an AsnContact
 * @returns an IAsnContact as promise
 */
export async function contactConverter(asnContact: AsnContact): Promise<IAsnContact> {
	let iStaticRights2;
	if (asnContact.optionalParams) {
		for (const [key, value] of Object.entries(asnContact.optionalParams)) {
			if (key === "iStaticRights2" && typeof value === "number")
				iStaticRights2 = value;
		}
	}
	return {
		u8sContactId: asnContact.u8sContactId,
		iContactFeatureSet: asnContact.iContactFeatureSet,
		iPresenceState: asnContact.iPresenceState,
		iStaticRights: asnContact.iStaticRights,
		iMonitorAccessRights: asnContact.iMonitorAccessRights,
		asnRemoteContact: await netDatabaseContactConverter(asnContact.asnRemoteContact),
		seqPhoneLines: asnContact.seqPhoneLines,
		asnAbsentState: absentStateConverter(asnContact.asnAbsentState),
		asnCustomNote: customNoteConverter(asnContact.asnCustomNote),
		seqActualAppointments: appointmentEntryListConverter(asnContact.seqActualAppointments),
		asnNextAppointment: appointmentEntryConverter(asnContact.asnNextAppointment),
		asnUserPropertyBag: asnContact.asnUserPropertyBag,
		iStaticRights2
	};
}
