import { IAsnAppointmentEntry } from "../serializable/IAsnAppointmentEntry";
import { AsnAppointmentEntry } from "../../asn1/EUCSrv/stubs/ENetUC_Common_Appointments";
import { validateAndGetISOStringFromDate } from "./helpers";

/**
 * the appointmentEntryConverter
 *
 * @param asnAppointmentEntry - the entry
 * @returns an IAsnAppointmentEntry object
 */
export function appointmentEntryConverter(asnAppointmentEntry?: AsnAppointmentEntry): IAsnAppointmentEntry | undefined {
	if (!asnAppointmentEntry)
		return undefined;
	let Location: string | undefined;
	if (asnAppointmentEntry?.optionalParams) {
		for (const [key, value] of Object.entries(asnAppointmentEntry.optionalParams)) {
			if (key === "Location" && typeof value === "string") {
				Location = value;
				break;
			}
		}
	}
	return {
		u8sEntryID: asnAppointmentEntry.u8sEntryID,
		u8sOwner: asnAppointmentEntry.u8sOwner,
		u8sDataSource: asnAppointmentEntry.u8sDataSource,
		stStart: validateAndGetISOStringFromDate(asnAppointmentEntry.stStart),
		stEnd: validateAndGetISOStringFromDate(asnAppointmentEntry.stEnd),
		iDuration: asnAppointmentEntry.iDuration,
		u8sSubject: asnAppointmentEntry.u8sSubject,
		u8sText: asnAppointmentEntry.u8sText,
		bAllDay: asnAppointmentEntry.bAllDay,
		iStatus: asnAppointmentEntry.iStatus,
		u8sCategory: asnAppointmentEntry.u8sCategory,
		iImportance: asnAppointmentEntry.iImportance,
		iSensitivity: asnAppointmentEntry.iSensitivity,
		bIsValid: asnAppointmentEntry.bIsValid,
		Location
	};
}

/**
 * the appointmentEntryListConverter
 *
 * @param asnAppointmentEntries - the entries
 * @returns an IAsnAppointmentEntry Array
 */
export function appointmentEntryListConverter(asnAppointmentEntries?: AsnAppointmentEntry[]): IAsnAppointmentEntry[] | undefined {
	if (!asnAppointmentEntries)
		return undefined;
	const convertedAppointmentList: IAsnAppointmentEntry[] = [];
	for (const appointment of asnAppointmentEntries) {
		const convertedAppointment = appointmentEntryConverter(appointment);
		if (convertedAppointment)
			convertedAppointmentList.push(convertedAppointment);
	}
	return convertedAppointmentList;
}
