import { UCCommon } from "uccommon";

import {
	ILogEntryCallback,
	ILogger,
	ILogMessage,
	IProcessLogMessage
} from "../../web-shared-components/helpers/logger/ILogger";
import {
	IFinalLokiLabels,
	ILokiConfig,
	ILokiStreamElement
} from "../../web-shared-components/helpers/logger/ILokiLogger";
import { theConfig } from "../globals";
import { getELogLevelFromString } from "./commonHelper";
import { LokiStream } from "./LokiStream";

/**
 * LokiLogger sends logs to estos Loki/Graphana monitoring
 */
export class LokiLogger implements ILogEntryCallback {
	// Instance of this class to use as singleton.
	private static instance: LokiLogger;
	// Cache for log objects (they get dispatched every 5 seconds)
	private logCache: LokiStream[] = [];
	// The time that dispatches the log entries
	private lokiPushLogInterval: NodeJS.Timeout | null = null;
	// Interface to main logger (theLogger)
	private logger: ILogger;
	// The config as handed over with a call to init
	private lokiConfig!: ILokiConfig;

	/**
	 * Singleton
	 * @param logger - interface to theLogger
	 * @returns instance of this class
	 */
	public static getInstance(logger: ILogger): LokiLogger {
		if (!LokiLogger.instance) LokiLogger.instance = new LokiLogger(logger);
		return LokiLogger.instance;
	}

	/**
	 * Private constructor because of singleton
	 * @param logger - interface to theLogger
	 */
	private constructor(logger: ILogger) {
		this.logger = logger;
	}

	/**
	 * Initialises this instance
	 * @param lokiConfig - loki configuration
	 */
	public init(lokiConfig: ILokiConfig): void {
		this.lokiConfig = lokiConfig;
		this.updateLokiPushLogInterval();
		// this.logger.addLogEntryCallback(this, filter);
	}

	/**
	 * Unititializes this instance
	 */
	public exit(): void {
		this.setEnabled(false);
	}

	/**
	 * Enables, disables the loki logger
	 * @param bEnabled - to enable, disable the loki logger
	 */
	public setEnabled(bEnabled: boolean): void {
		this.lokiConfig.bEnabled = bEnabled;
		this.updateLokiPushLogInterval();
	}

	/**
	 * Check if permissions are set and sets the timer or cancel its
	 */
	private updateLokiPushLogInterval() {
		if (this.lokiConfig.bEnabled && this.lokiConfig.host) {
			if (!this.lokiPushLogInterval) this.lokiPushLogInterval = setInterval(this.onTimer.bind(this), 5000);
		} else if (this.lokiPushLogInterval) {
			this.logCache = [];
			clearInterval(this.lokiPushLogInterval);
			this.lokiPushLogInterval = null;
		}
	}

	/**
	 * Handles the onTimer event
	 */
	private async onTimer(): Promise<void> {
		try {
			await this.flush();
		} catch (error: unknown) {
			(console as Console).error("Could not send logs to loki", error);
		}
	}

	/**
	 * Send all logs in cache to loki
	 * @returns - HTTP response from loki server
	 */
	public async flush(): Promise<void> {
		const logLength = this.logCache.length;
		if (logLength === 0) return;
		if (!this.lokiConfig.bEnabled) return;
		if (!this.lokiConfig.host) return;
		const streams: ILokiStreamElement[] = [];

		for (let i = 0; i < logLength; i++) {
			const lokiStream = this.logCache[i];
			if (!lokiStream) continue;
			streams.push(lokiStream.getAsStreamElement());
		}

		let headers: HeadersInit = {
			"Content-Type": "application/json"
		};

		if (this.lokiConfig.basicAuth)
			headers = { ...headers, ...{ Authorization: `Basic ${UCCommon.base64Encode(this.lokiConfig.basicAuth)}` } };

		const content = JSON.stringify({ streams });

		this.logCache.splice(0, logLength);

		try {
			await fetch(this.lokiConfig.host, {
				method: "POST",
				headers,
				body: content
			});
		} catch (error: unknown) {
			(console as Console).error("Could not send logs to loki", error);
		}
	}

	/**
	 * Gets notified by the logger
	 * @param logMessage - log entry to log
	 * @returns - undefined as we do want other callbacks to act on the log message
	 */
	public onLogMessage(logMessage: ILogMessage): IProcessLogMessage | undefined {
		if (theConfig.config.oem !== "estos") {
			// we don't want to send data if we are not estos OEM
			return undefined;
		}
		if (!this.lokiPushLogInterval) {
			// Logger is not initialized (no timer that will flush the logs)
			return undefined;
		}
		if (!this.lokiConfig.logAsn1 && logMessage.message === "asn1Transport") {
			// Either send asn1 logs or discard those messages
			return undefined;
		}

		if (
			logMessage.message === "result in" ||
			logMessage.message === "result out" ||
			logMessage.message === "event in" ||
			logMessage.message === "event out" ||
			logMessage.message === "invoke in" ||
			logMessage.message === "invoke out"
		)
			return undefined;

		let labels: IFinalLokiLabels = {
			...this.lokiConfig.labels,
			level: logMessage.level
		};

		if (this.lokiConfig.alternateLabels) {
			if (logMessage.lokiLabelsKey) {
				labels = {
					...labels,
					...this.lokiConfig.alternateLabels.get(logMessage.lokiLabelsKey)
				};
			}
		}

		const logLevelFromConfig = theConfig.config.logLevel;
		if (getELogLevelFromString(logMessage.level) <= getELogLevelFromString(logLevelFromConfig)) {
			const lokiLog = new LokiStream(labels, logMessage);
			this.logCache.push(lokiLog);
		}
		return undefined;
	}
}
