export interface IA11yTargetList {
	list: HTMLElement[];
	first: HTMLElement;
	last: HTMLElement;
}

export enum EA11yId {
	callMenuPREFIX,
	chatActivityBackArrow,
	chatActivityListbox,
	chatConversationListbox,
	chatHeaderToolbar,
	chatHeaderBackArrow,
	chatInfoInitPoint,
	chatMessageArea,
	chatMessageInputField,
	chatPREFIXconversation,
	chatPREFIXactivity,
	chatPREFIXEmojiSelector,
	chatPREFIXfooterButton,
	chatPREFIXheaderButton,
	chatPREFIXinfoUser,
	chatPREFIXinfoGroup,
	chatPREFIXmessageMenu,
	chatPREFIXmediaBrowser,
	chatPREFIXpreview,
	chatInitPoint,
	chatPREFIXsearch,
	communicationPREFIXsearchDial,
	communicationPREFIXcallHistory,
	contextMenuPREFIX,
	contextSubMenuPREFIX,
	loginFieldPREFIX,
	dialogNewGroupInitPoint,
	dialogPREFIXnewGroup,
	dialogPREFIXuploadGroupImage,
	headerSmToggleButton,
	informationPREFIX,
	journalPREFIXcontactCard,
	journalPREFIXtable,
	journalMenuPREFIX,
	meMenuPREFIX,
	settingsPREFIX,
	searchResultsMenuPREFIX,
	smPREFIXselectorButton,
	avCallContextMenuPREFIX,
	searchResultsDialMenuPREFIX,
    sharedButtonPREFIX
}

/**
 * Contains helper functions for a11y (accessibility)
 */
export default class A11yHelper {
	private static instance: A11yHelper | null = null;
	private a11yNamesMap: Map<EA11yId, string>;

	/**
	 * Constructs a11yHelper.
	 * Method is private as we follow the Singleton Approach using getInstance
	 */
	private constructor() {
		this.a11yNamesMap = new Map<EA11yId, string>();

		// CHAT  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.chatActivityBackArrow, EA11yId.chatPREFIXactivity + "back-arrow");
		this.a11yNamesMap.set(EA11yId.chatActivityListbox, EA11yId.chatPREFIXactivity + "listbox");
		this.a11yNamesMap.set(EA11yId.chatConversationListbox, "a11y-chat-conversation-listbox");
		this.a11yNamesMap.set(EA11yId.chatHeaderToolbar, "a11y-chat-header-toolbar");
		this.a11yNamesMap.set(EA11yId.chatHeaderBackArrow, "a11y-chat-header-back-arrow");
		this.a11yNamesMap.set(EA11yId.chatInfoInitPoint, "a11y-chat-info-initial");
		this.a11yNamesMap.set(EA11yId.chatMessageArea, "a11y-chat-message-area");
		this.a11yNamesMap.set(EA11yId.chatMessageInputField, "a11y-chat-message-input-textfield");
		this.a11yNamesMap.set(EA11yId.chatPREFIXconversation, "a11y-chat-cc-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXactivity, "a11y-chat-activity-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXfooterButton, "a11y-chat-footer-button-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXheaderButton, "a11y-chat-header-tb-button-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXinfoUser, "a11y-chat-info-user-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXinfoGroup, "a11y-chat-info-group-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXmediaBrowser, "a11y-chat-media-browser-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXpreview, "a11y-chat-preview-");
		this.a11yNamesMap.set(EA11yId.chatInitPoint, "a11y-chat-initial-searchfield");
		this.a11yNamesMap.set(EA11yId.chatPREFIXEmojiSelector, "a11y-chat-emoji-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXsearch, "a11y-chat-search-");

		// LOGIN ---------------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.loginFieldPREFIX, "a11y-login-");

		// COMMUNICATION  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.communicationPREFIXsearchDial, "a11y-comm-search-dial-");
		this.a11yNamesMap.set(EA11yId.communicationPREFIXsearchDial, "a11y-comm-call-history-");

		// DIALOGS  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.dialogNewGroupInitPoint, "a11y-dialog-new-group-headline");
		this.a11yNamesMap.set(EA11yId.dialogPREFIXnewGroup, "a11y-dialog-new-group-");
		this.a11yNamesMap.set(EA11yId.dialogPREFIXuploadGroupImage, "a11y-dialog-upload-group-image");

		// JOURNAL  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.journalPREFIXtable, "a11y-journal-table-");
		this.a11yNamesMap.set(EA11yId.journalPREFIXcontactCard, "a11y-journal-contact-card-");

		// HEADER  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.headerSmToggleButton, "a11y-header-button-toggle-sm");

		// INFORMATION  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.informationPREFIX, "a11y-information-");

		// SIDEMENU  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.smPREFIXselectorButton, "a11y-sm-button-selector-");

		// SETTINGS  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.settingsPREFIX, "a11y-settings-");

		// CONTEXT MENUS  ------------------------------------------------------------
		this.a11yNamesMap.set(EA11yId.contextMenuPREFIX, "a11y-context-menu-");
		this.a11yNamesMap.set(EA11yId.contextSubMenuPREFIX, "a11y-context-sub-menu-");

		this.a11yNamesMap.set(EA11yId.meMenuPREFIX, "a11y-me-menu-");
		this.a11yNamesMap.set(EA11yId.journalMenuPREFIX, "a11y-journal-menu-");
		this.a11yNamesMap.set(EA11yId.callMenuPREFIX, "a11y-call-menu-");
		this.a11yNamesMap.set(EA11yId.searchResultsMenuPREFIX, "a11y-search-results-menu-");
		this.a11yNamesMap.set(EA11yId.chatPREFIXmessageMenu, "a11y-chat-message-menu-");
		this.a11yNamesMap.set(EA11yId.avCallContextMenuPREFIX, "a11y-av-call-context-menu-");
		this.a11yNamesMap.set(EA11yId.searchResultsDialMenuPREFIX, "a11y-search-results-dial-menu-");
		this.a11yNamesMap.set(EA11yId.sharedButtonPREFIX, "a11y-shared-button");
	}

	/**
	 * Gets instance of a11Helper to use as singleton.
	 *
	 * @returns - an instance of this class.
	 */
	public static getInstance(): A11yHelper {
		if (!this.instance)
			this.instance = new A11yHelper();

		return this.instance;
	}

	/**
	 * move focus to another element by arrow key
	 *
	 * @param event - keyboard event to trigger focus shift
	 * @param listOfTargets - IA11yTargetList of targets with first and last target in a component with multiple elements
	 * @param vertical - set true if the direction in which the focus should move is vertical
	 */
	public moveFocusThroughTargetListByKey(
		event: React.KeyboardEvent,
		listOfTargets: IA11yTargetList,
		vertical?: true): void {
		//
		if (!event || listOfTargets.list.length < 1)
			return;

		const targetFromEvent = event.currentTarget as HTMLElement;
		const targetList = listOfTargets.list;
		const firstTarget = listOfTargets.first;
		const lastTarget = listOfTargets.last;
		let flag = false;

		const moveFocusToTab = (target: HTMLElement) => {
			target.focus();
		};

		const moveFocusToPreviousTab = (target: HTMLElement) => {
			let index;
			if (target === firstTarget)
				moveFocusToTab(lastTarget);
			else {
				index = targetList.indexOf(target);
				moveFocusToTab(targetList[index - 1]);
			}
		};

		const moveFocusToNextTab = (target: HTMLElement) => {
			let index;
			if (target === lastTarget)
				moveFocusToTab(firstTarget);
			else {
				index = targetList.indexOf(target);
				moveFocusToTab(targetList[index + 1]);
			}
		};
		switch (event.key) {
			case "ArrowUp":
				if (vertical) {
					moveFocusToPreviousTab(targetFromEvent);
					flag = true;
				}
				break;
			case "ArrowLeft":
				moveFocusToPreviousTab(targetFromEvent);
				flag = true;
				break;
			case "ArrowDown":
				if (vertical) {
					moveFocusToNextTab(targetFromEvent);
					flag = true;
				}
				break;
			case "ArrowRight":
				moveFocusToNextTab(targetFromEvent);
				flag = true;
				break;
			case "Home":
				moveFocusToTab(firstTarget);
				flag = true;
				break;
			case "End":
				moveFocusToTab(lastTarget);
				flag = true;
				break;
			default:
				break;
		}

		if (flag) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	/**
	 * move focus to another a11y element
	 *
	 * @param a11yIdToFind - a11y id of the element to which the focus should be moved
	 * @param goBackA11yIdToSet - a11y id of the start element to which the shift-tab key should be used to jump back from the new element (optional)
	 * @param a11yLabel - text for the aria label for and during movement (screenreader)
	 */
	public shiftFocusToElement = (a11yIdToFind: string, goBackA11yIdToSet?: string | null, a11yLabel?: string | null): void => {
		const goToElement = document.getElementById(a11yIdToFind);

		if (goToElement) {
			if (goBackA11yIdToSet)
				goToElement.setAttribute("data-a11y-goback", goBackA11yIdToSet);

			if (a11yLabel)
				goToElement.setAttribute("aria-label", a11yLabel);

			goToElement.focus();
		}
	};

	/**
	 * move focus back to an single a11y element
	 *
	 * @param a11yIdToFind - a11y id of the element to which the focus should be moved
	 */
	public shiftFocusBackToElement = (a11yIdToFind: string): void => {
		const a11yElement = document.getElementById(a11yIdToFind);
		const dataA11yGoBackId = a11yElement?.getAttribute("data-a11y-goback");

		if (dataA11yGoBackId) {
			const goBackToElement = document.getElementById(dataA11yGoBackId);
			if (goBackToElement) {
				goBackToElement.setAttribute("tabindex", "1");
				goBackToElement.focus();
				goBackToElement.setAttribute("tabindex", "0");
			}
		}
	};

	/**
	 * Function to organize frequently used a11y jump points. Returns an id (= string).
	 *
	 * @param a11yJumpPoint - name of the jump point
	 * @returns the id of the element
	 */
	public getId(a11yJumpPoint: EA11yId): string {
		return this.a11yNamesMap.get(a11yJumpPoint) || "";
	}
}
