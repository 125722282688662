import React, { useEffect, useRef, useState } from "react";
import styles from "./PresenceIconWithTooltip.module.scss";
import { theSharedBrowser } from "../../helpers/helpers";
import { IContactContainer } from "../../interfaces/interfaces";
import { IConfigCD } from "../../interfaces/interfacesConfig";
import { TimeoutOrNullT } from "../../interfaces/types";
import { ICombiPic } from "../contactDetailsComponent/models/ModelCombinedContact";
import { ICustomerLabels } from "../contactDetailsComponent/models/ModelContentData";
import { PresenceTooltip } from "../presenceTooltip/PresenceTooltip";
import UserPresenceIcon from "../userPresenceIcon/UserPresenceIcon";
import { Box } from "@mui/material";
import { IAsnAppointmentEntry } from "../../interfaces/serializable/IAsnAppointmentEntry";

export interface IPresenceIconprops {
    contact: IContactContainer | undefined;
    customLabels: ICustomerLabels;
    contactDetails: IConfigCD;
    combiPic: ICombiPic | undefined;
    currentAppointmentTime: string[];
    nextAppointmentTime: string;
    size?: number;
    presenceTooltipDelay?: number;
    pttShouldBeClosed?: boolean;
    triggerPTT?: boolean;
    mouseHover: () => void;
}

export const PresenceIconWithTooltip = (props: IPresenceIconprops): React.ReactElement => {
	const [showPresenceTooltipMenu, setShowPresenceTooltipMenu] = useState<boolean>(false);
	const [cursorOverPTT, setCursorOverPTT] = useState<boolean>(false);

	const showTooltipTimeout = useRef<TimeoutOrNullT>(null);
	const leaveUPITimeout = useRef<TimeoutOrNullT>(null);
	const findPTT = useRef<TimeoutOrNullT>(null);
	const anchorEl = useRef<HTMLElement | null>(null);

	const presenceTooltipDelay: number = props.presenceTooltipDelay ?? 300;

	useEffect(() => {
		return () => {
			if (leaveUPITimeout.current !== null)
				clearTimeout(leaveUPITimeout.current);

			if (showTooltipTimeout.current !== null)
				clearTimeout(showTooltipTimeout.current);

			if (findPTT.current !== null)
				clearTimeout(findPTT.current);
		};
	}, []);

	useEffect(() => {
		if (props.pttShouldBeClosed) {
			setCursorOverPTT(false);
			setShowPresenceTooltipMenu(false);
		}
	}, [props.pttShouldBeClosed]);

	useEffect(() => {
		if (props.triggerPTT === true)
			setShowPresenceTooltipMenu(true);
	}, [props.triggerPTT]);

	const setBounds = (): void => {
		findPTT.current = setTimeout(() => {
			const pttMuiElement: HTMLElement | null = document.getElementById("basic-button");
			if (pttMuiElement) {
				pttMuiElement.style.pointerEvents = "all";
				pttMuiElement.onmouseenter = () => {
					setCursorOverPTT(true);
				};
				pttMuiElement.onmouseleave = () => {
					setCursorOverPTT(false);
					pttMuiElement.onmouseenter = null;
					pttMuiElement.onmouseleave = null;
					pttMuiElement.style.pointerEvents = "none";
				};
			}
			if (findPTT.current !== null)
				clearTimeout(findPTT.current);
		}, 10);
	};

	const appointmentEntry: IAsnAppointmentEntry = {
		u8sSubject: props.contact?.nextAppointment?.u8sSubject || "",
		stStart: props.contact?.nextAppointment?.stStart ?? "",
		stEnd: props.contact?.nextAppointment?.stEnd ?? "",
		bAllDay: props.contact?.nextAppointment?.bAllDay ?? false,
		bIsValid: props.contact?.nextAppointment?.bIsValid ?? false,
		iDuration: 0,
		iImportance: 0,
		iSensitivity: 0,
		iStatus: 0,
		u8sCategory: "",
		u8sDataSource: "",
		u8sEntryID: "",
		u8sOwner: "",
		u8sText: ""
	};

	const tooltipContact: IContactContainer = {
		reachability: props.contact?.reachability,
		presence: props.contact?.presence,
		asnNetDatabaseContact: props.contact?.asnNetDatabaseContact,
		contactID: props.contact?.asnNetDatabaseContact?.u8sSIPAddress || "",
		nextAppointment: appointmentEntry,
		currentAppointments: props.contact?.currentAppointments,
		customNote: props.contact?.customNote,
		absentState: props.contact?.absentState,
		agentState: props.contact?.agentState,
		isMobileAvailable: props.contact?.isMobileAvailable,
		msTeamsEmail: props.contact?.msTeamsEmail,
		clientCapabilities: props.contact?.clientCapabilities,
		seqPhoneLines: props.contact?.seqPhoneLines,
		seqLineForwards: props.contact?.seqLineForwards,
		seqCalls: props.contact?.seqCalls
	};

	const mouseOverUserPresence = (): void => {
		props.mouseHover();
		if (tooltipContact.contactID === "")
			return;

		if (showTooltipTimeout.current !== null)
			clearTimeout(showTooltipTimeout.current);

		showTooltipTimeout.current = setTimeout(() => {
			if (!props.pttShouldBeClosed) {
				setShowPresenceTooltipMenu(true);
				setBounds();
			}
		}, presenceTooltipDelay);
	};

	const mouseLeaveUserPresence = (): void => {
		if (showTooltipTimeout.current !== null)
			clearTimeout(showTooltipTimeout.current);

		leaveUPITimeout.current = setTimeout(() => {
			setShowPresenceTooltipMenu(false);
			if (leaveUPITimeout.current !== null)
				clearTimeout(leaveUPITimeout.current);
		}, 100);
	};

	const closePTT = (): void => {
		setCursorOverPTT(false);
		setShowPresenceTooltipMenu(false);
	};

	return (
		<>
			<Box
				onClick={(e) => {
					if (theSharedBrowser.is.touchScreen) {
						if (theSharedBrowser.is.touchScreen) {
							e.preventDefault();
							e.stopPropagation();
							setShowPresenceTooltipMenu(!showPresenceTooltipMenu);
						}
					}
				}}
				onMouseOver={() => { mouseOverUserPresence(); }}
				onMouseLeave={() => { mouseLeaveUserPresence(); }}
			>
				<UserPresenceIcon
					pict={props.combiPic}
					pres={props.contact?.presence || 0}
					unknownPresence={props.contact?.presence === undefined}
					size={props.size}
				/>
			</Box>

			{showPresenceTooltipMenu || cursorOverPTT
				? <PresenceTooltip
					ref={anchorEl}
					contact={tooltipContact}
					customLabels={props.customLabels.CustLabels}
					configCD={props.contactDetails.ContactDetails}
					nextAppointment={props.nextAppointmentTime}
					currentAppointment={props.currentAppointmentTime}
					close={() => { closePTT(); }}
				/>
				: null
			}
			<span className={styles.anchorForPresenceTooltip} data-id="pptRef" ref={anchorEl} />
		</>
	);
};
