// [PrintTSCodeOne]
// [PrintTSComments]
/*
 * ENetUC_Agent.ts
 * "UC-Server-Access-Protocol-Agent" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Agent\/AgentGroup\/ACD interface.
 * ## Module description
 * This module describes the functions for agents for ACD and contact portal functionalities.
 *
 * A client represents a user who can be an member in any number of agent groups.&lt;br \/&gt;
 * An agent is described by the ContactURI of the user (SIP address incl. \"sip:\" before) or a LineURI (Cti line, format \"tel:xyz\@pbx text\").
 * To login to a group, for example, you usually need the AgencyURI and the GroupURI. This allows the UCServer to identify exactly which agent should be used in which group.&lt;br \/&gt;
 * The client receives the necessary values when it gets them back as a result of asnAgentMonitorStart.
 *
 * ### Requirements for using the interface
 * A user gets access to the agent interface:
 * - If the user has activated the service \"Business card for anonymous users\" via his own or global profile in the UCServer administration.
 * - If he is a member of one or more groups that have been activated in the UCServer administration as an \"agent group\".
 * - If (identified by line ID) he has a line known to be part of an ACD group at the corresponding ECSTA.
 *
 * ### Functions
 * A user can set the following status on the interface:
 * - \"login\"\/\"logout\"
 * - \"Working after call\"
 * - \"ready\"\/\"not ready\" (still a \"work in progress\")
 *
 * ProCall 5.1 supports ACD functionality only for Panasonic telephone systems.&lt;br \/&gt;
 * As of ProCall 6, the agent interface is used for the contact portal and business card. Further PBX's are in development.
 *
 * ## Simple procedure if the agent groups are to be used ##
 * 1. The user must only call asnAgentMonitorStart; this registers the agent's events and supplies the list of groups in which the user is agent.
 * 2. From this point on, the UCServer sends events if the status of the user in these groups changes.
 * 3. Change the state of the agent:
 * 3.1. asnAgentLogin - Sets the status of the user to \"login\". Note: replaces asnAgentSetState(not ready).
 * More parameters may be required for the login. These can be found in the AgentGroupInfos
 * 3.2. asnAgentLogoff - Sets the status of the user to \"logoff\". Note: replaces asAgentSetState(noagent).
 * 3.3. asnAgentSetState - With this you can set the status to \"ready\", \"not ready\" and \"working after call\". Which states can be set is provided by the server in the result of asnAgentMonitorStart. See also AsnAgentGroupInfo.
 * 4. Log off events with asnAgentMonitorStop.
 *
 * With these five functions it is possible to operate the ACD\/presence groups on the UCServer.
 *
 * ## AsnAgentGroupInfo
 * AsnAgentGroupInfo structures are usually exchanged in the analysis and results. This structure contains the description of an agent\/ACD group.&lt;br \/&gt;
 * AsnAgentGroupInfo contains the data about an agent group. The following are important:
 * - iGroupCapabilities: This shows which status changes are supported. See also AsnAgentGroupCapabilitiesEnum. If the highest bit is set (0x80000000), this group is a PBX\/ACD group. If the bit is not set, the group is managed by the UCServer.
 * - iAgentState: The current status of the agent, i.e. the user in this group.
 * - optionalParams,key \"AgentLoginRequiredParameters\": In the case of a PBX\/ACD group, this integer contains information about which parameters are required for logging on to the ACD group. The values (bit field) are described in AsnAgentLoginRequiredParametersEnum.
 *
 *
 * ## Events
 * For any changes to the agent groups and agent status, two events are sent by the UCServer to the registered clients:
 * - asnAgentNotifyGroupsChanged - There have been some changes in the user's available groups
 * - asnAgentNotifyStateChanged - The user's status in a group has changed.
 *
 * ## Comment
 * The API is user centered. This means if the server is asked for the AgentGroupInfos,
 * the server displays the information concerning its group membership in a simple list.
 * The most simple for the normal user.
 *
 * ## Supervisor Interface
 * Not implemented yet.
 *
 * ## Anonymous users
 * Anonymous users can query which groups are available, including users, and then display the corresponding presence and login\/logoff status based on the use case.
 *
 * @private
 */
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_ServicesAgents from "./ENetUC_ServicesAgents";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_Agent";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAgentLoginRequiredParametersEnum {
	/** AgentID Required (for example, the PIN) */
	eAGENTLOGINREQUIRESAGENTID = 1,
	/** GroupID Required */
	eAGENTLOGINREQUIRESGROUPID = 2,
	/** Password Required */
	eAGENTLOGINREQUIRESPASSWORD = 4,
	/** DeviceID Required */
	eAGENTLOGINREQUIRESDEVICEID = 8
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnSupportedAgentStatesEnum {
	/** \"logout\" \/ \"no agent\" */
	eAGENTSTATELOGGEDOUT = 1,
	/** \"not ready\" */
	eAGENTSTATELOGGEDINNOTREADY = 2,
	/** \"ready\" */
	eAGENTSTATELOGGEDINREADY = 4,
	/** \"busy\" */
	eAGENTSTATELOGGEDINBUSY = 8,
	/** \"working after call\" */
	eAGENTSTATELOGGEDINWORKINGAFTERCALL = 16
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAgentStateEnum {
	/** Agent logged out from group - (is set implicitly at AgentLogoff() by the server) */
	eAGENTSTATENOAGENT = 0,
	/** Agent unavailable */
	eAGENTSTATENOTREADY = 1,
	/** Agent available in group - (is initially set at AgentLogin() by the server) */
	eAGENTSTATEREADY = 2,
	/** Agent is busy */
	eAGENTSTATEBUSY = 3,
	/** Agent is working after a call */
	eAGENTSTATEWORKINGAFTERCALL = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAgentGroupCapabilitiesEnum {
	/** (0x01) \"login\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSLOGIN = 1,
	/** (0x02) \"logout\" is supported \/ same as state eAGENTSTATENOAGENT */
	eAGENTCAPSUPPORTEDAGENTREQUESTSLOGOUT = 2,
	/** (0x04) \"not ready\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSNOTREADY = 4,
	/** (0x08) \"ready\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSREADY = 8,
	/** (0x10) \"busy\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSBUSY = 16,
	/** (0x20) \"working after call\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSWORKINGAFTERCALL = 32,
	/** (0xff) (currently not supported) */
	eAGENTCAPSUPPORTEDAGENTREQUESTSMASK = 255,
	/** (0x20000000) The group is a (Contact)Portal Group */
	eAGENTCAPISPORTALGROUP = 536870912,
	/**
	 * (0x40000000) The agents in this group can handle PUBLIC-CHATs
	 * See AsnConversationType.
	 */
	eAGENTCAPISPUBLICCHATGROUP = 1073741824
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAgentBroadcastTypeEnum {
	general = 1,
	requestoverdue = 2
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * AsnAgentGroupInfo encapsulates the data for an agent (ACD) group from the agent's point of view.
 * An AsnAgentGroupInfo can only be uniquely identified with the combination of u8sAgentURI and u8sGroupURI.
 *
 * ## OptionalParameters - Subsequent Enhancements to the data structure ##
 * - \"PhoneNumber\" String phone number to be displayed in the client; only server to client.
 * - \"AgentLoginRequiredParameters\" DWORD bit flags that indicate which data the PBX requires for the user\/line logon. Only sent server to client. Not filled for contact portal\/business card. See AsnAgentLoginRequiredParametersEnum.
 * - \"SupportedAgentStates\" DWORD bit flags indicating which agents status values can be used in this group. Only sent server to client. See also AsnSupportedAgentStatesEnum.
 *
 * @private
 */
export class AsnAgentGroupInfo {
	public constructor(that: AsnAgentGroupInfo) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentGroupInfo {
		return new AsnAgentGroupInfo({
			u8sAgentURI: "",
			u8sGroupURI: "",
			u8sDisplayName: "",
			iGroupCapabilities: 0,
			iAgentState: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAgentURI",
			"u8sGroupURI",
			"u8sDisplayName",
			"iGroupCapabilities",
			"iAgentState"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnAgentGroupInfo";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentGroupInfo",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupURI" }),
				new asn1ts.Utf8String({ name: "u8sDisplayName" }),
				new asn1ts.Integer({ name: "iGroupCapabilities" }),
				new asn1ts.Integer({ name: "iAgentState" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** URI of the group agent (LineURI or ContactURI) e.g. \"tel:+4912345678;phys=678\@ECSTA for OpenScape Business\" or \"sip:test.tester\@test.de\" */
	public u8sAgentURI!: string;
	/** URI of the group (e.g. \"pbxg:601\@ECSTA for Panasonic\" or \"sip:mygroup\@estos.de\"). */
	public u8sGroupURI!: string;
	/** Display name of the group. */
	public u8sDisplayName!: string;
	/**
	 * This shows which status changes are supported. See also AsnAgentGroupCapabilitiesEnum.
	 * If the highest bit is set (0x80000000), this group is a PBX\/ACD group.
	 * If the bit is not set, the group is managed by the UCServer.
	 * See AsnAgentGroupCapabilitiesEnum.
	 */
	public iGroupCapabilities!: number;
	/**
	 * Agent status of the agent within the group as defined in AsnAgentStateEnum.
	 * See AsnAgentStateEnum.
	 */
	public iAgentState!: number;
	/**
	 * OptionalParameters - Subsequent extensions to the data structure
	 * - \"PhoneNumber\" String phone number for display in client; only server to client.
	 * - \"AgentLoginRequiredParameters\" DWORD bit flags that indicate which data the PBX requires for the user\/line logon. Only sent server to client. Not filled for contact portal\/business card. See AsnAgentLoginRequiredParametersEnum.
	 * - \"SupportedAgentStates\" DWORD bit flags indicating which agents status values can be used in this group. Only sent server to client. See AsnSupportedAgentStatesEnum.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentMonitorStartArgument {
	public constructor(that: AsnAgentMonitorStartArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentMonitorStartArgument {
		return new AsnAgentMonitorStartArgument({
			u8sAgentURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAgentURI"
		];
		return p;
	}

	public static type = "AsnAgentMonitorStartArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentMonitorStartArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Agent URI e.g. \"sip:xyz\@domain.de\". An empty string is supplemented by the UCServer with the ID of the logged on user. */
	public u8sAgentURI!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The CrossRefID that was returned by the server at asnAgentMonitorStart.
 *
 * @private
 */
export class AsnAgentMonitorStopArgument {
	public constructor(that: AsnAgentMonitorStopArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentMonitorStopArgument {
		return new AsnAgentMonitorStopArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID"
		];
		return p;
	}

	public static type = "AsnAgentMonitorStopArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentMonitorStopArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sMonitorCrossRefID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This structure is only restored if successful. If the call is unsuccessful, it will return an AsnRequestError.
 *
 * @private
 */
export class AsnAgentMonitorStopResult {
	public constructor(that?: AsnAgentMonitorStopResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentMonitorStopResult {
		return new AsnAgentMonitorStopResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentMonitorStopResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentMonitorStopResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Parameters for Agent Login
 * Parameters for logging in to the agent\/ACD functionality of the PC server (business card, contact portal) or the telephone system.
 *
 * @private
 */
export class AsnAgentLoginArgument {
	public constructor(that: AsnAgentLoginArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentLoginArgument {
		return new AsnAgentLoginArgument({
			u8sAgentURI: "",
			u8sGroupURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAgentURI",
			"u8sGroupURI"
		];
		if (bIncludeOptionals)
			p.push("acdLoginData");
		return p;
	}

	public static type = "AsnAgentLoginArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentLoginArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupURI" }),
				ENetUC_ServicesAgents.AsnAgentACDLoginData.getASN1Schema({ name: "acdLoginData", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/**
	 * Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\".
	 * If the URI starts with \"tel:\", the login is transferred to the specified line and then to the telephone system.
	 * \"sip:\" indicates that the user is logging in for the contact portal\/business card.
	 * The information can be obtained from the AsnGroupInfo
	 */
	public u8sAgentURI!: string;
	/**
	 * Group URI (e.g. \"pbxg:601\@ECSTA for Panasonic\" or \"sip:mygroup\@estos.de\").
	 * This identifies the group to which the user is to be logged in to.
	 * The information can be obtained from the AsnGroupInfo.
	 */
	public u8sGroupURI!: string;
	/** For PBX ACD, additional data may be required for login. See AsnAgentACDLoginData for details. */
	public acdLoginData?: ENetUC_ServicesAgents.AsnAgentACDLoginData;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result for agent login
 * If the server returns the result, the login was successful.
 * If the login fails, the AsnRequestError is returned as the result.
 * This contains more details about the error.&lt;br \/&gt;
 * This structure is only returned if successful. If the call is unsuccessful, AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentLoginResult {
	public constructor(that?: AsnAgentLoginResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentLoginResult {
		return new AsnAgentLoginResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentLoginResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentLoginResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for agent logoff(Automatic Call Distribution)
 *
 * @private
 */
export class AsnAgentLogoffArgument {
	public constructor(that: AsnAgentLogoffArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentLogoffArgument {
		return new AsnAgentLogoffArgument({
			u8sAgentURI: "",
			u8sGroupURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAgentURI",
			"u8sGroupURI"
		];
		return p;
	}

	public static type = "AsnAgentLogoffArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentLogoffArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupURI" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\" */
	public u8sAgentURI!: string;
	/** Group URI (e.g. \"pbxg:601\@ECSTA for Panasonic\" or \"sip:mygroup\@estos.de\"). */
	public u8sGroupURI!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result for agent logoff
 * This structure is only returned if successful. If the call is unsuccessful, an AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentLogoffResult {
	public constructor(that?: AsnAgentLogoffResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentLogoffResult {
		return new AsnAgentLogoffResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentLogoffResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentLogoffResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Set new Agent State
 *
 * @private
 */
export class AsnAgentSetStateArgument {
	public constructor(that: AsnAgentSetStateArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSetStateArgument {
		return new AsnAgentSetStateArgument({
			u8sAgentURI: "",
			iAgentState: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAgentURI",
			"iAgentState"
		];
		if (bIncludeOptionals)
			p.push("u8sGroupURI");
		return p;
	}

	public static type = "AsnAgentSetStateArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSetStateArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupURI", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iAgentState" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\" */
	public u8sAgentURI!: string;
	/**
	 * Group URI (e.g. \"pbxg:601\@ECSTA for Panasonic\" or \"sip:mygroup\@estos.de\").
	 * The information can be obtained from the AsnGroupInfo.
	 */
	public u8sGroupURI?: string;
	/** Agent status of the agent to be set as defined in AsnAgentStateEnum. */
	public iAgentState!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result for AsnAgentSetState
 * This structure is only returned if successful. If the call is unsuccessful, an AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentSetStateResult {
	public constructor(that?: AsnAgentSetStateResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSetStateResult {
		return new AsnAgentSetStateResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentSetStateResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSetStateResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentSupervisorMonitorStartArgument {
	public constructor(that?: AsnAgentSupervisorMonitorStartArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSupervisorMonitorStartArgument {
		return new AsnAgentSupervisorMonitorStartArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentSupervisorMonitorStartArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSupervisorMonitorStartArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentSupervisorMonitorStopArgument {
	public constructor(that: AsnAgentSupervisorMonitorStopArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSupervisorMonitorStopArgument {
		return new AsnAgentSupervisorMonitorStopArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID"
		];
		return p;
	}

	public static type = "AsnAgentSupervisorMonitorStopArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSupervisorMonitorStopArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentSupervisorMonitorStopResult {
	public constructor(that?: AsnAgentSupervisorMonitorStopResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSupervisorMonitorStopResult {
		return new AsnAgentSupervisorMonitorStopResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentSupervisorMonitorStopResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSupervisorMonitorStopResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Defines a broadcast element
 * Broadcasts are used to notify or exchange user data between agent clients.
 * A broadcast can be sent to the members of different agent groups.
 * As soon as an agent confirms this broadcast, it is considered completed for the system (UCServer).
 * The broadcast functionality is only available for agents of the PC server (business card, contact portal) - not for PBX\/ACD\/telephone systems.
 * All broadcast information is temporary! When the server is restarted, all broadcast elements that are still open are discarded.
 *
 * @private
 */
export class AsnAgentBroadcastItem {
	public constructor(that: AsnAgentBroadcastItem) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastItem {
		return new AsnAgentBroadcastItem({
			u8sBroadcastID: "",
			listu8sGroupURIs: new ENetUC_Common.UTF8StringList(),
			iBroadcastType: 0,
			data: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sBroadcastID",
			"listu8sGroupURIs",
			"iBroadcastType",
			"data"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastItem";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastItem",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sBroadcastID" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sGroupURIs" }),
				new asn1ts.Integer({ name: "iBroadcastType" }),
				new asn1ts.Utf8String({ name: "data" }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sBroadcastID!: string;
	/** Broadcast item recipient list */
	public listu8sGroupURIs!: ENetUC_Common.UTF8StringList;
	/** Broadcast type, see AsnAgentBroadcastTypeEnum. */
	public iBroadcastType!: number;
	/** User data (not interpreted by the server) */
	public data!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastGetItemsForGroupsArgument {
	public constructor(that: AsnAgentBroadcastGetItemsForGroupsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastGetItemsForGroupsArgument {
		return new AsnAgentBroadcastGetItemsForGroupsArgument({
			listu8sGroupURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listu8sGroupURIs"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastGetItemsForGroupsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastGetItemsForGroupsArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sGroupURIs" }),
				new asn1ts.Extension()
			]
		});
	}

	public listu8sGroupURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastCreateArgument {
	public constructor(that: AsnAgentBroadcastCreateArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastCreateArgument {
		return new AsnAgentBroadcastCreateArgument({
			listu8sGroupIDs: new ENetUC_Common.UTF8StringList(),
			iBroadcastType: 0,
			data: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listu8sGroupIDs",
			"iBroadcastType",
			"data"
		];
		if (bIncludeOptionals)
			p.push("iTTL");
		return p;
	}

	public static type = "AsnAgentBroadcastCreateArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastCreateArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sGroupIDs" }),
				new asn1ts.Integer({ name: "iBroadcastType" }),
				new asn1ts.Utf8String({ name: "data" }),
				new asn1ts.Integer({ name: "iTTL", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Recipient list (do not have to be URIs, because the anonymous agent does not necessarily know them - it also works with the name of the group) */
	public listu8sGroupIDs!: ENetUC_Common.UTF8StringList;
	/** Broadcast type, see AsnAgentBroadcastTypeEnum. */
	public iBroadcastType!: number;
	/** User data (not evaluated by the server, only passed on to the client) */
	public data!: string;
	/**
	 * Time To Live (Seconds)
	 * - 0: dispatch only to currently logged on agents (default)
	 * - otherwise: number of seconds that the broadcast remains available on the server. This allows agents to inquire afterwards whether broadcasts are still available. Maximum value here is 7 days (= 604800sec)
	 * Specifying \"-1\" means that the broadcast will be maintained until the server shuts down or the broadcast has been confirmed
	 */
	public iTTL?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastCreateResult {
	public constructor(that: AsnAgentBroadcastCreateResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastCreateResult {
		return new AsnAgentBroadcastCreateResult({
			u8sBroadcastID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sBroadcastID"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastCreateResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastCreateResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sBroadcastID" }),
				new asn1ts.Extension()
			]
		});
	}

	/**
	 * Unique ID of the broadcast generated by the server
	 * Any agent client can confirm this broadcast,
	 * by sending an AsnAgentBroadcastConfirmArgument message to the server itself.
	 */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastConfirmArgument {
	public constructor(that: AsnAgentBroadcastConfirmArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastConfirmArgument {
		return new AsnAgentBroadcastConfirmArgument({
			u8sBroadcastID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sBroadcastID"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastConfirmArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastConfirmArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sBroadcastID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** The BroadcastID generated by the server. The server ignores duplicate confirmations! See AsnAgentBroadcastCreateArgument. */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastConfirmResult {
	public constructor(that?: AsnAgentBroadcastConfirmResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastConfirmResult {
		return new AsnAgentBroadcastConfirmResult();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentBroadcastConfirmResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastConfirmResult",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastCheckArgument {
	public constructor(that: AsnAgentBroadcastCheckArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastCheckArgument {
		return new AsnAgentBroadcastCheckArgument({
			u8sBroadcastID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sBroadcastID"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastCheckArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastCheckArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sBroadcastID" }),
				new asn1ts.Extension()
			]
		});
	}

	/** The BroadcastID generated by the server. See AsnAgentBroadcastCreateArgument. */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastCheckResult {
	public constructor(that: AsnAgentBroadcastCheckResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastCheckResult {
		return new AsnAgentBroadcastCheckResult({
			bExists: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bExists"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastCheckResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastCheckResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bExists" }),
				new asn1ts.Extension()
			]
		});
	}

	public bExists!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for AgentNotifyStateChanged. Included is the CrossRefID for which the event is transmitted, the AgencyURI and GroupURI (unique agent group assignment) and the new agent state.
 *
 * @private
 */
export class AsnAgentNotifyStateChangedArgument {
	public constructor(that: AsnAgentNotifyStateChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentNotifyStateChangedArgument {
		return new AsnAgentNotifyStateChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sAgentURI: "",
			u8sGroupURI: "",
			iAgentState: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID",
			"u8sAgentURI",
			"u8sGroupURI",
			"iAgentState"
		];
		return p;
	}

	public static type = "AsnAgentNotifyStateChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentNotifyStateChangedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupURI" }),
				new asn1ts.Integer({ name: "iAgentState" }),
				new asn1ts.Extension()
			]
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
	/** Agent group URI (LineURI or ContactURI) */
	public u8sAgentURI!: string;
	/** Group URI- must be unique in the UCServer */
	public u8sGroupURI!: string;
	/** AgentState of the agent in the group */
	public iAgentState!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBlockedRTCGetUsersArgument {
	public constructor(that?: AsnAgentBlockedRTCGetUsersArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBlockedRTCGetUsersArgument {
		return new AsnAgentBlockedRTCGetUsersArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentBlockedRTCGetUsersArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBlockedRTCGetUsersArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBlockedRTCGetUsersResult {
	public constructor(that: AsnAgentBlockedRTCGetUsersResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBlockedRTCGetUsersResult {
		return new AsnAgentBlockedRTCGetUsersResult({
			listu8sBlockedUserURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listu8sBlockedUserURIs"
		];
		return p;
	}

	public static type = "AsnAgentBlockedRTCGetUsersResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBlockedRTCGetUsersResult",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sBlockedUserURIs" }),
				new asn1ts.Extension()
			]
		});
	}

	/** list of all blocked users */
	public listu8sBlockedUserURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for AgentNotifyAnonymousBlockedChanged.
 *
 * @private
 */
export class AsnAgentNotifyBlockedRTCChangedArgument {
	public constructor(that: AsnAgentNotifyBlockedRTCChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentNotifyBlockedRTCChangedArgument {
		return new AsnAgentNotifyBlockedRTCChangedArgument({
			listu8sBlockedUserURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listu8sBlockedUserURIs"
		];
		return p;
	}

	public static type = "AsnAgentNotifyBlockedRTCChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentNotifyBlockedRTCChangedArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sBlockedUserURIs" }),
				new asn1ts.Extension()
			]
		});
	}

	/** list of all blocked users */
	public listu8sBlockedUserURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousBlockRTCArgument {
	public constructor(that: AsnAgentAnonymousBlockRTCArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousBlockRTCArgument {
		return new AsnAgentAnonymousBlockRTCArgument({
			u8sAnonymousUserURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI"
		];
		if (bIncludeOptionals) {
			p.push(
				"listu8sAffectedURIs",
				"iExpireTime"
			);
		}
		return p;
	}

	public static type = "AsnAgentAnonymousBlockRTCArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousBlockRTCArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sAffectedURIs", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iExpireTime", idBlock: { optionalID: 1 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** anonymous user URI - which has to be blocked */
	public u8sAnonymousUserURI!: string;
	/**
	 * (optional) List of internal users who are affected by blocking
	 * If not provided (or empty list), blocking will be done for ALL agent users
	 * list can contain agent-sipURI or groupID\/groupURI in mixed mode
	 */
	public listu8sAffectedURIs?: ENetUC_Common.UTF8StringList;
	/** Time To Block (in hours - default: 24) */
	public iExpireTime?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousBlockRTCResult {
	public constructor(that: AsnAgentAnonymousBlockRTCResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousBlockRTCResult {
		return new AsnAgentAnonymousBlockRTCResult({
			u8sAnonymousUserURI: "",
			bBlockedRTC: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI",
			"bBlockedRTC"
		];
		return p;
	}

	public static type = "AsnAgentAnonymousBlockRTCResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousBlockRTCResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				new asn1ts.Boolean({ name: "bBlockedRTC" }),
				new asn1ts.Extension()
			]
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousUnblockRTCArgument {
	public constructor(that: AsnAgentAnonymousUnblockRTCArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousUnblockRTCArgument {
		return new AsnAgentAnonymousUnblockRTCArgument({
			u8sAnonymousUserURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI"
		];
		if (bIncludeOptionals)
			p.push("listu8sAffectedURIs");
		return p;
	}

	public static type = "AsnAgentAnonymousUnblockRTCArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousUnblockRTCArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listu8sAffectedURIs", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** anonymous user URI - which will be unblocked again */
	public u8sAnonymousUserURI!: string;
	/**
	 * (optional) List of internal users who are affected by unblocking
	 * If not provided (or empty list), unblocking will be for ALL formerly affected users
	 * list can contain agent-sipURI or groupID\/groupURI in mixed mode
	 */
	public listu8sAffectedURIs?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousUnblockRTCResult {
	public constructor(that: AsnAgentAnonymousUnblockRTCResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousUnblockRTCResult {
		return new AsnAgentAnonymousUnblockRTCResult({
			u8sAnonymousUserURI: "",
			bBlockedRTC: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI",
			"bBlockedRTC"
		];
		return p;
	}

	public static type = "AsnAgentAnonymousUnblockRTCResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousUnblockRTCResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				new asn1ts.Boolean({ name: "bBlockedRTC" }),
				new asn1ts.Extension()
			]
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousIsBlockedRTCForAgentArgument {
	public constructor(that: AsnAgentAnonymousIsBlockedRTCForAgentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousIsBlockedRTCForAgentArgument {
		return new AsnAgentAnonymousIsBlockedRTCForAgentArgument({
			u8sAnonymousUserURI: "",
			u8sAgentURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI",
			"u8sAgentURI"
		];
		return p;
	}

	public static type = "AsnAgentAnonymousIsBlockedRTCForAgentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousIsBlockedRTCForAgentArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				new asn1ts.Utf8String({ name: "u8sAgentURI" }),
				new asn1ts.Extension()
			]
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** affected agent user URI */
	public u8sAgentURI!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousIsBlockedRTCForAgentResult {
	public constructor(that: AsnAgentAnonymousIsBlockedRTCForAgentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousIsBlockedRTCForAgentResult {
		return new AsnAgentAnonymousIsBlockedRTCForAgentResult({
			bBlockedRTC: false
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"bBlockedRTC"
		];
		return p;
	}

	public static type = "AsnAgentAnonymousIsBlockedRTCForAgentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousIsBlockedRTCForAgentResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bBlockedRTC" }),
				new asn1ts.Extension()
			]
		});
	}

	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentGetAgentGroupsForAnonymousArgument {
	public constructor(that: AsnAgentGetAgentGroupsForAnonymousArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentGetAgentGroupsForAnonymousArgument {
		return new AsnAgentGetAgentGroupsForAnonymousArgument({
			u8sAnonymousUserURI: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sAnonymousUserURI"
		];
		return p;
	}

	public static type = "AsnAgentGetAgentGroupsForAnonymousArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentGetAgentGroupsForAnonymousArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAnonymousUserURI" }),
				new asn1ts.Extension()
			]
		});
	}

	/** anonymous user URI */
	public u8sAnonymousUserURI!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousGetAgentsArgument {
	public constructor(that?: AsnAgentAnonymousGetAgentsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousGetAgentsArgument {
		return new AsnAgentAnonymousGetAgentsArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAgentAnonymousGetAgentsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousGetAgentsArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAgentGroupInfoList extends Array<AsnAgentGroupInfo> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAgentGroupInfo.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentMonitorStartResult {
	public constructor(that: AsnAgentMonitorStartResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentMonitorStartResult {
		return new AsnAgentMonitorStartResult({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID",
			"listGroups"
		];
		return p;
	}

	public static type = "AsnAgentMonitorStartResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentMonitorStartResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				AsnAgentGroupInfoList.getASN1Schema({ name: "listGroups" }),
				new asn1ts.Extension()
			]
		});
	}

	/** CrossrefID for the associated events */
	public u8sMonitorCrossRefID!: string;
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentSupervisorMonitorStartResult {
	public constructor(that: AsnAgentSupervisorMonitorStartResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentSupervisorMonitorStartResult {
		return new AsnAgentSupervisorMonitorStartResult({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID",
			"listGroups"
		];
		return p;
	}

	public static type = "AsnAgentSupervisorMonitorStartResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentSupervisorMonitorStartResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				AsnAgentGroupInfoList.getASN1Schema({ name: "listGroups" }),
				new asn1ts.Extension()
			]
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAgentBroadcastItemList extends Array<AsnAgentBroadcastItem> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAgentBroadcastItem.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastGetItemsForGroupsResult {
	public constructor(that: AsnAgentBroadcastGetItemsForGroupsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastGetItemsForGroupsResult {
		return new AsnAgentBroadcastGetItemsForGroupsResult({
			items: new AsnAgentBroadcastItemList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"items"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastGetItemsForGroupsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastGetItemsForGroupsResult",
			...params,
			value: [
				AsnAgentBroadcastItemList.getASN1Schema({ name: "items" }),
				new asn1ts.Extension()
			]
		});
	}

	public items!: AsnAgentBroadcastItemList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentBroadcastEventArgument {
	public constructor(that: AsnAgentBroadcastEventArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentBroadcastEventArgument {
		return new AsnAgentBroadcastEventArgument({
			items: new AsnAgentBroadcastItemList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"items"
		];
		return p;
	}

	public static type = "AsnAgentBroadcastEventArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentBroadcastEventArgument",
			...params,
			value: [
				AsnAgentBroadcastItemList.getASN1Schema({ name: "items" }),
				new asn1ts.Extension()
			]
		});
	}

	public items!: AsnAgentBroadcastItemList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument contains the CrossRefID for which the event is sent and the list of the user's agent groups.
 *
 * @private
 */
export class AsnAgentNotifyGroupsChangedArgument {
	public constructor(that: AsnAgentNotifyGroupsChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentNotifyGroupsChangedArgument {
		return new AsnAgentNotifyGroupsChangedArgument({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sMonitorCrossRefID",
			"listGroups"
		];
		return p;
	}

	public static type = "AsnAgentNotifyGroupsChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentNotifyGroupsChangedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				AsnAgentGroupInfoList.getASN1Schema({ name: "listGroups" }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sMonitorCrossRefID!: string;
	/** complete List of groups */
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentGetAgentGroupsForAnonymousResult {
	public constructor(that: AsnAgentGetAgentGroupsForAnonymousResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentGetAgentGroupsForAnonymousResult {
		return new AsnAgentGetAgentGroupsForAnonymousResult({
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listGroups"
		];
		return p;
	}

	public static type = "AsnAgentGetAgentGroupsForAnonymousResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentGetAgentGroupsForAnonymousResult",
			...params,
			value: [
				AsnAgentGroupInfoList.getASN1Schema({ name: "listGroups" }),
				new asn1ts.Extension()
			]
		});
	}

	/** list of agentGroups the anonymous has contacted the agent */
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAgentAnonymousGetAgentsResult {
	public constructor(that: AsnAgentAnonymousGetAgentsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAgentAnonymousGetAgentsResult {
		return new AsnAgentAnonymousGetAgentsResult({
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listGroups"
		];
		return p;
	}

	public static type = "AsnAgentAnonymousGetAgentsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAgentAnonymousGetAgentsResult",
			...params,
			value: [
				AsnAgentGroupInfoList.getASN1Schema({ name: "listGroups" }),
				new asn1ts.Extension()
			]
		});
	}

	public listGroups!: AsnAgentGroupInfoList;
}
