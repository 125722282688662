// [PrintTSCodeOne]
// [PrintTSComments]
/*
 * ENetUC_AV.ts
 * "UC-Server-Access-Protocol-AV" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Audio\/Video interface.
 * ## Audio \/ Video Conversation Modul
 * UML section is not exported to this file!
 * ## Moduledescription
 * This module defines structures and operations related to audio\/video communication controlling.
 *
 * @private
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_AV";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAVMakeCallErrorEnum {
	general = 100
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumCodecType {
	ePCMU = 0,
	ePCMA = 8,
	eG722 = 9,
	eG729 = 18,
	eOPUS = 96,
	eTELEPHONE_EVENT = 101,
	eG726_16 = 110,
	eG726_24 = 111,
	eG726_32 = 112,
	eG726_40 = 113,
	eVP8 = 200,
	eH264 = 264
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAVMediaType {
	eMEDIATYPEAUDIOVIDEO = 0,
	eMEDIATYPEAUDIO = 1,
	eMEDIATYPEDESKTOPSHARING = 2,
	eMEDIATYPEDESTKOPREQUESTSHARING = 3,
	eMEDIATYPESIP = 65535
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ConnectionID with CallID and physical endpoint (URI \/ EndPointID)
 * -&gt; wird benutzt wenn man keine LogEndpointID hat oder wenn alle Endpunkte einer Verbindung (LogEndpointIDs) an einer speziellen EndpointID erreicht werden sollen
 *
 * @private
 */
export class AsnAVCallAndConnectionID {
	public constructor(that: AsnAVCallAndConnectionID) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallAndConnectionID {
		return new AsnAVCallAndConnectionID({
			u8sCallID: "",
			u8sEndpointID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"u8sEndpointID"
		];
		return p;
	}

	public static type = "AsnAVCallAndConnectionID";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallAndConnectionID",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "u8sEndpointID" }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	public u8sEndpointID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * **************** AVMakeCall (DEPRECATED) *****************
 * **********************************************************
 * Argument for the asnAVMakeCall operation
 *
 * @deprecated 21.06.2023 -&gt; AsnAVMakeCallV2Argument
 * @private
 */
export class AsnAVMakeCallArgument {
	public constructor(that: AsnAVMakeCallArgument) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVMakeCallArgument {
		return new AsnAVMakeCallArgument({
			callType: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"callType",
			"u8sUriFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals) {
			p.push(
				"newCallParams",
				"u8sAssociatedTextChatConversationID",
				"u8sCaps",
				"optionalParams"
			);
		}
		return p;
	}

	public static type = "AsnAVMakeCallArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVMakeCallArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.OctetString({ name: "u8sBody" }),
				ENetUC_Common_SIPCTI.AsnCtiNewCallParams.getASN1Schema({ name: "newCallParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 2 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 3 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Eigene SIP-Identität - wenn nicht definiert oder falsch dann unkorrekte Presenceanzeigen vom Server */
	public u8sUriFrom!: string;
	/** SIP-Identität des Angerufenen (Empfänger) */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Offer) */
	public u8sBody!: string;
	/** Additional data for the call */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	/**
	 * optionalParams Name \"IsPublicCall\" Value Boolean
	 * optionalParams Name \"IsRemoteControlCallOut\" Value Boolean
	 * optionalParams Name \"IsRemoteControlCallIn\" Value Boolean
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ********************** AVMakeCallV2 **********************
 * **********************************************************
 * Argument for the asnAVMakeCallV2 operation
 *
 * @private
 */
export class AsnAVMakeCallV2Argument {
	public constructor(that: AsnAVMakeCallV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVMakeCallV2Argument {
		return new AsnAVMakeCallV2Argument({
			callType: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"callType",
			"u8sUriFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals) {
			p.push(
				"newCallParams",
				"u8sAssociatedTextChatConversationID",
				"u8sCaps",
				"bIsPublicCall",
				"bIsRemoteControlCallOut",
				"bIsRemoteControlCallIn"
			);
		}
		return p;
	}

	public static type = "AsnAVMakeCallV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVMakeCallV2Argument",
			...params,
			value: [
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.Utf8String({ name: "u8sBody" }),
				ENetUC_Common_SIPCTI.AsnCtiNewCallParams.getASN1Schema({ name: "newCallParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 2 } }),
				new asn1ts.Boolean({ name: "bIsPublicCall", idBlock: { optionalID: 3 } }),
				new asn1ts.Boolean({ name: "bIsRemoteControlCallOut", idBlock: { optionalID: 4 } }),
				new asn1ts.Boolean({ name: "bIsRemoteControlCallIn", idBlock: { optionalID: 5 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Eigene SIP-Identität - wenn nicht definiert oder falsch dann unkorrekte Presenceanzeigen vom Server */
	public u8sUriFrom!: string;
	/** SIP-Identität des Angerufenen (Empfänger) */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Offer) */
	public u8sBody!: string;
	/** Additional data for the call */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	public bIsPublicCall?: boolean;
	public bIsRemoteControlCallOut?: boolean;
	public bIsRemoteControlCallIn?: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * **************** asnAVCallStateChanged *******************
 * **********************************************************
 * ** signal call state changes
 *
 * @private
 */
export class AsnAVCallParty {
	public constructor(that: AsnAVCallParty) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallParty {
		return new AsnAVCallParty({
			endpointID: "",
			endPointState: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"endpointID",
			"endPointState"
		];
		return p;
	}

	public static type = "AsnAVCallParty";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallParty",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "endpointID" }),
				new asn1ts.Integer({ name: "endPointState" }),
				new asn1ts.Extension()
			]
		});
	}

	/** endpointID of the party */
	public endpointID!: string;
	/** ffs */
	public endPointState!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ************* asnAVCallMessage (deprecated) **************
 * **********************************************************
 *
 * @deprecated 21.06.2023 -&gt; AsnAVCallMessageICEV2
 * @private
 */
export class AsnAVCallMessageICE {
	public constructor(that: AsnAVCallMessageICE) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallMessageICE {
		return new AsnAVCallMessageICE({
			candidate: "",
			sdpMid: "",
			sdpMLineIndex: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"candidate",
			"sdpMid",
			"sdpMLineIndex"
		];
		return p;
	}

	public static type = "AsnAVCallMessageICE";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallMessageICE",
			...params,
			value: [
				new asn1ts.OctetString({ name: "candidate" }),
				new asn1ts.OctetString({ name: "sdpMid" }),
				new asn1ts.Integer({ name: "sdpMLineIndex" }),
				new asn1ts.Extension()
			]
		});
	}

	public candidate!: string;
	public sdpMid!: string;
	public sdpMLineIndex!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ****************** asnAVCallMessageV2 ********************
 * **********************************************************
 *
 * @private
 */
export class AsnAVCallMessageICEV2 {
	public constructor(that: AsnAVCallMessageICEV2) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallMessageICEV2 {
		return new AsnAVCallMessageICEV2({
			candidate: "",
			sdpMid: "",
			sdpMLineIndex: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"candidate",
			"sdpMid",
			"sdpMLineIndex"
		];
		return p;
	}

	public static type = "AsnAVCallMessageICEV2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallMessageICEV2",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "candidate" }),
				new asn1ts.Utf8String({ name: "sdpMid" }),
				new asn1ts.Integer({ name: "sdpMLineIndex" }),
				new asn1ts.Extension()
			]
		});
	}

	public candidate!: string;
	public sdpMid!: string;
	public sdpMLineIndex!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * *************** asnAVConnect (deprecated) ****************
 * **********************************************************
 * ** Connect\/accept an AV Connection and drops forked users
 *
 * @deprecated 21.06.2023 -&gt; AsnAVConnectV2Argument
 * @private
 */
export class AsnAVConnectArgument {
	public constructor(that: AsnAVConnectArgument) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVConnectArgument {
		return new AsnAVConnectArgument({
			u8sCallID: "",
			endpointIDTo: "",
			connectedEndPointID: "",
			callType: 0,
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"connectedEndPointID",
			"callType",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals)
			p.push("u8sCaps");
		return p;
	}

	public static type = "AsnAVConnectArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVConnectArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "connectedEndPointID" }),
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.OctetString({ name: "u8sBody" }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public connectedEndPointID!: string;
	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ******************** asnAVConnectV2 **********************
 * **********************************************************
 * ** Connect\/accept an AV Connection and drops forked users
 *
 * @private
 */
export class AsnAVConnectV2Argument {
	public constructor(that: AsnAVConnectV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVConnectV2Argument {
		return new AsnAVConnectV2Argument({
			u8sCallID: "",
			endpointIDTo: "",
			connectedEndPointID: "",
			callType: 0,
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"connectedEndPointID",
			"callType",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals)
			p.push("u8sCaps");
		return p;
	}

	public static type = "AsnAVConnectV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVConnectV2Argument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "connectedEndPointID" }),
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.Utf8String({ name: "u8sBody" }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public connectedEndPointID!: string;
	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ***********************************************************
 * ***************** asnAVAlert (deprecated) *****************
 * ***********************************************************
 * ** Indicate a remote ringing device, may enable early media
 * -&gt; sofort nach Erhalt einen neuen Rufes sollte Alert oder Drop geschickt werden
 * -&gt; fuer Early media koennte im Alert ein SDP mitgeschickt werden -&gt; derzeit schicken wirs im Connect
 *
 * @deprecated 21.06.2023 -&gt; AsnAVAlertV2Argument
 * @private
 */
export class AsnAVAlertArgument {
	public constructor(that: AsnAVAlertArgument) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVAlertArgument {
		return new AsnAVAlertArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals)
			p.push("u8sCaps");
		return p;
	}

	public static type = "AsnAVAlertArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVAlertArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.OctetString({ name: "u8sBody" }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public endpointIDFrom!: string;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit empty String: L\"\" - moeglich: L\"application\/sdp\" */
	public u8sContentType!: string;
	/** derzeit empty String: L\"\" - moeglich: Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ***********************************************************
 * *********************** asnAVAlert ************************
 * ***********************************************************
 * ** Indicate a remote ringing device, may enable early media
 * -&gt; sofort nach Erhalt einen neuen Rufes sollte Alert oder Drop geschickt werden
 * -&gt; fuer Early media koennte im Alert ein SDP mitgeschickt werden -&gt; derzeit schicken wirs im Connect
 *
 * @private
 */
export class AsnAVAlertV2Argument {
	public constructor(that: AsnAVAlertV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVAlertV2Argument {
		return new AsnAVAlertV2Argument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals)
			p.push("u8sCaps");
		return p;
	}

	public static type = "AsnAVAlertV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVAlertV2Argument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.Utf8String({ name: "u8sBody" }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public endpointIDFrom!: string;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit empty String: L\"\" - moeglich: L\"application\/sdp\" */
	public u8sContentType!: string;
	/** derzeit empty String: L\"\" - moeglich: Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestSTUNandTURNArgument {
	public constructor(that?: AsnRequestSTUNandTURNArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestSTUNandTURNArgument {
		return new AsnRequestSTUNandTURNArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnRequestSTUNandTURNArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestSTUNandTURNArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetSTUNandTURNArgument {
	public constructor(that?: AsnGetSTUNandTURNArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetSTUNandTURNArgument {
		return new AsnGetSTUNandTURNArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnGetSTUNandTURNArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetSTUNandTURNArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetSTUNandTURNResult {
	public constructor(that: AsnGetSTUNandTURNResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetSTUNandTURNResult {
		return new AsnGetSTUNandTURNResult({
			listConfigSTUNandTURN: new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listConfigSTUNandTURN"
		];
		return p;
	}

	public static type = "AsnGetSTUNandTURNResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetSTUNandTURNResult",
			...params,
			value: [
				ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList.getASN1Schema({ name: "listConfigSTUNandTURN" }),
				new asn1ts.Extension()
			]
		});
	}

	public listConfigSTUNandTURN!: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSTUNandTURNConfigChangedArgument {
	public constructor(that: AsnSTUNandTURNConfigChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSTUNandTURNConfigChangedArgument {
		return new AsnSTUNandTURNConfigChangedArgument({
			listConfigSTUNandTURN: new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"listConfigSTUNandTURN"
		];
		return p;
	}

	public static type = "AsnSTUNandTURNConfigChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSTUNandTURNConfigChangedArgument",
			...params,
			value: [
				ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList.getASN1Schema({ name: "listConfigSTUNandTURN" }),
				new asn1ts.Extension()
			]
		});
	}

	public listConfigSTUNandTURN!: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * WebRtc Browser kompatible iceServers
 *
 * @private
 */
export class AsnIceServer {
	public constructor(that: AsnIceServer) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnIceServer {
		return new AsnIceServer({
			urls: new ENetUC_Common.UTF8StringList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"urls"
		];
		if (bIncludeOptionals) {
			p.push(
				"username",
				"credential"
			);
		}
		return p;
	}

	public static type = "AsnIceServer";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnIceServer",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "username", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "credential", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "urls" }),
				new asn1ts.Extension()
			]
		});
	}

	/** turn username to use */
	public username?: string;
	/** turn password to use */
	public credential?: string;
	/** uris */
	public urls!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAVGetIceServersArgument {
	public constructor(that?: AsnAVGetIceServersArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVGetIceServersArgument {
		return new AsnAVGetIceServersArgument();
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p: string[] = [];
		return p;
	}

	public static type = "AsnAVGetIceServersArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVGetIceServersArgument",
			...params,
			value: [
				new asn1ts.Extension()
			]
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnConfigCodec {
	public constructor(that: AsnConfigCodec) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnConfigCodec {
		return new AsnConfigCodec({
			iType: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iType"
		];
		return p;
	}

	public static type = "AsnConfigCodec";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnConfigCodec",
			...params,
			value: [
				new asn1ts.Integer({ name: "iType" }),
				new asn1ts.Extension()
			]
		});
	}

	/** Codec type */
	/** (see EnumCodecType) */
	public iType!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * *** asnAVMedia to inform server about media changes in ***
 * *** existing call (currently to enable journalling of  ***
 * *** media switching)                                   ***
 * **********************************************************
 * ** Connect\/accept an AV Connection and drops forked users
 *
 * @private
 */
export class AsnAVMediaChangeArgument {
	public constructor(that: AsnAVMediaChangeArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVMediaChangeArgument {
		return new AsnAVMediaChangeArgument({
			u8sCallID: "",
			endpointIDTo: "",
			connectedEndPointID: "",
			iMediaType: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: new Uint8Array(0),
			u8sMediaID: "",
			iAvFlags: 0,
			iAvDropReason: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"connectedEndPointID",
			"iMediaType",
			"u8sUriFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody",
			"u8sMediaID",
			"iAvFlags",
			"iAvDropReason"
		];
		return p;
	}

	public static type = "AsnAVMediaChangeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVMediaChangeArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "connectedEndPointID" }),
				new asn1ts.Integer({ name: "iMediaType" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.OctetString({ name: "u8sBody" }),
				new asn1ts.Utf8String({ name: "u8sMediaID" }),
				new asn1ts.Integer({ name: "iAvFlags" }),
				new asn1ts.Integer({ name: "iAvDropReason" }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers, hier des Peers (MediaChange messages werden im server terminiert) */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public connectedEndPointID!: string;
	/** Media this message relates to */
	/** (see AsnAVMediaType) */
	public iMediaType!: number;
	/** Uri des Message Sendenden */
	public u8sUriFrom!: string;
	/** Uri des Message Empfaengers */
	public u8sUriTo!: string;
	/** derzeit L\"application\/xx\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: Uint8Array;
	/** ID of the different Media Requests is used as the Connection ID in the journal -&gt; generated by the client when a new media is addedbr \/&gt;Note: Tracker Id of the original call is as well the Connection ID */
	public u8sMediaID!: string;
	/** Media related flags, currently only IS_ORIGINATOR 0x0001L */
	public iAvFlags!: number;
	/** EAVCause::dropCause */
	public iAvDropReason!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ********* asnAVTransferGetID\/asnAVTransferStart **********
 * **********************************************************
 * ** transfer message argument
 *
 * @private
 */
export class AsnAVTransferArgument {
	public constructor(that: AsnAVTransferArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVTransferArgument {
		return new AsnAVTransferArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			iTransferResult: 0,
			u8sUriTransferTo: "",
			u8sUriTransferFrom: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"iTransferResult",
			"u8sUriTransferTo",
			"u8sUriTransferFrom"
		];
		if (bIncludeOptionals)
			p.push("u8sTransferTargetID");
		return p;
	}

	public static type = "AsnAVTransferArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVTransferArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Integer({ name: "iTransferResult" }),
				new asn1ts.Utf8String({ name: "u8sUriTransferTo" }),
				new asn1ts.Utf8String({ name: "u8sUriTransferFrom" }),
				new asn1ts.Utf8String({ name: "u8sTransferTargetID", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Senders */
	public endpointIDFrom!: string;
	/** for result Messages, SIP status, e.g.: '202' accepted, '100' trying, '200' OK */
	public iTransferResult!: number;
	/** Destination of transfer */
	public u8sUriTransferTo!: string;
	/** Transfering Party */
	public u8sUriTransferFrom!: string;
	/** we ask the Consultation call (C-partner) for an ID to identify it in the transfer request to the A-partner */
	public u8sTransferTargetID?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnAVCallOrConnection {
	public constructor(that?: AsnAVCallOrConnection) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallOrConnection {
		return new AsnAVCallOrConnection();
	}

	public static type = "AsnAVCallOrConnection";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnAVCallOrConnection",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID", idBlock: { optionalID: 0 } }),
				AsnAVCallAndConnectionID.getASN1Schema({ name: "connectionID", idBlock: { optionalID: 1 } })
			]
		});
	}

	public u8sCallID?: string;
	public connectionID?: AsnAVCallAndConnectionID;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of the asnAVMakeCall and asnAVMakeCallV2 operation
 *
 * @private
 */
export class AsnAVMakeCallResult {
	public constructor(that: AsnAVMakeCallResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVMakeCallResult {
		return new AsnAVMakeCallResult({
			callAndConnectionID: AsnAVCallAndConnectionID["initEmpty"].call(0),
			u8sCTIConnectionID: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"callAndConnectionID",
			"u8sCTIConnectionID"
		];
		return p;
	}

	public static type = "AsnAVMakeCallResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVMakeCallResult",
			...params,
			value: [
				AsnAVCallAndConnectionID.getASN1Schema({ name: "callAndConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sCTIConnectionID" }),
				new asn1ts.Extension()
			]
		});
	}

	public callAndConnectionID!: AsnAVCallAndConnectionID;
	/** CID des erzeugten EVirtualSIPAVCall-Objekts im Line-Call-Model. Nach Rücksprache mit RB leider nicht mit der AV-ConnectionID vereinbar. */
	public u8sCTIConnectionID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * *********************** AVDropCall ***********************
 * **********************************************************
 * ** Drop an AV Connection
 * to drop\/reject an incoming call it is recommended to use iAvDropReason BUSY (1) or REJECT (16)
 * to hang up an active call it is recommended to use iAvDropReason NORMAL_CALL_CLEARING (0)
 * to hang up an outgoing call pending (e.g. ringback) it is recommended to use iAvDropReason CANCEL (2) or NORMAL_CALL_CLEARING (0)
 * further cause definitions in: http:\/\/svnserver:8080\/svn\/devprocall\/trunk\/estos\/common\/libs\/enetctilib\/include\/EAVCause.h (and the EAVCause.cpp file)
 *
 * @private
 */
export class AsnAVDropCallArgument {
	public constructor(that: AsnAVDropCallArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVDropCallArgument {
		return new AsnAVDropCallArgument({
			dropTarget: AsnAVCallOrConnection["initEmpty"].call(0),
			u8sCause: "",
			iAvDropReason: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"dropTarget",
			"u8sCause",
			"iAvDropReason"
		];
		return p;
	}

	public static type = "AsnAVDropCallArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVDropCallArgument",
			...params,
			value: [
				AsnAVCallOrConnection.getASN1Schema({ name: "dropTarget" }),
				new asn1ts.Utf8String({ name: "u8sCause" }),
				new asn1ts.Integer({ name: "iAvDropReason" }),
				new asn1ts.Extension()
			]
		});
	}

	/** will man alle Endpoints ausloesen muss man die CallID schicken (ohne  connectionID)&lt;br \/&gt;will man alle LogEndpoints eines Endpoints ausloesen muss man die EndpointID schicken */
	public dropTarget!: AsnAVCallOrConnection;
	/** g_EAVCause.GetASNCauseStrFromEAVCause(iAvDropReason) sollte benutzt werden - String zur besseren Lesbarkeit im Trace */
	public u8sCause!: string;
	/** EAVCause::dropCause */
	public iAvDropReason!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * **************** asnAVNewCall (deprecated) ***************
 * **********************************************************
 * ** Invoked (Event) signalling a new (incoming) call
 *
 * @deprecated 21.06.2023 -&gt; AsnAVNewCallV2Argument
 * @private
 */
export class AsnAVNewCallArgument {
	public constructor(that: AsnAVNewCallArgument) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVNewCallArgument {
		return new AsnAVNewCallArgument({
			callAndConnectionID: AsnAVCallAndConnectionID["initEmpty"].call(0),
			u8sCTIConnectionID: "",
			callType: 0,
			u8sUriFrom: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"callAndConnectionID",
			"u8sCTIConnectionID",
			"callType",
			"u8sUriFrom",
			"endpointIDFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals) {
			p.push(
				"newCallParams",
				"u8sAssociatedTextChatConversationID",
				"u8sCaps",
				"optionalParams"
			);
		}
		return p;
	}

	public static type = "AsnAVNewCallArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVNewCallArgument",
			...params,
			value: [
				AsnAVCallAndConnectionID.getASN1Schema({ name: "callAndConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sCTIConnectionID" }),
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.OctetString({ name: "u8sBody" }),
				ENetUC_Common_SIPCTI.AsnCtiNewCallParams.getASN1Schema({ name: "newCallParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 2 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 3 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** IDs fuer den Angerufenen (called party) */
	public callAndConnectionID!: AsnAVCallAndConnectionID;
	/** CID des erzeugten EVirtualSIPAVCall-Objekts im Line-Call-Model. Nach Rücksprache mit RB leider nicht mit der AV-ConnectionID vereinbar. */
	public u8sCTIConnectionID!: string;
	/** Media Type */
	/** (see AsnAVMediaType) */
	public callType!: number;
	/** SIP-Identität des Anrufenden */
	public u8sUriFrom!: string;
	/** EndpointIDs des Anrufenden (calling party) */
	public endpointIDFrom!: string;
	/** SIP-Identität des Angerufenen */
	public u8sUriTo!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sContentType!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sBody!: string;
	/** Additional data for the call */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	/**
	 * optionalParams Name \"CTITrackerID\" Value String
	 * optionalParams Name \"localContact\" Value BER encoded localContact
	 * optionalParams Name \"u8sHoldContactNumberSC\" Value String
	 * optionalParams Name \"holdContact\" Value BER encoded holdContact
	 * optionalParams Name \"u8sRedirectingNumberSC\" Value String
	 * optionalParams Name \"redirectingContact\" Value BER encoded redirectingContact
	 * optionalParams Name \"IsRemoteControlCallOut\" Value Boolean
	 * optionalParams Name \"IsRemoteControlCallIn\" Value Boolean
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * **********************************************************
 * ********************** asnAVNewCall **********************
 * **********************************************************
 * ** Invoked (Event) signalling a new (incoming) call
 *
 * @private
 */
export class AsnAVNewCallV2Argument {
	public constructor(that: AsnAVNewCallV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVNewCallV2Argument {
		return new AsnAVNewCallV2Argument({
			callAndConnectionID: AsnAVCallAndConnectionID["initEmpty"].call(0),
			u8sCTIConnectionID: "",
			callType: 0,
			u8sUriFrom: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"callAndConnectionID",
			"u8sCTIConnectionID",
			"callType",
			"u8sUriFrom",
			"endpointIDFrom",
			"u8sUriTo",
			"u8sContentType",
			"u8sBody"
		];
		if (bIncludeOptionals) {
			p.push(
				"newCallParams",
				"u8sAssociatedTextChatConversationID",
				"u8sCaps",
				"u8sCTITrackerID",
				"asnLocalContact",
				"u8sHoldContactNumberSC",
				"asnHoldContact",
				"u8sRedirectingNumberSC",
				"asnRedirectingContact"
			);
		}
		return p;
	}

	public static type = "AsnAVNewCallV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVNewCallV2Argument",
			...params,
			value: [
				AsnAVCallAndConnectionID.getASN1Schema({ name: "callAndConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sCTIConnectionID" }),
				new asn1ts.Integer({ name: "callType" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType" }),
				new asn1ts.Utf8String({ name: "u8sBody" }),
				ENetUC_Common_SIPCTI.AsnCtiNewCallParams.getASN1Schema({ name: "newCallParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAssociatedTextChatConversationID", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sCaps", idBlock: { optionalID: 2 } }),
				new asn1ts.Utf8String({ name: "u8sCTITrackerID", idBlock: { optionalID: 3 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnLocalContact", idBlock: { optionalID: 4 } }),
				new asn1ts.Utf8String({ name: "u8sHoldContactNumberSC", idBlock: { optionalID: 5 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnHoldContact", idBlock: { optionalID: 6 } }),
				new asn1ts.Utf8String({ name: "u8sRedirectingNumberSC", idBlock: { optionalID: 7 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRedirectingContact", idBlock: { optionalID: 8 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** IDs fuer den Angerufenen (called party) */
	public callAndConnectionID!: AsnAVCallAndConnectionID;
	/** CID des erzeugten EVirtualSIPAVCall-Objekts im Line-Call-Model. Nach Rücksprache mit RB leider nicht mit der AV-ConnectionID vereinbar. */
	public u8sCTIConnectionID!: string;
	/** Media Type */
	/** (see AsnAVMediaType) */
	public callType!: number;
	/** SIP-Identität des Anrufenden */
	public u8sUriFrom!: string;
	/** EndpointIDs des Anrufenden (calling party) */
	public endpointIDFrom!: string;
	/** SIP-Identität des Angerufenen */
	public u8sUriTo!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sContentType!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sBody!: string;
	/** Additional data for the call */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	/** Associated call tracker id */
	public u8sCTITrackerID?: string;
	/** the resolved contact for the hold number */
	public asnLocalContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** the hold number in supercanonic notation */
	public u8sHoldContactNumberSC?: string;
	/** the resolved contact for the hold number */
	public asnHoldContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** the hold number in supercanonic notation */
	public u8sRedirectingNumberSC?: string;
	/** the resolved contact for the hold number */
	public asnRedirectingContact?: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAVCallPartyList extends Array<AsnAVCallParty> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAVCallParty.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAVCallStateChangedArgument {
	public constructor(that: AsnAVCallStateChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallStateChangedArgument {
		return new AsnAVCallStateChangedArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			callState: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			parties: new AsnAVCallPartyList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"callState",
			"u8sUriFrom",
			"u8sUriTo",
			"parties"
		];
		if (bIncludeOptionals)
			p.push("optionalParams");
		return p;
	}

	public static type = "AsnAVCallStateChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallStateChangedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Integer({ name: "callState" }),
				new asn1ts.Utf8String({ name: "u8sUriFrom" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				AsnAVCallPartyList.getASN1Schema({ name: "parties" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 0 } }),
				new asn1ts.Extension()
			]
		});
	}

	/** Die CallID identifiziert den Call */
	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public endpointIDFrom!: string;
	/** EDataStructuresAVMessages.h */
	public callState!: number;
	/** Uri des Message Sendenden */
	public u8sUriFrom!: string;
	/** Uri des Message Empfaengers */
	public u8sUriTo!: string;
	/** ffs - wird derzeit als empty Sequence geschickt -&gt; EAVCallPartyList als empty list */
	public parties!: AsnAVCallPartyList;
	/**
	 * optionalParams Name \"u8sRedirectingNumberSC\" Value String
	 * optionalParams Name \"redirectingContact\" Value BER encoded redirectingContact
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ** send end to end messages
 *
 * @deprecated 21.06.2023 -&gt; AsnAVCallMessageV2Argument
 * @private
 */
export class AsnAVCallMessageArgument {
	public constructor(that: AsnAVCallMessageArgument) {
		TSDeprecatedCallback.deprecatedObject(1687305600, MODULE_NAME, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallMessageArgument {
		return new AsnAVCallMessageArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			messageType: 0,
			u8sUriTo: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"messageType",
			"u8sUriTo"
		];
		if (bIncludeOptionals) {
			p.push(
				"u8sContentType",
				"u8sBody",
				"iceCandidate",
				"u8sConnectedNumberSC",
				"asnConnectedContact",
				"u8sRedirectingNumberSC",
				"asnRedirectingContact"
			);
		}
		return p;
	}

	public static type = "AsnAVCallMessageArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallMessageArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Integer({ name: "messageType" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType", idBlock: { optionalID: 0 } }),
				new asn1ts.OctetString({ name: "u8sBody", idBlock: { optionalID: 1 } }),
				AsnAVCallMessageICE.getASN1Schema({ name: "iceCandidate", idBlock: { optionalID: 2 } }),
				new asn1ts.Utf8String({ name: "u8sConnectedNumberSC", idBlock: { optionalID: 3 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnConnectedContact", idBlock: { optionalID: 4 } }),
				new asn1ts.Utf8String({ name: "u8sRedirectingNumberSC", idBlock: { optionalID: 5 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRedirectingContact", idBlock: { optionalID: 6 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	public endpointIDTo!: string;
	public endpointIDFrom!: string;
	/**
	 * MessageTypes&lt;br \/&gt;
	 * e.g. for stream renegotiation (like Hold over federation) EAV_MESSTYPE_RENEGOTIATE_OFFER (6), EAV_MESSTYPE_RENEGOTIATE_ANSWER (7), EAV_MESSTYPE_RENEGOTIATE_DECLINE (8)
	 * e.g. for Softphone EAV_MESSTYPE_REINVITE_OFFER (9), EAV_MESSTYPE_REINVITE_ANSWER (10), EAV_MESSTYPE_PRANSWER (11)
	 * or for ICE candidate handling EAV_MESSTYPE_ICE_CANDIDATE_SDP (2), last candidate EAV_MESSTYPE_ICE_CANDIDATE_END (5)
	 * or for remote camera device control EAV_MESSTYPE_CAM_CONTROL (3) with arguments in u8sBody like STR_CAMCONTROL_PANLEFT (\"PANLEFT\")
	 * or for Remote Desktop Modus 	EAV_MESSTYPE_REMOTE_ACCEPT (13), EAV_MESSTYPE_REMOTE_HOLD (14),	EAV_MESSTYPE_REMOTE_RETRIEVE(15),
	 * EAV_MESSTYPE_REMOTE_HOLD_ACK (16), EAV_MESSTYPE_REMOTE_TRANSFER_REQ (17), EAV_MESSTYPE_REMOTE_3PTY_REQ (18),	EAV_MESSTYPE_REMOTE_3PTY_IND (19),
	 * EAV_MESSTYPE_REMOTE_BTRANS_REQ (20), EAV_MESSTYPE_REMOTE_MIC_MUTE_IND (21), EAV_MESSTYPE_REMOTE_REM_PARTY_CHANGED (22)
	 * EDataStructuresAVMessages.h
	 */
	public messageType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/camcontrol\" oder L\"application\/mediastate\" */
	public u8sContentType?: string;
	/** EAVCallMessageArgument::STR_... */
	public u8sBody?: string;
	public iceCandidate?: AsnAVCallMessageICE;
	/** the remote number in supercanonic notation */
	public u8sConnectedNumberSC?: string;
	/** the resolved contact for the remote number */
	public asnConnectedContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** the redirecting number in supercanonic notation */
	public u8sRedirectingNumberSC?: string;
	/** the resolved contact for the redirecting number */
	public asnRedirectingContact?: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ** send end to end messages
 *
 * @private
 */
export class AsnAVCallMessageV2Argument {
	public constructor(that: AsnAVCallMessageV2Argument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVCallMessageV2Argument {
		return new AsnAVCallMessageV2Argument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			messageType: 0,
			u8sUriTo: ""
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"u8sCallID",
			"endpointIDTo",
			"endpointIDFrom",
			"messageType",
			"u8sUriTo"
		];
		if (bIncludeOptionals) {
			p.push(
				"u8sContentType",
				"u8sBody",
				"iceCandidate"
			);
		}
		return p;
	}

	public static type = "AsnAVCallMessageV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVCallMessageV2Argument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCallID" }),
				new asn1ts.Utf8String({ name: "endpointIDTo" }),
				new asn1ts.Utf8String({ name: "endpointIDFrom" }),
				new asn1ts.Integer({ name: "messageType" }),
				new asn1ts.Utf8String({ name: "u8sUriTo" }),
				new asn1ts.Utf8String({ name: "u8sContentType", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sBody", idBlock: { optionalID: 1 } }),
				AsnAVCallMessageICEV2.getASN1Schema({ name: "iceCandidate", idBlock: { optionalID: 2 } }),
				new asn1ts.Extension()
			]
		});
	}

	public u8sCallID!: string;
	public endpointIDTo!: string;
	public endpointIDFrom!: string;
	/**
	 * MessageTypes&lt;br \/&gt;
	 * e.g. for stream renegotiation (like Hold over federation) EAV_MESSTYPE_RENEGOTIATE_OFFER (6), EAV_MESSTYPE_RENEGOTIATE_ANSWER (7), EAV_MESSTYPE_RENEGOTIATE_DECLINE (8)
	 * e.g. for Softphone EAV_MESSTYPE_REINVITE_OFFER (9), EAV_MESSTYPE_REINVITE_ANSWER (10), EAV_MESSTYPE_PRANSWER (11)
	 * or for ICE candidate handling EAV_MESSTYPE_ICE_CANDIDATE_SDP (2), last candidate EAV_MESSTYPE_ICE_CANDIDATE_END (5)
	 * or for remote device control (e.g. camera) EAV_MESSTYPE_CAM_CONTROL (3) with arguments in u8sBody like STR_CAMCONTROL_PANLEFT (\"PANLEFT\")
	 * EDataStructuresAVMessages.h
	 */
	public messageType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/camcontrol\" oder L\"application\/mediastate\" */
	public u8sContentType?: string;
	/** EAVCallMessageArgument::STR_... */
	public u8sBody?: string;
	public iceCandidate?: AsnAVCallMessageICEV2;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnIceServerList extends Array<AsnIceServer> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnIceServer.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAVGetIceServersResult {
	public constructor(that: AsnAVGetIceServersResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAVGetIceServersResult {
		return new AsnAVGetIceServersResult({
			iceServers: new AsnIceServerList()
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"iceServers"
		];
		return p;
	}

	public static type = "AsnAVGetIceServersResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAVGetIceServersResult",
			...params,
			value: [
				AsnIceServerList.getASN1Schema({ name: "iceServers" }),
				new asn1ts.Extension()
			]
		});
	}

	public iceServers!: AsnIceServerList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnConfigCodecList extends Array<AsnConfigCodec> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnConfigCodec.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnConfigPorts {
	public constructor(that: AsnConfigPorts) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnConfigPorts {
		return new AsnConfigPorts({
			minPort: 0,
			maxPort: 0
		});
	}

	public static getOwnPropertyNames(bIncludeOptionals: boolean = true): string[] {
		const p = [
			"minPort",
			"maxPort"
		];
		return p;
	}

	public static type = "AsnConfigPorts";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnConfigPorts",
			...params,
			value: [
				new asn1ts.Integer({ name: "minPort" }),
				new asn1ts.Integer({ name: "maxPort" }),
				new asn1ts.Extension()
			]
		});
	}

	/** minimim config port */
	public minPort!: number;
	/** maximum config port */
	public maxPort!: number;
}
