import { AsnCustomNote } from "../../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { IAsnCustomNote } from "../serializable/IAsnCustomNote";

/**
 * the customNoteConverter
 * @param asnCustomNote - an AsnCustomNote object
 * @returns an IAsnCustomNote or undefined
 */
export function customNoteConverter(asnCustomNote?: AsnCustomNote): IAsnCustomNote | undefined {
	if (!asnCustomNote)
		return undefined;
	return {
		u8sContactID: asnCustomNote.u8sContactID,
		u8sUsername: asnCustomNote.u8sUsername,
		u8sNote: asnCustomNote.u8sNote
	};
}
