// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientPersistence_Converter.ts
 * "UC-Server-Access-Protocol-ClientPersistence" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_ClientPersistence from "./ENetUC_ClientPersistence";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_ClientPersistence_Converter";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceItem_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceItem, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceItem & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceItem");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceItem & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceItem";
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnClientPersistenceItem"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceItem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceItem");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceItem | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceItem>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceItem["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItem"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceItem | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceItem";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceItem");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStoreID", "string", errors, newContext);
		TSConverter.validateParam(s, "data", "string", errors, newContext);
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStoreID, name: "u8sItemStoreID" }));
			t.push(new asn1ts.Utf8String({ value: s.data, name: "data" }));
			if (s.iRevision !== undefined)
				t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItem"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceItem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceItem");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceItem | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceItem.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceItem["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStoreID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "data", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItem"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceCreateArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceCreateArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceCreateArgument";
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceCreateArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceCreateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceCreateArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStoreID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "data", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
			if (s.u8sItemStoreID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sItemStoreID, name: "u8sItemStoreID", idBlock: { optionalID: 0 } }));
			t.push(new asn1ts.Utf8String({ value: s.data, name: "data" }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceCreateArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStoreID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "data", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceCreateResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceCreateResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceCreateResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceCreateResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceCreateResult";

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceCreateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceCreateResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceCreateResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceCreateResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceCreateResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceCreateResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceCreateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceCreateResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceCreateResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceCreateResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceCreateResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceReadArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceReadArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceReadArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceReadArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceReadArgument";
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iLastKnownGlobalRevision", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iItemKeyWildcardType", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bCountAvailableItemsOnly", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iItemLimit", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnClientPersistenceReadArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceReadArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceReadArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceReadArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceReadArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iLastKnownGlobalRevision", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iItemKeyWildcardType", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "bCountAvailableItemsOnly", "boolean", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iItemLimit", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceReadArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceReadArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceReadArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStoreID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "iLastKnownGlobalRevision", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iItemKeyWildcardType", "number", errors, newContext, true);
		TSConverter.validateParam(s, "bCountAvailableItemsOnly", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "iItemLimit", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
			if (s.u8sItemStoreID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sItemStoreID, name: "u8sItemStoreID", idBlock: { optionalID: 0 } }));
			if (s.iLastKnownGlobalRevision !== undefined)
				t.push(new asn1ts.Integer({ value: s.iLastKnownGlobalRevision, name: "iLastKnownGlobalRevision", idBlock: { optionalID: 1 } }));
			if (s.iItemKeyWildcardType !== undefined)
				t.push(new asn1ts.Integer({ value: s.iItemKeyWildcardType, name: "iItemKeyWildcardType", idBlock: { optionalID: 2 } }));
			if (s.bCountAvailableItemsOnly !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bCountAvailableItemsOnly, name: "bCountAvailableItemsOnly", idBlock: { optionalID: 3 } }));
			if (s.iItemLimit !== undefined)
				t.push(new asn1ts.Integer({ value: s.iItemLimit, name: "iItemLimit", idBlock: { optionalID: 4 } }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceReadArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceReadArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceReadArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceReadArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceReadArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStoreID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iLastKnownGlobalRevision", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iItemKeyWildcardType", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bCountAvailableItemsOnly", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iItemLimit", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceReadArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceReadResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceReadResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceReadResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceReadResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceReadResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceReadResult";

		if (errors.validateResult(newContext, "AsnClientPersistenceReadResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceReadResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceReadResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceReadResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceReadResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceReadResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceReadResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceReadResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceReadResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceReadResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceReadResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceReadResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceReadResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceReadResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceReadResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceReadResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceReadResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceUpdateArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceUpdateArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceUpdateArgument";
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceUpdateArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "data", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceUpdateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceUpdateArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStoreID", "string", errors, newContext);
		TSConverter.validateParam(s, "data", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStoreID, name: "u8sItemStoreID" }));
			t.push(new asn1ts.Utf8String({ value: s.data, name: "data" }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceUpdateArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStoreID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "data", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceUpdateResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceUpdateResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceUpdateResult";

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceUpdateResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceUpdateResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceUpdateResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceUpdateResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceUpdateResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceDeleteArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceDeleteArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceDeleteArgument";
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceDeleteArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStoreID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceDeleteArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceDeleteArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStoreID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStoreID, name: "u8sItemStoreID" }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceDeleteArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStoreID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceDeleteResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceDeleteResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceDeleteResult";

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceDeleteResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceDeleteResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceDeleteResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceDeleteResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceDeleteResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceSubscribeArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceSubscribeArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceSubscribeArgument";
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iSubscribeType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceSubscribeArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iSubscribeType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sItemStore", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceSubscribeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceSubscribeArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "iSubscribeType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sItemStore", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			t.push(new asn1ts.Integer({ value: s.iSubscribeType, name: "iSubscribeType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sItemStore, name: "u8sItemStore" }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceSubscribeArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iSubscribeType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sItemStore", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceSubscribeResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceSubscribeResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceSubscribeResult";

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceSubscribeResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceSubscribeResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceSubscribeResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceSubscribeResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceSubscribeResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceGetDatabaseIDArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceGetDatabaseIDArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceGetDatabaseIDArgument";

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceGetDatabaseIDArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceGetDatabaseIDArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceGetDatabaseIDArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceGetDatabaseIDArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceGetDatabaseIDResult_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceGetDatabaseIDResult");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceGetDatabaseIDResult";
		TSConverter.fillJSONParam(s, t, "u8sDatabaseID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceGetDatabaseIDResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sDatabaseID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceGetDatabaseIDResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceGetDatabaseIDResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sDatabaseID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseID, name: "u8sDatabaseID" }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceGetDatabaseIDResult");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sDatabaseID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceGetDatabaseIDResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceItemList_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceItemList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceItemList");

		const t = [] as ENetUC_ClientPersistence.AsnClientPersistenceItemList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientPersistenceItem_Converter.toJSON(se, errors, newContext, "AsnClientPersistenceItem");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItemList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceItemList");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceItemList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientPersistence.AsnClientPersistenceItemList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientPersistenceItem_Converter.fromJSON(se, errors, newContext, "AsnClientPersistenceItem", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItemList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceItemList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceItemList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientPersistenceItem_Converter.toBER(s[id], errors, newContext, "AsnClientPersistenceItem");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnClientPersistenceItemList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceItemList");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceItemList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceItemList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientPersistence.AsnClientPersistenceItemList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientPersistenceItem_Converter.fromBER(se, errors, newContext, "AsnClientPersistenceItem", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceItemList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientPersistenceEventArgument_Converter {
	public static toJSON(s: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientPersistence.AsnClientPersistenceEventArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceEventArgument");

		const t = {} as ENetUC_ClientPersistence.AsnClientPersistenceEventArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientPersistenceEventArgument";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iGlobalRevision", "number", errors, newContext, true);
		if (s.changedItems) {
			const _changedItems = AsnClientPersistenceItemList_Converter.toJSON(s.changedItems, errors, newContext, "changedItems");
			if (_changedItems)
				t.changedItems = _changedItems;
		}
		if (s.deletedItems) {
			const _deletedItems = AsnClientPersistenceItemList_Converter.toJSON(s.deletedItems, errors, newContext, "deletedItems");
			if (_deletedItems)
				t.deletedItems = _deletedItems;
		}
		TSConverter.fillJSONParam(s, t, "u8sDatabaseID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iAvailableItemsCount", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnClientPersistenceEventArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceEventArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceEventArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientPersistence.AsnClientPersistenceEventArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceEventArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iGlobalRevision", "number", errors, newContext, true);
			const _changeditems = AsnClientPersistenceItemList_Converter.fromJSON(s.changedItems, errors, newContext, "changedItems", true);
			if (_changeditems)
				t.changedItems = _changeditems;
			const _deleteditems = AsnClientPersistenceItemList_Converter.fromJSON(s.deletedItems, errors, newContext, "deletedItems", true);
			if (_deleteditems)
				t.deletedItems = _deleteditems;
			TSConverter.fillJSONParam(s, t, "u8sDatabaseID", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iAvailableItemsCount", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceEventArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientPersistenceEventArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientPersistenceEventArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "iGlobalRevision", "number", errors, newContext, true);
		const _changedItems = AsnClientPersistenceItemList_Converter.toBER(s.changedItems, errors, newContext, "changedItems", 1);
		const _deletedItems = AsnClientPersistenceItemList_Converter.toBER(s.deletedItems, errors, newContext, "deletedItems", 2);
		TSConverter.validateParam(s, "u8sDatabaseID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "iAvailableItemsCount", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCrossRefID, name: "u8sCrossRefID" }));
			if (s.iGlobalRevision !== undefined)
				t.push(new asn1ts.Integer({ value: s.iGlobalRevision, name: "iGlobalRevision", idBlock: { optionalID: 0 } }));
			if (_changedItems)
				t.push(_changedItems);
			if (_deletedItems)
				t.push(_deletedItems);
			if (s.u8sDatabaseID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseID, name: "u8sDatabaseID", idBlock: { optionalID: 3 } }));
			if (s.iAvailableItemsCount !== undefined)
				t.push(new asn1ts.Integer({ value: s.iAvailableItemsCount, name: "iAvailableItemsCount", idBlock: { optionalID: 4 } }));
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceEventArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientPersistence.AsnClientPersistenceEventArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientPersistenceEventArgument");

		let t: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientPersistence.AsnClientPersistenceEventArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientPersistence.AsnClientPersistenceEventArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iGlobalRevision", "Integer", errors, newContext, true);
			t.changedItems = AsnClientPersistenceItemList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "changedItems"), errors, newContext, "changedItems", true);
			t.deletedItems = AsnClientPersistenceItemList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "deletedItems"), errors, newContext, "deletedItems", true);
			TSConverter.fillASN1Param(s, t, "u8sDatabaseID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iAvailableItemsCount", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnClientPersistenceEventArgument"))
			return t;

		return undefined;
	}
}
