import { IAsnAbsentState } from "../serializable/IAsnAbsentState";
import { AsnAbsentState } from "../../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { validateAndGetISOStringFromDate } from "./helpers";

/**
 *	Convert the absentState from ASN to IAsnAbsentState
 *
 * @param asnAbsentState - the absent state
 * @returns an IAsnAbsentState object
 */
export function absentStateConverter(asnAbsentState?: AsnAbsentState): IAsnAbsentState | undefined {
	if (!asnAbsentState)
		return undefined;
	let iReachability;
	if (asnAbsentState.optionalParams) {
		for (const [key, value] of Object.entries(asnAbsentState.optionalParams)) {
			if (key === "iReachability" && typeof value === "number") {
				iReachability = value;
				break;
			}
		}
	}
	return {
		u8sContactId: asnAbsentState?.u8sContactId ?? "",
		u8sUsername: asnAbsentState?.u8sUsername ?? "",
		stTimeFrom: validateAndGetISOStringFromDate(asnAbsentState?.stTimeFrom),
		stTimeTo: validateAndGetISOStringFromDate(asnAbsentState?.stTimeTo),
		iAbsentState: asnAbsentState?.iAbsentState ?? 0,
		u8sMessage: asnAbsentState?.u8sMessage ?? "",
		u8sAbsentReason: asnAbsentState?.u8sAbsentReason ?? "",
		iPresenceState: asnAbsentState?.iPresenceState ?? 0,
		// optionalParams
		iReachability
	};
}
