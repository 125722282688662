import {
	AsnClientAppID,
	AsnGetUserTokenArgument,
	AsnGetUserTokenResult,
	AsnLogonError
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Auth";
import { AsnRequestError } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { AsnContact } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { EError, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { contactConverter } from "../../web-shared-components/interfaces/converters/contactConverter";
import { IContactContainer } from "../../web-shared-components/interfaces/interfaces";
import { getUserStaticRights } from "../../web-shared-components/interfaces/IUserStaticRights";
import {
	theClientPersistenceManager,
	theContactManager,
	theCtiManager,
	theJournalManager,
	theMeManager
} from "../globals";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { b64EncodeUnicode, generateGUID } from "../lib/common";
import { EAsnLogonErrorEnumEX } from "../lib/ErrorInfoHelper";
import SessionStorageHelper from "../lib/SessionStorageHelper";
import UCCHelper from "../lib/UCCHelper";
import { getState } from "../zustand/store";
import { SocketTransport } from "./SocketTransport";

/**
 * Interface for the created sessions, stored into an array.
 */
interface IStoredSession {
	sessionID: string;
	ucserverUri: string;
}

// Result of the get UC Proxy request
interface IGetUCWS {
	redirect: string;
}

// Result of the get UCServer version
interface IGetUCSToken {
	ucserverprotocolversion: string;
}

// Result of the get UCServer version
export interface IUCServerInfo {
	ucserverinterfaceversion: string;
	ucserverprotocolversion: string;
	ucserverversion: string;
}

// Result of the create UCServer session
interface ICreateUCSSession {
	sessionid: string;
	ownContact?: AsnContact;
}

// Simplified interface to handle the messages received through the websocket
export interface IUCClientHandler {
	on_WebSocketClosed(): void;
}

export interface ILoginParameters {
	UCSid?: string;
	UCSUri?: string;
	token?: string;
	useEntraIdAuth?: boolean;
	username?: string;
	password?: string;
	keepMeLoggedIn?: boolean;
}

/**
 * Private client for UCServer.
 * It takes care of doing all the stuff needed to create a session against UCServer.
 *
 */
export class UCClient extends BaseSingleton implements IUCClientHandler {
	// Instance of this class to use as singleton.
	private static instance: UCClient;
	// The UC Connect Controller URL
	public UCControllerURL = "https://uccontroller.ucconnect.de";
	private ucServerURI: string | undefined;
	private ucsid: string | undefined;
	private token: string | undefined;
	private socket: SocketTransport;
	public sessionID?: string;
	private previousSessions: IStoredSession[] = [];
	private ownContact?: AsnContact;
	private isConnecting = false;
	// Take track if the connection has been lost once. Needed to resubscribe to the presences after a reconnection.
	private connectionWasLost = false;
	private reconnectingInterval: NodeJS.Timeout | string | number | undefined;

	/**
	 * Constructs UcsPrivateClient.
	 * Method is private as we follow the Singleton Approach using getInstance
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);

		this.socket = SocketTransport.getInstance();
		this.socket.setUCClientHandler(this);
	}

	/**
	 * Gets instance of UCClient to use as singleton.
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): UCClient {
		if (!UCClient.instance) UCClient.instance = new UCClient(attributes);

		return UCClient.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "UCClient"
		};
	}

	/**
	 * Handling the web socket closed event.
	 * Start a reconnecting trial on intervals.
	 */
	public on_WebSocketClosed() {
		this.connectionWasLost = true;
		if (this.reconnectingInterval) clearInterval(this.reconnectingInterval);

		this.reconnectingInterval = setInterval(this.reconnectStandAlone.bind(this), 8000);
	}

	/**
	 * Clear the reconnecting related parameters
	 */
	private clearReconnecting() {
		if (this.reconnectingInterval) clearInterval(this.reconnectingInterval);
	}

	/**
	 * Reconnect logic for stand-alone application
	 */
	private async reconnectStandAlone() {
		// Without a stored token it cannot try to reconnect.
		// We don't store any password.
		if (!this.token) return;

		const parameters: ILoginParameters = {
			UCSid: this.ucsid,
			UCSUri: this.ucServerURI,
			token: this.token
		};

		await this.connectStandAlone(parameters);
	}

	/**
	 * get the user token
	 * @returns the token or an error as a number
	 */
	private async getUserToken(): Promise<string | number> {
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) resolve(EAsnLogonErrorEnumEX.exUnexpectedError);
				else {
					const res = result as unknown as AsnGetUserTokenResult;
					resolve(res.sToken);
				}
			};
			const argument = new AsnGetUserTokenArgument({ iType: 1 });
			this.socket.send("asnGetUserToken", argument, callBack);
		});
	}

	/**
	 * Connect to UCserver via websocket.
	 * Logic for the StandAlone application.
	 * @param parameters - the ILoginParameters object containing all the info needed
	 * @param loginParameters
	 */
	public async connectStandAlone(loginParameters: ILoginParameters): Promise<string | number | undefined> {
		if (this.isConnecting) return;

		this.isConnecting = true;

		// this is the ucsid used to perform the login with entraid
		// since we are losing it after the redirection, it's stored in the session storage
		// the logic for authorization is done according to it
		const ucsIdForEntraId = SessionStorageHelper.getEntraIdLoginUCSID();
		// same if we used a local server that supports entraid
		const ucsURIForEntraId = SessionStorageHelper.getEntraIdLoginUCServerURI();
		// however, this should only be used if the useEntraIdAuth is set to true (logic checked in AppPWA)
		if (loginParameters.useEntraIdAuth) {
			if (ucsIdForEntraId) loginParameters.UCSid = ucsIdForEntraId;
			// Store in the localStorage to be able to recognize the login method after closing the tab
			// LocalStorageHelper.setEntraIdLoginUCSID(ucsIdForEntraId);
			else if (ucsURIForEntraId) loginParameters.UCSUri = ucsURIForEntraId;
			// LocalStorageHelper.setEntraIdLoginUCServerURI(ucsURIForEntraId);
		}
		// Update the class attributes with the correct values
		if (loginParameters.UCSid) this.ucsid = loginParameters.UCSid;
		if (loginParameters.UCSUri) this.ucServerURI = loginParameters.UCSUri;

		try {
			const startResult = await this.start(loginParameters);
			if (typeof startResult === "number") {
				this.isConnecting = false;

				return startResult;
			}

			return this.token;
		} catch (e) {
			// store.dispatch(setBanner("noServerConnection"));
			this.isConnecting = false;
			// In case the server is not responding since the beginning, let's start the reconnecting interval.
			// Otherwise, the interval is set on web socket closed event.
			// this.startReconnecting();
			return EAsnLogonErrorEnumEX.exUnexpectedError;
		}
	}

	/**
	 * Starts the client:
	 * 1. creates a session in UCServer;
	 * 2. connects a WebSocket to it, using the returned sessionid;
	 * 3. sets the client capabilities;
	 * @param token - a string containing the token
	 * @param loginparameters
	 * @returns - a promise with errors or true if no errors
	 */
	private async start(loginparameters: ILoginParameters): Promise<boolean | number> {
		const sessionID = await this.createSession(loginparameters);
		if (typeof sessionID === "number") return sessionID;

		if (typeof sessionID === "string") {
			this.sessionID = sessionID;
			try {
				await this.socket.connect(sessionID);
			} catch (e) {
				return e as number;
			}

			const tokenResult = await this.getUserToken();
			if (typeof tokenResult === "number") return tokenResult;
			this.token = tokenResult;
			getState().setMySelfToken(tokenResult);

			this.clearReconnecting();
			this.isConnecting = false;
			// Setup subscriptions
			if (this.connectionWasLost) theContactManager.initAndResubscribe();

			await theJournalManager.journalSubscribeEvents();
			await theCtiManager.ctiPhoneLineSubscribeEvents();
			await theClientPersistenceManager.init();
			await theMeManager.init();

			getState().setMySelfIsLogged(true);
			if (this.ucsid) getState().setMySelfUCSID(this.ucsid);
			else if (this.ucServerURI) getState().setMySelfUCServerURI(this.ucServerURI);

			return true;
		} else {
			this.isConnecting = false;
			getState().setMySelfToken(undefined);
			getState().setMySelfIsLogged(false);

			return EAsnLogonErrorEnumEX.exInvalidSessionID;
		}
	}

	/**
	 * Stops the client.
	 * Destroys the session in UCServer;
	 * @returns - a promise with errors or true if no errors
	 */
	private async stop(): Promise<boolean | Error> {
		if ((await this.destroySession()) instanceof Error) return new Error("Unable to set destroy session in UCServer");

		return true;
	}

	/**
	 * Gets UCServer version from local server or from UCController.
	 * @returns - a promise with a string containing the server info, or an error
	 */
	public async getUCServerVersion(): Promise<IUCServerInfo | AsnRequestError> {
		let url;
		if (this.ucsid) {
			url =
				this.UCControllerURL +
				"/controller/client/ucserverversion?ucsid=" +
				this.ucsid +
				"&needswcs=1&nocache=" +
				Date.now();
		} else if (this.ucServerURI) url = this.ucServerURI + "/ws/client/ucserverversion?nocache=" + Date.now();

		if (!url) {
			this.logger.error(
				"No ucsid or ucserver url provided",
				"getUCServerVersion",
				this,
				{},
				EError.InvalidUCControllerURL
			);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "No ucsid or ucserver url provided" });
		}
		try {
			const getUcwebUrlResponse = await fetch(url);
			const ucwebUrlData = (await getUcwebUrlResponse.json()) as IUCServerInfo;
			if (ucwebUrlData && ucwebUrlData.ucserverprotocolversion) return ucwebUrlData;
			else {
				this.logger.error(
					"Invalid ucserverprotocolversion",
					"getUCServerVersion",
					this,
					{},
					EError.InvalidProtocolVersion
				);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucserverprotocolversion" });
			}
		} catch (e) {
			this.logger.error(
				"Invalid ucserverprotocolversion",
				"getUCServerVersion",
				this,
				{
					e
				},
				EError.InvalidProtocolVersion
			);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucserverprotocolversion" });
		}
	}

	/**
	 * get the client version
	 * @returns the clientVersion as a string
	 */
	public getClientVersion(): string | undefined {
		const clientVersion = getState().clientVersion;
		return clientVersion;
	}

	/**
	 * get the client device  ID
	 * @returns the clientDeviceID as a string
	 */
	public getClientDeviceID(): string {
		const clientDeviceId = generateGUID();
		return clientDeviceId;
	}

	/**
	 * get the client Device Name
	 * @returns the clientDeviceName as a string
	 */
	public getClientDeviceName(): string | undefined {
		const applicationName = getState().applicationName;
		const clientDeviceName = applicationName + "-" + this.getClientDeviceID();
		return clientDeviceName;
	}

	/**
	 * Creates a session on UCServer.
	 * @param loginParameters - token / or username and pw
	 * @returns - a promise containing the session id or an error
	 */
	private async createSession(loginParameters: ILoginParameters): Promise<string | number> {
		const ClientDeviceId = this.getClientDeviceID();
		const ClientDeviceName = this.getClientDeviceName();
		const ClientVersion = this.getClientVersion();

		let authorizationString = "";
		if (loginParameters.token) {
			if (loginParameters.useEntraIdAuth) authorizationString = "Bearer " + loginParameters.token;
			else authorizationString = "JWT " + loginParameters.token;
		} else if (loginParameters.username) {
			const loginBase64 = b64EncodeUnicode(loginParameters.username + ":" + (loginParameters.password ?? ""));
			authorizationString = "Basic " + loginBase64;
		}

		const createSessionPayload = {
			negotiate: {
				iClientProtocolVersion: 70,
				optionalParams: {
					waitForReconnectTimeInSec: 5,
					SoftphoneClient: 1,
					ProvideAVLine: 1,
					ClientDeviceName,
					ClientDeviceId
				}
			},
			logon: {
				u8sVersion: ClientVersion
			}
		};

		const body = JSON.stringify(createSessionPayload);

		// In case the ucserver uri is not defined and we use ucsid, let's ask ucconnect
		// ucsid is set ONLY when the login form shows that field, which is mutally excluding the local server,
		// so we want to ask the url on each createSession
		if (this.ucsid) {
			const ucserverUri = await UCCHelper.getUriFromUCC(this.ucsid, new URL(this.UCControllerURL));
			if (typeof ucserverUri === "number") return ucserverUri;
			this.ucServerURI = ucserverUri;
		}

		if (!this.ucServerURI) return EAsnLogonErrorEnumEX.exInvalidUCServerURI;

		this.socket.setUCCEndpoint(this.ucServerURI);

		const TeamsAppId = AsnClientAppID.eCLIENTAPPIDMSTEAMSAPP;

		const url = this.ucServerURI + `/ws/client/createsession?clientappid=${TeamsAppId}`;

		const headers: Record<string, string> = {
			Authorization: authorizationString,
			// "x-ucsid": ucsid,
			"x-epid": generateGUID(),
			"x-no401": "1",
			"Content-Type": "application/json"
		};

		if (this.ucsid) headers["x-ucsid"] = this.ucsid;

		const options = {
			method: "POST",
			headers,
			body
		};

		try {
			// Before creating a new session clean the potential existing ones
			await this.killPreviousSessions();

			const sessionResponse = await fetch(url, options);
			const response: unknown = await sessionResponse.json();

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (response.error) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const err = response.error as AsnLogonError;
				this.logger.error(err.u8sErrorString || "", "createSession", this, { err }, EError.SessionNoID);
				getState().setMySelfToken(undefined);
				getState().setMySelfIsLogged(false);
				return err.iErrorDetail;
			} else {
				const session = response as ICreateUCSSession;
				const lastContact = localStorage.getItem("last-logged-user-id");
				if (session && session.sessionid && session.ownContact) {
					if (lastContact && lastContact !== session.ownContact.u8sContactId) {
						// The logged-in user is different from the previous one:
						// - Clean up store
						getState().reset();
						// - Clean up the browser cache API
						// await theFileTransferManager.deleteCaches();
						// - Set the new user contact id in the localStorage for the next check
						localStorage.setItem("last-logged-user-id", session.ownContact.u8sContactId);
					} else localStorage.setItem("last-logged-user-id", session.ownContact.u8sContactId);
					this.logger.info("Session created", "createSession", this, {
						ucsid: this.ucsid,
						// logging the whole URL object fired an exception on IDB
						ucControllerUrl: this.UCControllerURL,
						ucServerURI: this.ucServerURI,
						clientID: ClientDeviceId
					});

					this.ownContact = session.ownContact;
					console.log("Own contact", this.ownContact);

					const convertedOwnContact = await contactConverter(session.ownContact);
					const myOwnContact: IContactContainer = {
						contactID: this.ownContact.u8sContactId,
						isMyOwnContact: true,
						asnNetDatabaseContact: this.ownContact.asnRemoteContact,
						userStaticRights: getUserStaticRights(this.ownContact.iStaticRights, convertedOwnContact.iStaticRights2)
					};
					getState().setOwnContact(myOwnContact);

					getState().setOwnContactId(this.ownContact.u8sContactId);
					getState().setOwnUserPropertyBag(this.ownContact.asnUserPropertyBag);
					getState().setContactsDetails([
						{ ...this.ownContact.asnRemoteContact, u8sEntryID: this.ownContact.u8sContactId }
					]);

					// Keep track of the created sessions and store them into an array.
					this.previousSessions.push({
						sessionID: session.sessionid,
						ucserverUri: this.ucServerURI
					});
					return session.sessionid;
				} else return EAsnLogonErrorEnumEX.exInvalidSessionID;
			}
		} catch (e) {
			this.logger.error("Error creating session", "createSession", this, { e }, EAsnLogonErrorEnumEX.exUnexpectedError);
			return e as number;
		}
	}

	/**
	 * Destroys the UCServer session.
	 * @param sessionID - optional session id to destroy. In case is not set it destroys the current one.
	 * @param ucserverUri - optional path where to call the destroysession method. In case is not set it uses the current stored one.
	 * @returns - a promise true if success, false in case of no session available or an error if fails.
	 */
	private async destroySession(sessionID?: string, ucserverUri?: string): Promise<boolean | Error> {
		if (!this.sessionID || !sessionID) return false;

		const url =
			(ucserverUri || this.ucServerURI) + "/ws/client/destroysession?ucsessionid=" + (sessionID || this.sessionID);
		const options = {
			method: "GET"
		};
		try {
			await fetch(url, options);
			// const destroySessionResponse = await fetch(url, options);
			// const destroySessionData = await destroySessionResponse.json();
			// console.log(destroySessionData);
			this.ownContact = undefined;
			return true;
		} catch (e) {
			this.logger.error("Error destroying session", "createSession", this, { e }, EError.SessionDestroyGeneric);
			return e as Error;
		}
	}

	/**
	 * Kills the previous created sessions.
	 */
	private async killPreviousSessions() {
		await Promise.all(
			this.previousSessions.map(async (session: IStoredSession) => {
				await this.destroySession(session.sessionID, session.ucserverUri);
			})
		);
		this.previousSessions = [];
	}

	/**
	 * Get my own contact as resulted by the session creation
	 * @returns - my own contact or undefined if not set
	 */
	public getOwnContact(): AsnContact | undefined {
		return this.ownContact;
	}
}
