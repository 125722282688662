// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_CTI_Converter.ts
 * "UC-Server-Access-Protocol-CTI" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_CTI from "./ENetUC_CTI";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_CTI_Converter";
export const MODULE_LASTCHANGE = "2024-06-19T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 1718755200;
export const MODULE_VERSION = "8.1718755200.0";

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallData_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallData, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallData & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallData");

		const t = {} as ENetUC_CTI.AsnCtiCallData & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiCallData";
		TSConverter.fillJSONParam(s, t, "key", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "value", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiCallData"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallData | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallData");

		let t: ENetUC_CTI.AsnCtiCallData | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallData>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiCallData["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "key", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "value", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiCallData"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallData | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallData";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallData");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "key", "string", errors, newContext);
		TSConverter.validateParam(s, "value", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.key, name: "key" }));
			t.push(new asn1ts.Utf8String({ value: s.value, name: "value" }));
		}

		if (errors.validateResult(newContext, "AsnCtiCallData"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallData | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallData");

		let t: ENetUC_CTI.AsnCtiCallData | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallData.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiCallData["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "key", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "value", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiCallData"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineInfo_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineInfo, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineInfo & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineInfo");

		const t = {} as ENetUC_CTI.AsnCtiLineInfo & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineInfo";
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLineOpenStatus", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDescriptor", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineLocationID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLineFeatures", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardFeaturesSERVER", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardFeaturesUSER", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfo"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineInfo | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineInfo");

		let t: ENetUC_CTI.AsnCtiLineInfo | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineInfo>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineInfo["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iLineOpenStatus", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDescriptor", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLineLocationID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iLineFeatures", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardFeaturesSERVER", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardFeaturesUSER", "number", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfo"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineInfo | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineInfo";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineInfo");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iType", "number", errors, newContext);
		TSConverter.validateParam(s, "iLineOpenStatus", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDescriptor", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineLocationID", "string", errors, newContext);
		TSConverter.validateParam(s, "iLineFeatures", "number", errors, newContext);
		TSConverter.validateParam(s, "iForwardFeaturesPBX", "number", errors, newContext);
		TSConverter.validateParam(s, "iForwardFeaturesSERVER", "number", errors, newContext);
		TSConverter.validateParam(s, "iForwardFeaturesUSER", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iType, name: "iType" }));
			t.push(new asn1ts.Integer({ value: s.iLineOpenStatus, name: "iLineOpenStatus" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDescriptor, name: "u8sDescriptor" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumber, name: "u8sPhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineLocationID, name: "u8sLineLocationID" }));
			t.push(new asn1ts.Integer({ value: s.iLineFeatures, name: "iLineFeatures" }));
			t.push(new asn1ts.Integer({ value: s.iForwardFeaturesPBX, name: "iForwardFeaturesPBX" }));
			t.push(new asn1ts.Integer({ value: s.iForwardFeaturesSERVER, name: "iForwardFeaturesSERVER" }));
			t.push(new asn1ts.Integer({ value: s.iForwardFeaturesUSER, name: "iForwardFeaturesUSER" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfo"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineInfo | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineInfo");

		let t: ENetUC_CTI.AsnCtiLineInfo | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineInfo.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineInfo["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLineOpenStatus", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDescriptor", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineLocationID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLineFeatures", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardFeaturesPBX", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardFeaturesSERVER", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardFeaturesUSER", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfo"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRemoteOfficeState_Converter {
	public static toJSON(s: ENetUC_CTI.AsnRemoteOfficeState, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnRemoteOfficeState & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoteOfficeState");

		const t = {} as ENetUC_CTI.AsnRemoteOfficeState & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRemoteOfficeState";
		TSConverter.fillJSONParam(s, t, "u8sController", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iEnabled", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDestination", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRemoteOfficeState"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnRemoteOfficeState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoteOfficeState");

		let t: ENetUC_CTI.AsnRemoteOfficeState | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnRemoteOfficeState>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnRemoteOfficeState["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sController", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iEnabled", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDestination", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRemoteOfficeState"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnRemoteOfficeState | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRemoteOfficeState";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoteOfficeState");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sController", "string", errors, newContext);
		TSConverter.validateParam(s, "iEnabled", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDestination", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sController, name: "u8sController" }));
			t.push(new asn1ts.Integer({ value: s.iEnabled, name: "iEnabled" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDestination, name: "u8sDestination" }));
		}

		if (errors.validateResult(newContext, "AsnRemoteOfficeState"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnRemoteOfficeState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoteOfficeState");

		let t: ENetUC_CTI.AsnRemoteOfficeState | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnRemoteOfficeState.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnRemoteOfficeState["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sController", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iEnabled", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDestination", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRemoteOfficeState"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSnapshotPhoneLineArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSnapshotPhoneLineArgument");

		const t = {} as ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSnapshotPhoneLineArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSnapshotPhoneLineArgument");

		let t: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSnapshotPhoneLineArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSnapshotPhoneLineArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSnapshotPhoneLineArgument");

		let t: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineMonitorStartArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineMonitorStartArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineMonitorStartArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStartArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineMonitorStartArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineMonitorStartArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStartArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStartArgument");

		let t: ENetUC_CTI.AsnCtiLineMonitorStartArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineMonitorStartArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineMonitorStartArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineMonitorStartArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineMonitorStartArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStartArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStartArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStartArgument");

		let t: ENetUC_CTI.AsnCtiLineMonitorStartArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineMonitorStartArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineMonitorStartArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineMonitorStopArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineMonitorStopArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineMonitorStopArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStopArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineMonitorStopArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineMonitorStopArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStopArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStopArgument");

		let t: ENetUC_CTI.AsnCtiLineMonitorStopArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineMonitorStopArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineMonitorStopArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineMonitorStopArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineMonitorStopArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStopArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStopArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStopArgument");

		let t: ENetUC_CTI.AsnCtiLineMonitorStopArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineMonitorStopArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineMonitorStopArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineMonitorStopResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineMonitorStopResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineMonitorStopResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStopResult");

		const t = {} as ENetUC_CTI.AsnCtiLineMonitorStopResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineMonitorStopResult";

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStopResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStopResult");

		let t: ENetUC_CTI.AsnCtiLineMonitorStopResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineMonitorStopResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineMonitorStopResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineMonitorStopResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineMonitorStopResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStopResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStopResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStopResult");

		let t: ENetUC_CTI.AsnCtiLineMonitorStopResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineMonitorStopResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineMonitorStopResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStopResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetDoNotDisturbArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetDoNotDisturbArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetDoNotDisturbArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bDoNotDisturb", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetDoNotDisturbArgument");

		let t: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bDoNotDisturb", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetDoNotDisturbArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetDoNotDisturbArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "bDoNotDisturb", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Boolean({ value: s.bDoNotDisturb, name: "bDoNotDisturb" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetDoNotDisturbArgument");

		let t: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bDoNotDisturb", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetDoNotDisturbResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetDoNotDisturbResult");

		const t = {} as ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetDoNotDisturbResult";

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetDoNotDisturbResult");

		let t: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetDoNotDisturbResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetDoNotDisturbResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetDoNotDisturbResult");

		let t: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetDoNotDisturbResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetRemoteOfficeResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetRemoteOfficeResult");

		const t = {} as ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetRemoteOfficeResult";

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetRemoteOfficeResult");

		let t: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetRemoteOfficeResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetRemoteOfficeResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetRemoteOfficeResult");

		let t: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetNewPasswordArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetNewPasswordArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetNewPasswordArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineSetNewPasswordArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetNewPasswordArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPassword", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetNewPasswordArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetNewPasswordArgument");

		let t: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetNewPasswordArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetNewPasswordArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPassword", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetNewPasswordArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetNewPasswordArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPassword", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPassword, name: "u8sPassword" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetNewPasswordArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetNewPasswordArgument");

		let t: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetNewPasswordArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetNewPasswordArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPassword", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetNewPasswordResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetNewPasswordResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetNewPasswordResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetNewPasswordResult");

		const t = {} as ENetUC_CTI.AsnCtiLineSetNewPasswordResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetNewPasswordResult";

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetNewPasswordResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetNewPasswordResult");

		let t: ENetUC_CTI.AsnCtiLineSetNewPasswordResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetNewPasswordResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetNewPasswordResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetNewPasswordResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetNewPasswordResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetNewPasswordResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetNewPasswordResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetNewPasswordResult");

		let t: ENetUC_CTI.AsnCtiLineSetNewPasswordResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetNewPasswordResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetNewPasswordResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetNewPasswordResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiQueryPBXLineArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiQueryPBXLineArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiQueryPBXLineArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiQueryPBXLineArgument");

		const t = {} as ENetUC_CTI.AsnCtiQueryPBXLineArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiQueryPBXLineArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiQueryPBXLineArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiQueryPBXLineArgument");

		let t: ENetUC_CTI.AsnCtiQueryPBXLineArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiQueryPBXLineArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiQueryPBXLineArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiQueryPBXLineArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiQueryPBXLineArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiQueryPBXLineArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiQueryPBXLineArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiQueryPBXLineArgument");

		let t: ENetUC_CTI.AsnCtiQueryPBXLineArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiQueryPBXLineArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiQueryPBXLineArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiQueryPBXLineResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiQueryPBXLineResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiQueryPBXLineResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiQueryPBXLineResult");

		const t = {} as ENetUC_CTI.AsnCtiQueryPBXLineResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiQueryPBXLineResult";
		const _seqLinesFound = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.seqLinesFound, errors, newContext, "seqLinesFound");
		if (_seqLinesFound)
			t.seqLinesFound = _seqLinesFound;

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiQueryPBXLineResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiQueryPBXLineResult");

		let t: ENetUC_CTI.AsnCtiQueryPBXLineResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiQueryPBXLineResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiQueryPBXLineResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqlinesfound = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.seqLinesFound, errors, newContext, "seqLinesFound", false);
			if (_seqlinesfound)
				t.seqLinesFound = _seqlinesfound;
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiQueryPBXLineResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiQueryPBXLineResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiQueryPBXLineResult");

		// [Print_BER_EncoderSeqDefCode]
		const _seqLinesFound = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.seqLinesFound, errors, newContext, "seqLinesFound");
		if (!errors.hasNewErrors()) {
			if (_seqLinesFound)
				t.push(_seqLinesFound);
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiQueryPBXLineResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiQueryPBXLineResult");

		let t: ENetUC_CTI.AsnCtiQueryPBXLineResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiQueryPBXLineResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiQueryPBXLineResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqlinesfound = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLinesFound"), errors, newContext, "seqLinesFound");
			if (_seqlinesfound)
				t.seqLinesFound = _seqlinesfound;
		}

		if (errors.validateResult(newContext, "AsnCtiQueryPBXLineResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineDoNotDisturbChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineDoNotDisturbChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineDoNotDisturbChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineDoNotDisturbChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineDoNotDisturbChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineDoNotDisturbChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineDoNotDisturbChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineDoNotDisturbChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iDoNotDisturb", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.iDoNotDisturb, name: "iDoNotDisturb" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineDoNotDisturbChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineDoNotDisturbChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDoNotDisturb", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineDoNotDisturbChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineInvalidPasswordChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineInvalidPasswordChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineInvalidPasswordChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "invalidPasswordState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInvalidPasswordChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineInvalidPasswordChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "invalidPasswordState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInvalidPasswordChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineInvalidPasswordChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineInvalidPasswordChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "invalidPasswordState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sUserName", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.invalidPasswordState, name: "invalidPasswordState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUserName, name: "u8sUserName" }));
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInvalidPasswordChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineInvalidPasswordChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "invalidPasswordState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUserName", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInvalidPasswordChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyMonitorStoppedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyMonitorStoppedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyMonitorStoppedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiNotifyMonitorStoppedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyMonitorStoppedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMonitorStoppedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyMonitorStoppedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyMonitorStoppedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMonitorStoppedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyMonitorStoppedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMonitorStoppedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineSortOrderChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineSortOrderChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineSortOrderChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		const _u8sLineURISortOrder = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sLineURISortOrder, errors, newContext, "u8sLineURISortOrder");
		if (_u8sLineURISortOrder)
			t.u8sLineURISortOrder = _u8sLineURISortOrder;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineSortOrderChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineSortOrderChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			const _u8slineurisortorder = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sLineURISortOrder, errors, newContext, "u8sLineURISortOrder", false);
			if (_u8slineurisortorder)
				t.u8sLineURISortOrder = _u8slineurisortorder;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineSortOrderChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineSortOrderChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineSortOrderChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		const _u8sLineURISortOrder = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sLineURISortOrder, errors, newContext, "u8sLineURISortOrder");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			if (_u8sLineURISortOrder)
				t.push(_u8sLineURISortOrder);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineSortOrderChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineSortOrderChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			const _u8slineurisortorder = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sLineURISortOrder"), errors, newContext, "u8sLineURISortOrder");
			if (_u8slineurisortorder)
				t.u8sLineURISortOrder = _u8slineurisortorder;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineSortOrderChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineForwardingChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineForwardingChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineForwardingChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
		if (_seqLineForwards)
			t.seqLineForwards = _seqLineForwards;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineForwardingChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineForwardingChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", false);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineForwardingChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineForwardingChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineForwardingChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_seqLineForwards)
				t.push(_seqLineForwards);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineForwardingChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineForwardingChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards");
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineForwardingChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyMakeCallResultArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyMakeCallResultArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyMakeCallResultArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "success", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiNotifyMakeCallResultArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyMakeCallResultArgument");

		let t: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "success", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMakeCallResultArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyMakeCallResultArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyMakeCallResultArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "success", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.success, name: "success" }));
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMakeCallResultArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyMakeCallResultArgument");

		let t: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "success", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyMakeCallResultArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallFeature_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallFeature, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallFeature & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallFeature");

		const t = {} as ENetUC_CTI.AsnCtiCallFeature & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiCallFeature";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiCallFeature"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallFeature | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallFeature");

		let t: ENetUC_CTI.AsnCtiCallFeature | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallFeature>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiCallFeature["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeature"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallFeature | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallFeature";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallFeature");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "iFeatures", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.iFeatures, name: "iFeatures" }));
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeature"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallFeature | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallFeature");

		let t: ENetUC_CTI.AsnCtiCallFeature | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallFeature.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiCallFeature["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFeatures", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeature"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineProjectSettingsChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineProjectSettingsChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineProjectSettingsChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, newContext);
		const _projectList = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toJSON(s.projectList, errors, newContext, "projectList");
		if (_projectList)
			t.projectList = _projectList;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineProjectSettingsChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineProjectSettingsChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, newContext, false);
			const _projectlist = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromJSON(s.projectList, errors, newContext, "projectList", false);
			if (_projectlist)
				t.projectList = _projectlist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineProjectSettingsChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineProjectSettingsChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineProjectSettingsChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLocationID", "string", errors, newContext);
		const _projectList = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toBER(s.projectList, errors, newContext, "projectList");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocationID, name: "u8sLocationID" }));
			if (_projectList)
				t.push(_projectList);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineProjectSettingsChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineProjectSettingsChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocationID", "Utf8String", errors, newContext);
			const _projectlist = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "projectList"), errors, newContext, "projectList");
			if (_projectlist)
				t.projectList = _projectlist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineProjectSettingsChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiMakeCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiMakeCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiMakeCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMakeCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiMakeCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiMakeCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMakeCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMakeCallArgument");

		let t: ENetUC_CTI.AsnCtiMakeCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiMakeCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiMakeCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiMakeCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiMakeCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMakeCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sPhoneNumberFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumberTo", "string", errors, newContext);
		TSConverter.validateParam(s, "iNumberFormat", "number", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberFrom, name: "u8sPhoneNumberFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberTo, name: "u8sPhoneNumberTo" }));
			t.push(new asn1ts.Integer({ value: s.iNumberFormat, name: "iNumberFormat" }));
			if (_newCallParams)
				t.push(_newCallParams);
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMakeCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMakeCallArgument");

		let t: ENetUC_CTI.AsnCtiMakeCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiMakeCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiMakeCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iNumberFormat", "Integer", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiMakeCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiMakeCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiMakeCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMakeCallResult");

		const t = {} as ENetUC_CTI.AsnCtiMakeCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiMakeCallResult";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMakeCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMakeCallResult");

		let t: ENetUC_CTI.AsnCtiMakeCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiMakeCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiMakeCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiMakeCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiMakeCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMakeCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMakeCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMakeCallResult");

		let t: ENetUC_CTI.AsnCtiMakeCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiMakeCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiMakeCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiMakeCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiDropCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiDropCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiDropCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDropCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiDropCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiDropCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiDropCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDropCallArgument");

		let t: ENetUC_CTI.AsnCtiDropCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiDropCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiDropCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiDropCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiDropCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDropCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDropCallArgument");

		let t: ENetUC_CTI.AsnCtiDropCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiDropCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiDropCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiDropCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiDropCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiDropCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDropCallResult");

		const t = {} as ENetUC_CTI.AsnCtiDropCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiDropCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiDropCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDropCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDropCallResult");

		let t: ENetUC_CTI.AsnCtiDropCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiDropCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiDropCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiDropCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiDropCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDropCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDropCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDropCallResult");

		let t: ENetUC_CTI.AsnCtiDropCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiDropCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiDropCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiDropCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineDropCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineDropCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineDropCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineDropCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineDropCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineDropCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineDescriptor", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineDropCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineDropCallArgument");

		let t: ENetUC_CTI.AsnCtiLineDropCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineDropCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineDropCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineDescriptor", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineDropCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineDropCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineDropCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineDescriptor", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineDescriptor, name: "u8sLineDescriptor" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineDropCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineDropCallArgument");

		let t: ENetUC_CTI.AsnCtiLineDropCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineDropCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineDropCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineDescriptor", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineDropCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineDropCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineDropCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineDropCallResult");

		const t = {} as ENetUC_CTI.AsnCtiLineDropCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineDropCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiLineDropCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineDropCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineDropCallResult");

		let t: ENetUC_CTI.AsnCtiLineDropCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineDropCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineDropCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineDropCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineDropCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineDropCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineDropCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineDropCallResult");

		let t: ENetUC_CTI.AsnCtiLineDropCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineDropCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineDropCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiLineDropCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiAnswerCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiAnswerCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiAnswerCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiAnswerCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiAnswerCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiAnswerCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiAnswerCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiAnswerCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiAnswerCallArgument");

		let t: ENetUC_CTI.AsnCtiAnswerCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiAnswerCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiAnswerCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiAnswerCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiAnswerCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiAnswerCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiAnswerCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiAnswerCallArgument");

		let t: ENetUC_CTI.AsnCtiAnswerCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiAnswerCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiAnswerCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiAnswerCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiAnswerCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiAnswerCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiAnswerCallResult");

		const t = {} as ENetUC_CTI.AsnCtiAnswerCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiAnswerCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiAnswerCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiAnswerCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiAnswerCallResult");

		let t: ENetUC_CTI.AsnCtiAnswerCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiAnswerCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiAnswerCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiAnswerCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiAnswerCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiAnswerCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiAnswerCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiAnswerCallResult");

		let t: ENetUC_CTI.AsnCtiAnswerCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiAnswerCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiAnswerCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiAnswerCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiRedirectCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiRedirectCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiRedirectCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRedirectCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiRedirectCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiRedirectCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiRedirectCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRedirectCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRedirectCallArgument");

		let t: ENetUC_CTI.AsnCtiRedirectCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiRedirectCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiRedirectCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiRedirectCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiRedirectCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRedirectCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iNumberFormat", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumber, name: "u8sPhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.iNumberFormat, name: "iNumberFormat" }));
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRedirectCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRedirectCallArgument");

		let t: ENetUC_CTI.AsnCtiRedirectCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiRedirectCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiRedirectCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iNumberFormat", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiRedirectCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiRedirectCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiRedirectCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRedirectCallResult");

		const t = {} as ENetUC_CTI.AsnCtiRedirectCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiRedirectCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiRedirectCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRedirectCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRedirectCallResult");

		let t: ENetUC_CTI.AsnCtiRedirectCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiRedirectCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiRedirectCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiRedirectCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiRedirectCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRedirectCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRedirectCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRedirectCallResult");

		let t: ENetUC_CTI.AsnCtiRedirectCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiRedirectCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiRedirectCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiRedirectCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiPickupCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiPickupCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiPickupCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiPickupCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiPickupCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiPickupCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiPickupCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiPickupCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiPickupCallArgument");

		let t: ENetUC_CTI.AsnCtiPickupCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiPickupCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiPickupCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiPickupCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiPickupCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiPickupCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iNumberFormat", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumber, name: "u8sPhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.iNumberFormat, name: "iNumberFormat" }));
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiPickupCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiPickupCallArgument");

		let t: ENetUC_CTI.AsnCtiPickupCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiPickupCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiPickupCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iNumberFormat", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiPickupCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiPickupCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiPickupCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiPickupCallResult");

		const t = {} as ENetUC_CTI.AsnCtiPickupCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiPickupCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiPickupCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiPickupCallResult");

		let t: ENetUC_CTI.AsnCtiPickupCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiPickupCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiPickupCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiPickupCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiPickupCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiPickupCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiPickupCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiPickupCallResult");

		let t: ENetUC_CTI.AsnCtiPickupCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiPickupCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiPickupCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiPickupCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiStartCallRecordingArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiStartCallRecordingArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiStartCallRecordingArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStartCallRecordingArgument");

		const t = {} as ENetUC_CTI.AsnCtiStartCallRecordingArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiStartCallRecordingArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStartCallRecordingArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStartCallRecordingArgument");

		let t: ENetUC_CTI.AsnCtiStartCallRecordingArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiStartCallRecordingArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiStartCallRecordingArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiStartCallRecordingArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiStartCallRecordingArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStartCallRecordingArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStartCallRecordingArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStartCallRecordingArgument");

		let t: ENetUC_CTI.AsnCtiStartCallRecordingArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiStartCallRecordingArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiStartCallRecordingArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiStartCallRecordingResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiStartCallRecordingResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiStartCallRecordingResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStartCallRecordingResult");

		const t = {} as ENetUC_CTI.AsnCtiStartCallRecordingResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiStartCallRecordingResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStartCallRecordingResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStartCallRecordingResult");

		let t: ENetUC_CTI.AsnCtiStartCallRecordingResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiStartCallRecordingResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiStartCallRecordingResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiStartCallRecordingResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiStartCallRecordingResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStartCallRecordingResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStartCallRecordingResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStartCallRecordingResult");

		let t: ENetUC_CTI.AsnCtiStartCallRecordingResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiStartCallRecordingResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiStartCallRecordingResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiStartCallRecordingResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiStopCallRecordingArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiStopCallRecordingArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiStopCallRecordingArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStopCallRecordingArgument");

		const t = {} as ENetUC_CTI.AsnCtiStopCallRecordingArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiStopCallRecordingArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStopCallRecordingArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStopCallRecordingArgument");

		let t: ENetUC_CTI.AsnCtiStopCallRecordingArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiStopCallRecordingArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiStopCallRecordingArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiStopCallRecordingArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiStopCallRecordingArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStopCallRecordingArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStopCallRecordingArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStopCallRecordingArgument");

		let t: ENetUC_CTI.AsnCtiStopCallRecordingArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiStopCallRecordingArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiStopCallRecordingArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiStopCallRecordingResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiStopCallRecordingResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiStopCallRecordingResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStopCallRecordingResult");

		const t = {} as ENetUC_CTI.AsnCtiStopCallRecordingResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiStopCallRecordingResult";

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStopCallRecordingResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStopCallRecordingResult");

		let t: ENetUC_CTI.AsnCtiStopCallRecordingResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiStopCallRecordingResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiStopCallRecordingResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiStopCallRecordingResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiStopCallRecordingResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiStopCallRecordingResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiStopCallRecordingResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiStopCallRecordingResult");

		let t: ENetUC_CTI.AsnCtiStopCallRecordingResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiStopCallRecordingResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiStopCallRecordingResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCtiStopCallRecordingResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetCallDataResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetCallDataResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetCallDataResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetCallDataResult");

		const t = {} as ENetUC_CTI.AsnCtiSetCallDataResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetCallDataResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSetCallDataResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetCallDataResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetCallDataResult");

		let t: ENetUC_CTI.AsnCtiSetCallDataResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetCallDataResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetCallDataResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetCallDataResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetCallDataResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetCallDataResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetCallDataResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetCallDataResult");

		let t: ENetUC_CTI.AsnCtiSetCallDataResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetCallDataResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetCallDataResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiMergeCallsArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiMergeCallsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiMergeCallsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMergeCallsArgument");

		const t = {} as ENetUC_CTI.AsnCtiMergeCallsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiMergeCallsArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID1", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID2", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiMergeCallsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMergeCallsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMergeCallsArgument");

		let t: ENetUC_CTI.AsnCtiMergeCallsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiMergeCallsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiMergeCallsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID1", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID2", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiMergeCallsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiMergeCallsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMergeCallsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID1", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID2", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID1, name: "u8sConnectionID1" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID2, name: "u8sConnectionID2" }));
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMergeCallsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMergeCallsArgument");

		let t: ENetUC_CTI.AsnCtiMergeCallsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiMergeCallsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiMergeCallsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID1", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID2", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiMergeCallsResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiMergeCallsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiMergeCallsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMergeCallsResult");

		const t = {} as ENetUC_CTI.AsnCtiMergeCallsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiMergeCallsResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiMergeCallsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMergeCallsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMergeCallsResult");

		let t: ENetUC_CTI.AsnCtiMergeCallsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiMergeCallsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiMergeCallsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiMergeCallsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiMergeCallsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiMergeCallsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiMergeCallsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiMergeCallsResult");

		let t: ENetUC_CTI.AsnCtiMergeCallsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiMergeCallsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiMergeCallsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiMergeCallsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSwapHoldArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSwapHoldArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSwapHoldArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSwapHoldArgument");

		const t = {} as ENetUC_CTI.AsnCtiSwapHoldArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSwapHoldArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSwapHoldArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSwapHoldArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSwapHoldArgument");

		let t: ENetUC_CTI.AsnCtiSwapHoldArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSwapHoldArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSwapHoldArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSwapHoldArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSwapHoldArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSwapHoldArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSwapHoldArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSwapHoldArgument");

		let t: ENetUC_CTI.AsnCtiSwapHoldArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSwapHoldArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSwapHoldArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSwapHoldResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSwapHoldResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSwapHoldResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSwapHoldResult");

		const t = {} as ENetUC_CTI.AsnCtiSwapHoldResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSwapHoldResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSwapHoldResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSwapHoldResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSwapHoldResult");

		let t: ENetUC_CTI.AsnCtiSwapHoldResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSwapHoldResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSwapHoldResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSwapHoldResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSwapHoldResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSwapHoldResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSwapHoldResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSwapHoldResult");

		let t: ENetUC_CTI.AsnCtiSwapHoldResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSwapHoldResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSwapHoldResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSwapHoldResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiHoldArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiHoldArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiHoldArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiHoldArgument");

		const t = {} as ENetUC_CTI.AsnCtiHoldArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiHoldArgument";
		TSConverter.fillJSONParam(s, t, "bHold", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiHoldArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiHoldArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiHoldArgument");

		let t: ENetUC_CTI.AsnCtiHoldArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiHoldArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiHoldArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bHold", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiHoldArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiHoldArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiHoldArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiHoldArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bHold", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bHold, name: "bHold" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiHoldArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiHoldArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiHoldArgument");

		let t: ENetUC_CTI.AsnCtiHoldArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiHoldArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiHoldArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bHold", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiHoldArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiHoldResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiHoldResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiHoldResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiHoldResult");

		const t = {} as ENetUC_CTI.AsnCtiHoldResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiHoldResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiHoldResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiHoldResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiHoldResult");

		let t: ENetUC_CTI.AsnCtiHoldResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiHoldResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiHoldResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiHoldResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiHoldResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiHoldResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiHoldResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiHoldResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiHoldResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiHoldResult");

		let t: ENetUC_CTI.AsnCtiHoldResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiHoldResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiHoldResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiHoldResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetForwardArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetForwardArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetForwardArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetForwardArgument");

		const t = {} as ENetUC_CTI.AsnCtiSetForwardArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetForwardArgument";
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetForwardArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetForwardArgument");

		let t: ENetUC_CTI.AsnCtiSetForwardArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetForwardArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetForwardArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetForwardArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetForwardArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetForwardArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sPhoneNumberFrom", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumberTo", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberFrom, name: "u8sPhoneNumberFrom" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberTo, name: "u8sPhoneNumberTo" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetForwardArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetForwardArgument");

		let t: ENetUC_CTI.AsnCtiSetForwardArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetForwardArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetForwardArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberFrom", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberTo", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetForwardResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetForwardResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetForwardResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetForwardResult");

		const t = {} as ENetUC_CTI.AsnCtiSetForwardResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetForwardResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bExistingForwardingChanged", "boolean", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetForwardResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetForwardResult");

		let t: ENetUC_CTI.AsnCtiSetForwardResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetForwardResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetForwardResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bExistingForwardingChanged", "boolean", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetForwardResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetForwardResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetForwardResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bExistingForwardingChanged", "boolean", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			t.push(new asn1ts.Boolean({ value: s.bExistingForwardingChanged, name: "bExistingForwardingChanged" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetForwardResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetForwardResult");

		let t: ENetUC_CTI.AsnCtiSetForwardResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetForwardResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetForwardResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bExistingForwardingChanged", "Boolean", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiSetForwardResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiRemoveForwardArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiRemoveForwardArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiRemoveForwardArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRemoveForwardArgument");

		const t = {} as ENetUC_CTI.AsnCtiRemoveForwardArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiRemoveForwardArgument";
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRemoveForwardArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRemoveForwardArgument");

		let t: ENetUC_CTI.AsnCtiRemoveForwardArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiRemoveForwardArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiRemoveForwardArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberFrom", "string", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiRemoveForwardArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiRemoveForwardArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRemoveForwardArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sPhoneNumberFrom", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberFrom, name: "u8sPhoneNumberFrom" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRemoveForwardArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRemoveForwardArgument");

		let t: ENetUC_CTI.AsnCtiRemoveForwardArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiRemoveForwardArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiRemoveForwardArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberFrom", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiRemoveForwardResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiRemoveForwardResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiRemoveForwardResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRemoveForwardResult");

		const t = {} as ENetUC_CTI.AsnCtiRemoveForwardResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiRemoveForwardResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRemoveForwardResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRemoveForwardResult");

		let t: ENetUC_CTI.AsnCtiRemoveForwardResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiRemoveForwardResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiRemoveForwardResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiRemoveForwardResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiRemoveForwardResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiRemoveForwardResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiRemoveForwardResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiRemoveForwardResult");

		let t: ENetUC_CTI.AsnCtiRemoveForwardResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiRemoveForwardResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiRemoveForwardResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiRemoveForwardResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiDialDigitsArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiDialDigitsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiDialDigitsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDialDigitsArgument");

		const t = {} as ENetUC_CTI.AsnCtiDialDigitsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiDialDigitsArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDigitMode", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDigitDuration", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDigitsToDial", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiDialDigitsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDialDigitsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDialDigitsArgument");

		let t: ENetUC_CTI.AsnCtiDialDigitsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiDialDigitsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiDialDigitsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDigitMode", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDigitDuration", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDigitsToDial", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiDialDigitsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiDialDigitsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDialDigitsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "iDigitMode", "number", errors, newContext);
		TSConverter.validateParam(s, "iDigitDuration", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDigitsToDial", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.iDigitMode, name: "iDigitMode" }));
			t.push(new asn1ts.Integer({ value: s.iDigitDuration, name: "iDigitDuration" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDigitsToDial, name: "u8sDigitsToDial" }));
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDialDigitsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDialDigitsArgument");

		let t: ENetUC_CTI.AsnCtiDialDigitsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiDialDigitsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiDialDigitsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDigitMode", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDigitDuration", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDigitsToDial", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiDialDigitsResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiDialDigitsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiDialDigitsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDialDigitsResult");

		const t = {} as ENetUC_CTI.AsnCtiDialDigitsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiDialDigitsResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiDialDigitsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDialDigitsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDialDigitsResult");

		let t: ENetUC_CTI.AsnCtiDialDigitsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiDialDigitsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiDialDigitsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiDialDigitsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiDialDigitsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiDialDigitsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiDialDigitsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiDialDigitsResult");

		let t: ENetUC_CTI.AsnCtiDialDigitsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiDialDigitsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiDialDigitsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiDialDigitsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCompleteCallArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCompleteCallArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCompleteCallArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCompleteCallArgument");

		const t = {} as ENetUC_CTI.AsnCtiCompleteCallArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiCompleteCallArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiCompleteCallArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCompleteCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCompleteCallArgument");

		let t: ENetUC_CTI.AsnCtiCompleteCallArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCompleteCallArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiCompleteCallArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCompleteCallArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCompleteCallArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCompleteCallArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCompleteCallArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCompleteCallArgument");

		let t: ENetUC_CTI.AsnCtiCompleteCallArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCompleteCallArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiCompleteCallArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCompleteCallResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCompleteCallResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCompleteCallResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCompleteCallResult");

		const t = {} as ENetUC_CTI.AsnCtiCompleteCallResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiCompleteCallResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiCompleteCallResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCompleteCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCompleteCallResult");

		let t: ENetUC_CTI.AsnCtiCompleteCallResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCompleteCallResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiCompleteCallResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCompleteCallResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCompleteCallResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCompleteCallResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCompleteCallResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCompleteCallResult");

		let t: ENetUC_CTI.AsnCtiCompleteCallResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCompleteCallResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiCompleteCallResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiCompleteCallResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiConferenceArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiConferenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiConferenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiConferenceArgument");

		const t = {} as ENetUC_CTI.AsnCtiConferenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiConferenceArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID1", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID2", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiConferenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiConferenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiConferenceArgument");

		let t: ENetUC_CTI.AsnCtiConferenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiConferenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiConferenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID1", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID2", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiConferenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiConferenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiConferenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID1", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID2", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID1, name: "u8sConnectionID1" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID2, name: "u8sConnectionID2" }));
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiConferenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiConferenceArgument");

		let t: ENetUC_CTI.AsnCtiConferenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiConferenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiConferenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID1", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID2", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiConferenceResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiConferenceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiConferenceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiConferenceResult");

		const t = {} as ENetUC_CTI.AsnCtiConferenceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiConferenceResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiConferenceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiConferenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiConferenceResult");

		let t: ENetUC_CTI.AsnCtiConferenceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiConferenceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiConferenceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiConferenceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiConferenceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiConferenceResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiConferenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiConferenceResult");

		let t: ENetUC_CTI.AsnCtiConferenceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiConferenceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiConferenceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiConferenceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetupConferenceArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetupConferenceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetupConferenceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetupConferenceArgument");

		const t = {} as ENetUC_CTI.AsnCtiSetupConferenceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetupConferenceArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext);
		if (s.newCallParams) {
			const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(s.newCallParams, errors, newContext, "newCallParams");
			if (_newCallParams)
				t.newCallParams = _newCallParams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetupConferenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetupConferenceArgument");

		let t: ENetUC_CTI.AsnCtiSetupConferenceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetupConferenceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetupConferenceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumberTo", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iNumberFormat", "number", errors, newContext, false);
			const _newcallparams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(s.newCallParams, errors, newContext, "newCallParams", true);
			if (_newcallparams)
				t.newCallParams = _newcallparams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetupConferenceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetupConferenceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetupConferenceArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumberTo", "string", errors, newContext);
		TSConverter.validateParam(s, "iNumberFormat", "number", errors, newContext);
		const _newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toBER(s.newCallParams, errors, newContext, "newCallParams", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumberTo, name: "u8sPhoneNumberTo" }));
			t.push(new asn1ts.Integer({ value: s.iNumberFormat, name: "iNumberFormat" }));
			if (_newCallParams)
				t.push(_newCallParams);
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetupConferenceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetupConferenceArgument");

		let t: ENetUC_CTI.AsnCtiSetupConferenceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetupConferenceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetupConferenceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumberTo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iNumberFormat", "Integer", errors, newContext);
			t.newCallParams = ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "newCallParams"), errors, newContext, "newCallParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetupConferenceResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetupConferenceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetupConferenceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetupConferenceResult");

		const t = {} as ENetUC_CTI.AsnCtiSetupConferenceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetupConferenceResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetupConferenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetupConferenceResult");

		let t: ENetUC_CTI.AsnCtiSetupConferenceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetupConferenceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetupConferenceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetupConferenceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetupConferenceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetupConferenceResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetupConferenceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetupConferenceResult");

		let t: ENetUC_CTI.AsnCtiSetupConferenceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetupConferenceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetupConferenceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSetupConferenceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetActiveLineAddressArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetActiveLineAddressArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetActiveLineAddressArgument");

		const t = {} as ENetUC_CTI.AsnCtiSetActiveLineAddressArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetActiveLineAddressArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAddressID", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetActiveLineAddressArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetActiveLineAddressArgument");

		let t: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetActiveLineAddressArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetActiveLineAddressArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAddressID", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetActiveLineAddressArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetActiveLineAddressArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iAddressID", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.iAddressID, name: "iAddressID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetActiveLineAddressArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetActiveLineAddressArgument");

		let t: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetActiveLineAddressArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetActiveLineAddressArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAddressID", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetActiveLineAddressResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetActiveLineAddressResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetActiveLineAddressResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetActiveLineAddressResult");

		const t = {} as ENetUC_CTI.AsnCtiSetActiveLineAddressResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetActiveLineAddressResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetActiveLineAddressResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetActiveLineAddressResult");

		let t: ENetUC_CTI.AsnCtiSetActiveLineAddressResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetActiveLineAddressResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetActiveLineAddressResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetActiveLineAddressResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetActiveLineAddressResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetActiveLineAddressResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetActiveLineAddressResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetActiveLineAddressResult");

		let t: ENetUC_CTI.AsnCtiSetActiveLineAddressResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetActiveLineAddressResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetActiveLineAddressResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiSetActiveLineAddressResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineActiveAddressChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineActiveAddressChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineActiveAddressChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAddressID", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiNotifyLineActiveAddressChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineActiveAddressChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAddressID", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineActiveAddressChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineActiveAddressChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineActiveAddressChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "iAddressID", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.iAddressID, name: "iAddressID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineActiveAddressChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineActiveAddressChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAddressID", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineActiveAddressChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMobileRegisterDeviceArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnMobileRegisterDeviceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnMobileRegisterDeviceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMobileRegisterDeviceArgument");

		const t = {} as ENetUC_CTI.AsnMobileRegisterDeviceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMobileRegisterDeviceArgument";
		TSConverter.fillJSONParam(s, t, "iRegisterType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDeviceIMEI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUserIMSI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDevicePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sGroupName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sNetworkAvailable", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLineFeatures", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, true);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnMobileRegisterDeviceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMobileRegisterDeviceArgument");

		let t: ENetUC_CTI.AsnMobileRegisterDeviceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnMobileRegisterDeviceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnMobileRegisterDeviceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRegisterType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDeviceIMEI", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUserIMSI", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDevicePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sGroupName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sNetworkAvailable", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iLineFeatures", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iForwardFeaturesPBX", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, true);
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnMobileRegisterDeviceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMobileRegisterDeviceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMobileRegisterDeviceArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRegisterType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDeviceIMEI", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUserIMSI", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDevicePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sGroupName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sNetworkAvailable", "string", errors, newContext);
		TSConverter.validateParam(s, "iLineFeatures", "number", errors, newContext);
		TSConverter.validateParam(s, "iForwardFeaturesPBX", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext, true);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRegisterType, name: "iRegisterType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDeviceIMEI, name: "u8sDeviceIMEI" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUserIMSI, name: "u8sUserIMSI" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDevicePhoneNumber, name: "u8sDevicePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sGroupName, name: "u8sGroupName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sNetworkAvailable, name: "u8sNetworkAvailable" }));
			t.push(new asn1ts.Integer({ value: s.iLineFeatures, name: "iLineFeatures" }));
			t.push(new asn1ts.Integer({ value: s.iForwardFeaturesPBX, name: "iForwardFeaturesPBX" }));
			if (s.u8sMonitorCrossRefID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnMobileRegisterDeviceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMobileRegisterDeviceArgument");

		let t: ENetUC_CTI.AsnMobileRegisterDeviceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnMobileRegisterDeviceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnMobileRegisterDeviceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRegisterType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDeviceIMEI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUserIMSI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDevicePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sGroupName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sNetworkAvailable", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLineFeatures", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iForwardFeaturesPBX", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext, true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMobileRegisterDeviceResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnMobileRegisterDeviceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnMobileRegisterDeviceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMobileRegisterDeviceResult");

		const t = {} as ENetUC_CTI.AsnMobileRegisterDeviceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMobileRegisterDeviceResult";
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnMobileRegisterDeviceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMobileRegisterDeviceResult");

		let t: ENetUC_CTI.AsnMobileRegisterDeviceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnMobileRegisterDeviceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnMobileRegisterDeviceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnMobileRegisterDeviceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMobileRegisterDeviceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMobileRegisterDeviceResult");

		// [Print_BER_EncoderSeqDefCode]
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnMobileRegisterDeviceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMobileRegisterDeviceResult");

		let t: ENetUC_CTI.AsnMobileRegisterDeviceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnMobileRegisterDeviceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnMobileRegisterDeviceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnMobileRegisterDeviceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallDataList_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallDataList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallDataList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallDataList");

		const t = [] as ENetUC_CTI.AsnCtiCallDataList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnCtiCallData_Converter.toJSON(se, errors, newContext, "AsnCtiCallData");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnCtiCallDataList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallDataList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallDataList");

		let t: ENetUC_CTI.AsnCtiCallDataList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallDataList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_CTI.AsnCtiCallDataList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnCtiCallData_Converter.fromJSON(se, errors, newContext, "AsnCtiCallData", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallDataList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallDataList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallDataList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallDataList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnCtiCallData_Converter.toBER(s[id], errors, newContext, "AsnCtiCallData");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnCtiCallDataList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallDataList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallDataList");

		let t: ENetUC_CTI.AsnCtiCallDataList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallDataList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_CTI.AsnCtiCallDataList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnCtiCallData_Converter.fromBER(se, errors, newContext, "AsnCtiCallData", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallDataList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineInfoList_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineInfoList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineInfoList");

		const t = [] as ENetUC_CTI.AsnCtiLineInfoList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnCtiLineInfo_Converter.toJSON(se, errors, newContext, "AsnCtiLineInfo");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfoList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineInfoList");

		let t: ENetUC_CTI.AsnCtiLineInfoList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineInfoList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_CTI.AsnCtiLineInfoList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnCtiLineInfo_Converter.fromJSON(se, errors, newContext, "AsnCtiLineInfo", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfoList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineInfoList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineInfoList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineInfoList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnCtiLineInfo_Converter.toBER(s[id], errors, newContext, "AsnCtiLineInfo");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnCtiLineInfoList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineInfoList");

		let t: ENetUC_CTI.AsnCtiLineInfoList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineInfoList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_CTI.AsnCtiLineInfoList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnCtiLineInfo_Converter.fromBER(se, errors, newContext, "AsnCtiLineInfo", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnCtiLineInfoList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallInformation_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallInformation, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallInformation & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallInformation");

		const t = {} as ENetUC_CTI.AsnCtiCallInformation & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiCallInformation";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCallState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCallType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDisconnectedReason", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bOutbound", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bInternal", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bCallerIDBlocked", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCallRecordingState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bConferenceCtlr", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConferenceCtlrID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocalNumberSC", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRemoteNumberSC", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iRemoteContactSource", "number", errors, newContext);
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(s.projectPinRule, errors, newContext, "projectPinRule");
		if (_projectPinRule)
			t.projectPinRule = _projectPinRule;
		TSConverter.fillJSONParam(s, t, "u8sNote", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTrackerID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "utcCallCreated", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "utcCallConnected", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "utcCallDisconnected", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bIsUnknownHoldContact", "boolean", errors, newContext);
		if (s.pLocalContact) {
			const _pLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.pLocalContact, errors, newContext, "pLocalContact");
			if (_pLocalContact)
				t.pLocalContact = _pLocalContact;
		}
		if (s.pRemoteContact) {
			const _pRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.pRemoteContact, errors, newContext, "pRemoteContact");
			if (_pRemoteContact)
				t.pRemoteContact = _pRemoteContact;
		}
		TSConverter.fillJSONParam(s, t, "pu8sHoldNumberSC", "string", errors, newContext, true);
		if (s.pHoldContact) {
			const _pHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.pHoldContact, errors, newContext, "pHoldContact");
			if (_pHoldContact)
				t.pHoldContact = _pHoldContact;
		}
		TSConverter.fillJSONParam(s, t, "pu8sRedirectingNumber", "string", errors, newContext, true);
		if (s.pRedirectingContact) {
			const _pRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.pRedirectingContact, errors, newContext, "pRedirectingContact");
			if (_pRedirectingContact)
				t.pRedirectingContact = _pRedirectingContact;
		}
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}
		if (s.callDataList) {
			const _callDataList = AsnCtiCallDataList_Converter.toJSON(s.callDataList, errors, newContext, "callDataList");
			if (_callDataList)
				t.callDataList = _callDataList;
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformation"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallInformation | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallInformation");

		let t: ENetUC_CTI.AsnCtiCallInformation | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallInformation>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiCallInformation["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iCallState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iCallType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iDisconnectedReason", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iFeatures", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bOutbound", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bInternal", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bCallerIDBlocked", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iCallRecordingState", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "bConferenceCtlr", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sConferenceCtlrID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLocalNumberSC", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sRemoteNumberSC", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iRemoteContactSource", "number", errors, newContext, false);
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(s.projectPinRule, errors, newContext, "projectPinRule", false);
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
			TSConverter.fillJSONParam(s, t, "u8sNote", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sTrackerID", "string", errors, newContext, false);
			if (TSConverter.validateParam(s, "utcCallCreated", "string", errors, newContext, false))
				t.utcCallCreated = new Date(s.utcCallCreated);
			if (TSConverter.validateParam(s, "utcCallConnected", "string", errors, newContext, false))
				t.utcCallConnected = new Date(s.utcCallConnected);
			if (TSConverter.validateParam(s, "utcCallDisconnected", "string", errors, newContext, false))
				t.utcCallDisconnected = new Date(s.utcCallDisconnected);
			TSConverter.fillJSONParam(s, t, "bIsUnknownHoldContact", "boolean", errors, newContext, false);
			const _plocalcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.pLocalContact, errors, newContext, "pLocalContact", true);
			if (_plocalcontact)
				t.pLocalContact = _plocalcontact;
			const _premotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.pRemoteContact, errors, newContext, "pRemoteContact", true);
			if (_premotecontact)
				t.pRemoteContact = _premotecontact;
			TSConverter.fillJSONParam(s, t, "pu8sHoldNumberSC", "string", errors, newContext, true);
			const _pholdcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.pHoldContact, errors, newContext, "pHoldContact", true);
			if (_pholdcontact)
				t.pHoldContact = _pholdcontact;
			TSConverter.fillJSONParam(s, t, "pu8sRedirectingNumber", "string", errors, newContext, true);
			const _predirectingcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.pRedirectingContact, errors, newContext, "pRedirectingContact", true);
			if (_predirectingcontact)
				t.pRedirectingContact = _predirectingcontact;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
			const _calldatalist = AsnCtiCallDataList_Converter.fromJSON(s.callDataList, errors, newContext, "callDataList", true);
			if (_calldatalist)
				t.callDataList = _calldatalist;
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformation"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallInformation | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallInformation";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallInformation");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "iCallState", "number", errors, newContext);
		TSConverter.validateParam(s, "iCallType", "number", errors, newContext);
		TSConverter.validateParam(s, "iDisconnectedReason", "number", errors, newContext);
		TSConverter.validateParam(s, "iFeatures", "number", errors, newContext);
		TSConverter.validateParam(s, "bOutbound", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bInternal", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bCallerIDBlocked", "boolean", errors, newContext);
		TSConverter.validateParam(s, "iCallRecordingState", "number", errors, newContext);
		TSConverter.validateParam(s, "bConferenceCtlr", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sConferenceCtlrID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLocalNumberSC", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRemoteNumberSC", "string", errors, newContext);
		TSConverter.validateParam(s, "iRemoteContactSource", "number", errors, newContext);
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toBER(s.projectPinRule, errors, newContext, "projectPinRule");
		TSConverter.validateParam(s, "u8sNote", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTrackerID", "string", errors, newContext);
		TSConverter.validateParam(s, "utcCallCreated", "Date", errors, newContext);
		TSConverter.validateParam(s, "utcCallConnected", "Date", errors, newContext);
		TSConverter.validateParam(s, "utcCallDisconnected", "Date", errors, newContext);
		TSConverter.validateParam(s, "bIsUnknownHoldContact", "boolean", errors, newContext);
		const _pLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.pLocalContact, errors, newContext, "pLocalContact", 0);
		const _pRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.pRemoteContact, errors, newContext, "pRemoteContact", 1);
		TSConverter.validateParam(s, "pu8sHoldNumberSC", "string", errors, newContext, true);
		const _pHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.pHoldContact, errors, newContext, "pHoldContact", 3);
		TSConverter.validateParam(s, "pu8sRedirectingNumber", "string", errors, newContext, true);
		const _pRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.pRedirectingContact, errors, newContext, "pRedirectingContact", 6);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 7);
		const _callDataList = AsnCtiCallDataList_Converter.toBER(s.callDataList, errors, newContext, "callDataList", 8);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Integer({ value: s.iCallState, name: "iCallState" }));
			t.push(new asn1ts.Integer({ value: s.iCallType, name: "iCallType" }));
			t.push(new asn1ts.Integer({ value: s.iDisconnectedReason, name: "iDisconnectedReason" }));
			t.push(new asn1ts.Integer({ value: s.iFeatures, name: "iFeatures" }));
			t.push(new asn1ts.Boolean({ value: s.bOutbound, name: "bOutbound" }));
			t.push(new asn1ts.Boolean({ value: s.bInternal, name: "bInternal" }));
			t.push(new asn1ts.Boolean({ value: s.bCallerIDBlocked, name: "bCallerIDBlocked" }));
			t.push(new asn1ts.Integer({ value: s.iCallRecordingState, name: "iCallRecordingState" }));
			t.push(new asn1ts.Boolean({ value: s.bConferenceCtlr, name: "bConferenceCtlr" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConferenceCtlrID, name: "u8sConferenceCtlrID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocalNumberSC, name: "u8sLocalNumberSC" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRemoteNumberSC, name: "u8sRemoteNumberSC" }));
			t.push(new asn1ts.Integer({ value: s.iRemoteContactSource, name: "iRemoteContactSource" }));
			if (_projectPinRule)
				t.push(_projectPinRule);
			t.push(new asn1ts.Utf8String({ value: s.u8sNote, name: "u8sNote" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTrackerID, name: "u8sTrackerID" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcCallCreated), name: "utcCallCreated" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcCallConnected), name: "utcCallConnected" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcCallDisconnected), name: "utcCallDisconnected" }));
			t.push(new asn1ts.Boolean({ value: s.bIsUnknownHoldContact, name: "bIsUnknownHoldContact" }));
			if (_pLocalContact)
				t.push(_pLocalContact);
			if (_pRemoteContact)
				t.push(_pRemoteContact);
			if (s.pu8sHoldNumberSC !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.pu8sHoldNumberSC, name: "pu8sHoldNumberSC", idBlock: { optionalID: 2 } }));
			if (_pHoldContact)
				t.push(_pHoldContact);
			if (s.pu8sRedirectingNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.pu8sRedirectingNumber, name: "pu8sRedirectingNumber", idBlock: { optionalID: 5 } }));
			if (_pRedirectingContact)
				t.push(_pRedirectingContact);
			if (_optionalParams)
				t.push(_optionalParams);
			if (_callDataList)
				t.push(_callDataList);
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformation"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallInformation | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallInformation");

		let t: ENetUC_CTI.AsnCtiCallInformation | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallInformation.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiCallInformation["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCallState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCallType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDisconnectedReason", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFeatures", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bOutbound", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bInternal", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bCallerIDBlocked", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCallRecordingState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bConferenceCtlr", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConferenceCtlrID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocalNumberSC", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRemoteNumberSC", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iRemoteContactSource", "Integer", errors, newContext);
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "projectPinRule"), errors, newContext, "projectPinRule");
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
			TSConverter.fillASN1Param(s, t, "u8sNote", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTrackerID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "utcCallCreated", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "utcCallConnected", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "utcCallDisconnected", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bIsUnknownHoldContact", "Boolean", errors, newContext);
			t.pLocalContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "pLocalContact"), errors, newContext, "pLocalContact", true);
			t.pRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "pRemoteContact"), errors, newContext, "pRemoteContact", true);
			TSConverter.fillASN1Param(s, t, "pu8sHoldNumberSC", "Utf8String", errors, newContext, true);
			t.pHoldContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "pHoldContact"), errors, newContext, "pHoldContact", true);
			TSConverter.fillASN1Param(s, t, "pu8sRedirectingNumber", "Utf8String", errors, newContext, true);
			t.pRedirectingContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "pRedirectingContact"), errors, newContext, "pRedirectingContact", true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
			t.callDataList = AsnCtiCallDataList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callDataList"), errors, newContext, "callDataList", true);
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformation"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallInformationList_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallInformationList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallInformationList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallInformationList");

		const t = [] as ENetUC_CTI.AsnCtiCallInformationList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnCtiCallInformation_Converter.toJSON(se, errors, newContext, "AsnCtiCallInformation");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformationList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallInformationList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallInformationList");

		let t: ENetUC_CTI.AsnCtiCallInformationList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallInformationList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_CTI.AsnCtiCallInformationList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnCtiCallInformation_Converter.fromJSON(se, errors, newContext, "AsnCtiCallInformation", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformationList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallInformationList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallInformationList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallInformationList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnCtiCallInformation_Converter.toBER(s[id], errors, newContext, "AsnCtiCallInformation");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnCtiCallInformationList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallInformationList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallInformationList");

		let t: ENetUC_CTI.AsnCtiCallInformationList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallInformationList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_CTI.AsnCtiCallInformationList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnCtiCallInformation_Converter.fromBER(se, errors, newContext, "AsnCtiCallInformation", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallInformationList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiEnumPhoneLinesArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiEnumPhoneLinesArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiEnumPhoneLinesArgument");

		const t = {} as ENetUC_CTI.AsnCtiEnumPhoneLinesArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiEnumPhoneLinesArgument";
		TSConverter.fillJSONParam(s, t, "typeofLines", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "attachChangeNotify", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiEnumPhoneLinesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiEnumPhoneLinesArgument");

		let t: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiEnumPhoneLinesArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiEnumPhoneLinesArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "typeofLines", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "attachChangeNotify", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiEnumPhoneLinesArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiEnumPhoneLinesArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "typeofLines", "number", errors, newContext);
		TSConverter.validateParam(s, "attachChangeNotify", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Enumerated({ value: s.typeofLines, name: "typeofLines" }));
			t.push(new asn1ts.Integer({ value: s.attachChangeNotify, name: "attachChangeNotify" }));
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiEnumPhoneLinesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiEnumPhoneLinesArgument");

		let t: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiEnumPhoneLinesArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiEnumPhoneLinesArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "typeofLines", "Enumerated", errors, newContext);
			TSConverter.fillASN1Param(s, t, "attachChangeNotify", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiEnumPhoneLinesResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiEnumPhoneLinesResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiEnumPhoneLinesResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiEnumPhoneLinesResult");

		const t = {} as ENetUC_CTI.AsnCtiEnumPhoneLinesResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiEnumPhoneLinesResult";
		const _seqLines = AsnCtiLineInfoList_Converter.toJSON(s.seqLines, errors, newContext, "seqLines");
		if (_seqLines)
			t.seqLines = _seqLines;
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiEnumPhoneLinesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiEnumPhoneLinesResult");

		let t: ENetUC_CTI.AsnCtiEnumPhoneLinesResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiEnumPhoneLinesResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiEnumPhoneLinesResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqlines = AsnCtiLineInfoList_Converter.fromJSON(s.seqLines, errors, newContext, "seqLines", false);
			if (_seqlines)
				t.seqLines = _seqlines;
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiEnumPhoneLinesResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiEnumPhoneLinesResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiEnumPhoneLinesResult");

		// [Print_BER_EncoderSeqDefCode]
		const _seqLines = AsnCtiLineInfoList_Converter.toBER(s.seqLines, errors, newContext, "seqLines");
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (_seqLines)
				t.push(_seqLines);
			if (s.u8sLineMonitorCrossRefID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiEnumPhoneLinesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiEnumPhoneLinesResult");

		let t: ENetUC_CTI.AsnCtiEnumPhoneLinesResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiEnumPhoneLinesResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiEnumPhoneLinesResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqlines = AsnCtiLineInfoList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLines"), errors, newContext, "seqLines");
			if (_seqlines)
				t.seqLines = _seqlines;
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnCtiEnumPhoneLinesResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSnapshotPhoneLineResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSnapshotPhoneLineResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSnapshotPhoneLineResult");

		const t = {} as ENetUC_CTI.AsnCtiSnapshotPhoneLineResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSnapshotPhoneLineResult";
		const _lineInfo = AsnCtiLineInfo_Converter.toJSON(s.lineInfo, errors, newContext, "lineInfo");
		if (_lineInfo)
			t.lineInfo = _lineInfo;
		const _seqCalls = AsnCtiCallInformationList_Converter.toJSON(s.seqCalls, errors, newContext, "seqCalls");
		if (_seqCalls)
			t.seqCalls = _seqCalls;
		TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
		if (_seqLineForwards)
			t.seqLineForwards = _seqLineForwards;
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (_remoteOfficeState)
			t.remoteOfficeState = _remoteOfficeState;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSnapshotPhoneLineResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSnapshotPhoneLineResult");

		let t: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSnapshotPhoneLineResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSnapshotPhoneLineResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _lineinfo = AsnCtiLineInfo_Converter.fromJSON(s.lineInfo, errors, newContext, "lineInfo", false);
			if (_lineinfo)
				t.lineInfo = _lineinfo;
			const _seqcalls = AsnCtiCallInformationList_Converter.fromJSON(s.seqCalls, errors, newContext, "seqCalls", false);
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext, false);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", false);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState", false);
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSnapshotPhoneLineResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSnapshotPhoneLineResult");

		// [Print_BER_EncoderSeqDefCode]
		const _lineInfo = AsnCtiLineInfo_Converter.toBER(s.lineInfo, errors, newContext, "lineInfo");
		const _seqCalls = AsnCtiCallInformationList_Converter.toBER(s.seqCalls, errors, newContext, "seqCalls");
		TSConverter.validateParam(s, "iDoNotDisturb", "number", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards");
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toBER(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			if (_lineInfo)
				t.push(_lineInfo);
			if (_seqCalls)
				t.push(_seqCalls);
			t.push(new asn1ts.Integer({ value: s.iDoNotDisturb, name: "iDoNotDisturb" }));
			if (_seqLineForwards)
				t.push(_seqLineForwards);
			if (_remoteOfficeState)
				t.push(_remoteOfficeState);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSnapshotPhoneLineResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSnapshotPhoneLineResult");

		let t: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSnapshotPhoneLineResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSnapshotPhoneLineResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _lineinfo = AsnCtiLineInfo_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lineInfo"), errors, newContext, "lineInfo");
			if (_lineinfo)
				t.lineInfo = _lineinfo;
			const _seqcalls = AsnCtiCallInformationList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqCalls"), errors, newContext, "seqCalls");
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			TSConverter.fillASN1Param(s, t, "iDoNotDisturb", "Integer", errors, newContext);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards");
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteOfficeState"), errors, newContext, "remoteOfficeState");
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiSnapshotPhoneLineResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineMonitorStartResult_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineMonitorStartResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineMonitorStartResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStartResult");

		const t = {} as ENetUC_CTI.AsnCtiLineMonitorStartResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineMonitorStartResult";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toJSON(s.lineInfo, errors, newContext, "lineInfo");
		if (_lineInfo)
			t.lineInfo = _lineInfo;
		const _seqCalls = AsnCtiCallInformationList_Converter.toJSON(s.seqCalls, errors, newContext, "seqCalls");
		if (_seqCalls)
			t.seqCalls = _seqCalls;
		TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
		if (_seqLineForwards)
			t.seqLineForwards = _seqLineForwards;
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (_remoteOfficeState)
			t.remoteOfficeState = _remoteOfficeState;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStartResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStartResult");

		let t: ENetUC_CTI.AsnCtiLineMonitorStartResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineMonitorStartResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineMonitorStartResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			const _lineinfo = AsnCtiLineInfo_Converter.fromJSON(s.lineInfo, errors, newContext, "lineInfo", false);
			if (_lineinfo)
				t.lineInfo = _lineinfo;
			const _seqcalls = AsnCtiCallInformationList_Converter.fromJSON(s.seqCalls, errors, newContext, "seqCalls", false);
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			TSConverter.fillJSONParam(s, t, "iDoNotDisturb", "number", errors, newContext, false);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", false);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState", false);
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineMonitorStartResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineMonitorStartResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineMonitorStartResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toBER(s.lineInfo, errors, newContext, "lineInfo");
		const _seqCalls = AsnCtiCallInformationList_Converter.toBER(s.seqCalls, errors, newContext, "seqCalls");
		TSConverter.validateParam(s, "iDoNotDisturb", "number", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards");
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toBER(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			if (_lineInfo)
				t.push(_lineInfo);
			if (_seqCalls)
				t.push(_seqCalls);
			t.push(new asn1ts.Integer({ value: s.iDoNotDisturb, name: "iDoNotDisturb" }));
			if (_seqLineForwards)
				t.push(_seqLineForwards);
			if (_remoteOfficeState)
				t.push(_remoteOfficeState);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineMonitorStartResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineMonitorStartResult");

		let t: ENetUC_CTI.AsnCtiLineMonitorStartResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineMonitorStartResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineMonitorStartResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			const _lineinfo = AsnCtiLineInfo_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lineInfo"), errors, newContext, "lineInfo");
			if (_lineinfo)
				t.lineInfo = _lineinfo;
			const _seqcalls = AsnCtiCallInformationList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqCalls"), errors, newContext, "seqCalls");
			if (_seqcalls)
				t.seqCalls = _seqcalls;
			TSConverter.fillASN1Param(s, t, "iDoNotDisturb", "Integer", errors, newContext);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards");
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteOfficeState"), errors, newContext, "remoteOfficeState");
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiLineMonitorStartResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiLineSetRemoteOfficeArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetRemoteOfficeArgument");

		const t = {} as ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiLineSetRemoteOfficeArgument";
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (_remoteOfficeState)
			t.remoteOfficeState = _remoteOfficeState;

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetRemoteOfficeArgument");

		let t: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState", false);
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiLineSetRemoteOfficeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiLineSetRemoteOfficeArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toBER(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_remoteOfficeState)
				t.push(_remoteOfficeState);
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiLineSetRemoteOfficeArgument");

		let t: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteOfficeState"), errors, newContext, "remoteOfficeState");
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
		}

		if (errors.validateResult(newContext, "AsnCtiLineSetRemoteOfficeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineInfoChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineInfoChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineInfoChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toJSON(s.lineInfo, errors, newContext, "lineInfo");
		if (_lineInfo)
			t.lineInfo = _lineInfo;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInfoChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineInfoChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _lineinfo = AsnCtiLineInfo_Converter.fromJSON(s.lineInfo, errors, newContext, "lineInfo", false);
			if (_lineinfo)
				t.lineInfo = _lineinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInfoChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineInfoChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineInfoChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toBER(s.lineInfo, errors, newContext, "lineInfo");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_lineInfo)
				t.push(_lineInfo);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInfoChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineInfoChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _lineinfo = AsnCtiLineInfo_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lineInfo"), errors, newContext, "lineInfo");
			if (_lineinfo)
				t.lineInfo = _lineinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineInfoChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineRemoteOfficeChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineRemoteOfficeChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineRemoteOfficeChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (_remoteOfficeState)
			t.remoteOfficeState = _remoteOfficeState;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineRemoteOfficeChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineRemoteOfficeChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromJSON(s.remoteOfficeState, errors, newContext, "remoteOfficeState", false);
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineRemoteOfficeChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineRemoteOfficeChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineRemoteOfficeChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _remoteOfficeState = AsnRemoteOfficeState_Converter.toBER(s.remoteOfficeState, errors, newContext, "remoteOfficeState");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_remoteOfficeState)
				t.push(_remoteOfficeState);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineRemoteOfficeChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineRemoteOfficeChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _remoteofficestate = AsnRemoteOfficeState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteOfficeState"), errors, newContext, "remoteOfficeState");
			if (_remoteofficestate)
				t.remoteOfficeState = _remoteofficestate;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineRemoteOfficeChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineCallInformationChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineCallInformationChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineCallInformationChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "reason", "number", errors, newContext);
		const _callinformation = AsnCtiCallInformation_Converter.toJSON(s.callinformation, errors, newContext, "callinformation");
		if (_callinformation)
			t.callinformation = _callinformation;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallInformationChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineCallInformationChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "reason", "number", errors, newContext, false);
			const _callinformation = AsnCtiCallInformation_Converter.fromJSON(s.callinformation, errors, newContext, "callinformation", false);
			if (_callinformation)
				t.callinformation = _callinformation;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallInformationChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineCallInformationChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineCallInformationChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "reason", "number", errors, newContext);
		const _callinformation = AsnCtiCallInformation_Converter.toBER(s.callinformation, errors, newContext, "callinformation");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Integer({ value: s.reason, name: "reason" }));
			if (_callinformation)
				t.push(_callinformation);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallInformationChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineCallInformationChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "reason", "Integer", errors, newContext);
			const _callinformation = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callinformation"), errors, newContext, "callinformation");
			if (_callinformation)
				t.callinformation = _callinformation;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallInformationChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineAddRemoveArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineAddRemoveArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineAddRemoveArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "addremove", "number", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toJSON(s.lineInfo, errors, newContext, "lineInfo");
		if (_lineInfo)
			t.lineInfo = _lineInfo;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineAddRemoveArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineAddRemoveArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "addremove", "number", errors, newContext, false);
			const _lineinfo = AsnCtiLineInfo_Converter.fromJSON(s.lineInfo, errors, newContext, "lineInfo", false);
			if (_lineinfo)
				t.lineInfo = _lineinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineAddRemoveArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineAddRemoveArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineAddRemoveArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "addremove", "number", errors, newContext);
		const _lineInfo = AsnCtiLineInfo_Converter.toBER(s.lineInfo, errors, newContext, "lineInfo");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			t.push(new asn1ts.Enumerated({ value: s.addremove, name: "addremove" }));
			if (_lineInfo)
				t.push(_lineInfo);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineAddRemoveArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineAddRemoveArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "addremove", "Enumerated", errors, newContext);
			const _lineinfo = AsnCtiLineInfo_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lineInfo"), errors, newContext, "lineInfo");
			if (_lineinfo)
				t.lineInfo = _lineinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineAddRemoveArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyConferenceCreatedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferenceCreatedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyConferenceCreatedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		if (_conferenceControllerCallInfo)
			t.conferenceControllerCallInfo = _conferenceControllerCallInfo;
		const _conferenceMemberCallInfoList = AsnCtiCallInformationList_Converter.toJSON(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList");
		if (_conferenceMemberCallInfoList)
			t.conferenceMemberCallInfoList = _conferenceMemberCallInfoList;

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceCreatedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferenceCreatedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo", false);
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfolist = AsnCtiCallInformationList_Converter.fromJSON(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList", false);
			if (_conferencemembercallinfolist)
				t.conferenceMemberCallInfoList = _conferencemembercallinfolist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceCreatedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyConferenceCreatedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferenceCreatedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		const _conferenceMemberCallInfoList = AsnCtiCallInformationList_Converter.toBER(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_conferenceControllerCallInfo)
				t.push(_conferenceControllerCallInfo);
			if (_conferenceMemberCallInfoList)
				t.push(_conferenceMemberCallInfoList);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceCreatedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferenceCreatedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceControllerCallInfo"), errors, newContext, "conferenceControllerCallInfo");
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfolist = AsnCtiCallInformationList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceMemberCallInfoList"), errors, newContext, "conferenceMemberCallInfoList");
			if (_conferencemembercallinfolist)
				t.conferenceMemberCallInfoList = _conferencemembercallinfolist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceCreatedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyConferenceDestroyedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferenceDestroyedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyConferenceDestroyedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		if (_conferenceControllerCallInfo)
			t.conferenceControllerCallInfo = _conferenceControllerCallInfo;
		const _conferenceMemberCallInfoList = AsnCtiCallInformationList_Converter.toJSON(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList");
		if (_conferenceMemberCallInfoList)
			t.conferenceMemberCallInfoList = _conferenceMemberCallInfoList;

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceDestroyedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferenceDestroyedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo", false);
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfolist = AsnCtiCallInformationList_Converter.fromJSON(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList", false);
			if (_conferencemembercallinfolist)
				t.conferenceMemberCallInfoList = _conferencemembercallinfolist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceDestroyedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyConferenceDestroyedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferenceDestroyedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		const _conferenceMemberCallInfoList = AsnCtiCallInformationList_Converter.toBER(s.conferenceMemberCallInfoList, errors, newContext, "conferenceMemberCallInfoList");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_conferenceControllerCallInfo)
				t.push(_conferenceControllerCallInfo);
			if (_conferenceMemberCallInfoList)
				t.push(_conferenceMemberCallInfoList);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceDestroyedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferenceDestroyedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceControllerCallInfo"), errors, newContext, "conferenceControllerCallInfo");
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfolist = AsnCtiCallInformationList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceMemberCallInfoList"), errors, newContext, "conferenceMemberCallInfoList");
			if (_conferencemembercallinfolist)
				t.conferenceMemberCallInfoList = _conferencemembercallinfolist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferenceDestroyedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyConferencePartyAddedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferencePartyAddedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyConferencePartyAddedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		if (_conferenceControllerCallInfo)
			t.conferenceControllerCallInfo = _conferenceControllerCallInfo;
		const _conferenceMemberCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo");
		if (_conferenceMemberCallInfo)
			t.conferenceMemberCallInfo = _conferenceMemberCallInfo;

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyAddedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferencePartyAddedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo", false);
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo", false);
			if (_conferencemembercallinfo)
				t.conferenceMemberCallInfo = _conferencemembercallinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyAddedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyConferencePartyAddedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferencePartyAddedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		const _conferenceMemberCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_conferenceControllerCallInfo)
				t.push(_conferenceControllerCallInfo);
			if (_conferenceMemberCallInfo)
				t.push(_conferenceMemberCallInfo);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyAddedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferencePartyAddedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceControllerCallInfo"), errors, newContext, "conferenceControllerCallInfo");
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceMemberCallInfo"), errors, newContext, "conferenceMemberCallInfo");
			if (_conferencemembercallinfo)
				t.conferenceMemberCallInfo = _conferencemembercallinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyAddedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyConferencePartyRemovedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferencePartyRemovedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyConferencePartyRemovedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		if (_conferenceControllerCallInfo)
			t.conferenceControllerCallInfo = _conferenceControllerCallInfo;
		const _conferenceMemberCallInfo = AsnCtiCallInformation_Converter.toJSON(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo");
		if (_conferenceMemberCallInfo)
			t.conferenceMemberCallInfo = _conferenceMemberCallInfo;

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyRemovedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferencePartyRemovedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo", false);
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfo = AsnCtiCallInformation_Converter.fromJSON(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo", false);
			if (_conferencemembercallinfo)
				t.conferenceMemberCallInfo = _conferencemembercallinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyRemovedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyConferencePartyRemovedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyConferencePartyRemovedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _conferenceControllerCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceControllerCallInfo, errors, newContext, "conferenceControllerCallInfo");
		const _conferenceMemberCallInfo = AsnCtiCallInformation_Converter.toBER(s.conferenceMemberCallInfo, errors, newContext, "conferenceMemberCallInfo");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_conferenceControllerCallInfo)
				t.push(_conferenceControllerCallInfo);
			if (_conferenceMemberCallInfo)
				t.push(_conferenceMemberCallInfo);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyRemovedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyConferencePartyRemovedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _conferencecontrollercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceControllerCallInfo"), errors, newContext, "conferenceControllerCallInfo");
			if (_conferencecontrollercallinfo)
				t.conferenceControllerCallInfo = _conferencecontrollercallinfo;
			const _conferencemembercallinfo = AsnCtiCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "conferenceMemberCallInfo"), errors, newContext, "conferenceMemberCallInfo");
			if (_conferencemembercallinfo)
				t.conferenceMemberCallInfo = _conferencemembercallinfo;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyConferencePartyRemovedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiCallFeaturesList_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiCallFeaturesList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiCallFeaturesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallFeaturesList");

		const t = [] as ENetUC_CTI.AsnCtiCallFeaturesList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnCtiCallFeature_Converter.toJSON(se, errors, newContext, "AsnCtiCallFeature");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeaturesList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallFeaturesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallFeaturesList");

		let t: ENetUC_CTI.AsnCtiCallFeaturesList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiCallFeaturesList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_CTI.AsnCtiCallFeaturesList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnCtiCallFeature_Converter.fromJSON(se, errors, newContext, "AsnCtiCallFeature", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeaturesList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiCallFeaturesList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiCallFeaturesList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiCallFeaturesList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnCtiCallFeature_Converter.toBER(s[id], errors, newContext, "AsnCtiCallFeature");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnCtiCallFeaturesList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiCallFeaturesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiCallFeaturesList");

		let t: ENetUC_CTI.AsnCtiCallFeaturesList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiCallFeaturesList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_CTI.AsnCtiCallFeaturesList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnCtiCallFeature_Converter.fromBER(se, errors, newContext, "AsnCtiCallFeature", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnCtiCallFeaturesList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiNotifyLineCallFeaturesChangedArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineCallFeaturesChangedArgument");

		const t = {} as ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiNotifyLineCallFeaturesChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext);
		const _callFeaturesList = AsnCtiCallFeaturesList_Converter.toJSON(s.callFeaturesList, errors, newContext, "callFeaturesList");
		if (_callFeaturesList)
			t.callFeaturesList = _callFeaturesList;

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallFeaturesChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineCallFeaturesChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLineMonitorCrossRefID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLinePhoneNumber", "string", errors, newContext, false);
			const _callfeatureslist = AsnCtiCallFeaturesList_Converter.fromJSON(s.callFeaturesList, errors, newContext, "callFeaturesList", false);
			if (_callfeatureslist)
				t.callFeaturesList = _callfeatureslist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallFeaturesChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiNotifyLineCallFeaturesChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiNotifyLineCallFeaturesChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLineMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLinePhoneNumber", "string", errors, newContext);
		const _callFeaturesList = AsnCtiCallFeaturesList_Converter.toBER(s.callFeaturesList, errors, newContext, "callFeaturesList");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLineMonitorCrossRefID, name: "u8sLineMonitorCrossRefID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinePhoneNumber, name: "u8sLinePhoneNumber" }));
			if (_callFeaturesList)
				t.push(_callFeaturesList);
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallFeaturesChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiNotifyLineCallFeaturesChangedArgument");

		let t: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLineMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinePhoneNumber", "Utf8String", errors, newContext);
			const _callfeatureslist = AsnCtiCallFeaturesList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callFeaturesList"), errors, newContext, "callFeaturesList");
			if (_callfeatureslist)
				t.callFeaturesList = _callfeatureslist;
		}

		if (errors.validateResult(newContext, "AsnCtiNotifyLineCallFeaturesChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCtiSetCallDataArgument_Converter {
	public static toJSON(s: ENetUC_CTI.AsnCtiSetCallDataArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_CTI.AsnCtiSetCallDataArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetCallDataArgument");

		const t = {} as ENetUC_CTI.AsnCtiSetCallDataArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCtiSetCallDataArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		const _callDataList = AsnCtiCallDataList_Converter.toJSON(s.callDataList, errors, newContext, "callDataList");
		if (_callDataList)
			t.callDataList = _callDataList;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetCallDataArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetCallDataArgument");

		let t: ENetUC_CTI.AsnCtiSetCallDataArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_CTI.AsnCtiSetCallDataArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_CTI.AsnCtiSetCallDataArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext, false);
			const _calldatalist = AsnCtiCallDataList_Converter.fromJSON(s.callDataList, errors, newContext, "callDataList", false);
			if (_calldatalist)
				t.callDataList = _calldatalist;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_CTI.AsnCtiSetCallDataArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCtiSetCallDataArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCtiSetCallDataArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		const _callDataList = AsnCtiCallDataList_Converter.toBER(s.callDataList, errors, newContext, "callDataList");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			if (_callDataList)
				t.push(_callDataList);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_CTI.AsnCtiSetCallDataArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCtiSetCallDataArgument");

		let t: ENetUC_CTI.AsnCtiSetCallDataArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_CTI.AsnCtiSetCallDataArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_CTI.AsnCtiSetCallDataArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			const _calldatalist = AsnCtiCallDataList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callDataList"), errors, newContext, "callDataList");
			if (_calldatalist)
				t.callDataList = _calldatalist;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCtiSetCallDataArgument"))
			return t;

		return undefined;
	}
}
