import { StateCreator } from "zustand";

import { StoreT } from "./store";

export type MyselfSliceT = IState & IActions;

export interface IIdleProfile {
	idleEnabled: boolean;
	idleDelay: number; // Minutes
	idleSessionLock: boolean;
}

interface IState {
	contactID: string;
	loggedIn: boolean;
	token: string | undefined;
	ucsid: string | undefined;
	ucserveruri: string | undefined;
	lastCustomNotes: string[];
	cookiesAccepted: boolean;
	idleProfile: IIdleProfile;
}

interface IActions {
	setMySelfIsLogged: (loggedIn: boolean) => void;
	setMySelfUCSID: (ucsid: string) => void;
	setMySelfUCServerURI: (ucserveruri: string) => void;
	setMySelfToken: (token: string | undefined) => void;
	setMyselfIdleSettings: (idleProfile: IIdleProfile) => void;
}

export const initialState = {
	contactID: "",
	loggedIn: false,
	token: undefined,
	ucsid: undefined,
	ucserveruri: undefined,
	lastCustomNotes: [],
	cookiesAccepted: false,
	idleProfile: { idleEnabled: true, idleDelay: 5, idleSessionLock: true }
};

export const createMyselfSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	MyselfSliceT
> = (set) => ({
	...initialState,
	setMySelfIsLogged: (loggedIn: boolean) => {
		set(
			(prev) => ({
				...prev,
				loggedIn
			}),
			false,
			"setMySelfIsLogged"
		);
	},
	setMySelfUCSID: (ucsid: string) => {
		set(
			(prev) => ({
				...prev,
				ucsid
			}),
			false,
			"setMySelfUCSID"
		);
	},
	setMySelfUCServerURI: (ucserveruri: string) => {
		set(
			(prev) => ({
				...prev,
				ucserveruri
			}),
			false,
			"setMySelfUCServerURI"
		);
	},
	setMySelfToken: (token: string | undefined) => {
		set(
			(prev) => ({
				...prev,
				token
			}),
			false,
			"setMySelfToken"
		);
	},
	setMyselfIdleSettings: (idleProfile: IIdleProfile) => {
		set(
			(prev) => ({
				...prev,
				idleProfile
			}),
			false,
			"setMyselfIdleSettings"
		);
	}
});
