// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientContent_Converter.ts
 * "UC-Server-Access-Protocol-ClientContent" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_ClientContent from "./ENetUC_ClientContent";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_ClientContent_Converter";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSEncoderDecoderCode]
export class AsnGetClientContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnGetClientContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnGetClientContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientContentArgument");

		const t = {} as ENetUC_ClientContent.AsnGetClientContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetClientContentArgument";
		TSConverter.fillJSONParam(s, t, "iContactFilter", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetClientContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientContentArgument");

		let t: ENetUC_ClientContent.AsnGetClientContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnGetClientContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnGetClientContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iContactFilter", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnGetClientContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnGetClientContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetClientContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientContentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iContactFilter", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iContactFilter, name: "iContactFilter" }));
		}

		if (errors.validateResult(newContext, "AsnGetClientContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientContentArgument");

		let t: ENetUC_ClientContent.AsnGetClientContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnGetClientContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnGetClientContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iContactFilter", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetClientContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSubscribeClientContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnSubscribeClientContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnSubscribeClientContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSubscribeClientContentArgument");

		const t = {} as ENetUC_ClientContent.AsnSubscribeClientContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSubscribeClientContentArgument";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		if (s.u8sGroupClusterIDRestriction) {
			const _u8sGroupClusterIDRestriction = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sGroupClusterIDRestriction, errors, newContext, "u8sGroupClusterIDRestriction");
			if (_u8sGroupClusterIDRestriction)
				t.u8sGroupClusterIDRestriction = _u8sGroupClusterIDRestriction;
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnSubscribeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSubscribeClientContentArgument");

		let t: ENetUC_ClientContent.AsnSubscribeClientContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnSubscribeClientContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnSubscribeClientContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);
			const _u8sgroupclusteridrestriction = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sGroupClusterIDRestriction, errors, newContext, "u8sGroupClusterIDRestriction", true);
			if (_u8sgroupclusteridrestriction)
				t.u8sGroupClusterIDRestriction = _u8sgroupclusteridrestriction;
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnSubscribeClientContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSubscribeClientContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSubscribeClientContentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		const _u8sGroupClusterIDRestriction = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sGroupClusterIDRestriction, errors, newContext, "u8sGroupClusterIDRestriction", 2);
		if (!errors.hasNewErrors()) {
			if (s.iRevision !== undefined)
				t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision", idBlock: { optionalID: 0 } }));
			if (s.iEventFlagsToSubscribe !== undefined)
				t.push(new asn1ts.Integer({ value: s.iEventFlagsToSubscribe, name: "iEventFlagsToSubscribe", idBlock: { optionalID: 1 } }));
			if (_u8sGroupClusterIDRestriction)
				t.push(_u8sGroupClusterIDRestriction);
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnSubscribeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSubscribeClientContentArgument");

		let t: ENetUC_ClientContent.AsnSubscribeClientContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnSubscribeClientContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnSubscribeClientContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iEventFlagsToSubscribe", "Integer", errors, newContext, true);
			t.u8sGroupClusterIDRestriction = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sGroupClusterIDRestriction"), errors, newContext, "u8sGroupClusterIDRestriction", true);
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSubscribeClientContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnSubscribeClientContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnSubscribeClientContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSubscribeClientContentResult");

		const t = {} as ENetUC_ClientContent.AsnSubscribeClientContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSubscribeClientContentResult";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnSubscribeClientContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnSubscribeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSubscribeClientContentResult");

		let t: ENetUC_ClientContent.AsnSubscribeClientContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnSubscribeClientContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnSubscribeClientContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnSubscribeClientContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSubscribeClientContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSubscribeClientContentResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnSubscribeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSubscribeClientContentResult");

		let t: ENetUC_ClientContent.AsnSubscribeClientContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnSubscribeClientContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnSubscribeClientContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSubscribeClientContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribeClientContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnUnsubscribeClientContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeClientContentArgument");

		const t = {} as ENetUC_ClientContent.AsnUnsubscribeClientContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribeClientContentArgument";

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeClientContentArgument");

		let t: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnUnsubscribeClientContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnUnsubscribeClientContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribeClientContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeClientContentArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeClientContentArgument");

		let t: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnUnsubscribeClientContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnUnsubscribeClientContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribeClientContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnUnsubscribeClientContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnUnsubscribeClientContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeClientContentResult");

		const t = {} as ENetUC_ClientContent.AsnUnsubscribeClientContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribeClientContentResult";

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeClientContentResult");

		let t: ENetUC_ClientContent.AsnUnsubscribeClientContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnUnsubscribeClientContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnUnsubscribeClientContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnUnsubscribeClientContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribeClientContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeClientContentResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeClientContentResult");

		let t: ENetUC_ClientContent.AsnUnsubscribeClientContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnUnsubscribeClientContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnUnsubscribeClientContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeClientContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnChangeClientContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnChangeClientContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnChangeClientContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnChangeClientContentResult");

		const t = {} as ENetUC_ClientContent.AsnChangeClientContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnChangeClientContentResult";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnChangeClientContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnChangeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnChangeClientContentResult");

		let t: ENetUC_ClientContent.AsnChangeClientContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnChangeClientContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnChangeClientContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnChangeClientContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnChangeClientContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnChangeClientContentResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnChangeClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnChangeClientContentResult");

		let t: ENetUC_ClientContent.AsnChangeClientContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnChangeClientContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnChangeClientContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentItem_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentItem, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentItem & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentItem");

		const t = {} as ENetUC_ClientContent.AsnClientContentItem & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentItem";
		TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPresenceID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext);
		const _listNetDatabaseContactIDs = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(s.listNetDatabaseContactIDs, errors, newContext, "listNetDatabaseContactIDs");
		if (_listNetDatabaseContactIDs)
			t.listNetDatabaseContactIDs = _listNetDatabaseContactIDs;
		const _selectedNetDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(s.selectedNetDatabaseContactID, errors, newContext, "selectedNetDatabaseContactID");
		if (_selectedNetDatabaseContactID)
			t.selectedNetDatabaseContactID = _selectedNetDatabaseContactID;

		if (errors.validateResult(newContext, "AsnClientContentItem"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentItem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentItem");

		let t: ENetUC_ClientContent.AsnClientContentItem | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentItem>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentItem["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPresenceID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext, false);
			const _listnetdatabasecontactids = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(s.listNetDatabaseContactIDs, errors, newContext, "listNetDatabaseContactIDs", false);
			if (_listnetdatabasecontactids)
				t.listNetDatabaseContactIDs = _listnetdatabasecontactids;
			const _selectednetdatabasecontactid = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(s.selectedNetDatabaseContactID, errors, newContext, "selectedNetDatabaseContactID", false);
			if (_selectednetdatabasecontactid)
				t.selectedNetDatabaseContactID = _selectednetdatabasecontactid;
		}

		if (errors.validateResult(newContext, "AsnClientContentItem"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentItem | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentItem";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentItem");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPresenceID", "string", errors, newContext);
		TSConverter.validateParam(s, "iIsEditable", "number", errors, newContext);
		const _listNetDatabaseContactIDs = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toBER(s.listNetDatabaseContactIDs, errors, newContext, "listNetDatabaseContactIDs");
		const _selectedNetDatabaseContactID = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toBER(s.selectedNetDatabaseContactID, errors, newContext, "selectedNetDatabaseContactID");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sID, name: "u8sID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPresenceID, name: "u8sPresenceID" }));
			t.push(new asn1ts.Integer({ value: s.iIsEditable, name: "iIsEditable" }));
			if (_listNetDatabaseContactIDs)
				t.push(_listNetDatabaseContactIDs);
			if (_selectedNetDatabaseContactID)
				t.push(_selectedNetDatabaseContactID);
		}

		if (errors.validateResult(newContext, "AsnClientContentItem"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentItem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentItem");

		let t: ENetUC_ClientContent.AsnClientContentItem | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentItem.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentItem["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPresenceID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsEditable", "Integer", errors, newContext);
			const _listnetdatabasecontactids = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listNetDatabaseContactIDs"), errors, newContext, "listNetDatabaseContactIDs");
			if (_listnetdatabasecontactids)
				t.listNetDatabaseContactIDs = _listnetdatabasecontactids;
			const _selectednetdatabasecontactid = ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "selectedNetDatabaseContactID"), errors, newContext, "selectedNetDatabaseContactID");
			if (_selectednetdatabasecontactid)
				t.selectedNetDatabaseContactID = _selectednetdatabasecontactid;
		}

		if (errors.validateResult(newContext, "AsnClientContentItem"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentSubscribeToTagsArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSubscribeToTagsArgument");

		const t = {} as ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentSubscribeToTagsArgument";
		TSConverter.fillJSONParam(s, t, "iSubscribe", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSubscribeToTagsArgument");

		let t: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iSubscribe", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentSubscribeToTagsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSubscribeToTagsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iSubscribe", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iSubscribe, name: "iSubscribe" }));
		}

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSubscribeToTagsArgument");

		let t: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iSubscribe", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentSubscribeToTagsResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSubscribeToTagsResult");

		const t = {} as ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentSubscribeToTagsResult";

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSubscribeToTagsResult");

		let t: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentSubscribeToTagsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSubscribeToTagsResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSubscribeToTagsResult");

		let t: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnClientContentSubscribeToTagsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentTaggedIDsChangedArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentTaggedIDsChangedArgument");

		const t = {} as ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentTaggedIDsChangedArgument";
		const _u8sTaggedIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs");
		if (_u8sTaggedIDs)
			t.u8sTaggedIDs = _u8sTaggedIDs;
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientContentTaggedIDsChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentTaggedIDsChangedArgument");

		let t: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8staggedids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs", false);
			if (_u8staggedids)
				t.u8sTaggedIDs = _u8staggedids;
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientContentTaggedIDsChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentTaggedIDsChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentTaggedIDsChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sTaggedIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs");
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_u8sTaggedIDs)
				t.push(_u8sTaggedIDs);
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
		}

		if (errors.validateResult(newContext, "AsnClientContentTaggedIDsChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentTaggedIDsChangedArgument");

		let t: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8staggedids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sTaggedIDs"), errors, newContext, "u8sTaggedIDs");
			if (_u8staggedids)
				t.u8sTaggedIDs = _u8staggedids;
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientContentTaggedIDsChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentSaveTagsArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentSaveTagsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentSaveTagsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSaveTagsArgument");

		const t = {} as ENetUC_ClientContent.AsnClientContentSaveTagsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentSaveTagsArgument";
		const _u8sTaggedIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs");
		if (_u8sTaggedIDs)
			t.u8sTaggedIDs = _u8sTaggedIDs;
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSaveTagsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSaveTagsArgument");

		let t: ENetUC_ClientContent.AsnClientContentSaveTagsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentSaveTagsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentSaveTagsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8staggedids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs", false);
			if (_u8staggedids)
				t.u8sTaggedIDs = _u8staggedids;
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentSaveTagsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentSaveTagsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSaveTagsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sTaggedIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sTaggedIDs, errors, newContext, "u8sTaggedIDs");
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_u8sTaggedIDs)
				t.push(_u8sTaggedIDs);
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSaveTagsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSaveTagsArgument");

		let t: ENetUC_ClientContent.AsnClientContentSaveTagsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentSaveTagsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentSaveTagsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8staggedids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sTaggedIDs"), errors, newContext, "u8sTaggedIDs");
			if (_u8staggedids)
				t.u8sTaggedIDs = _u8staggedids;
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentSaveTagsResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentSaveTagsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentSaveTagsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSaveTagsResult");

		const t = {} as ENetUC_ClientContent.AsnClientContentSaveTagsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentSaveTagsResult";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSaveTagsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSaveTagsResult");

		let t: ENetUC_ClientContent.AsnClientContentSaveTagsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentSaveTagsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentSaveTagsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentSaveTagsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentSaveTagsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentSaveTagsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentSaveTagsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentSaveTagsResult");

		let t: ENetUC_ClientContent.AsnClientContentSaveTagsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentSaveTagsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentSaveTagsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientContentSaveTagsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeFunctionKey_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeFunctionKey, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeFunctionKey & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeFunctionKey");

		const t = {} as ENetUC_ClientContent.AsnAdministrativeFunctionKey & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdministrativeFunctionKey";
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKey"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeFunctionKey | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeFunctionKey");

		let t: ENetUC_ClientContent.AsnAdministrativeFunctionKey | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeFunctionKey>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnAdministrativeFunctionKey["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKey"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeFunctionKey | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeFunctionKey";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeFunctionKey");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sValue", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iType, name: "iType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sValue, name: "u8sValue" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKey"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeFunctionKey | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeFunctionKey");

		let t: ENetUC_ClientContent.AsnAdministrativeFunctionKey | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeFunctionKey.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnAdministrativeFunctionKey["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sValue", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKey"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAndSubscribeAdministrativeMonitorContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentArgument");

		const t = {} as ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAndSubscribeAdministrativeMonitorContentArgument";
		TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentArgument");

		let t: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAndSubscribeAdministrativeMonitorContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iEventFlagsToSubscribe", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (s.iEventFlagsToSubscribe !== undefined)
				t.push(new asn1ts.Integer({ value: s.iEventFlagsToSubscribe, name: "iEventFlagsToSubscribe", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentArgument");

		let t: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iEventFlagsToSubscribe", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribeAdministrativeMonitorContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentArgument");

		const t = {} as ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribeAdministrativeMonitorContentArgument";

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentArgument");

		let t: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribeAdministrativeMonitorContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentArgument");

		let t: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUnsubscribeAdministrativeMonitorContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentResult");

		const t = {} as ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUnsubscribeAdministrativeMonitorContentResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentResult");

		let t: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUnsubscribeAdministrativeMonitorContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUnsubscribeAdministrativeMonitorContentResult");

		let t: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUnsubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentItemList_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentItemList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentItemList");

		const t = [] as ENetUC_ClientContent.AsnClientContentItemList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientContentItem_Converter.toJSON(se, errors, newContext, "AsnClientContentItem");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientContentItemList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentItemList");

		let t: ENetUC_ClientContent.AsnClientContentItemList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentItemList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientContent.AsnClientContentItemList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientContentItem_Converter.fromJSON(se, errors, newContext, "AsnClientContentItem", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentItemList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentItemList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentItemList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentItemList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientContentItem_Converter.toBER(s[id], errors, newContext, "AsnClientContentItem");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnClientContentItemList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentItemList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentItemList");

		let t: ENetUC_ClientContent.AsnClientContentItemList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentItemList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientContent.AsnClientContentItemList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientContentItem_Converter.fromBER(se, errors, newContext, "AsnClientContentItem", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentItemList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeFunctionKeyList_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeFunctionKeyList");

		const t = [] as ENetUC_ClientContent.AsnAdministrativeFunctionKeyList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnAdministrativeFunctionKey_Converter.toJSON(se, errors, newContext, "AsnAdministrativeFunctionKey");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKeyList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeFunctionKeyList");

		let t: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeFunctionKeyList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientContent.AsnAdministrativeFunctionKeyList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnAdministrativeFunctionKey_Converter.fromJSON(se, errors, newContext, "AsnAdministrativeFunctionKey", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKeyList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeFunctionKeyList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeFunctionKeyList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnAdministrativeFunctionKey_Converter.toBER(s[id], errors, newContext, "AsnAdministrativeFunctionKey");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKeyList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeFunctionKeyList");

		let t: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeFunctionKeyList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientContent.AsnAdministrativeFunctionKeyList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnAdministrativeFunctionKey_Converter.fromBER(se, errors, newContext, "AsnAdministrativeFunctionKey", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnAdministrativeFunctionKeyList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentGroup_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentGroup, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentGroup & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroup");

		const t = {} as ENetUC_ClientContent.AsnClientContentGroup & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentGroup";
		TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sOrderBy", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAutorefresh", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iExpanded", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsPersistent", "number", errors, newContext);
		const _listItems = AsnClientContentItemList_Converter.toJSON(s.listItems, errors, newContext, "listItems");
		if (_listItems)
			t.listItems = _listItems;

		if (errors.validateResult(newContext, "AsnClientContentGroup"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroup | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroup");

		let t: ENetUC_ClientContent.AsnClientContentGroup | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentGroup>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentGroup["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sOrderBy", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iAutorefresh", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iExpanded", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsPersistent", "number", errors, newContext, false);
			const _listitems = AsnClientContentItemList_Converter.fromJSON(s.listItems, errors, newContext, "listItems", false);
			if (_listitems)
				t.listItems = _listitems;
		}

		if (errors.validateResult(newContext, "AsnClientContentGroup"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentGroup | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentGroup";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroup");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sOrderBy", "string", errors, newContext);
		TSConverter.validateParam(s, "iAutorefresh", "number", errors, newContext);
		TSConverter.validateParam(s, "iExpanded", "number", errors, newContext);
		TSConverter.validateParam(s, "iIsEditable", "number", errors, newContext);
		TSConverter.validateParam(s, "iIsPersistent", "number", errors, newContext);
		const _listItems = AsnClientContentItemList_Converter.toBER(s.listItems, errors, newContext, "listItems");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sID, name: "u8sID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sOrderBy, name: "u8sOrderBy" }));
			t.push(new asn1ts.Integer({ value: s.iAutorefresh, name: "iAutorefresh" }));
			t.push(new asn1ts.Integer({ value: s.iExpanded, name: "iExpanded" }));
			t.push(new asn1ts.Integer({ value: s.iIsEditable, name: "iIsEditable" }));
			t.push(new asn1ts.Integer({ value: s.iIsPersistent, name: "iIsPersistent" }));
			if (_listItems)
				t.push(_listItems);
		}

		if (errors.validateResult(newContext, "AsnClientContentGroup"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroup | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroup");

		let t: ENetUC_ClientContent.AsnClientContentGroup | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentGroup.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentGroup["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sOrderBy", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAutorefresh", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iExpanded", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsEditable", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsPersistent", "Integer", errors, newContext);
			const _listitems = AsnClientContentItemList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listItems"), errors, newContext, "listItems");
			if (_listitems)
				t.listItems = _listitems;
		}

		if (errors.validateResult(newContext, "AsnClientContentGroup"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentGroupList_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentGroupList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupList");

		const t = [] as ENetUC_ClientContent.AsnClientContentGroupList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientContentGroup_Converter.toJSON(se, errors, newContext, "AsnClientContentGroup");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupList");

		let t: ENetUC_ClientContent.AsnClientContentGroupList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentGroupList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientContent.AsnClientContentGroupList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientContentGroup_Converter.fromJSON(se, errors, newContext, "AsnClientContentGroup", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentGroupList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentGroupList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientContentGroup_Converter.toBER(s[id], errors, newContext, "AsnClientContentGroup");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnClientContentGroupList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupList");

		let t: ENetUC_ClientContent.AsnClientContentGroupList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentGroupList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientContent.AsnClientContentGroupList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientContentGroup_Converter.fromBER(se, errors, newContext, "AsnClientContentGroup", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeMonitorGroup_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeMonitorGroup, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeMonitorGroup & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorGroup");

		const t = {} as ENetUC_ClientContent.AsnAdministrativeMonitorGroup & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdministrativeMonitorGroup";
		TSConverter.fillJSONParam(s, t, "u8sGroupURI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sGroupName", "string", errors, newContext);
		const _listContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.listContactIDs, errors, newContext, "listContactIDs");
		if (_listContactIDs)
			t.listContactIDs = _listContactIDs;
		if (s.listFunctionKeys) {
			const _listFunctionKeys = AsnAdministrativeFunctionKeyList_Converter.toJSON(s.listFunctionKeys, errors, newContext, "listFunctionKeys");
			if (_listFunctionKeys)
				t.listFunctionKeys = _listFunctionKeys;
		}
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroup"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorGroup | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorGroup");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorGroup | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeMonitorGroup>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorGroup["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sGroupURI", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sGroupName", "string", errors, newContext, false);
			const _listcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.listContactIDs, errors, newContext, "listContactIDs", false);
			if (_listcontactids)
				t.listContactIDs = _listcontactids;
			const _listfunctionkeys = AsnAdministrativeFunctionKeyList_Converter.fromJSON(s.listFunctionKeys, errors, newContext, "listFunctionKeys", true);
			if (_listfunctionkeys)
				t.listFunctionKeys = _listfunctionkeys;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroup"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeMonitorGroup | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeMonitorGroup";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorGroup");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sGroupURI", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sGroupName", "string", errors, newContext);
		const _listContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.listContactIDs, errors, newContext, "listContactIDs");
		const _listFunctionKeys = AsnAdministrativeFunctionKeyList_Converter.toBER(s.listFunctionKeys, errors, newContext, "listFunctionKeys", 0);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 10);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sGroupURI, name: "u8sGroupURI" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sGroupName, name: "u8sGroupName" }));
			if (_listContactIDs)
				t.push(_listContactIDs);
			if (_listFunctionKeys)
				t.push(_listFunctionKeys);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroup"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorGroup | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorGroup");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorGroup | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeMonitorGroup.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorGroup["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sGroupURI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sGroupName", "Utf8String", errors, newContext);
			const _listcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listContactIDs"), errors, newContext, "listContactIDs");
			if (_listcontactids)
				t.listContactIDs = _listcontactids;
			t.listFunctionKeys = AsnAdministrativeFunctionKeyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listFunctionKeys"), errors, newContext, "listFunctionKeys", true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroup"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeMonitorGroupList_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorGroupList");

		const t = [] as ENetUC_ClientContent.AsnAdministrativeMonitorGroupList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnAdministrativeMonitorGroup_Converter.toJSON(se, errors, newContext, "AsnAdministrativeMonitorGroup");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroupList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorGroupList");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeMonitorGroupList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientContent.AsnAdministrativeMonitorGroupList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnAdministrativeMonitorGroup_Converter.fromJSON(se, errors, newContext, "AsnAdministrativeMonitorGroup", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroupList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeMonitorGroupList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorGroupList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnAdministrativeMonitorGroup_Converter.toBER(s[id], errors, newContext, "AsnAdministrativeMonitorGroup");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroupList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorGroupList");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeMonitorGroupList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientContent.AsnAdministrativeMonitorGroupList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnAdministrativeMonitorGroup_Converter.fromBER(se, errors, newContext, "AsnAdministrativeMonitorGroup", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorGroupList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAndSubscribeAdministrativeMonitorContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentResult");

		const t = {} as ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAndSubscribeAdministrativeMonitorContentResult";
		const _listAdministrativeMonitorGroups = AsnAdministrativeMonitorGroupList_Converter.toJSON(s.listAdministrativeMonitorGroups, errors, newContext, "listAdministrativeMonitorGroups");
		if (_listAdministrativeMonitorGroups)
			t.listAdministrativeMonitorGroups = _listAdministrativeMonitorGroups;

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentResult");

		let t: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _listadministrativemonitorgroups = AsnAdministrativeMonitorGroupList_Converter.fromJSON(s.listAdministrativeMonitorGroups, errors, newContext, "listAdministrativeMonitorGroups", false);
			if (_listadministrativemonitorgroups)
				t.listAdministrativeMonitorGroups = _listadministrativemonitorgroups;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAndSubscribeAdministrativeMonitorContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentResult");

		// [Print_BER_EncoderSeqDefCode]
		const _listAdministrativeMonitorGroups = AsnAdministrativeMonitorGroupList_Converter.toBER(s.listAdministrativeMonitorGroups, errors, newContext, "listAdministrativeMonitorGroups");
		if (!errors.hasNewErrors()) {
			if (_listAdministrativeMonitorGroups)
				t.push(_listAdministrativeMonitorGroups);
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAndSubscribeAdministrativeMonitorContentResult");

		let t: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _listadministrativemonitorgroups = AsnAdministrativeMonitorGroupList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listAdministrativeMonitorGroups"), errors, newContext, "listAdministrativeMonitorGroups");
			if (_listadministrativemonitorgroups)
				t.listAdministrativeMonitorGroups = _listadministrativemonitorgroups;
		}

		if (errors.validateResult(newContext, "AsnGetAndSubscribeAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeMonitorContentGroupAddedArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupAddedArgument");

		const t = {} as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdministrativeMonitorContentGroupAddedArgument";
		const _adminMonitorGroupAdded = AsnAdministrativeMonitorGroup_Converter.toJSON(s.adminMonitorGroupAdded, errors, newContext, "adminMonitorGroupAdded");
		if (_adminMonitorGroupAdded)
			t.adminMonitorGroupAdded = _adminMonitorGroupAdded;

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupAddedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupAddedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _adminmonitorgroupadded = AsnAdministrativeMonitorGroup_Converter.fromJSON(s.adminMonitorGroupAdded, errors, newContext, "adminMonitorGroupAdded", false);
			if (_adminmonitorgroupadded)
				t.adminMonitorGroupAdded = _adminmonitorgroupadded;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupAddedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeMonitorContentGroupAddedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupAddedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _adminMonitorGroupAdded = AsnAdministrativeMonitorGroup_Converter.toBER(s.adminMonitorGroupAdded, errors, newContext, "adminMonitorGroupAdded");
		if (!errors.hasNewErrors()) {
			if (_adminMonitorGroupAdded)
				t.push(_adminMonitorGroupAdded);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupAddedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupAddedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _adminmonitorgroupadded = AsnAdministrativeMonitorGroup_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "adminMonitorGroupAdded"), errors, newContext, "adminMonitorGroupAdded");
			if (_adminmonitorgroupadded)
				t.adminMonitorGroupAdded = _adminmonitorgroupadded;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupAddedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeMonitorContentGroupChangedArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupChangedArgument");

		const t = {} as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdministrativeMonitorContentGroupChangedArgument";
		const _adminMonitorGroupChanged = AsnAdministrativeMonitorGroup_Converter.toJSON(s.adminMonitorGroupChanged, errors, newContext, "adminMonitorGroupChanged");
		if (_adminMonitorGroupChanged)
			t.adminMonitorGroupChanged = _adminMonitorGroupChanged;

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupChangedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _adminmonitorgroupchanged = AsnAdministrativeMonitorGroup_Converter.fromJSON(s.adminMonitorGroupChanged, errors, newContext, "adminMonitorGroupChanged", false);
			if (_adminmonitorgroupchanged)
				t.adminMonitorGroupChanged = _adminmonitorgroupchanged;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeMonitorContentGroupChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _adminMonitorGroupChanged = AsnAdministrativeMonitorGroup_Converter.toBER(s.adminMonitorGroupChanged, errors, newContext, "adminMonitorGroupChanged");
		if (!errors.hasNewErrors()) {
			if (_adminMonitorGroupChanged)
				t.push(_adminMonitorGroupChanged);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupChangedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _adminmonitorgroupchanged = AsnAdministrativeMonitorGroup_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "adminMonitorGroupChanged"), errors, newContext, "adminMonitorGroupChanged");
			if (_adminmonitorgroupchanged)
				t.adminMonitorGroupChanged = _adminmonitorgroupchanged;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdministrativeMonitorContentGroupRemovedArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupRemovedArgument");

		const t = {} as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdministrativeMonitorContentGroupRemovedArgument";
		const _adminMonitorGroupRemoved = AsnAdministrativeMonitorGroup_Converter.toJSON(s.adminMonitorGroupRemoved, errors, newContext, "adminMonitorGroupRemoved");
		if (_adminMonitorGroupRemoved)
			t.adminMonitorGroupRemoved = _adminMonitorGroupRemoved;

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupRemovedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupRemovedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _adminmonitorgroupremoved = AsnAdministrativeMonitorGroup_Converter.fromJSON(s.adminMonitorGroupRemoved, errors, newContext, "adminMonitorGroupRemoved", false);
			if (_adminmonitorgroupremoved)
				t.adminMonitorGroupRemoved = _adminmonitorgroupremoved;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupRemovedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdministrativeMonitorContentGroupRemovedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdministrativeMonitorContentGroupRemovedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _adminMonitorGroupRemoved = AsnAdministrativeMonitorGroup_Converter.toBER(s.adminMonitorGroupRemoved, errors, newContext, "adminMonitorGroupRemoved");
		if (!errors.hasNewErrors()) {
			if (_adminMonitorGroupRemoved)
				t.push(_adminMonitorGroupRemoved);
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupRemovedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdministrativeMonitorContentGroupRemovedArgument");

		let t: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _adminmonitorgroupremoved = AsnAdministrativeMonitorGroup_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "adminMonitorGroupRemoved"), errors, newContext, "adminMonitorGroupRemoved");
			if (_adminmonitorgroupremoved)
				t.adminMonitorGroupRemoved = _adminmonitorgroupremoved;
		}

		if (errors.validateResult(newContext, "AsnAdministrativeMonitorContentGroupRemovedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentGroupCluster_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentGroupCluster, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentGroupCluster & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupCluster");

		const t = {} as ENetUC_ClientContent.AsnClientContentGroupCluster & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentGroupCluster";
		TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsPersistent", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsTabbed", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sNeighbourClusterID", "string", errors, newContext);
		const _listGroups = AsnClientContentGroupList_Converter.toJSON(s.listGroups, errors, newContext, "listGroups");
		if (_listGroups)
			t.listGroups = _listGroups;

		if (errors.validateResult(newContext, "AsnClientContentGroupCluster"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupCluster | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupCluster");

		let t: ENetUC_ClientContent.AsnClientContentGroupCluster | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentGroupCluster>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentGroupCluster["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsPersistent", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsTabbed", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sNeighbourClusterID", "string", errors, newContext, false);
			const _listgroups = AsnClientContentGroupList_Converter.fromJSON(s.listGroups, errors, newContext, "listGroups", false);
			if (_listgroups)
				t.listGroups = _listgroups;
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupCluster"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentGroupCluster | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentGroupCluster";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupCluster");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		TSConverter.validateParam(s, "iIsEditable", "number", errors, newContext);
		TSConverter.validateParam(s, "iIsPersistent", "number", errors, newContext);
		TSConverter.validateParam(s, "iIsTabbed", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sNeighbourClusterID", "string", errors, newContext);
		const _listGroups = AsnClientContentGroupList_Converter.toBER(s.listGroups, errors, newContext, "listGroups");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sID, name: "u8sID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
			t.push(new asn1ts.Integer({ value: s.iIsEditable, name: "iIsEditable" }));
			t.push(new asn1ts.Integer({ value: s.iIsPersistent, name: "iIsPersistent" }));
			t.push(new asn1ts.Integer({ value: s.iIsTabbed, name: "iIsTabbed" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sNeighbourClusterID, name: "u8sNeighbourClusterID" }));
			if (_listGroups)
				t.push(_listGroups);
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupCluster"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupCluster | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupCluster");

		let t: ENetUC_ClientContent.AsnClientContentGroupCluster | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentGroupCluster.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentGroupCluster["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsEditable", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsPersistent", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsTabbed", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sNeighbourClusterID", "Utf8String", errors, newContext);
			const _listgroups = AsnClientContentGroupList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listGroups"), errors, newContext, "listGroups");
			if (_listgroups)
				t.listGroups = _listgroups;
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupCluster"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentGroupClusterList_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentGroupClusterList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupClusterList");

		const t = [] as ENetUC_ClientContent.AsnClientContentGroupClusterList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientContentGroupCluster_Converter.toJSON(se, errors, newContext, "AsnClientContentGroupCluster");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupClusterList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupClusterList");

		let t: ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentGroupClusterList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientContent.AsnClientContentGroupClusterList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientContentGroupCluster_Converter.fromJSON(se, errors, newContext, "AsnClientContentGroupCluster", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupClusterList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentGroupClusterList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentGroupClusterList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientContentGroupCluster_Converter.toBER(s[id], errors, newContext, "AsnClientContentGroupCluster");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnClientContentGroupClusterList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentGroupClusterList");

		let t: ENetUC_ClientContent.AsnClientContentGroupClusterList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentGroupClusterList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientContent.AsnClientContentGroupClusterList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientContentGroupCluster_Converter.fromBER(se, errors, newContext, "AsnClientContentGroupCluster", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientContentGroupClusterList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentStructure_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentStructure, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentStructure & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentStructure");

		const t = {} as ENetUC_ClientContent.AsnClientContentStructure & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentStructure";
		TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRevision", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLastWriter", "string", errors, newContext);
		const _listGroupClusters = AsnClientContentGroupClusterList_Converter.toJSON(s.listGroupClusters, errors, newContext, "listGroupClusters");
		if (_listGroupClusters)
			t.listGroupClusters = _listGroupClusters;

		if (errors.validateResult(newContext, "AsnClientContentStructure"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentStructure | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentStructure");

		let t: ENetUC_ClientContent.AsnClientContentStructure | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentStructure>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentStructure["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sRevision", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sLastWriter", "string", errors, newContext, false);
			const _listgroupclusters = AsnClientContentGroupClusterList_Converter.fromJSON(s.listGroupClusters, errors, newContext, "listGroupClusters", false);
			if (_listgroupclusters)
				t.listGroupClusters = _listgroupclusters;
		}

		if (errors.validateResult(newContext, "AsnClientContentStructure"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentStructure | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentStructure";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentStructure");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iIsEditable", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRevision", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLastWriter", "string", errors, newContext);
		const _listGroupClusters = AsnClientContentGroupClusterList_Converter.toBER(s.listGroupClusters, errors, newContext, "listGroupClusters");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iIsEditable, name: "iIsEditable" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sVersion, name: "u8sVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRevision, name: "u8sRevision" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLastWriter, name: "u8sLastWriter" }));
			if (_listGroupClusters)
				t.push(_listGroupClusters);
		}

		if (errors.validateResult(newContext, "AsnClientContentStructure"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentStructure | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentStructure");

		let t: ENetUC_ClientContent.AsnClientContentStructure | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentStructure.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentStructure["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iIsEditable", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRevision", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLastWriter", "Utf8String", errors, newContext);
			const _listgroupclusters = AsnClientContentGroupClusterList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listGroupClusters"), errors, newContext, "listGroupClusters");
			if (_listgroupclusters)
				t.listGroupClusters = _listgroupclusters;
		}

		if (errors.validateResult(newContext, "AsnClientContentStructure"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetClientContentResult_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnGetClientContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnGetClientContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientContentResult");

		const t = {} as ENetUC_ClientContent.AsnGetClientContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetClientContentResult";
		TSConverter.fillJSONParam(s, t, "u8sContentRevision", "string", errors, newContext);
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.contactList, errors, newContext, "contactList");
		if (_contactList)
			t.contactList = _contactList;
		const _contentstructure = AsnClientContentStructure_Converter.toJSON(s.contentstructure, errors, newContext, "contentstructure");
		if (_contentstructure)
			t.contentstructure = _contentstructure;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnGetClientContentResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientContentResult");

		let t: ENetUC_ClientContent.AsnGetClientContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnGetClientContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnGetClientContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContentRevision", "string", errors, newContext, false);
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.contactList, errors, newContext, "contactList", false);
			if (_contactlist)
				t.contactList = _contactlist;
			const _contentstructure = AsnClientContentStructure_Converter.fromJSON(s.contentstructure, errors, newContext, "contentstructure", false);
			if (_contentstructure)
				t.contentstructure = _contentstructure;
			const _optionalparams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (_optionalparams)
				t.optionalParams = _optionalparams;
		}

		if (errors.validateResult(newContext, "AsnGetClientContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnGetClientContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetClientContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientContentResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContentRevision", "string", errors, newContext);
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.contactList, errors, newContext, "contactList");
		const _contentstructure = AsnClientContentStructure_Converter.toBER(s.contentstructure, errors, newContext, "contentstructure");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContentRevision, name: "u8sContentRevision" }));
			if (_contactList)
				t.push(_contactList);
			if (_contentstructure)
				t.push(_contentstructure);
			if (_optionalParams)
				t.push(_optionalParams);
		}

		if (errors.validateResult(newContext, "AsnGetClientContentResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnGetClientContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientContentResult");

		let t: ENetUC_ClientContent.AsnGetClientContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnGetClientContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnGetClientContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContentRevision", "Utf8String", errors, newContext);
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactList"), errors, newContext, "contactList");
			if (_contactlist)
				t.contactList = _contactlist;
			const _contentstructure = AsnClientContentStructure_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contentstructure"), errors, newContext, "contentstructure");
			if (_contentstructure)
				t.contentstructure = _contentstructure;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnGetClientContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnChangeClientContentArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnChangeClientContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnChangeClientContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnChangeClientContentArgument");

		const t = {} as ENetUC_ClientContent.AsnChangeClientContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnChangeClientContentArgument";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext);
		if (s.addedOrChangedContacts) {
			const _addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts");
			if (_addedOrChangedContacts)
				t.addedOrChangedContacts = _addedOrChangedContacts;
		}
		if (s.removedContacts) {
			const _removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(s.removedContacts, errors, newContext, "removedContacts");
			if (_removedContacts)
				t.removedContacts = _removedContacts;
		}
		if (s.listContentstructure) {
			const _listContentstructure = AsnClientContentGroupClusterList_Converter.toJSON(s.listContentstructure, errors, newContext, "listContentstructure");
			if (_listContentstructure)
				t.listContentstructure = _listContentstructure;
		}
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnChangeClientContentArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnChangeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnChangeClientContentArgument");

		let t: ENetUC_ClientContent.AsnChangeClientContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnChangeClientContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnChangeClientContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iIsEditable", "number", errors, newContext, false);
			const _addedorchangedcontacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts", true);
			if (_addedorchangedcontacts)
				t.addedOrChangedContacts = _addedorchangedcontacts;
			const _removedcontacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(s.removedContacts, errors, newContext, "removedContacts", true);
			if (_removedcontacts)
				t.removedContacts = _removedcontacts;
			const _listcontentstructure = AsnClientContentGroupClusterList_Converter.fromJSON(s.listContentstructure, errors, newContext, "listContentstructure", true);
			if (_listcontentstructure)
				t.listContentstructure = _listcontentstructure;
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnChangeClientContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnChangeClientContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnChangeClientContentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "iIsEditable", "number", errors, newContext);
		const _addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts", 0);
		const _removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toBER(s.removedContacts, errors, newContext, "removedContacts", 1);
		const _listContentstructure = AsnClientContentGroupClusterList_Converter.toBER(s.listContentstructure, errors, newContext, "listContentstructure", 2);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sVersion, name: "u8sVersion" }));
			t.push(new asn1ts.Integer({ value: s.iIsEditable, name: "iIsEditable" }));
			if (_addedOrChangedContacts)
				t.push(_addedOrChangedContacts);
			if (_removedContacts)
				t.push(_removedContacts);
			if (_listContentstructure)
				t.push(_listContentstructure);
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnChangeClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnChangeClientContentArgument");

		let t: ENetUC_ClientContent.AsnChangeClientContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnChangeClientContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnChangeClientContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIsEditable", "Integer", errors, newContext);
			t.addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "addedOrChangedContacts"), errors, newContext, "addedOrChangedContacts", true);
			t.removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "removedContacts"), errors, newContext, "removedContacts", true);
			t.listContentstructure = AsnClientContentGroupClusterList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listContentstructure"), errors, newContext, "listContentstructure", true);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnChangeClientContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientContentChangedArgument_Converter {
	public static toJSON(s: ENetUC_ClientContent.AsnClientContentChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientContent.AsnClientContentChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentChangedArgument");

		const t = {} as ENetUC_ClientContent.AsnClientContentChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientContentChangedArgument";
		TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext);
		if (s.addedOrChangedContacts) {
			const _addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts");
			if (_addedOrChangedContacts)
				t.addedOrChangedContacts = _addedOrChangedContacts;
		}
		if (s.removedContacts) {
			const _removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(s.removedContacts, errors, newContext, "removedContacts");
			if (_removedContacts)
				t.removedContacts = _removedContacts;
		}
		if (s.listContentstructure) {
			const _listContentstructure = AsnClientContentGroupClusterList_Converter.toJSON(s.listContentstructure, errors, newContext, "listContentstructure");
			if (_listContentstructure)
				t.listContentstructure = _listContentstructure;
		}
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientContentChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentChangedArgument");

		let t: ENetUC_ClientContent.AsnClientContentChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientContent.AsnClientContentChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientContent.AsnClientContentChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRevision", "number", errors, newContext, false);
			const _addedorchangedcontacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts", true);
			if (_addedorchangedcontacts)
				t.addedOrChangedContacts = _addedorchangedcontacts;
			const _removedcontacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(s.removedContacts, errors, newContext, "removedContacts", true);
			if (_removedcontacts)
				t.removedContacts = _removedcontacts;
			const _listcontentstructure = AsnClientContentGroupClusterList_Converter.fromJSON(s.listContentstructure, errors, newContext, "listContentstructure", true);
			if (_listcontentstructure)
				t.listContentstructure = _listcontentstructure;
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnClientContentChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientContent.AsnClientContentChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientContentChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientContentChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRevision", "number", errors, newContext);
		const _addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.addedOrChangedContacts, errors, newContext, "addedOrChangedContacts", 0);
		const _removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toBER(s.removedContacts, errors, newContext, "removedContacts", 1);
		const _listContentstructure = AsnClientContentGroupClusterList_Converter.toBER(s.listContentstructure, errors, newContext, "listContentstructure", 2);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iRevision, name: "iRevision" }));
			if (_addedOrChangedContacts)
				t.push(_addedOrChangedContacts);
			if (_removedContacts)
				t.push(_removedContacts);
			if (_listContentstructure)
				t.push(_listContentstructure);
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
		}

		if (errors.validateResult(newContext, "AsnClientContentChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientContent.AsnClientContentChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientContentChangedArgument");

		let t: ENetUC_ClientContent.AsnClientContentChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientContent.AsnClientContentChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientContent.AsnClientContentChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRevision", "Integer", errors, newContext);
			t.addedOrChangedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "addedOrChangedContacts"), errors, newContext, "addedOrChangedContacts", true);
			t.removedContacts = ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "removedContacts"), errors, newContext, "removedContacts", true);
			t.listContentstructure = AsnClientContentGroupClusterList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listContentstructure"), errors, newContext, "listContentstructure", true);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientContentChangedArgument"))
			return t;

		return undefined;
	}
}
