import { EError, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { theLogger } from "../globals";
import { generateGUID } from "./commonHelper";
import { EAsnLogonErrorEnumEX } from "./ErrorInfoHelper";
import SessionStorageHelper from "./SessionStorageHelper";

export interface ILoginOauthRedirect {
	redirect: string;
}

// Result of the get UC Proxy request
interface IGetUCWS {
	redirect: string;
}

/**
 * UCCHelper class
 */
export default class UCCHelper {
	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public static getLogData(): ILogData {
		return {
			className: "UCCHelper"
		};
	}

	/**
	 * Get login challenges from ucserver
	 * @param ucServerURI - ucserver uri
	 * @param ucsID - ucsid
	 * @returns - error of type EAsnLogonErrorEnumEX or undefined
	 */
	public static async getLoginChallenges(ucServerURI: string, ucsID?: string): Promise<number | string[]> {
		const url = ucServerURI + "/ws/client/createsession";
		const headers: Record<string, string> = {
			"x-epid": generateGUID(),
			"access-control-request-headers": "WWW-Authenticate",
			"Content-Type": "application/json"
		};

		if (ucsID) headers["x-ucsid"] = ucsID;

		const options = {
			method: "POST",
			headers
		};

		try {
			const sessionResponse = await fetch(url, options);
			const auth = sessionResponse.headers.get("WWW-Authenticate");

			if (!auth) return EAsnLogonErrorEnumEX.exFetchError;

			const splitByBasic = auth.split(/(?=Basic)/g);

			const matchScopesRegex = /(?<=scope=")(.*?)(?=")/gm;
			const allAuthChallenges: string[] = [];
			for (const basic of splitByBasic) {
				if (basic.search("Basic realm") === -1) continue;
				const scopesMatches = [...basic.matchAll(matchScopesRegex)];
				if (scopesMatches.length === 0) allAuthChallenges.push("basic");
				const scopes = Array.from(scopesMatches.map((item) => item[0]));
				allAuthChallenges.push(...scopes);
			}

			theLogger.info("Login challenges found", "getLoginChallenges", this.getLogData(), {
				challenges: allAuthChallenges
			});
			return allAuthChallenges;
		} catch (e) {
			theLogger.error("Error fetching login challenges", "getLoginChallenges", this, { e });
			return EAsnLogonErrorEnumEX.exFetchError;
		}
	}

	/**
	 * Get getEntraIdLink
	 * This must be called after getLoginChances
	 * @param ucServerURI - ucserver uri
	 * @param ucsID - ucsid
	 * @returns - error or undefined
	 */
	public static async getEntraIdLink(ucServerURI: string, ucsID?: string): Promise<number | string> {
		const url = ucServerURI + "/ws/client/createsession?format=json";
		const headers = new Headers();
		headers.set("Authorization", `Basic realm="${ucsID}",scope="entraid"`);
		if (ucsID) headers.set("x-ucsid", ucsID);
		try {
			const response = await fetch(url, { method: "GET", headers, redirect: "manual" });
			const json = (await response.json()) as unknown as ILoginOauthRedirect;
			if (json.redirect) {
				if (ucsID)
					// using ucconnect
					SessionStorageHelper.setEntraIdLoginUCSID(ucsID);
				// using local server
				else SessionStorageHelper.setEntraIdLoginUCServerURI(ucServerURI);
				return `${json.redirect}&redirect_uri=${encodeURIComponent(window.location.origin)}&response_mode=fragment`;
			}
			return EAsnLogonErrorEnumEX.exOAuthEntraIdLinkNotAvailable;
		} catch (e) {
			theLogger.error("Error fetching EntraId Link", "getEntraIdLink", this, { e });
			return EAsnLogonErrorEnumEX.exFetchError;
		}
	}

	/**
	 * Gets the URI of UCServer from UCController.
	 * @param ucsid - the ucsid
	 * @param uccontrollerUrl - the uccontroller endpoint
	 * @returns - a promise with a string containing the URI, or an error of type EAsnLogonErrorEnumEX
	 */
	public static async getUriFromUCC(ucsid: string, uccontrollerUrl?: URL): Promise<string | number> {
		if (!uccontrollerUrl) {
			theLogger.error(
				"You need to setup the uccontroller URL",
				"getUriFromUCC",
				this.getLogData(),
				{},
				EError.InvalidUCControllerURL
			);
			return EAsnLogonErrorEnumEX.exInvalidUCControllerURL;
		}
		const url = new URL(uccontrollerUrl);
		url.pathname += "controller/client/ucws";
		url.search = "?ucsid=" + ucsid + "&needswcs=1";

		try {
			const getUcwebUrlResponse = await fetch(url, { cache: "no-store" });
			const ucwebUrlData = (await getUcwebUrlResponse.json()) as IGetUCWS;
			if (ucwebUrlData && ucwebUrlData.redirect) return ucwebUrlData.redirect;
			else {
				theLogger.error("Invalid ucsid", "getUriFromUCC", this.getLogData(), { ucwebUrlData }, EError.InvalidUCSID);
				return EAsnLogonErrorEnumEX.exInvalidUcsidFromUCController;
			}
		} catch (e) {
			theLogger.error("Fetch error", "getUriFromUCC", this.getLogData(), { e }, EError.FetchFailed);
			return EAsnLogonErrorEnumEX.exFetchError;
		}
	}
}
