import { IAsnNetDatabaseContact } from "../serializable/IAsnNetDatabaseContact";
import { AsnNetDatabaseContact } from "../../asn1/EUCSrv/stubs/ENetUC_Common";
import { stringPairListConverter } from "./stringPairConverter";
import { bufferToBase64 } from "./helpers";

/**
 * the netDatabaseContactConverter
 * @param asnNetDatabaseContact - an AsnNetDatabaseContact object
 * @returns an IAsnNetDatabaseContact as promise or undefined
 */
export async function netDatabaseContactConverter(asnNetDatabaseContact?: AsnNetDatabaseContact): Promise<IAsnNetDatabaseContact | undefined> {
	if (!asnNetDatabaseContact)
		return undefined;
	let jpegPhoto;
	let jpegPhotoHash;
	if (asnNetDatabaseContact.optionalParams) {
		if (!Array.isArray(asnNetDatabaseContact.optionalParams)) {
			for (const [key, value] of Object.entries(asnNetDatabaseContact.optionalParams)) {
				if (key === "jpegPhoto") {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const binaryData = value.binarydata;
					if (typeof binaryData === "string")
						jpegPhoto = binaryData;
					else
						jpegPhoto = await bufferToBase64(binaryData);
					break;
				}
			}
		} else {
			for (const param of Object.entries(asnNetDatabaseContact.optionalParams)) {
				const optionalParam = param[1];
				if (optionalParam.key === "jpegPhoto") {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const binaryData = optionalParam.value.binarydata;
					if (typeof binaryData === "string")
						jpegPhoto = binaryData;
					else
						jpegPhoto = await bufferToBase64(binaryData);
				}
				if (optionalParam.key === "jpegPhotoHash") {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const binaryData = optionalParam.value.binarydata;
					if (typeof binaryData === "string")
						jpegPhotoHash = binaryData;
					else
						jpegPhotoHash = await bufferToBase64(binaryData);
				}
			}
		}
	}
	return {
		u8sFound: asnNetDatabaseContact.u8sFound,
		u8sDatabaseName: asnNetDatabaseContact.u8sDatabaseName,
		u8sEntryIDDB: asnNetDatabaseContact.u8sEntryIDDB,
		u8sEntryIDStore: asnNetDatabaseContact.u8sEntryIDStore,
		u8sEntryID: asnNetDatabaseContact.u8sEntryID,
		u8sCtiServerUserName: asnNetDatabaseContact.u8sCtiServerUserName,
		u8sDisplayName: asnNetDatabaseContact.u8sDisplayName,
		u8sFirstName: asnNetDatabaseContact.u8sFirstName,
		u8sLastName: asnNetDatabaseContact.u8sLastName,
		u8sJobTitle: asnNetDatabaseContact.u8sJobTitle,
		u8sCompany: asnNetDatabaseContact.u8sCompany,
		u8sDepartment: asnNetDatabaseContact.u8sDepartment,
		u8sOfficeRoomNumber: asnNetDatabaseContact.u8sOfficeRoomNumber,
		u8sCustomerID: asnNetDatabaseContact.u8sCustomerID,
		u8sBusinessAddressStreet: asnNetDatabaseContact.u8sBusinessAddressStreet,
		u8sBusinessAddressPostalCode: asnNetDatabaseContact.u8sBusinessAddressPostalCode,
		u8sBusinessAddressCity: asnNetDatabaseContact.u8sBusinessAddressCity,
		u8sBusinessAddressState: asnNetDatabaseContact.u8sBusinessAddressState,
		u8sBusinessAddressCountry: asnNetDatabaseContact.u8sBusinessAddressCountry,
		u8sPrivateAddressStreet: asnNetDatabaseContact.u8sPrivateAddressStreet,
		u8sPrivateAddressPostalCode: asnNetDatabaseContact.u8sPrivateAddressPostalCode,
		u8sPrivateAddressCity: asnNetDatabaseContact.u8sPrivateAddressCity,
		u8sPrivateAddressState: asnNetDatabaseContact.u8sPrivateAddressState,
		u8sPrivateAddressCountry: asnNetDatabaseContact.u8sPrivateAddressCountry,
		u8sOtherAddressStreet: asnNetDatabaseContact.u8sOtherAddressStreet,
		u8sOtherAddressPostalCode: asnNetDatabaseContact.u8sOtherAddressPostalCode,
		u8sOtherAddressCity: asnNetDatabaseContact.u8sOtherAddressCity,
		u8sOtherAddressState: asnNetDatabaseContact.u8sOtherAddressState,
		u8sOtherAddressCountry: asnNetDatabaseContact.u8sOtherAddressCountry,
		u8sEMail: asnNetDatabaseContact.u8sEMail,
		u8sEMail2: asnNetDatabaseContact.u8sEMail2,
		u8sEMail3: asnNetDatabaseContact.u8sEMail3,
		u8sSIPAddress: asnNetDatabaseContact.u8sSIPAddress,
		u8sWebPageURL: asnNetDatabaseContact.u8sWebPageURL,
		u8sPhoneBusiness: asnNetDatabaseContact.u8sPhoneBusiness,
		u8sPhoneBusiness2: asnNetDatabaseContact.u8sPhoneBusiness2,
		u8sCompanyMainTelephoneNumber: asnNetDatabaseContact.u8sCompanyMainTelephoneNumber,
		u8sAssistantTelephoneNumber: asnNetDatabaseContact.u8sAssistantTelephoneNumber,
		u8sPhoneHome: asnNetDatabaseContact.u8sPhoneHome,
		u8sPhoneHome2: asnNetDatabaseContact.u8sPhoneHome2,
		u8sPrimaryTelephoneNumber: asnNetDatabaseContact.u8sPrimaryTelephoneNumber,
		u8sPhoneMobile: asnNetDatabaseContact.u8sPhoneMobile,
		u8sCarTelephoneNumber: asnNetDatabaseContact.u8sCarTelephoneNumber,
		u8sRadioTelephoneNumber: asnNetDatabaseContact.u8sRadioTelephoneNumber,
		u8sPagerTelephoneNumber: asnNetDatabaseContact.u8sPagerTelephoneNumber,
		u8sOtherTelephoneNumber: asnNetDatabaseContact.u8sOtherTelephoneNumber,
		u8sCallbackTelephoneNumber: asnNetDatabaseContact.u8sCallbackTelephoneNumber,
		u8sISDNTelephoneNumber: asnNetDatabaseContact.u8sISDNTelephoneNumber,
		u8sTTYTTDTelephoneNumber: asnNetDatabaseContact.u8sTTYTTDTelephoneNumber,
		u8sFaxBusiness: asnNetDatabaseContact.u8sFaxBusiness,
		u8sFaxHome: asnNetDatabaseContact.u8sFaxHome,
		/** also called \"note\" */
		u8sBody: asnNetDatabaseContact.u8sBody,
		u8sDirectWebLink: asnNetDatabaseContact.u8sDirectWebLink,
		/** additional fields, mostly filled with runtime information */
		customFields: stringPairListConverter(asnNetDatabaseContact.customFields),
		bValid: asnNetDatabaseContact.bValid,
		iPrivateContact: asnNetDatabaseContact.iPrivateContact,
		iCtiServerUser: asnNetDatabaseContact.iCtiServerUser,
		// optionalParams
		jpegPhoto,
		jpegPhotoHash
	};
}

/**
 * the netDatabaseContactListConverter
 * @param asnNetDatabaseContactList - an array of AsnNetDatabaseContact
 * @returns an array of IAsnNetDatabaseContact as promise or undefined
 */
export async function netDatabaseContactListConverter(asnNetDatabaseContactList?: AsnNetDatabaseContact[]): Promise<IAsnNetDatabaseContact[] | undefined> {
	if (!asnNetDatabaseContactList)
		return undefined;
	const convertedList: IAsnNetDatabaseContact[] = [];
	for (const contact of asnNetDatabaseContactList) {
		const convertedContact = await netDatabaseContactConverter(contact);
		if (convertedContact)
			convertedList.push(convertedContact);
	}
	return convertedList;
}
