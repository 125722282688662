import { AsnClientCapabilitiesV2, AsnClientCapabilityEnum } from "../asn1/EUCSrv/stubs/ENetUC_ClientCapabilities";

/**
 * Interface to represent a set of booleans out of AsnClientCapabilityEnum array
 * Here we list only the parameters that are relevant for the web applications.
 * A parameter name always continues the sentence starting with "can"
 * For example "client can handle audio", "client can share screen", etc
 */
export interface IClientCapabilities {
	handleAudio: boolean;
	handleVideo: boolean;
	shareScreen: boolean;
}

/**
 * Get client capabilities from the AsnClientCapabilitiesV2 (AsnClientCapabilityEnum array)
 * @param asnClientCapabilities - the AsnClientCapabilitiesV2
 * @returns - a IClientCapabilities interface
 */
export function getClientCapabilitiesFromAsn(asnClientCapabilities?: AsnClientCapabilitiesV2): IClientCapabilities {
	return {
		handleAudio: asnClientCapabilities?.eClientCapabilities.includes(AsnClientCapabilityEnum.bAudio) ?? false,
		handleVideo: asnClientCapabilities?.eClientCapabilities.includes(AsnClientCapabilityEnum.bVideo) ?? false,
		shareScreen: asnClientCapabilities?.eClientCapabilities.includes(AsnClientCapabilityEnum.bScreenShare) ?? false
	};
}

/**
 * Converts the capabilities from IClientCapabilities to AsnClientCapabilityEnum array
 * @param capabilities - the capabilities to convert
 * @returns - a AsnClientCapabilityEnum array
 */
export function convertClientCapabilitiesToAsn(capabilities: IClientCapabilities): AsnClientCapabilityEnum[] {
	const capabilitiesArray: AsnClientCapabilityEnum[] = [];
	if (capabilities.handleAudio)
		capabilitiesArray.push(AsnClientCapabilityEnum.bAudio);
	if (capabilities.handleVideo)
		capabilitiesArray.push(AsnClientCapabilityEnum.bVideo);
	if (capabilities.shareScreen)
		capabilitiesArray.push(AsnClientCapabilityEnum.bScreenShare);
	return capabilitiesArray;
}
