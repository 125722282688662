import { AsnUserStaticRights, AsnUserStaticRights2 } from "../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";

/**
 * Interface to represent a set of booleans out of
 * AsnUserStaticRights and AsnUserStaticRights2 bitmaps
 * Here we list only the parameters that are relevant for the web applications.
 * A parameter name always continues the sentence starting with "can"
 * For example "user can use CTI", "user can view Journal", etc
 */
export interface IUserStaticRights {
	// From AsnUserStaticRights //
	/** 0x00000001 - User may use text chat. */
	sendChat: boolean;
	/** 0x00080000 - Journal\/History functions are available for the user; if not, acces to the journal database is not available for this user. */
	viewJournal: boolean;
	/** 0x00100000 - Every user of the system may delete Journal\/History entries. */
	deleteJournal: boolean;
	/** 0x02000000 - User may use audio communication features without video. */
	startAudioChatOrSIP: boolean;
	/** 0x04000000 - User may use audio and video communication features. */
	startVideoChat: boolean;
	/** 0x00800000 - User may accept or initiate desktop sharing session. */
	startDesktopSharing: boolean;
	// From AsnUserStaticRights2 //
	/** 0x00000200 - User is allowed to send binary data via the BinaryTransfer interface (default: allowed) */
	sendFilesViaBinaryTransfer: boolean;
	/** 0x00001000 - User may use conferencing functionality - (default: off - needs seperate license!) */
	useProCallMeetings: boolean;
	/** 0x00002000 - User may use MS Teams App */
	useTeams: boolean;
	/** 0x00008000 - User may use ProCall WebApp (PWA) */
	useWebApp: boolean;
}

/**
 * Helper to convert from bitflags into IUserStaticRights interface
 *
 * @param asnUserStaticRights - the AsnUserStaticRights
 * @param asnUserStaticRights2 - the AsnUserStaticRights2
 * @returns - a IUserStaticRights interface
 */
export function getUserStaticRights(asnUserStaticRights?: AsnUserStaticRights, asnUserStaticRights2?: AsnUserStaticRights2): IUserStaticRights {
	return {
		// From AsnUserStaticRights //
		/** 0x00000001 - User may use text chat. */
		sendChat: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSSENDUSERUSER),
		/** 0x00080000 - Journal\/History functions are available for the user; if not, acces to the journal database is not available for this user. */
		viewJournal: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSUSERVIEWJOURNAL),
		/** 0x00100000 - Every user of the system may delete Journal\/History entries. */
		deleteJournal: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSGLOBALDELETEJOURNAL),
		/** 0x02000000 - User may use audio communication features without video. */
		startAudioChatOrSIP: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSAVAUDIO),
		/** 0x04000000 - User may use audio and video communication features. */
		startVideoChat: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSAVVIDEO),
		/** 0x00800000 - User may accept or initiate desktop sharing session. */
		startDesktopSharing: asnUserStaticRights !== undefined && !!(asnUserStaticRights & AsnUserStaticRights.eUSERRIGHTSDESKSHAREFULL),
		// From AsnUserStaticRights2 //
		/** 0x00000200 - User is allowed to send binary data via the BinaryTransfer interface (default: allowed) */
		sendFilesViaBinaryTransfer: asnUserStaticRights2 !== undefined && !!(asnUserStaticRights2 & AsnUserStaticRights2.eUSERRIGHTS2ENABLECONTENTSHARINGFILES),
		/** 0x00001000 - User may use conferencing functionality - (default: off - needs seperate license!) */
		useProCallMeetings: asnUserStaticRights2 !== undefined && !!(asnUserStaticRights2 & AsnUserStaticRights2.eUSERRIGHTS2USECONFERENCING),
		/** 0x00002000 - User may use MS Teams App */
		useTeams: asnUserStaticRights2 !== undefined && !!(asnUserStaticRights2 & AsnUserStaticRights2.eUSERRIGHTS2USETEAMS),
		/** 0x00008000 - User may use ProCall WebApp (PWA) */
		useWebApp: asnUserStaticRights2 !== undefined && !!(asnUserStaticRights2 & AsnUserStaticRights2.eUSERRIGHTS2USEWEBAPP)
	};
}
